import { css } from 'styled-components';

export const input = css`
  &[type='text'],
  &[type='password'],
  &[type='email'] {
    font-family: ${({ theme }) => theme.fontfamily.mulish};
    font-size: ${({ theme }) => theme.fontsize.desktop.input};
    padding: 0 ${({ theme }) => theme.spacing.x1};
    color: ${({ theme }) => theme.palette.textOffblack};
    background-color: ${({ theme }) => theme.palette.backgroundGrey};
    border: 1px solid ${({ theme }) => theme.palette.backgroundStroke};
    outline: 0;
    border-radius: ${({ theme }) => theme.borderradius.medium};
    appearance: none;

    @media (max-width: 767.98px) {
      font-size: ${({ theme }) => theme.fontsize.mobile.input};
    }

    ::-ms-clear {
      display: none;
    }

    ::placeholder {
      color: ${({ theme }) => theme.palette.textPlaceholder};
      font-weight: ${({ theme }) => theme.fontweight.regular};
      opacity: 1;
    }

    &:hover {
      border-color: ${({ theme }) => theme.palette.backgroundStrokeHover};
    }

    &:active,
    &:focus {
      border-color: ${({ theme }) => theme.palette.purplePrimary};
      background-color: ${({ theme }) => theme.palette.backgroundWhite};
    }
  }

  &[type='checkbox'] {
    cursor: pointer;
    position: relative;
    appearance: none;
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    border: 1px solid ${({ theme }) => theme.palette.textPlaceholder};
    border-radius: 25%;
    outline: none;
    padding: 0;
    margin: 0;

    &:hover ~ label {
      color: ${({ theme }) => theme.palette.textOffblack};
      font-weight: ${({ theme }) => theme.fontweight.semibold};
    }

    &:hover {
      border-color: ${({ theme }) => theme.palette.textOffblack};
    }

    /** The new check symbol inside the checkbox - created using border */
    &::before {
      content: '';
      position: absolute;
      left: 4px;
      top: 1px;
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }

    /** Change the color of the checkbox background once checked */
    &:checked {
      background: ${({ theme }) => theme.palette.textOffblack};
      border-color: ${({ theme }) => theme.palette.textOffblack};
    }

    /** Change the label color of the dot once checked */
    &:checked ~ label {
      font-weight: ${({ theme }) => theme.fontweight.semibold};
      color: ${({ theme }) => theme.palette.textOffblack};
    }
  }
  &[type='radio'] {
    cursor: pointer;
    position: relative;
    appearance: none;
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    border: 1px solid ${({ theme }) => theme.palette.textPlaceholder};
    border-radius: 25%;
    outline: none;
    padding: 0;
    margin: 0;

    &:hover ~ label {
      color: ${({ theme }) => theme.palette.textOffblack};
      font-weight: ${({ theme }) => theme.fontweight.semibold};
    }

    &:hover {
      border-color: ${({ theme }) => theme.palette.textOffblack};
    }

    /** The new check symbol inside the checkbox - created using border */
    &::before {
      content: '';
      position: absolute;
      left: 4px;
      top: 1px;
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }

    /** Change the color of the checkbox background once checked */
    &:checked {
      background: ${({ theme }) => theme.palette.textOffblack};
      border-color: ${({ theme }) => theme.palette.textOffblack};
    }

    /** Change the label color of the dot once checked */
    &:checked ~ label {
      font-weight: ${({ theme }) => theme.fontweight.semibold};
      color: ${({ theme }) => theme.palette.textOffblack};
    }
  }


`;
