import React, {useContext, useEffect, useState,useCallback,useRef} from 'react';
import { Button, Col, Container,Row } from 'react-bootstrap';
import AnnotationWrapperCard from '../annotation-wrapper-card/index.js';

const AnnotationsCard = (props) => {
  const AnnotationsRef = useRef(null);

  // useEffect(() => {
  //   console.log('9',props.elementToScroll);

  //   if(typeof props.elementToScroll=== "string"){
  //     const listNode = AnnotationsRef.current;
  //     const annotationCardNode = listNode.querySelectorAll(props.elementToScroll);
  //     if(annotationCardNode && annotationCardNode.length>0 ){
  //       annotationCardNode[0].scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'center',
  //         inline: 'center'
  //       });
  //       props.setElementToScroll(undefined);
  //     }
  //     else{
  //       console.log('23',annotationCardNode);
  //       console.log('24',listNode.querySelectorAll(props.elementToScroll));
  //     }
  //   }

  // }, []);

  // useEffect(() => {
  //   console.log('31',props.elementToScroll);
  //   if(typeof props.elementToScroll=== "string"){
  //     const listNode = AnnotationsRef.current;
  //     const annotationCardNode = listNode.querySelectorAll(props.elementToScroll);
  //     if(annotationCardNode && annotationCardNode.length>0 ){
  //       annotationCardNode[0].scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'center',
  //         inline: 'center'
  //       });
  //       props.setElementToScroll(undefined);
  //     }
  //     else{
  //       console.log('44',annotationCardNode);
  //       console.log('45',listNode.querySelectorAll(props.elementToScroll));
  //     }
  //   }
  // }, [props.elementToScroll])
  
  

  return (
    <div ref= {AnnotationsRef}>
    {props.listAnnotations.map((annotation,index) => {
      return (
      <>
      <Row>
      <AnnotationWrapperCard
          elementToScroll={props.elementToScroll}
          setElementToScroll={props.setElementToScroll}
          keyToScroll= {props.keyToScroll}
          setKeyToScroll={props.setKeyToScroll}
          annotation_id = {annotation[0]}
          annotation = {annotation[1]}
          i ={index}></AnnotationWrapperCard>
      </Row>
      </>
      )})
    }
    </div>
  );
};

export default AnnotationsCard;
