import React, {useContext, useEffect, useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Styles from './styles';
import { ReactComponent as ErrorImage } from '../../assets/svgs/error-image.svg';
import Header from '../../components/header';
import { H2, P } from '../../components/theme/typography';
import Footer from '../../components/footer';
import CantFindFeedback from '../../components/cant-find-feedback';
import {getUser, removeUserSession,setUserSession,setOrganisationSession} from '../../functions/manageSessions';
import { AppContext } from '../../context';
import axios from 'axios';
import ConfirmLogin from '../../components/confirmLogin';
import { IOSView } from 'react-device-detect';
import LoadingIndicator from '../../components/loading-indicator';
import { ErrorMessage } from '../../components/form/styles';

const AliasCheckPage = ({ history, location,match }) => {

  const { 
    setRequestTrackerContext,
    requestTrackerContext,
    userOrganisationContext,
    setUserStats,
    setUserContext,
    setUserContextFilters,
    setRequestFilters,
    removeUserContextFilters,
  } = useContext(
    AppContext
  ); 
   const [requestCheckAlias, setRequestCheckAlias] = useState(false);
   const [alias, setAlias] = useState(undefined);
   const [reload,setReload] = useState(false);
   const [askToLogout, setAskToLogout] = useState(false);
   const [organisationName, setOrganisationName] = useState('');
   const [ssoLink, setSsoLink] = useState('');
   const [magicCode, setMagicCode] = useState('');
   const [errorMsg, setErrorMsg] = useState('');




   const request_magic_login = (code)=>{
    setRequestTrackerContext(prev => { return {...prev, 'login-magic-user':true}});
    axios({
      method: 'POST',
      url:`${window.BASE_URL_USER}login-magic-user`,
      withCredentials: true,
      headers: {'Content-Type': 'application/json' },
      data: JSON.stringify({'magic-code': code }),
    })
      .then((res) => {
        setRequestTrackerContext(prev => { return {...prev, 'login-magic-user':false}});
        if (res.data.success && res.data.user) {
          let user_info = res.data.user;
          // no need to call orgInfo context should be in charge of that 
          // if(user_info['org_name']){
          //   requestOrgInfo();
          // }
          // do not remove user 
          setReload(true);
          // setUserContextFilters(undefined);
          removeUserContextFilters();
          setRequestFilters(true);
          setUserContext(user_info);
          // TO DO : also get filters and sources you are erasing them 
          // setUserSession(user_info);
          setUserStats(undefined);
          // window.location =  window.BASE_API_URL;
        }
        if(res.data && res.data.code === 404){
          setErrorMsg ("Error");
          if(res.data.description){
            setErrorMsg (res.data.description);
          }
        }
      })
      .catch((error) => {
        console.log('error');
        setRequestTrackerContext(prev => { return {...prev, 'login-magic-user':false}});
        if(error && error.response && error.response.data && error.response.data.description){
          console.log('error',error.response.data.description);
        }
        setErrorMsg ("Error");
        if(error && error.response && error.response.code === "404"){
          setErrorMsg ("error 404 ");
          if(error.description){
            setErrorMsg (error.response.data.description);
          }
        }
        // console.log(error);
      });
  };

   const checkAlias = (aliasName) => {
    setRequestTrackerContext(prev => { return {...prev, 'check-alias':true}});
    axios({
      method: 'post',
      url:`${window.BASE_URL_USER}check-alias`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        alias: aliasName,
      }),
    })
      .then((res) => {
        if(res.data &&res.data.code_or_url && res.data.code_or_url !== null  ){
          let userInfo = getUser();

          if(res.data.type && res.data.type === 'magic'){
            setMagicCode(res.data.code_or_url);
            //res.data.user_in_aliased_org is always false since we are reddirect bc alias 
            if(userInfo && !userInfo['is_anonymous'] && !res.data.user_in_aliased_org)
            {
              if(userInfo['org_name']){
                setOrganisationName(userInfo['org_name']);
              }
              setAskToLogout(true);
            }
            else{
              request_magic_login(res.data.code_or_url);
            }
          }
          if(res.data.type && res.data.type === 'sso'){

            setSsoLink(res.data.code_or_url);
            if(userInfo && !res.data.user_in_aliased_org ){
              if (userInfo['org_name']){
                setOrganisationName(userInfo['org_name']);}
                setAskToLogout(true);
            }
            else{
              if(res.data.user_in_aliased_org){
                history.push('/');
              }
              window.open(res.data.code_or_url,'_self');
            }
          }
        }
        setRequestTrackerContext(prev => { return {...prev, 'check-alias':false}});
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'check-alias':false}});
        console.log("not such alias");
        console.log(error);
      });
  }

  useEffect(() => {

    if ( match && match.params &&  match.params.alias ) { 
      if (match.params.alias !== '' && (!userOrganisationContext 
            || (userOrganisationContext && Object.hasOwn(userOrganisationContext,'alias') &&  match.params.alias !== userOrganisationContext['alias'])) 
        ) {
        // let dirtyAlias =location.pathname.split('/')[1];
        setAlias(match.params.alias);
        if(requestTrackerContext && Object.hasOwn(requestTrackerContext,'logout-user') && requestTrackerContext['logout-user']){
          setRequestCheckAlias(false);// this will avoid the mobile bug that triggers history action when clicking logout
        }
        else{
          setRequestCheckAlias(true);
        }
        // setRequestCheckAlias(true);
      }
    }
  }, [match]);

  useEffect(() => {
    if (requestCheckAlias && typeof alias ==='string' && alias!== '' ){
      checkAlias(alias);
      setRequestCheckAlias(prev => false);
    }
  }, [requestCheckAlias ]);

  useEffect(() => {
    // for automatic login 
    // once we are in the org we reload
    if(reload){
      setReload(false);
      history.go(0);
    }

    
  }, [userOrganisationContext ]);
  
  useEffect(()=>{
    removeHrTag();
    setRequestTrackerContext(prev => { return {...prev, 'check-alias':false}});
  },[]);

  
  const goToSsoLink = ()=>{
    setRequestTrackerContext(prev => { return {...prev, 'check-alias':true}});
    window.open(ssoLink,'_self');
  };

  const removeHrTag = () => {
    let myElement = document.getElementsByTagName('body')[0];
    for (let i = 0; i < myElement.children.length; i++) {
      if( myElement.children[i].tagName === 'HR' 
          || myElement.children[i].tagName === 'ADDRESS'
           || myElement.children[i].tagName === "address" ){
        myElement.children[i].remove();
      }
    }
  };
  

  return (
    <Styles>
      <Header withSearchBar={true} withAccountButtons={true} />
      { !askToLogout &&  (requestTrackerContext['login-magic-user']  || requestTrackerContext['check-alias']) && (
      <main>
        <Container>
          <Row className=' justify-content-md-center  align-self-center' >
            <Col className='m-5'  >
            <svg width="100%" height="50px" viewBox="0 0 105 105" fill="#8771DF" aria-label="audio-loading"><circle cx="12.5" cy="12.5" r="12.5"><animate attributeName="fill-opacity" begin="0s" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="12.5" cy="52.5" r="12.5"><animate attributeName="fill-opacity" begin="100ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="52.5" cy="12.5" r="12.5"><animate attributeName="fill-opacity" begin="300ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="52.5" cy="52.5" r="12.5"><animate attributeName="fill-opacity" begin="600ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="92.5" cy="12.5" r="12.5"><animate attributeName="fill-opacity" begin="800ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="92.5" cy="52.5" r="12.5"><animate attributeName="fill-opacity" begin="400ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="12.5" cy="92.5" r="12.5"><animate attributeName="fill-opacity" begin="700ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="52.5" cy="92.5" r="12.5"><animate attributeName="fill-opacity" begin="500ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="92.5" cy="92.5" r="12.5"><animate attributeName="fill-opacity" begin="200ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle></svg>

            </Col>

          </Row>
        </Container>
      </main>
      )}
      {/* { !askToLogout && errorMsg && errorMsg !=="" && ! (requestTrackerContext['login-magic-user']  || requestTrackerContext['check-alias'])s &&  (
        <main>
        <Container>
          <Row className=' justify-content-md-center  align-self-center' >
            <Col className='m-5'  >
                <ErrorMessage  h2 regular colorError > {errorMsg} </ErrorMessage>
            </Col>
          </Row>
        </Container>
      </main>
      )} */}
      { askToLogout && ! (requestTrackerContext['login-magic-user']  || requestTrackerContext['check-alias'])&& ssoLink &&  (
        <ConfirmLogin organisation={organisationName} login={goToSsoLink} ></ConfirmLogin>
      )}
      { askToLogout && ! (requestTrackerContext['login-magic-user']  || requestTrackerContext['check-alias']) && !ssoLink &&  (
        <ConfirmLogin organisation={organisationName} magicLogin={request_magic_login} magicCode={magicCode} login={false} ></ConfirmLogin>
      )}
      <Footer sticky={ true} />

    </Styles>
  );
};

export default AliasCheckPage;
