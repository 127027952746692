import React from 'react';
import styled from 'styled-components';
import { sources_typewriter } from '../../functions/frontend_variables';

import Typewriter from 'typewriter-effect';


export default function TypewriterAnimation(){

    return(
        <Wrapper>
            <Typewriter options={{ 
            strings: sources_typewriter,
            autoStart: true,
            delay:50,
            pauseFor: 500,
            loop: true,
            skipAddStyles: false,
            }}/>
            </Wrapper>
    )
}


const Wrapper = styled.span`
display: inline-block;
text-align: left;
span{
    text-align: left;
    opacity: 1;
}
`
