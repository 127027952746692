import styled, { css } from 'styled-components';
import { linkStyles } from '../theme/link';
import { P, Span, Label } from '../theme/typography';

export const Suggestions = styled.div`
  width: 100%;
  font-family: Mulish,sans-serif;
  font-size: 14px;
  padding: 0 8px;
  color: #212452;
  background-color: #FBFBFD;
  border: 1px solid #E2E5ED;
  outline: 0;
  border-radius: 3px;

  .ac {
    @media (max-width: 767.98px) {
      border: 0px solid ${({ theme }) => theme.palette.backgroundStroke};
    }

    border-top: 0;
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
    
    margin: 0 auto;
    position: absolute;
    top: 48px;
    background-color: ${({ theme }) => theme.palette.backgroundWhite};
    z-index: 999;

    &::-webkit-scrollbar {
      display: none;
    }

    &_list {
      
      &--item {
        
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.palette.textOffblack};
        padding:0px ;
        cursor: default;

        @media (max-width: 767.98px) {
          padding: 12px ${({ theme }) => theme.spacing.x5};
        }

        b {
          font-weight: ${({ theme }) => theme.fontweight.semibold};
        }

        &.active {
          text-decoration: none;
          background-color: ${({ theme }) =>
            theme.palette.backgroundDropdownHover};
        }

        &--magnify {
          display: inline-flex;
          color: ${({ theme }) => theme.palette.textPlaceholder};
          margin-right: ${({ theme }) => theme.spacing.x2};

          @media (max-width: 767.98px) {
            display: none;
          }
        }
      }
    }
  }
  
  `
export const DeleteAccountMessagesWrapper =styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.x2};
`;

export const DeleteAccountButtonWrapper=styled.div`
margin-top: ${({ theme }) => theme.spacing.x4};
`;

export const DeleteAccountFormTitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.x2};
`;

export const ChangeEmailAddressFormTitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.x4};
`;

export const LoginFormTitleWrapper = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.x4};

  h1 {
    margin-bottom: ${({ theme }) => theme.spacing.x1};
  }
`;



export const PasswordResetFormTitleWrapper = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.x4};

  h1 {
    margin-bottom: ${({ theme }) => theme.spacing.x1};
  }
`;

export const SignUpFormTitleWrapper = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.x5};
`;

export const Breadcrumb = styled(P)`
  text-align: center;
  color: ${({ theme }) => theme.palette.textPlaceholder};
  margin-bottom: ${({ theme }) => theme.spacing.x4};
 
  span {
    .empty{
      margin-bottom: 24px;
      margin:24px;
    }
    // text-decoration: underline;
    // cursor: pointer;
    cursor: default;
  }

  > .active {
    color: ${({ theme }) => theme.palette.purplePrimary};
    text-decoration: none;
  }
`;

export const FormFieldWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.x1};

  ${Label} {
    display: inline-flex;
    margin-bottom: 4px;
    span{
      color: red;
    padding-left: 2px;
    }
    
  }
  .email_address{
    display: inline-flex;
    padding : 4px 0px 4px 0px;
  }
  .tag{
    display: inline-flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.palette.textPlaceholder};
    margin: 16px;
    padding: 4px ${({ theme }) => theme.spacing.x2};
    cursor: pointer;
  }
  .checkboxCustomUrl
  {
    margin:3px 10px 0px 10px ;
  }
  .separator {
    margin: 25px 0px 10px 0px;
    border-bottom: solid 1px rgb(226, 229, 237);
  }
  .btnContainer{
    width: 326px;
    margin: auto;
    @media (max-width: 767.98px){
      width: 100%;
      margin: 0;
    }
  }

`;
export const FormFieldRadio = styled.div`
  display: inline;
  flex-flow: column nowrap;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.x1};

  ${Label} {
    display: inline-flex;
    margin-bottom: 4px;
  }
  .email_address{
    display: inline-flex;
    padding : 4px 0px 4px 0px;
  }
  .tag{
    display: inline-flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.palette.textPlaceholder};
    margin: 16px;
    padding: 4px ${({ theme }) => theme.spacing.x2};
    cursor: pointer;
  }
  .checkboxCustomUrl
  {
    margin:3px 10px 0px 10px ;
  }
  .separator {
    margin: 25px 0px 10px 0px;
    border-bottom: solid 1px rgb(226, 229, 237);
  }
  .radioi2{
    font-size: 13px;
    line-height: 21px;
    font-weight: 600;
    color: #212452;
    width:25%;
  }
  .radioi2_dontknow{
    font-size: 13px;
    line-height: 21px;
    font-weight: 600;
    color: #212452;
    width:45%;
  }

  .radio{
    font-size: 13px;
    line-height: 21px;
    font-weight: 600;
    color: #212452;
    width:40%;
  }
  input[type="radio"]{
    margin: 2px 5px 0 0;
  }

`;

export const FormFieldWrapperTags = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  margin-bottom: 0px;

  ${Label} {
    display: inline-flex;
    margin-bottom: 4px;
  }
  .email_address{
    display: inline-flex;
    padding : 4px 0px 4px 0px;
  }
  .tag{
    display: inline-flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.palette.textPlaceholder};
    margin: 16px;
    padding: 4px ${({ theme }) => theme.spacing.x2};
    cursor: pointer;
  }
  .checkboxCustomUrl
  {
    margin:3px 10px 0px 10px ;
  }
  .separator {
    margin: 17px 0px 17px 0px;
    border-bottom: solid 1px rgb(226, 229, 237);
  }

`;

export const FormFieldWrapperSuggestedUrl = styled.div`
flex-flow: column nowrap;
width:100%;
position: relative;
margin-bottom: ${({ theme }) => theme.spacing.x1};
padding : 10px;
border: 1px solid #E2E5ED;
border-radius: 3px;

${Label} {
  display: inline-flex;
  margin-bottom: 4px;
}
.email_address{
  display: inline-flex;
  padding : 4px 0px 4px 0px;
}
.tag{
  display: inline-flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.textPlaceholder};
  margin: 16px;
  padding: 4px ${({ theme }) => theme.spacing.x2};
  cursor: pointer;
}
.checkboxCustomUrl
{
  margin:3px 10px 0px 10px ;
}
`;

export const FormConsentFieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  user-select: none;
  margin-top: ${({ theme }) => theme.spacing.x2};
  margin-bottom: ${({ theme }) => theme.spacing.x4};

  @media (max-width: 767.98px) {
    margin-top: ${({ theme }) => theme.spacing.x3};
  }

  input {
    margin-top: 3px !important;
  }

  label {
    cursor: pointer;
    padding-left: 12px;
  }

  .checkboxContainer{
    margin: auto;
    max-width:326px;
    text-align: start;
    background-color: blue;

    @media (max-width: 767.98px){
      width: 100%;
      margin: 0;
      background-color: red;
    }
  }
`;

export const FormDisclaimerFieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  user-select: none;
  margin-top: ${({ theme }) => theme.spacing.x2};
  margin-bottom: 0;

  @media (max-width: 767.98px) {
    margin-top: ${({ theme }) => theme.spacing.x3};
  }

  input {
    margin-top: 3px !important;
  }

  label {
    cursor: pointer;
    padding-left: 12px;
  }

  .checkboxContainer{
    margin: auto;
    max-width:326px;
    text-align: start;
    background-color: blue;

    @media (max-width: 767.98px){
      width: 100%;
      margin: 0;
      background-color: red;
    }
  }
`;

export const TextInput = styled.input`
  font-size: 15px !important;;
  height: ${({ theme }) => theme.spacing.x5};
  padding-right: ${({ theme }) => theme.spacing.x6};
  margin-bottom: 4px ;

  ${({ isError }) =>
    isError &&
    css`
      border-color: ${({ theme }) => theme.palette.colorError} !important;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${({ theme }) => theme.palette.textOffblack} !important;
    `}
    ${({ disabledGrey }) =>
    disabledGrey &&
    css`
      cursor: not-allowed;
      color: #97AAB6 !important;
    `}
`;

export const TextInputPractice =  styled.input`
  height: ${({ theme }) => theme.spacing.x5};
  padding-right: ${({ theme }) => theme.spacing.x6};
  margin-bottom: 0px;

  ${({ isError }) =>
    isError &&
    css`
      border-color: ${({ theme }) => theme.palette.colorError} !important;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${({ theme }) => theme.palette.textOffblack} !important;
    `}
`;

export const ShowHidePasswordEye = styled.div`
  cursor: pointer;
  position: absolute;
  top: 38%;
  right: 5%;
`;

// This gives a minimum height to the error message.
// It is needed to eliminate the jump effect when there is no error.
export const ErrorMessage = styled(Span)`
  min-height: 21px;
`;

export const LoginServerError = styled(Span)`
  display: inline-flex;
  margin-top: ${({ theme }) => theme.spacing.x1};
`;

export const Select = styled.select`
  height: ${({ theme }) => theme.spacing.x5};
  color:  ${ ({ theme }) => theme.palette.textOffblack};
  margin-bottom: 4px;
 
  &&.grey {
    color:#97AAB6;
  }

  ${({ isError }) =>
    isError &&
    css`
      border-color: ${({ theme }) => theme.palette.colorError} !important;
    `}

`;
export const RadioInput = styled.input`
  color: red! important;
  ${({ isError }) =>
    isError &&
    css`
      border-color: ${({ theme }) => theme.palette.colorError} !important;
    `}

`;

export const ForgotYourPasswordWrapper = styled.div`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.x3};

  a {
    ${linkStyles};
  }
`;

export const SaveChangesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom:5px

  @media (max-width: 767.98px) {
    margin-top: ${({ theme }) => theme.spacing.x4};
    flex-flow: column nowrap;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  
  input[type='checkbox']{
    zoom: 1.2;
    transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -moz-transform: scale(1.2);

  }

  span{
    margin-left:10px;
  }

  @media (max-width: 767.98px) {
    margin-top: ${({ theme }) => theme.spacing.x4};
    flex-flow: column nowrap;
  }
`

export const SaveChangesMessage = styled.div`
  margin-left: ${({ theme }) => theme.spacing.x2};

  @media (max-width: 767.98px) {
    margin-top: ${({ theme }) =>
      theme.spacing.x1}; // Save button top spacing on mobile
    margin-left: 0;
  }

  span {
    margin-left: ${({ theme }) => theme.spacing.x1};

    @media (max-width: 767.98px) {
    }
  }
`;
