import React, { useContext, useState } from 'react';
import { Container } from 'react-bootstrap';
import { SideFeedback, SuggestedQuestion } from "./styles";
import ModalSideFeedback from '../modal-feedback-side';
import { AppContext } from '../../context/index';
import axios from 'axios';
import { P } from '../theme/typography';
import { AiFillRobot } from 'react-icons/ai';
import ModalTriggerChat from '../modal-trigger-chat';
import ModalTriggerChatAnno from '../modal-trigger-chat-anno';


const SideFeedbackCard = (props ) => {
    const [showModalFeedback, setShowModalFeedback] = useState(false);
    const [showModalChat, setShowModalChat] = useState(false);

    const { 
        setRequestTrackerContext,
        fetchedSuggestedQuestion,
        setGoToChatPage,
        userContext
      } = useContext(AppContext);
    

    // const activateFeedback =() => {
    //     setShowModalFeedback(true);
    //   };

    const deactivateFeedback =() => {
      setShowModalFeedback(false);
    };

    const deactivateChat = () => {
      setShowModalChat(false);
    };

    const callReportIssue = (body) =>{
      const config = {
        method: 'post',
        url: `${window.BASE_URL_USER}report-issue`,
        withCredentials : true, 
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 10000, 
        data: JSON.stringify(body),
      //   signal: controller.signal,
      //   cancelToken: source.token,
      };
      setRequestTrackerContext(prev => { return {...prev, 'report-issue':true}});
      axios(config)
      .then((res) => {
        setRequestTrackerContext(prev => { return {...prev, 'report-issue':false}});
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'report-issue':false}});
        console.log(' 31 error: ', error);
      });
    };

    const handleChatModalTrigger = () => {
      if (userContext['dialog_test_passed']) {
        setGoToChatPage(true);
      } else {
        setShowModalChat(true);
      };
    };    

    return (
      <>
        <Container className='pt-3 pl-3'>
          {/* {userContext['dialog_allowed'] && fetchedSuggestedQuestion && fetchedSuggestedQuestion.length > 0 && (
            <SuggestedQuestion p1 regular textoffblack className="title">
              <div className="spelling-search" aria-label="Search">
                <P p1 regular textoffblack semibold  className='spelling-text'><AiFillRobot className='robot-icon' />Try our AI chatbot:</P>
                <P
                  className='suggestedQuery'
                  p1
                  regular
                  textoffblack
                  onClick={handleChatModalTrigger}
                >
                  {fetchedSuggestedQuestion}
                </P>
              </div>
            </SuggestedQuestion>
          )} */}

          <SideFeedback>
              <div className='sideFeedback'>
                  Not seeing what you are looking for?
                  <a
                      onClick={ (e)=>{setShowModalFeedback(true)}}
                  >Let us know</a>
              </div>
          </SideFeedback>
          <ModalSideFeedback
              modalFeedbackShow={showModalFeedback===true}
              modalFeedbackClose={deactivateFeedback}
              feecbackCall={callReportIssue}
              title=" Let us know how to improve medwise.ai " 
              result={undefined}
              i={9999}
          />
        </Container>
      
        <ModalTriggerChat
          modalFeedbackShow={showModalChat===true && userContext  && !userContext['is_anonymous'] }
          modalFeedbackClose={deactivateChat}
          // feecbackCall={callReportIssue}
          title="Deep AI Search" 
          result={undefined}
          i={9999}
        />   

        <ModalTriggerChatAnno
          modalFeedbackShow={showModalChat===true && userContext  && userContext['is_anonymous'] }
          modalFeedbackClose={deactivateChat}
          // feecbackCall={callReportIssue}
          title="Try Our AI Chatbot" 
          result={undefined}
          i={9999}
        />    
      </>
    );
};

  export default SideFeedbackCard;
