import React, { useEffect, useContext ,useState} from 'react';
import { AppContext } from '../../context';
import { Container, Row, Col ,Modal} from 'react-bootstrap';
import { H2, P, Span} from '../theme/typography';

import Styles from './styles';
import axios from 'axios';
import { getUser, getNumOfSearches,getNumOfGroupSearches, getNumOfCpdEntries, getNumOfBookmarks, removeUserStats } from '../../functions/manageSessions';
import { ReactComponent as MailLogo } from '../../assets/svgs/mail.svg';
import MessageBar from '../message-bar';

const SummaryCards = () => {
  const [numberSearches, setNumberSearches] = useState(undefined);
  const [numGroupedSearches, setNumGroupedSearches] = useState(undefined);
  const [numDocuments, setNumDocuments] = useState(undefined);
  const [numCpdEntries, setNumCpdEntries] = useState(undefined);
  const [numBookmarks, setNumBookmarks] = useState(undefined);
  const {userContext ,userStats,fetchedResults} = useContext(AppContext);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [isMagic, setIsMagic] = useState(true);
  const [messages, setMessages] = useState(undefined);
  const [showModal, setShowModal] = useState(false);

  const openModal = (e)=>{
    e.preventDefault();
    setShowModal(true);
  }; 
  const handleCloseModal = () => {
    setShowModal(false);
  }

  // useEffect(() => {
  //   // only once ... 
  //   // TO do move this to landing ???  or to context 
  //   // We want to react when 
  //   // probably better to call it request get-trending first thing when visiting
  //   setRequestTrackerContext(prev => { return {...prev, 'get-trending':true}});
  //     axios
  //         .get(`${window.BASE_URL_ASK}get-trending`)
  //         .then((res) => {
  //           setRequestTrackerContext(prev => { return {...prev, 'get-trending':false}});
  //         })
  //         .catch((error) => {
  //         setRequestTrackerContext(prev => { return {...prev, 'get-trending':false}});
  //           console.log(error);
  //         });
  // }, []);

  useEffect(() => {
    let user_info =userStats;
    if (user_info) {
      setIsLoggedIn(true);
      // setNumberSearches(getNumOfSearches());
      setNumberSearches(userStats['num_searches']);
      setNumGroupedSearches(userStats['num_grouped_searches']);
      setNumCpdEntries(userStats['num_cpd_entries']);
      // setNumBookmarks(userStats['num_bookmarks']);
      if(user_info['org-user-type'] && user_info['org-user-type']=== "magic"){
        setIsMagic(true);
      }else{
        setIsMagic(false);
      }
    }
   else {
      setIsLoggedIn(false);
    }
  },[userStats]);

  // TO DO do not use 

  return (
    <>
    {isLoggedIn&&(
        <Styles>
          <Container className='container-card' >
            <Row className='justify-content-around'>
            {/* {messages && messages.length> 0  &&( */}
            {/* {false &&(

              <Col md={4} sm={12} className='p-0' >
               <div className="card card-home1-message text-center pt-3 ">
               <div className='logo-wrapper'><MailLogo  stroke='#02b1c8'></MailLogo></div>
                <div className="card-body-messages">
                      <div className='message_wrapper'>
                      <div className="message_header">
                          <div className='message_header_tittle'>
                            {messages[0].title}
                          </div>
                          <div className='message_header_date'>{messages[0]['modified'].toString().substring(5, 10)}</div>
                        </div><div className='message_content'>
                            <p> {messages[0].message} </p>
                          </div>
                      </div>
                </div>
                  <div className="card-footer bg-white py-3">
                      <a href='' className="text-footer" onClick={(e) => {openModal(e)}}>See all Messages</a>
                  </div>
                </div>
              </Col>)} */}
              {typeof numberSearches !=='undefined' &&(
              <Col md={4} sm={12}  className='p-0'>
                <div className="card card-home1 text-center pt-3 ">
                <div className="card-body">
                      <div className="top-card">
                          <h3 className="highlight11 mx-auto">
                              <span className="text-highlight22" id="num_searches">{numberSearches.toString().substring(0,4)}</span>
                          </h3>
                      </div>
                      <h6 className="card-title text-headline">Search queries </h6>
                          <p className="card-text text-para">
                            The total number of queries on your account to date. To further explore past
                             queries and search results go to search history.
                          </p>
                  </div>
                  <div className="card-footer bg-white py-3">
                      <a href={window.BASE_URL_DASHBOARD+"dashboard/history.html"} className="text-footer">Go to search history</a>
                  </div>
                </div>
              </Col>)}
              {typeof numGroupedSearches !=='undefined' &&(
              <Col  md={4} sm={12}  className='p-0'>
                <div className="card card-home1 text-center pt-3 ">
                <div className="card-body">
                      <div className="top-card">
                          <h3 className="highlight11 mx-auto">
                              <span className="text-highlight22" > {numGroupedSearches.toString().substring(0,4)}</span>
                          </h3>
                      </div>
                      <h6 className="card-title text-headline">Grouped searches</h6>
                          <p className="card-text text-para">
                          This is the number of grouped searches. Searches are grouped together if they occur within 10 minutes of each other. Go to search history to explore past queries and search results.
                          </p>
                  </div>
                  <div className="card-footer bg-white py-3">
                    <a href={window.BASE_URL_DASHBOARD+"dashboard/history.html"} className="text-footer">Go to search history</a>
                  </div>
                </div>
              </Col >)}
              { typeof numCpdEntries !== 'undefined' &&(
              <Col md={4} sm={12}  className='p-0'>
                <div className="card card-home1 text-center pt-3 ">
                <div className="card-body">
                      <div className="top-card">
                          <h3 className="highlight11 mx-auto">
                              <span className="text-highlight22" id="num_searches">{numCpdEntries.toString().substring(0,4)}</span>
                          </h3>
                      </div>
                      <h6 className="card-title text-headline">CPD Entries</h6>
                          <p className="card-text text-para">
                            Visit CPD entries to explore, edit and prepare CPD entries for export to appraisal services. 
                          </p>
                  </div>
                  <div className="card-footer bg-white py-3">
                      <a href={window.BASE_URL_DASHBOARD+"dashboard/cpd_entries.html"} className="text-footer">Go to CPD entries</a>
                  </div>
                </div>
              </Col>)}



            </Row>
          </Container>
          {/* {messages && messages.length>0 && (
          <Modal
            size='lg'
            fullscreen={true} 
            show={showModal}
            onHide={handleCloseModal}
            centered
            style={{ overflow: "hidden" }}
            // scrollable
          >
          <Modal.Header  style={{ height: "20%", border:'0px'}} >
            <Modal.Title style={{ width: "100%" , "text-align": "center"}}>
            <H2 h2> {messages.length} Messages </H2> </Modal.Title>
            
          </Modal.Header>
          <Modal.Body style={{ 
            maxHeight: "400px",
             border:'0px',
             overflow:"auto",
             width:'100%' }}  className='modal-body' >
          {messages.map ((message) => {
            return ( 
              <>
              <Container  tyle={{ margin: "16px" }} >
                <Row >
                  <Col >
                  <MessageBar message={message} ></MessageBar>
                  </Col>
              </Row>
            </Container>
            </>
          );
        })}

          </Modal.Body>
        <Modal.Footer style={{padding:"12px" ,border:"0px", height: "80%"}}      
          >
          </Modal.Footer>
        </Modal>
        )} */}
        </Styles>
        )}
    </>
  );
};

export default SummaryCards;
