import React, { useState,useContext  } from 'react';
// import { ChakraBaseProvider, extendBaseTheme } from '@chakra-ui/react';
// import chakraTheme from '@chakra-ui/theme';
// import { Input } from '@chakra-ui/react'



import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import Button from '../../../button';
import { Span, Label, StyledLink, P } from '../../../theme/typography';
import './styles.css';
import { AppContext } from '../../../../context';
import {
  FormFieldWrapper,
  TextInput,
  ErrorMessage,
  FormConsentFieldWrapper,
  FormDisclaimerFieldWrapper,
} from '../../styles';
import GoogleBtn from '../../google-btn';

const SignUpStepEmail = (props) => {
  const [errorConsent, setErrorConsent] = useState(false);
  const [errorLinking, setErrorLinking]= useState(false);
  const [consent, setConsent]= useState(!props.isSignup);

  const {setRequestTrackerContext} = useContext(AppContext)

  var signupSchema2 = yup.object().shape({
    linking: yup.string().required("would you like to link your account ?"),
  });

  const { register:register2,
          getValues:getValues2,
          handleSubmit: handleSubmit2,
          errors: errors2,
          clearErrors: clearErrors2 } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(signupSchema2),
  });

  var signupSchema = yup.object().shape({
    email: yup
      .string().trim()
      .email('Please enter a valid email address')
      .required('This field is required'),
    consent: yup.boolean().oneOf([true]),
  });

  const { register, getValues, handleSubmit, errors, clearErrors } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(signupSchema),
  });


  // Since sign up is a multipage form, we need to make sure that the first
  // page is validated before letting the user navigate the second page.
  const handleStepOneNavigation = () => {
      var values = getValues([
        'email',
        'consent'
        ]);
      props.loginCallBack(values['email'].trim(), values['consent']);
  };



  const handleLinkClick = () => {
    setRequestTrackerContext(prev => {return{...prev, 'signupPage':true }});
    setTimeout(() => {
      setRequestTrackerContext(prev => {return{...prev, 'signupPage':false }});
    },5000);
  };

  const handleLinkDisableClick = ()=>{
    if(!consent){
      setErrorConsent(true);
    }
  };
  const handleCheckBoxClick= ()=>{
    if(consent){
      setConsent(false);
    }
    else{
      setConsent(true);
      setErrorConsent(false);
    }
  };

  return (
    <div className='p-0'>
          {/* consent row  */}
          <Row className='justify-content-center'>
          {props.isSignup &&(           
             <Col md={12} style={{textAlign: "center"}}>
              <FormConsentFieldWrapper className={(errorConsent || errors.email || errors.consent || props.registerError )?'checkboxContainer_error blink':'checkboxContainer' } > 
              <input
                  type="checkbox"
                  id="consent"
                  name="consent"
                  ref={register}
                  defaultChecked = { props.stepOneConsent}
                  onChange={()=> {handleCheckBoxClick()}}
                  className={(errorConsent || errors.email || props.registerError )?'error':'' }
                />
                <label htmlFor="consent" style={{margin:'auto',display:'content'}}>
                  <Span
                    c1
                    regular
                    textmainbody={(!errors.consent && !errorConsent)}
                    colorError={(errors.consent || errorConsent)}
                    key={'consentcheck_'+errorConsent}
                    style={{'paddingRight':'4px'}}
                  > 
                    I confirm that I have read the{' '}
                    <StyledLink textlink="true" to="/terms.html" target="_blank" rel="noopener noreferrer">
                      Terms and Conditions
                    </StyledLink>{' '}
                    and{' '}
                    <StyledLink textlink="true" to="/privacy.html" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </StyledLink>
                  </Span>
                </label>

              </FormConsentFieldWrapper>
              {errorConsent && (
              <ErrorMessage c1 regular colorError>
                { errorConsent &&  'This field is required'}
              </ErrorMessage>
              )}
            </Col>
            )}
          </Row>

          <Row className='justify-content-center'>
            <Col md={12} style={{textAlign: "center", height: "40px",cursor:'pointer'}} className="nhs-login-btn" onClick={()=>{handleLinkDisableClick()}} >
              <Button 
                className={consent?'enable':'disable'}
                type="external_link_nhs"
                to="https://users.medwise.ai/nhslogin?sso=true"
                colornhs
                buttonmedium
                width="326px"
                onClick={()=>handleLinkClick()}
              />
            </Col>
          </Row>

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: "25px"}}></div>

          <Row className='justify-content-center'>
            <Col md={12} style={{textAlign: "center",cursor:'pointer'}} onClick={()=>{handleLinkDisableClick()}}  >
              <Button
                className={consent?'enable':'disable'}
                type="external_link_icon"
                to="https://babylon.okta-emea.com/app/babylon_medwise_1/exk8g726er4nUE0vk0i7/sso/saml"
                emedblue
                buttonmedium
                text="Continue with eMED"
                width="326px"
                onClick={()=>handleLinkClick() }
              />
            </Col>
          </Row>
           
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: "25px"}}></div>

          <Row className='justify-content-center'>
            <Col md={12} style={{textAlign: "center"}}  onClick={()=>{handleLinkDisableClick()}} >
              <GoogleBtn enable={consent} >
              </GoogleBtn>
            </Col>
          </Row>
          <Row className='justify-content-center'>
          {!props.isSignup &&(
            <Col className='m-0 p-0' md={12} style={{textAlign: "center"}}>
            {/* <FormDisclaimerFieldWrapper className={'checkboxContainer' } > 
              <label htmlFor="consent" style={{margin:'auto',display:'content'}}>

              <P p1 regular textoffblack className='btnContainer' >By signing in or creating an account, you agree with our{' '}
                <StyledLink  textlink="true" to="/terms.html" target="_blank" rel="noopener noreferrer" style={{'display': 'inline'}}>
                  Terms and Conditions
                </StyledLink>{' '}
                and{' '}
                <StyledLink textlink="true" to="/privacy.html" target="_blank" rel="noopener noreferrer" style={{'display': 'inline'}}>
                  Privacy Policy
                </StyledLink>.
              </P>
              </label>

            </FormDisclaimerFieldWrapper> */}
            {errorConsent && (
            <ErrorMessage c1 regular colorError>
              { errorConsent &&  'This field is required'}
            </ErrorMessage>
            )}
            </Col>
            )}
          </Row>
          <Row className='justify-content-center'>
            <Col md={10}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: "50px"}}>
                <div style={{flex: 1, height: '1px', backgroundColor: '#dadce0'}} />
                <div>
                  <P h2 style={{width: '70px', textAlign: 'center', color: "#212452"}}>Or</P>
                </div>

                <div style={{flex: 1, height: '1px', backgroundColor: '#dadce0'}} />
              </div>
            </Col>
          </Row>
    <form onSubmit={handleSubmit(handleStepOneNavigation)} autoComplete={"on"}  id="email_submitted"  >
    {/* part 1 of form  */}
    <div >
      {/* email row */}
      <Row className='justify-content-center'>
        <Col  md={12} style={{textAlign: "center"}}>
          <FormFieldWrapper className='btnContainer' style={{display:'inline-flex' }}>
            <Label c1 semibold textoffblack >
              Email
            </Label>
            <TextInput
              type="text"
              name="email"
              autoFocus 
              placeholder="Enter your email address..."
              ref={register }
              isError={errors.email || props.registerError}
              defaulValue ={props.email? props.email:''}
              key={0}
              width={'326px'}
            />
            {errors.email && (
              <ErrorMessage c1 regular colorError>
                { errors.email && errors.email.message}
              </ErrorMessage>
           )}
            { props.registerError &&(
                <ErrorMessage c1 regular colorError>
                   {props.registerError}
                  {/* {!props.linked && (
                      <StyledLink textlink="true" to="/login">
                  &nbsp; Log in here.
                  </StyledLink> )} */}
                </ErrorMessage>)}
              
          </FormFieldWrapper>
        </Col>
      </Row>

    </div>

      {!props.linked &&(
        <div className='btnContainer' style={{display:"flex", justifyContent:'center'}}>
          <Button 
            id="email_submitted"
            type= "submit"
            colorprimary
            buttonmedium
            text="Next"
            width="326px"
          />
        </div>
      )}
    </form> 
     
     
    <form onSubmit={handleSubmit(handleStepOneNavigation)} id='email_org_submitted' >
      {props.linked &&(
        <div>
          <Button
            id="email_org_submitted"
            type= "submit"
            colorprimary
            buttonlarge
            text="Next"
            width="100%"

            onClick={handleStepOneNavigation}
          /> 

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: "50px"}}>
            <div style={{flex: 1, height: '1px', backgroundColor: '#dadce0'}} />
            <div>
              <P h2 style={{width: '70px', textAlign: 'center', color: "#212452"}}>Or</P>
            </div>

            <div style={{flex: 1, height: '1px', backgroundColor: '#dadce0'}} />
          </div>

          <P p1 regular textoffblack style={{textAlign: "center", marginBottom: '16px'}}>By signing in or creating an account, you agree with our{' '}
            <StyledLink textlink="true" to="/terms.html" target="_blank" rel="noopener noreferrer" style={{'display': 'inline'}}>
              Terms and Conditions
            </StyledLink>{' '}
            and{' '}
            <StyledLink textlink="true" to="/privacy.html" target="_blank" rel="noopener noreferrer" style={{'display': 'inline'}}>
              Privacy Policy
            </StyledLink>.
          </P>

          <Row className='justify-content-center'>
            <Col md={12} style={{textAlign: "center", height: "56px", border:'1px solid #dadce0'}} className="nhs-login-btn" >
              <Button
                type="external_link_nhs"
                to="https://users.medwise.ai/nhslogin?sso=true"
                colornhs
                buttonmedium
                width="326px"
                onClick={()=>handleLinkClick()}
              />
            </Col>
          </Row>

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: "25px"}}></div>

          <Row className='justify-content-center'>
            <Col md={12}  style={{textAlign: "center"}}>
              <Button
                type="external_link_icon"
                to="https://babylon.okta-emea.com/app/babylon_medwise_1/exk8g726er4nUE0vk0i7/sso/saml"
                colorprimaryreverse
                buttonmedium
                text="Continue with Babylon Okta"
                width="326px"
                onClick={()=>handleLinkClick() }
              />
            </Col>
          </Row>

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: "25px"}}></div>

          <Row className='justify-content-center'>
            <Col md={12} style={{textAlign: "center"}}>
              <GoogleBtn enable={consent} >
              </GoogleBtn>
            </Col>
          </Row>
        </div>
      )}
    </form>
    </div>
  );
};

export default SignUpStepEmail;
