import React, { useRef, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import QuestionThread from './question-thread';
import AnswerThread from './answer-thread';
import { Article, Styles } from './styles';

function ChatFlow({ q, dialogData, machineResults, suggestedQuestion, possibleAnswer, fetchComposeAnswer, fetchResults, trackers }) {
  const messagesEnd = useRef(undefined);
  const scrollToBottom = () => {
    return messagesEnd.current?.scrollIntoView({ behavior: 'smooth' })
  };

  useEffect(() => {
    scrollToBottom();
  }, [q, possibleAnswer]);

  return (
    <Article>
      <Row className='chat-container'>
        {suggestedQuestion && q && q.length > 0 && q.map((question, i) => {
          return (
            <>
              <QuestionThread question={question} suggestedQuestion={suggestedQuestion} index={i} />
              <AnswerThread dialogData={dialogData[i]} machineResults={Object.values(machineResults)[i]} possibleAnswer={possibleAnswer[i]} fetchComposeAnswer={fetchComposeAnswer[i]} fetchResults={fetchResults[i]} tracker={trackers[i]} index={i} />
            </>
          )
        })}
        {dialogData.length === 5 && (
          <p className='max-msg'>You reached the max thread capacity for this chat. Please make another search.</p>
        )}
        <div ref={messagesEnd} />
      </Row>
    </Article>
  )
};

export default ChatFlow