import styled from 'styled-components';
import { Label } from '../../../theme/typography';

export const FormFieldWrapperSuggestedUrl = styled.div`
flex-flow: column nowrap;
width:100%;
position: relative;
margin-bottom: ${({ theme }) => theme.spacing.x1};
border-radius: 3px;

${Label} {
  display: inline-flex;
  margin-bottom: 4px;
  cursor: pointer;
}
.email_address{
  display: inline-flex;
  padding : 4px 0px 4px 0px;
}
.tag{
  display: inline-flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.textPlaceholder};
  margin: 16px;
  padding: 4px ${({ theme }) => theme.spacing.x2};
  cursor: pointer;
}
.checkboxCustomUrl
{
  margin:3px 10px 0px 10px ;
}
a{
    font-size:13px;
    color: ${({ theme }) => theme.palette.purplePrimary};
}
`;