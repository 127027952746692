import styled from 'styled-components';
import { linkStyles } from '../theme/link';

export const Styles = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.x5};

  @media (max-width: 767.98px) {
    margin-top: ${({ theme }) => theme.spacing.x5};
  }

  p {
    ${linkStyles}
  }
`;

export default Styles;
