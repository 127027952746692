import React, {  useContext, useEffect,useState } from 'react';
import styled, { css } from 'styled-components';
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';
import {AppContext} from '../../context';
import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen';



export default function LoadingIndicator(){
  const {
    requestTrackerContext
  } = useContext(AppContext);
  const isSmallScreen = useIsSmallScreen();
  const [displayLoading, setDisplayLoading] = useState(false);



  useEffect(() => {
    let valuesRequest =  false;
    if (requestTrackerContext && Object.values(requestTrackerContext)){
      valuesRequest = Object.values(requestTrackerContext);
    }
    let reduceValues = valuesRequest.reduce(
      (accumulator, currentValue) => accumulator || currentValue
      );
      // if either is true set
    if(reduceValues ){
      setDisplayLoading(true);
    }
    else {
      setDisplayLoading(false);
    }
  }, [requestTrackerContext ]);

    return (
     
        <Wrapper className={isSmallScreen? 'isMobile':''} > 
           { displayLoading &&(
            <div >
              {/* <Loader type="Grid" color="#8771DF" height="100%" width="100%" /> */}
            <svg width="100%" height="100%" viewBox="0 0 105 105" fill="#8771DF" aria-label="audio-loading"><circle cx="12.5" cy="12.5" r="12.5"><animate attributeName="fill-opacity" begin="0s" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="12.5" cy="52.5" r="12.5"><animate attributeName="fill-opacity" begin="100ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="52.5" cy="12.5" r="12.5"><animate attributeName="fill-opacity" begin="300ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="52.5" cy="52.5" r="12.5"><animate attributeName="fill-opacity" begin="600ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="92.5" cy="12.5" r="12.5"><animate attributeName="fill-opacity" begin="800ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="92.5" cy="52.5" r="12.5"><animate attributeName="fill-opacity" begin="400ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="12.5" cy="92.5" r="12.5"><animate attributeName="fill-opacity" begin="700ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="52.5" cy="92.5" r="12.5"><animate attributeName="fill-opacity" begin="500ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle><circle cx="92.5" cy="92.5" r="12.5"><animate attributeName="fill-opacity" begin="200ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite"></animate></circle></svg>
            </div>
            )}
        </Wrapper> 

      );
    };

// export default LoadingIndicator;


const Wrapper = styled.div`

  margin:-10px 0px 0px 5px;
  width: 40px;
  height: 40px;
  @media (max-width:780px) {
    display:none;
  }

  &&.isMobile{
    margin: auto 50%;
    width:26px;
    height:26px;
    @media (max-width:780px) {
      display:inline-flex;
    }
  }

  `