import React, { useEffect,createRef,useState,useContext } from 'react';
import { FaHistory, FaSearch,FaExternalLinkAlt } from 'react-icons/fa';
import { P } from '../theme/typography';
import { Container, Row, Col } from 'react-bootstrap';
import PublisherTag from '../publisher-tag';
import { useKeyPress } from '../../functions/keypress';
import axios from 'axios';
import { AppContext } from '../../context/index';
import { stripTags } from '../../functions/utils';




const AcUserResult = ({ list, cursor, querySubmit, setHoveredAC,q, result }) => {
  const searchBox = createRef();
  const [linkSelected, setLinkSelected] = useState(false);
  const enterPress = useKeyPress('Enter', searchBox);
  const rightPress = useKeyPress('ArrowRight', searchBox);
  const leftPress = useKeyPress('ArrowLeft', searchBox);
  const { 
    setRequestTrackerContext,
    searchContext,
  } = useContext(AppContext);

  const handleClick =(e, value) =>{
    querySubmit(e, value['suggestion']);
  };

  const logClick = (args) =>{
    let config = {
      method: 'post',
      url: `${window.BASE_URL_USER}log-dropdown-search`,
      withCredentials : true, 
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(args),
    };
    setRequestTrackerContext(prev => { return {...prev, 'log-dropdown-search':true}});
    axios(config)
    .then((res) => {
      setRequestTrackerContext(prev => { return {...prev, 'log-dropdown-search':false}});
    })
    .catch((error) => {
      setRequestTrackerContext(prev => { return {...prev, 'log-dropdown-search':false}});
      console.log('error: ', error);
    });

  };

  const handleLinkClick=(e, value) =>{
    if(value && value["online_view_url"] && value["suggestion"]){
      e.stopPropagation();
      let parsed_args  = {
          "q": q,
          "online_view_url": value["online_view_url"],
          "user_suggestions_response": result ,
          "interaction_type": "visit",
          "clicked_suggestion": value["suggestion"] ,
        };

      logClick(parsed_args);
      window.open(value["online_view_url"],'_blank','noreferrer');

    }
  };

  useEffect( ()=>{
    let tempValue = list[cursor-1];
    if( tempValue&& tempValue &&enterPress && linkSelected && tempValue["online_view_url"]){
      window.open(tempValue["online_view_url"],'_blank','noreferrer');
    }
  },[enterPress]);

  useEffect( ()=>{
    let tempValue = list[cursor-1];
    if( tempValue&&  !linkSelected &&(rightPress || leftPress) &&tempValue["online_view_url"] ){
      setLinkSelected(true);
    }
    else{
      if( tempValue && (rightPress || leftPress) && tempValue["online_view_url"]){
        setLinkSelected(false);
      }
    }
  },[rightPress,leftPress]);


  const parseTittle = (title)=>{
    var parser = new DOMParser();
      var decodedTitle = parser.parseFromString(title, 'text/html').body.textContent;
      if(decodedTitle){
        return decodedTitle;
      }
      else{
        return title;
      }
  };


  return (
      // If AC list item needs to be filtered, it can be chained here.
      list.map((value,index) => {
        return (
          <li
            key={"UserSuggestions_"+index}
            onClick={(e) => handleClick(e,value)}
            onMouseEnter={() => setHoveredAC(index+1)}
            onMouseLeave={() => setHoveredAC(0)}
            className={`ac_list--item--user`}
          >
            <Container>
              <Row >
                <Col md={ value["online_view_url"] === null ? 12:4}   sm={ value["online_view_url"] === null ? 12:4}
                xs={ value["online_view_url"] === null ? 12:4}
                className={`d-flex ac_list--item--user ${(index + 1 === cursor && !linkSelected) ? ' active' : ' '}`}
                style={{"padding": "12px 0px 12px 16px"}}
                title={ "Search: " + value['suggestion']}

                >
                  <div className={"ac_list--item--magnify user"+ cursor + linkSelected+index } aria-label="Search"
                  id={value['suggestion'] }>
                    { index<list.length ?(<FaHistory style={{"margin": "auto 0"}} />):(<FaSearch />)}
                  </div>
                   <P
                    p1
                    regular
                    textoffblack
                    dangerouslySetInnerHTML={{ __html: value['suggestion'] }}
                  ></P>

                </Col>
                { value["online_view_url"] !== null &&  value["title"] !== null&&( 
                <Col md ={8} sm={8} xs={8}
                className={`d-flex justify-content-between ac_list--item--user  ${(index + 1 === cursor  && linkSelected )? ' link' : ' '}`}
                style={{"padding": "12px 0px","cursor": "pointer"}}
                onMouseEnter={() => setLinkSelected(true)}
                onMouseLeave={() => setLinkSelected(false)}
                            title={value["online_view_url"] !== null ?  value["online_view_url"]:""}
                            onClick={(e )=>{handleLinkClick(e,value)}}
                >
                  <>
                {typeof value['title'] === "string"&&(
                  <a  rel="noopener noreferrer" target='blank'> 
                        <div className="ac_list--item--externalLink"   >
                          <div className="svgWrapper">
                            <FaExternalLinkAlt />
                          </div>
                        <p className='linkTittle'>  {parseTittle(value['title'])} </p>
                        </div>
                        {typeof value['publisher_name'] === "string"&&(
                          <PublisherTag inSearchBar={true}  publisher={value['publisher_name']}></PublisherTag>)} 
                        </a>)} 

                  </>
                
                </Col>
                )}
              </Row>
            </Container>

           

          </li>
        );
      })
  );
};

export default AcUserResult;
