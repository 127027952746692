import React, { useEffect, useContext, useState,useMemo } from 'react';
import { AppContext } from '../../../context/index';
import {BtnFilterContainer} from './styles';
import { getUserFilters } from '../../../functions/manageSessions';
import FeatherIcon from 'feather-icons-react';
import { ReactComponent as MedwiseLogo } from '../../../assets/svgs/medwise-logo.svg';
import ModalNotLoggedIn from '../../modal-not-logged-in';
import { countWords } from '../../../functions/utils';



const BtnFiltersCard = ( ) => {
    const { 
        searchContext,
        setSearchContext,
        setIsResultsExpanded,
        userContextFilters,
        userContext,
        setLoadMore,
        setClearLocalWebResults,
        assistedAiAllowWebUsage,
        setShowAssistedAI,
        setLocalWebResults
      } = useContext(AppContext);
    const [showModal, setShowModal] = useState(false);
    const [rangeValue, setRangeValue] = useState(99);
    const [allBtn0Selected, setAllBtn0Selected] = useState(false); // we want to avoid checking on click for performance
    const [allBtn1Selected, setAllBtn1Selected] = useState(false); // we want to avoid checking on click for performance
    const [allBtn2Selected, setAllBtn2Selected] = useState(false); // we want to avoid checking on click for performance
    const [allBtn3Selected, setAllBtn3Selected] = useState(false); // we want to avoid checking on click for performance
    const [allBtn4Selected, setAllBtn4Selected] = useState(false); // we want to avoid checking on click for performance
    const [btnSelected, setBtnSelected] = useState([false,false,false,false]); // we want to avoid checking on click for performance



    const getBtnNames = () => {
        let userFilter = userContextFilters;
        let names ;
        if(userFilter && userFilter['filter-types']){
            names =  userFilter['filter-types'];
        }
        if(userFilter && userFilter['sidebar-order']){
            names = userFilter['sidebar-order'];
            if(userFilter['Web'] && !names.includes('Web') ){
               names.push('Web');
            };
        }
        return names; 
    };

    // return google filter
    const getGoogleSource =() => {
        let out =  {
            'encrypted_url': "custom:gAAAAABiMGJJQdUGog4lZd2jqr0jk8DZmQvBh139L5aOzVrh7ZZ76xX8uSQkkGPqznXJ7jZV-sft8U8ZZfnX0xNSK804FIc9gT6ckX1xqp7fOU35M9-sKyQ=",
            'name':'Web',
            'url': "https://www.google.co.uk", 
            'id':0 // id is always 0 
        };
        // let googleFilter = {'name':'Web','sources': [out]};
        return out;
    };

    // returns facets/sources
    // if no defaultSources found then return all the sources
    // when  name === WEB we have to return the hard coded source 
    const getFacetsForBtnName =(name) => {
        setLocalWebResults({0: [], 1: [], 2: [], 3: [], 4: []});
        let tempFacetsToSend=[];
        if( name === 'Web'){
            let out =  {
                'encrypted_url': "custom:gAAAAABiMGJJQdUGog4lZd2jqr0jk8DZmQvBh139L5aOzVrh7ZZ76xX8uSQkkGPqznXJ7jZV-sft8U8ZZfnX0xNSK804FIc9gT6ckX1xqp7fOU35M9-sKyQ=",
                'name':'Web',
                'url': "https://www.google.co.uk",
                'id': 0 // id is always 0 
            };
            // facets = {'name':'Web','sources': [out]};
            tempFacetsToSend = [out];
            if (!assistedAiAllowWebUsage) {
                setShowAssistedAI(false);
            } else {
                setShowAssistedAI(true);
            }
        }
        else{
            setShowAssistedAI(true);
            if( name === memoBtn0Name){
                if(memoAllDefaultSourceBtn0 && memoAllDefaultSourceBtn0.length > 0  ){
                    return memoAllDefaultSourceBtn0;
                }
                else{
                    return  memoAllSourceBtn0;
                }
            }
            if(  name === memoBtn1Name){
                if(memoAllDefaultSourceBtn1 && memoAllDefaultSourceBtn1.length > 0  ){
                    return memoAllDefaultSourceBtn1;
                }
                else{
                    return  memoAllSourceBtn1;
                }
            }
            if(  name === memoBtn2Name){
                if(memoAllDefaultSourceBtn2 && memoAllDefaultSourceBtn2.length > 0  ){
                    return memoAllDefaultSourceBtn2;
                }
                else{
                    return  memoAllSourceBtn2;
                }
            }
            if(  name === memoBtn3Name){
                if(memoAllDefaultSourceBtn3 && memoAllDefaultSourceBtn3.length > 0  ){
                    return memoAllDefaultSourceBtn3;
                }
                else{
                    return  memoAllSourceBtn3;
                }
            }
            if(  name === memoBtn4Name){
                if(memoAllDefaultSourceBtn4 && memoAllDefaultSourceBtn4.length > 0  ){
                    return memoAllDefaultSourceBtn4;
                }
                else{
                    return  memoAllSourceBtn4;
                }
            }
        }
        return tempFacetsToSend;
    };

    // returns an array of array each array contains all the sources of the corresponding btn
    // if we have a side order for the btn the array is in order
    const memoAllSourcesFromBtns = useMemo (()=>{
        let allSources = [] ;
        let btnNames =  getBtnNames(); 
        if (btnNames && userContextFilters){
            btnNames.forEach(btnName => {
                let btnSources = []; 
                userContextFilters[btnName].forEach(filter => {
                    if(filter['sources']){
                        filter['sources'].forEach(src => {
                            btnSources.push(src);
                        });
                    }
                });
                if(btnName ==="Web"){
                    btnSources =[getGoogleSource()];
                }
                allSources.push(btnSources);
            });
        }
        return allSources;

    },[userContextFilters]);

    // return an array of array, each array has a list of strings representing the url of the btn 
    // if side-order is present the array is in order
    const memoAllUrlsBtns = useMemo(()=> {
        let allUrls = [];
        if(memoAllSourcesFromBtns){
            memoAllSourcesFromBtns.forEach(sourcesBtn => {
                let allBtnUrls = [];
                sourcesBtn.forEach(src => {
                    if(src && src['encrypted_url']){
                        allBtnUrls.push(src['encrypted_url']);
                    }
                    else{
                        //TO DO CHECK that this is impossible and remove
                        // it maybe an filter and not a sources 
                        if(src && src['sources']){
                            src['sources'].forEach(src => {
                                if(src && src['encrypted_url']){
                                    allBtnUrls.push(src['encrypted_url']);
                                }
                            });
                        }
                    }
                });
                allUrls.push(allBtnUrls);
            });
            return allUrls;
        }
        

    }, [memoAllSourcesFromBtns, userContextFilters]);

    // TO DO refactor to update on filter update (first change filter update)
    // returns an array of sources
    const memoAllSourceBtn0 = useMemo(() => {
        let allSources = [] ;
        let allBtnNames = getBtnNames();
        if( userContextFilters && allBtnNames && allBtnNames[0]){
            let btnName =allBtnNames[0];
            userContextFilters[btnName].forEach(filter => {
                if(filter['sources']){
                    filter['sources'].forEach(src => {
                        allSources.push(src);
                    });
                }
            });
            if(btnName ==="Web"){
                allSources =[getGoogleSource()];
            }
        }
        return allSources;
    }, [userContextFilters]);// TO DO refactor to update on filter update (first change filter update)

    const memoAllSourceBtn1 =  useMemo(() => {
        let allSources = [] ;
        let allBtnNames = getBtnNames();
        if(userContextFilters  && allBtnNames && allBtnNames[1]){
            let btnName = allBtnNames[1];
            userContextFilters[btnName].forEach(filter => {
                if(filter['sources']){
                    filter['sources'].forEach(src => {
                        allSources.push(src);
                    });
                }
            });
            if(btnName && btnName ==="Web"){
                allSources =[getGoogleSource()];
            }
        }
        return allSources;
    }, [userContextFilters]);// TO DO refactor to update on filter update (first change filter update)

    const memoAllSourceBtn2 =  useMemo(() => {
        let allSources = [] ;
        if(userContextFilters && userContextFilters['filter-types']){
            let btnName = userContextFilters['filter-types'][2];
            userContextFilters[btnName].forEach(filter => {
                if(filter['sources']){
                    filter['sources'].forEach(src => {
                        allSources.push(src);
                    });
                }
            });
            if(btnName && btnName ==="Web"){
                allSources =[getGoogleSource()];
            }
        }
        return allSources;
    }, [userContextFilters]);
    // TO DO refactor to update on filter update (first change filter update)
    const memoAllSourceBtn3 =  useMemo(() => {
        let allSources = [] ;
        if(userContextFilters && userContextFilters['filter-types'] && userContextFilters['filter-types'][3]){
            let btnName = userContextFilters['filter-types'][3];
            userContextFilters[btnName].forEach(filter => {
                if(filter['sources']){
                    filter['sources'].forEach(src => {
                        allSources.push(src);
                    });
                }
            });
            if(btnName && btnName ==="Web"){
                allSources =[getGoogleSource()];
            }
        }
       
        return allSources;
    }, [userContextFilters]);
    // TO DO refactor to update on filter update (first change filter update)
    const memoAllSourceBtn4 =  useMemo(() => {
        let allSources = [] ;
        if(userContextFilters && userContextFilters['filter-types'] && userContextFilters['filter-types'][4]){
            let btnName = userContextFilters['filter-types'][4];
            userContextFilters[btnName].forEach(filter => {
                if(filter['sources']){
                    filter['sources'].forEach(src => {
                        allSources.push(src);
                    });
                }
            });
            if(btnName && btnName ==="Web"){
                allSources =[getGoogleSource()];
            }
        }
       
        return allSources;
    }, [userContextFilters]);
    // TO DO refactor to update on filter update (first change filter update)

    const memoSourceToUseBtn = useMemo(() => {
        let allSourcesToUse = [] ;
        if( userContextFilters && userContextFilters['filter-types']){
            let allBtnNames = getBtnNames() ;
            allBtnNames.forEach(name => {
                let tempDefaultSources = [];
                let tenpAllSourcesInBtn = [];
                userContextFilters[name].forEach(filter => {
                    if(filter['sources'] && filter['is_default']){
                        filter['sources'].forEach(src => {
                            tempDefaultSources.push(src);
                        });
                    }
                    if(filter['sources']){
                        filter['sources'].forEach(src => {
                            tenpAllSourcesInBtn.push(src);
                        });
                    }
                });
                if(name ==="Web"){
                    tempDefaultSources =[getGoogleSource()];
                }
                if(tempDefaultSources.length>0){
                    allSourcesToUse.push(tempDefaultSources);
                }
                else {
                    allSourcesToUse.push(tenpAllSourcesInBtn);
                }
            });
        }
        return allSourcesToUse;
            
    }, [userContextFilters]);


    // TO DO refactor to update on filter update (first change filter update)
    const memoAllDefaultSourceBtn0 = useMemo(() => {
        let allDefaultSources = [] ;
        let  userFilters = userContextFilters;
        if( userFilters && userFilters['filter-types'] && memoBtn0Name){
            userFilters[memoBtn0Name].forEach(filter => {
                if(filter['sources'] && filter['is_default']){
                    filter['sources'].forEach(src => {
                        allDefaultSources.push(src);
                    });
                }
            });
            if(memoBtn0Name && memoBtn0Name ==="Web"){
                allDefaultSources =[getGoogleSource()];
            }
        }
        return allDefaultSources;
    }, [ memoBtn0Name , userContextFilters ]);

    // TO DO refactor to update on filter update (first chang e filter update)
    const memoAllDefaultSourceBtn1 =  useMemo(() => {
        let allDefaultSources = [] ;
        let  userFilters = userContextFilters;
        if(userFilters && userFilters['filter-types'] && memoBtn1Name){
            userFilters[memoBtn1Name].forEach(filter => {
                if(filter['sources']  && filter['is_default']){
                    filter['sources'].forEach(src => {
                        allDefaultSources.push(src);
                    });
                }
            });
            if(memoBtn1Name && memoBtn1Name ==="Web"){
                allDefaultSources =[getGoogleSource()];
            }
        }
        return allDefaultSources;
    }, [memoBtn1Name ,userContextFilters]);

    // TO DO refactor to update on filter update (first chang e filter update)
    const memoAllDefaultSourceBtn2 =  useMemo(() => {
        let allDefaultSources = [] ;
        let  userFilters = userContextFilters;
        if(userFilters && userFilters['filter-types'] && memoBtn2Name){
            userFilters[memoBtn2Name].forEach(filter => {
                if(filter['sources'] && filter['is_default']){
                    filter['sources'].forEach(src => {
                        allDefaultSources.push(src);
                    });
                }
            });
            if(memoBtn2Name && memoBtn2Name ==="Web"){
                allDefaultSources =[getGoogleSource()];
            }
        }
        return allDefaultSources;
    }, [memoBtn2Name,userContextFilters]);

    // TO DO refactor to update on filter update (first chang e filter update)
    const memoAllDefaultSourceBtn3 =  useMemo(() => {
        let allDefaultSources = [] ;
        let  userFilters = userContextFilters;
        if(userFilters && userFilters['filter-types'] && userFilters['filter-types'][3] && memoBtn3Name){
            userFilters[memoBtn3Name].forEach(filter => {
                if(filter['sources'] && filter['is_default']){
                    filter['sources'].forEach(src => {
                        allDefaultSources.push(src);
                    });
                }
            });
            if(memoBtn3Name && memoBtn3Name ==="Web"){
                allDefaultSources =[getGoogleSource()];
            }
        }
        return allDefaultSources;
    }, [memoBtn3Name ,userContextFilters]);

    const memoAllDefaultSourceBtn4 =  useMemo(() => {
        let allDefaultSources = [] ;
        let  userFilters = userContextFilters;
        if(userFilters && userFilters['filter-types'] && userFilters['filter-types'][4] && memoBtn4Name){
            userFilters[memoBtn4Name].forEach(filter => {
                if(filter['sources'] && filter['is_default']){
                    filter['sources'].forEach(src => {
                        allDefaultSources.push(src);
                    });
                }
            });
            if(memoBtn4Name && memoBtn4Name ==="Web"){
                allDefaultSources =[getGoogleSource()];
            }
        }
        
        return allDefaultSources;
    }, [memoBtn4Name,userContextFilters]);




    // TO DO refactor to update on filter update (first change filter update)
    const memoBtn0Name = useMemo(() => {
        let btnName = "Web"
        let userFiltersInfo = userContextFilters;
        if(userFiltersInfo && userFiltersInfo['filter-types']){
            btnName = userFiltersInfo['filter-types'][0];
        }
        if(userFiltersInfo && userFiltersInfo['sidebar-order']){
            btnName = userFiltersInfo['sidebar-order'][0];
        }
        return btnName;
    }, [userContextFilters]);
    
    // TO DO refactor to update on filter update (first chang e filter update)
    const memoBtn1Name =  useMemo(() => {
        let btnName = "Local"
        if(userContextFilters && userContextFilters['filter-types'] && userContextFilters['filter-types'][1]){
            btnName = userContextFilters['filter-types'][1];
        }
        if(userContextFilters && userContextFilters['sidebar-order'] && userContextFilters['sidebar-order'][1]){
            btnName = userContextFilters['sidebar-order'][1];
        }
        return btnName;
    }, [userContextFilters]);
    // TO DO refactor to update on filter update (first chang e filter update)
    const memoBtn2Name =  useMemo(() => {
        let btnName = "Medwise"
        if(userContextFilters && userContextFilters['filter-types'] && userContextFilters['filter-types'][2]){
            btnName = userContextFilters['filter-types'][2];
        }
        if(userContextFilters && userContextFilters['sidebar-order'] && userContextFilters['sidebar-order'][2]){
            btnName = userContextFilters['sidebar-order'][2];
        }
        return btnName;
    }, [userContextFilters]);
    // TO DO refactor to update on filter update (first chang e filter update)
    const memoBtn3Name =  useMemo(() => {
        let btnName = "Patient"
        if(userContextFilters && userContextFilters['filter-types'] && userContextFilters['filter-types'][3]){
            btnName = userContextFilters['filter-types'][3];
        }
        if(userContextFilters && userContextFilters['sidebar-order'] && userContextFilters['sidebar-order'][3]){
            btnName = userContextFilters['sidebar-order'][3];
        }
        return btnName; }, [userContextFilters]);
    
    // TO DO refactor to update on filter update (first chang e filter update)
    const memoBtn4Name =  useMemo(() => {
        let btnName = "Patient"
        if(userContextFilters && userContextFilters['filter-types'] && userContextFilters['filter-types'][4]){
            btnName = userContextFilters['filter-types'][4];
        }
        if(userContextFilters && userContextFilters['sidebar-order'] && userContextFilters['sidebar-order'][4]){
            btnName = userContextFilters['sidebar-order'][4];
        }
        return btnName; }, [userContextFilters]);


    // range value change by :  
    useEffect(() => {

        let btnNames = getBtnNames();

        if(typeof rangeValue !== 'undefined'  && rangeValue !== 99 ){
            let index = rangeValue/50;
            if( btnNames && btnNames.length && index <= btnNames.length ){
                let tempSourcesToSend = getFacetsForBtnName(btnNames[index]);
                if(tempSourcesToSend &&  tempSourcesToSend.length >0 && btnSelected && !btnSelected[index]) {
                    if (searchContext.q) {
                        if (countWords(searchContext.q) > 4)
                            setIsResultsExpanded(true);
                        else
                            setIsResultsExpanded(false);
                    };
                    setLoadMore(undefined);
                    setClearLocalWebResults(false);
                    setSearchContext(currentVal => 
                        {return {...currentVal,
                                facets: tempSourcesToSend.slice(), 
                                forceSearch: true
                            };});
                }
                else {
                    if(tempSourcesToSend<=0 && memoAllSourceBtn0 && memoAllSourceBtn0.length>0 && btnSelected && !btnSelected[index]){
                        if (searchContext.q) {
                            if (countWords(searchContext.q) > 4)
                                setIsResultsExpanded(true);
                            else
                                setIsResultsExpanded(false);
                        };
                        setLoadMore(undefined);
                        setClearLocalWebResults(false);
                        setSearchContext(currentVal => 
                            {return {...currentVal,
                                    facets: memoAllSourceBtn0.slice(), 
                                    forceSearch: true
                                };});
                    }
                }
            }
           
        }
        // if( pvtRangeValue !== rangeValue){
        if(rangeValue === 0 ){
            // if(filters.length !== 1){
            let sourcesToSend0 = getFacetsForBtnName(btnNames[0]);
            if (sourcesToSend0 &&  sourcesToSend0.length >0 && !allBtn0Selected) {
                if (searchContext.q) {
                    if (countWords(searchContext.q) > 4)
                        setIsResultsExpanded(true);
                    else
                        setIsResultsExpanded(false);
                };
                setLoadMore(undefined);
                setClearLocalWebResults(false);
                setSearchContext(currentVal => 
                    {return {...currentVal,
                            facets: sourcesToSend0.slice(), 
                            forceSearch: true
                        };});
            }
            else{
                if(sourcesToSend0<=0 && memoAllSourceBtn0 && memoAllSourceBtn0.length>0 && !allBtn0Selected){
                    if (searchContext.q) {
                        if (countWords(searchContext.q) > 4)
                            setIsResultsExpanded(true);
                        else
                            setIsResultsExpanded(false);
                    };
                    setLoadMore(undefined);
                    setClearLocalWebResults(false);
                    setSearchContext(currentVal => 
                        {return {...currentVal,
                                facets: memoAllSourceBtn0.slice(), 
                                forceSearch: true
                            };});
                }
            }
        }
        if (rangeValue === 50 ){
            let sourcesToSend1 = getFacetsForBtnName(btnNames[1]);
            if(sourcesToSend1 &&  sourcesToSend1.length >0 && !allBtn1Selected){
                if (searchContext.q) {
                    if (countWords(searchContext.q) > 4)
                        setIsResultsExpanded(true);
                    else
                        setIsResultsExpanded(false);
                };
                setLoadMore(undefined);
                setClearLocalWebResults(false);
                setSearchContext(currentVal => 
                    {return {...currentVal,
                            facets: sourcesToSend1.slice(), 
                            forceSearch: true
                        };});
            }
        }
        if (rangeValue === 100 ){
            let sourcesToSend2 = getFacetsForBtnName(btnNames[2]);
            if(sourcesToSend2 && sourcesToSend2.length >0 && !allBtn2Selected) {
                if (searchContext.q) {
                    if (countWords(searchContext.q) > 4)
                        setIsResultsExpanded(true);
                    else
                        setIsResultsExpanded(false);
                };
                setLoadMore(undefined);
                setClearLocalWebResults(false);
                setSearchContext(currentVal => 
                    {return {...currentVal,
                            facets: sourcesToSend2.slice(), 
                            forceSearch: true
                        };});
            }
        }
        if (rangeValue=== 150 ){
            let sourcesToSend3 = getFacetsForBtnName(btnNames[3]);
            if(sourcesToSend3  && sourcesToSend3.length>0 && !allBtn3Selected ){
                if (searchContext.q) {
                    if (countWords(searchContext.q) > 4)
                        setIsResultsExpanded(true);
                    else
                        setIsResultsExpanded(false);
                };
                setLoadMore(undefined);
                setClearLocalWebResults(false);
                setSearchContext(currentVal => 
                    {return {...currentVal,
                            facets: sourcesToSend3.slice(), // there is no default option for btn3  
                            forceSearch: true
                        };});
            }
        }
        if (rangeValue=== 200 ){
            let sourcesToSend4 = getFacetsForBtnName(btnNames[4]);
            if(sourcesToSend4  && sourcesToSend4.length>0 && !allBtn4Selected ){
                if (searchContext.q) {
                    if (countWords(searchContext.q) > 4)
                        setIsResultsExpanded(true);
                    else
                        setIsResultsExpanded(false);
                };
                setLoadMore(undefined);
                setClearLocalWebResults(false);
                setSearchContext(currentVal => 
                    {return {...currentVal,
                            facets: sourcesToSend4.slice(), // there is no default option for btn3  
                            forceSearch: true
                        };});
            }
        }
      },[rangeValue]);





    // when searchContext changes update btn
    useEffect (()=>{
        let btnNames = getBtnNames(); 
        if( userContextFilters && btnNames && memoAllUrlsBtns ){
            let dictOfUrls = [] ;
            memoAllUrlsBtns.forEach(urlOfBtn => {
                let tempsDictUrls = urlOfBtn.reduce((dictionary,url) =>{
                    dictionary[url]=true;
                    return dictionary;
                },{}) ;
                dictOfUrls.push(tempsDictUrls);
            });
            // remove every src from dics
            searchContext.facets.forEach(filter => {
                let tempUrl = filter["encrypted_url"];
                // sometime the facets is a single source and not a filter
                // we need 4 int bc some sources can be in >1 btn/tooggle
                if(filter['sources'] && !tempUrl)
                {
                    filter['sources'].forEach(src => {
                        let tempSourceUrl = src["encrypted_url"];

                        dictOfUrls.forEach(tempDict => {
                            if(tempSourceUrl && tempDict[tempSourceUrl]){
                                // it is in local sources
                                delete tempDict[tempSourceUrl];
                            }
                        });
                    });
                }
                // is a source 
                else {
                    if(tempUrl ){

                        dictOfUrls.forEach(tempDict => {
                            if(tempUrl && tempDict[tempUrl]){
                                // it is in local sources
                                delete tempDict[tempUrl];
                            }
                        });
                    }
                }
            });
            let array = [];
            // console.log('dict of url length',Object.keys(dictOfUrls[0]).length);
            // console.log(' facets lenght',  searchContext.facets.length);
            if( searchContext.facets && dictOfUrls && dictOfUrls[0] && Object.keys(dictOfUrls[0]).length === 0 && searchContext.facets.length === getFacetsForBtnName(memoBtn0Name).length){
                setRangeValue(0);
                setBtnSelected([true,false,false,false,false]);
                setAllBtn0Selected(true);
                setAllBtn1Selected(false);
                setAllBtn2Selected(false);
                setAllBtn3Selected(false);
                setAllBtn4Selected(false);
            }
            else if(searchContext.facets && dictOfUrls && dictOfUrls[1] && Object.keys(dictOfUrls[1]).length === 0 && searchContext.facets.length === getFacetsForBtnName(memoBtn1Name).length )
            {
                setRangeValue( 50 );
                setBtnSelected([false,true,false,false,false]);
                setAllBtn0Selected(false);
                setAllBtn1Selected(true);
                setAllBtn2Selected(false);
                setAllBtn3Selected(false);
                setAllBtn4Selected(false);

                // setIsActive(1);
                //check if all local filters
            }
            else if(searchContext.facets && dictOfUrls && dictOfUrls[2] && Object.keys(dictOfUrls[2]).length === 0 && searchContext.facets.length === getFacetsForBtnName(memoBtn2Name).length  ) 
            {
                setRangeValue(100 );
                setBtnSelected([false,false,true,false,false]);
                setAllBtn0Selected(false);
                setAllBtn1Selected(false);
                setAllBtn2Selected(true);
                setAllBtn3Selected(false);
                setAllBtn4Selected(false);
            }
            else if(searchContext.facets && dictOfUrls && dictOfUrls[3] && Object.keys(dictOfUrls[3]).length === 0 && searchContext.facets.length === getFacetsForBtnName(memoBtn3Name).length  ) 
            {
                setRangeValue(150);
                setBtnSelected([false,false,false,true,false]);
                setAllBtn0Selected(false);
                setAllBtn1Selected(false);
                setAllBtn2Selected(false);
                setAllBtn3Selected(true);
                setAllBtn4Selected(false);
            }
            else if(searchContext.facets && dictOfUrls && dictOfUrls[4] && Object.keys(dictOfUrls[4]).length === 0 && searchContext.facets.length === getFacetsForBtnName(memoBtn4Name).length  ) 
            {
                setRangeValue(200 );
                setBtnSelected([false,false,false,false,true]);
                setAllBtn0Selected(false);
                setAllBtn1Selected(false);
                setAllBtn2Selected(false);
                setAllBtn3Selected(false);
                setAllBtn4Selected(true);
            }
            else{
                setRangeValue( 99 );
                setAllBtn0Selected(false);
                setAllBtn1Selected(false);
                setAllBtn2Selected(false);
                setAllBtn3Selected(false);
                setAllBtn4Selected(false);
                setBtnSelected([false,false,false,false,false]);
            }
        }
    },[searchContext , userContextFilters ,memoAllUrlsBtns]); 


    const handleLocalClick =( )=>{
        // //is_anonymous
        if(userContext && userContext['is_anonymous']){
            setShowModal(true);
        }
    };


    return (
        <BtnFilterContainer> 
            {memoSourceToUseBtn.map((btnFilters,i) => {
                return(
                <>
                    { (btnFilters.length >0 || btnFilters ==="Web") ? 
                        (
                            <span className= {(rangeValue === i*50) ? 'filter-tab first-tab  active':'filter-tab first-tab'}
                            onClick={()=>{setRangeValue(val => i*50)}} 
                            >
                                {i===0 && (<MedwiseLogo className="filter-icon" />)}
                                {i ==1 && (<FeatherIcon icon="map-pin" className="filter-icon" />)}
                                {i ==2 && (<FeatherIcon icon="heart" className="filter-icon" />)}
                                {i ==3 && (<FeatherIcon icon="book-open"className="filter-icon" />)}
                                {i ==4 && (<FeatherIcon icon="globe" className="filter-icon" />)}
                                {getBtnNames()[i]}
                            </span>
                        ) : (
                            <>
                            {userContext && userContext['is_anonymous'] && (
                                <span className="filter-tab first-tab grey" onClick={()=> handleLocalClick(getBtnNames()[i])}>
                                    {i ==0 && (<FeatherIcon icon="home" className="filter-icon" />)}
                                    {i ==1 && (<FeatherIcon icon="map-pin" className="filter-icon" />)}
                                    {i ==2 && (<FeatherIcon icon="heart" className="filter-icon" />)}
                                    {i ==3 && (<FeatherIcon icon="book-open"className="filter-icon" />)}
                                    {i ==4 && (<FeatherIcon icon="globe" className="filter-icon" />)}
                                    {getBtnNames()[i]}
                                </span>
                            )}
                            </>
                        )
                    }
                </>
                );}
            )}


            <ModalNotLoggedIn 
                modalFeedbackShow= {showModal } 
                modalFeedbackClose={()=> setShowModal(false)}
                title="Please login for more sources"
                ></ModalNotLoggedIn>
        </BtnFilterContainer>
                

    );
};

export default BtnFiltersCard;