import React from 'react';
import Autosuggest from 'react-autosuggest';
import ContainerSuggest from "./styles";
import levenshtein from 'js-levenshtein';

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value,options) => {
  let val = value.trim();
  // let valLenght = val.lenght;
  // let out = [] ; 
  // const escapedValue = escapeRegexCharacters(val);
  if (val === '') {
    return [];
  }
  // const regex = new RegExp('^' + escapedValue, 'i');
  options.sort((a, b) => {
    let arrayA = a.trim().split(' ');
    let arrayB = b.trim().split(' ');
    let minA = levenshtein(val.toLowerCase(),a.toLowerCase());
    let minB = levenshtein(val.toLowerCase(),b.toLowerCase());
    if(val.length === 1){
        if(val.toLowerCase()===arrayA[0].toLowerCase().split('')[0]){
          let tempVal =-999;
          minA =tempVal;
        }
        if(val.toLowerCase()===arrayB[0].toLowerCase().split('')[0]){
          let tempVal = -999;
          minB = tempVal;
        }
    }
    else{
      arrayA.forEach(word => {
        let tempVal = levenshtein(val.toLowerCase(),word.toLowerCase());
        if(tempVal < minA){
          minA =tempVal;
        }
    });
    arrayB.forEach(word => {
        let tempVal = levenshtein(val.toLowerCase(),word.toLowerCase());
        if(tempVal < minB){
          minB = tempVal;
        }    
    });
    }
    return (minA-minB);
  });
  return options.slice(0,10);
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => {
  return suggestion;
};

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion}
  </div>
);

class OrgFieldAutosuggest extends React.Component {
  constructor(props) {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.

    this.state = {
      value: '',
      suggestions: [],
      options: props.options
    };
    // this.state.value= this.props.data;

  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
    this.props.setData(newValue);
    this.props.modifyError(false);
    };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    // console.log('69 sattae', this.state);
    this.setState({
      suggestions: getSuggestions(value,this.state.options)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Start typing...',
      value,
      onChange: this.onChange
    };

    // Finally, render it!
    return (
      <ContainerSuggest className={this.props.error ? 'error': ''}>
      <Autosuggest 
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
      </ContainerSuggest>
    );
  }
}

export class AccountOrgFieldAutosuggest extends React.Component {
  constructor(props) {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.

    this.state = {
      value: props.value,
      suggestions: [],
      options: props.options
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
    this.props.setData(newValue);
    };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value,this.state.options)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Type your info',
      value,
      onChange: this.onChange
    };

    // Finally, render it!
    return (
      <ContainerSuggest>
      <Autosuggest 
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
      </ContainerSuggest>
    );
  }
}

export default OrgFieldAutosuggest;
