import styled, { css } from 'styled-components';
import { linkStyles } from '../../components/theme/link';

const sharedFormWrapper = css`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${({ theme }) => theme.palette.backgroundWhite};
  border-radius: ${({ theme }) => theme.borderradius.medium};
  box-shadow: 0 5px 9px ${({ theme }) => theme.palette.backgroundStrokeHover};
  padding: ${({ theme }) => theme.spacing.x5} ${({ theme }) => theme.spacing.x6};
  margin-top: ${({ theme }) => theme.spacing.x5};

  @media (max-width: 767.98px) {
    margin-top: ${({ theme }) => theme.spacing.x3};
    padding: ${({ theme }) => theme.spacing.x3};
    box-shadow: none;
  }
`;

export const FormWrapper = styled.div`
  ${sharedFormWrapper};
`;

export const SwitchFlow = styled.div`
  text-align: center;
  margin: ${({ theme }) => theme.spacing.x5} 0;

  @media (max-width: 767.98px) {
    margin: ${({ theme }) => theme.spacing.x3} 0;
  }

  a {
    ${linkStyles}
  }
`;
