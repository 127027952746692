import React, { useState,useContext } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import { getToken } from '../../../functions/manageSessions';
import { AppContext } from '../../../context';

import Button from '../../button';

import { H1, Span, StyledLink, Label } from '../../theme/typography';
import { ReactComponent as EyeOnIcon } from '../../../assets/svgs/eye-on-icon.svg';
import { ReactComponent as EyeOffIcon } from '../../../assets/svgs/eye-off-icon.svg';
import {
  LoginFormTitleWrapper,
  FormFieldWrapper,
  TextInput,
  ShowHidePasswordEye,
  ErrorMessage,
  ForgotYourPasswordWrapper,
  LoginServerError,
} from '../styles';

const LoginFormAfterSignup = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const { setUserContext ,removeUserOrganisationContext} = useContext(AppContext);


  const history = useHistory();

  const signupSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid e-mail address')
      .required('Please complete this field'),
    password: yup.string().required('Please enter your password').min(8, ''),
  });

  // This useForm hook initiates react-hook-form lib.
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(signupSchema),
  });

  // This onSubmit method is part of a react-hook-form lib.
  const onSubmit = (data) => {
    // console.log('Log in Form Data: ', data);
    axios({
      method: 'post',
      url: `${window.BASE_URL_USER}login-user`,
      withCredentials: true ,
      data: JSON.stringify({
        csrf_token: getToken(),
        email: data.email,
        password: data.password,
      }),
    })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          const user_info = res.data.user_info;
          // TO DO also set filters 
          // setUserSession(user_info);
          removeUserOrganisationContext();
          setUserContext(user_info);
          setLoginError(false);
          window.location = window.BASE_API_URL
          // history.push('/');
        } else {
          setLoginError(true);
        }
      })
      .catch((error) => {
        // If the user hasn't confirmed their account, send them to /unconfirmed
        if(error.response.data.error_short && error.response.data.error_short === 'user_unconfirmed') {
          window.location = '/unconfirmed'
        }
        console.log('Error: ', error.response);
      });
  };

  const togglePasswordVisiblity = () => {
    setIsPasswordVisible(isPasswordVisible ? false : true);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoginFormTitleWrapper>
        <H1 h1 semibold textoffblack>
          Account successfully verified!
        </H1>
        <Span p1 regular textmainbody>
          Please login again below
        </Span>
      </LoginFormTitleWrapper>
      <Button
        type="link"
        colorprimary
        buttonlarge
        text="Go to log in"
        width="100%"
        to="/login"
      />
      {loginError && (
        <LoginServerError c1 regular colorError>
          Wrong email or password. Try again or click “Forgot your password”
        </LoginServerError>
      )}
      <ForgotYourPasswordWrapper>
        <StyledLink
          p1="true"
          regular="true"
          textlink="true"
          to="/passwordreset"
        >
          Forgot your password?
        </StyledLink>
      </ForgotYourPasswordWrapper>
    </form>
  );
};

export default LoginFormAfterSignup;
