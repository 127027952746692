import React from 'react';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';


export default function TypewriterAnimationChat({ string }){
    return(
        <Wrapper>
            <Typewriter 
                onInit={(typewriter) => {
                    typewriter.typeString(string)
                    .start()
                    .callFunction(() => {
                        if (document.querySelector(".Typewriter__cursor"))
                            document.querySelector(".Typewriter__cursor").remove();
                    });
                }}

                options={{ 
                    autoStart: true,
                    delay:50,
                    loop: false,
                    skipAddStyles: false,
                }}
            />
        </Wrapper>
    )
}


const Wrapper = styled.span`
display: inline-block;
text-align: left;
span{
    text-align: left;
    opacity: 1;
}
`
