import React, {useContext, useEffect,useState,useMemo} from 'react';
import { Styles} from './styles';
import { AppContext } from '../../context';
import { FaPills } from "react-icons/fa";
import { FaChild } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import {Span} from '../theme/typography';
import axios from 'axios';


const DrugNameButtons = () => {
  const {
    fetchedResults, // btnNames are here
    searchContext,  // need it for feedback
  } = useContext(
    AppContext
  );
  const [drugList, setDrugList] = useState([]);
  const [filterDrugList, setFilterDrugList] = useState([]);
  const [childrenOnly, setChildrenOnly] = useState(false);
  const [onlyChildrenDrugs, setOnlyChildrenDrugs] = useState(false);




  // useEffect(() => {
  //   let tempDrugList =[] ;// this list is filter
  //   let tempAllDrugList =[];//  not all filter all drugs results
  //   // tempDrugList = [{chunk_title: 'Paracetamol1', online_view_url:'google.com' },{chunk_title: 'Paracetamol2', online_view_url:'google.com' },{chunk_title: 'Paracetamol3', online_view_url:'google.com' }]
  //   if(fetchedResults && fetchedResults.length > 0 ){
  //     tempAllDrugList =  fetchedResults.filter((result) => result && result.result_type && (result.result_type.toLowerCase() === 'drug' ||result.result_type.toLowerCase() === 'drug_children' ) );
  //     tempDrugList =  fetchedResults.filter((result) => result && result.result_type && (result.result_type.toLowerCase() === 'drug'));
  //   }
  //   if(fetchedResults && fetchedResults.length > 0 && childrenOnly){
  //     tempDrugList =  fetchedResults.filter((result) => result && result.result_type && (result.result_type.toLowerCase() === 'drug_children' ) );
  //   }
  //   setDrugList(tempAllDrugList);
  //   setFilterDrugList(tempDrugList);
  // }, [fetchedResults,childrenOnly]);

  const adultDrugList = useMemo(
    () => {
      let tempDrugList = []; 
      if(fetchedResults && fetchedResults.length > 0){
        tempDrugList =  fetchedResults.filter((result) => result && result.result_type && (result.result_type.toLowerCase() === 'drug') );
      } 
      return tempDrugList;
    },
    [fetchedResults]
  );

  const childrenDrugList = useMemo(
    () => {
      let tempDrugList = []; 
      if(fetchedResults && fetchedResults.length > 0 ){
        tempDrugList =  fetchedResults.filter((result) => result && result.result_type && (result.result_type.toLowerCase() === 'drug_children') );
      } 
      return tempDrugList;
    },
    [fetchedResults]
  );

useEffect(() => {
  if(adultDrugList &&childrenDrugList && adultDrugList.length <=0 && childrenDrugList.length> 0 ){
    setChildrenOnly(true);
    setOnlyChildrenDrugs(true);
  }
  else{
    setChildrenOnly(false);
    setOnlyChildrenDrugs(false);
  }
}, [childrenDrugList,adultDrugList])

  const handleOnClick = (e,drug)=>{
    // e.stopPropagation();
    let body = {
      interaction_type: "visit",
      online_view_url: drug.online_view_url,
      q: searchContext.q,
    };
    const config = {
      method: 'post',
      url: `${window.BASE_URL_USER}log-result-interaction`,
      withCredentials : true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(body),
    };
    axios(config);
  };
  const handleCheckBoxClick = ()=>{
    // e.stopPropagation();
   setChildrenOnly(()=>{return !childrenOnly});
  };

  // const drugNames = ['Acetaminophen','Albuterol','Metoprolol','Acetaminophen','Albuterol','Metoprolol']
  if( (adultDrugList && childrenDrugList) && adultDrugList.concat(childrenDrugList).length > 0){
    //  && (userOrganisationContext && userOrganisationContext['drugs_buttons_enabled'])){


    return (
      <Styles 
      // style={{'border':'1px solid #c9d3da', 'margin':'1px 5px 0 5pxpx' }} 
       >
        {/* <div c2 >
         Access BNF through Medicines Complete
        </div> */}


        <div style={{'margin':'auto 0 auto 4px ','display':'flex','align-items': 'center','color':'212452'}}> Drug information from BNF: </div>

        {/* <div style={{'margin':'auto 0 auto 0px ','display':'flex','align-items': 'center','color':'212452', 'width':'100%'}}>
        <div style={{'margin':'auto 0 auto 0px ','display':'flex','align-items': 'center','color':'212452', 'width':'50%'}}> Access BNF through Medicines Complete: </div> */}

        {/* <div style={{overflow:'hidden',margin:' 0 5px 0 0',height:'100%',width:'17px', marginLeft:'5px'}}>
        <FaPills style={{height:'100%',width:'220%',}}></FaPills>
        </div> */}
        {/* <div style={{'width':'100%'}}>
          <FaPills style={{height:'35px'}} ></FaPills>
        </div> */}
        {/* <div style={{'margin':'auto 0 ','display':'flex','align-items': 'center', width:'100%','justify-content': 'space-between'}}>
        <FaPills style={{height:'35px'}} ></FaPills>
          <div style={{'margin':'auto 0 ','display':'flex','align-items': 'center'}}>
            <input type="checkbox"
                label="Children only"/>
            <label for="Children only" style={{'padding': '0px 0 0 3px'}}>
              <Span> <FaChild/> Children Only</Span>
            </label>
          </div>
        </div> */}
        {childrenDrugList && childrenDrugList.length > 0 &&(
        <div style={{'margin':'auto 0 auto 4px ','display':'flex','align-items': 'center'}}>
          <input type="checkbox"
              label="Children"
              checked= {childrenOnly}
              disabled= {onlyChildrenDrugs}
              onClick={()=>handleCheckBoxClick()}/>
          <label for="Children" style={{'padding': '0px 0 0 3px', 'display':'flex','align-items': 'center'}}>
            {/* <Span> <FaChild/> Children Only</Span> */}
            {/* <Span style={{}}>  */}
              <FaPills style={{'margin': '0px 3px 0 0px',}}/>
                For children
                {/* </Span> */}
          </label>
        </div>
        )}
        {/* </div> */}
        {!childrenOnly &&
        adultDrugList.map((drug) => {
          return (
          //   <>
          //   <ul class="tree">
          //     <li>
          //       <details open>
          //         <summary >Giant planets</summary>
          //         <ul>
          //           <li>
          //             <details open>
          //               <summary>Gas giants</summary>
          //               <ul>
          //                 <li>
          //                 <details open>
          //                   <summary className='selected'>Saturn</summary>
          //                   <ul>
          //                     <li className='selected'>satelite 0</li>
          //                     <li>satelite 1</li>
          //                   </ul>
          //                 </details>
          //                 </li>
          //               </ul>
          //             </details>
          //           </li>
          //           <li>
          //             <details>
          //               <summary>Ice giants</summary>
          //               <ul>
          //                 <li>Uranus</li>
          //                 <li>Neptune</li>
          //               </ul>
          //             </details>
          //           </li>
          //         </ul>
          //       </details>
          //     </li>
          //   </ul>
             <a onClick={(e) => handleOnClick(e, drug)} href={drug.online_view_url} 
                rel="noopener noreferrer" target='_blank' className='DrugNamePill' 
                id={'drugPill'}>
                {drug.chunk_title} </a>
                // </>
          );}
        )}
        {childrenOnly &&
        childrenDrugList.map((drug) => {
          return (
             <a onClick={(e) => handleOnClick(e, drug)} 
            //  style={{'border-color': 'blue',}}
              href={drug.online_view_url} 
                rel="noopener noreferrer" target='_blank' className='DrugNamePill' 
                id={'drugPill'}> 
                {/* <FaChild></FaChild> */}
                {drug.chunk_title} </a>
          );}
        )}
        {/* </div> */}
           {/* <div style={{position:'block'}}>
              <FaChild style={{height:'35px'}} ></FaChild>
              <input  type="checkbox"
              // checked ={ props.isCheckedDic[props.filter['sources'][0]['encrypted_url']] ?  props.isCheckedDic[props.filter['sources'][0]['encrypted_url']] : false }
              // onChange={()=> { handleCheckBoxClick (props.filter['sources'][0])} }
              ></input>
           </div> */}
      </Styles>
    );
  }
  else{
    return null;
  }
};

export default DrugNameButtons;
