import styled from 'styled-components';

export const SideFilterContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 3px 0 0 0;
    margin:  0 0 0 0 ;
    line-break: after;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #212452;
    // border: 1px solid rgba(0,0,0,0.125);
    border-radius: 3px;
    overflow: hidden;
    min-height: 36px;
    width:100%;
    option{
      cursor: pointer;
    }
    .filters-btn{
      // max-width:110px;
      // border-radius:8px;
      display: flex;
      border: 1px solid rgba(0,0,0,0.125);
      min-height: 36px;
      align-items: center;
    }
    .btn-text-wrapper-mobile{

      width: calc(100% - 14px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: auto; 
      :hover{
        cursor:pointer;
      }
    }
    .chevron-btn{
      height:14px;
      margin:auto 5px auto 0;
    }

    .greyOut{
      color: grey;
    }
    .source-link{
      svg{
        color: 212452;
      }
    }
    .filter-box{
        width:100%;
        background-color: #fff;
        margin-left:8px;
        padding-bottom:0;
    }
    .sources-filters{
      text-align: center;
      justify-content: space-between;

    }
  

  .filterGroups {
    // margin:8px;
    // max-width:110px;
    // border-radius:8px;
    
    display:flex;
    min-height: 36px;
    border: 1px solid rgba(0,0,0,0.125) ;
    border-left: 0px; 
    cursor: pointer;
    font-size: 14px;
    
    &.middle{
      border-right: none;
      border-left: none;
    }
    &.active{
      background-color:  #E4E0F7;
    }
    &.grey {
      color: grey;
      cursor: not-allowed;
      // border: 1px solid grey ;
    }
  }
        

    
`;


export default SideFilterContainer;
