import styled from 'styled-components';

export const BtnFilterContainer = styled.div`
    display: flex;
    align-items: end;
    padding: 0;
    // margin:  0 0 0 ${({ theme }) => theme.spacing.x2} ;
    line-break: after;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    color: #212452;
    overflow: hidden;

    .filter-tab {
      display:flex;
      padding: 4px 0px 11px 0px;
      margin-right: 12px;
      margin-left: 10px;
      font-size: 14px;
      cursor: pointer;
      height: 32px;
    }
    .filter-icon {
      width: 16px;
      height: 16px;
      vertical-align: text-bottom;
      margin-right: 5px;
      margin-top: 2px;
      margin-bottom: 2px;

    }
    
  .filter-tab.active {
    color: #8771DF;
    border-bottom: 3px solid #8771DF;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .filter-tab.grey {
    color: grey;
    cursor: pointer;
  }

  .first-tab {
    margin-left: 0;
  }

`;


export default BtnFilterContainer;
