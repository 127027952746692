import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  createRef,
  useMemo,
} from 'react';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { AppContext } from '../../context';
import { isColor, search, search2, stripTags } from '../../functions/utils';
import AcResult from './AcResult';
import Styles from './styles';
import { FaTimes } from 'react-icons/fa';
import { BiMessageDetail } from 'react-icons/bi';
import {suggest_on, sources} from '../../functions/frontend_variables';
import { getDefaultFilters,getAllSources } from '../../functions/manageSessions';
import AcUserResult from './AcUserResult';
import { removeDuplicatesFromSecondArray } from '../../functions/utils';

const ChatBar = ({ isAtHeader, btnColor }) => {
  const searchBox = createRef();
  const [acResults, setAcResults] = useState([]);
  const [acUserResults, setUserAcResults] = useState([]);
  const [combineSuggestions, setCombineSuggestions] = useState([]);
  const [value, setValue] = useState(''); // value has 2 function:  represent input value of the user, and trigger suggestions
  const [shadowValue, setShadowValue] = useState('');
  const [cursor, setCursor] = useState(0);
  const [triggerSuggest, setTriggerSuggest] = useState(false);
  const [searchBtnColor, setSearchBtnColor] = useState(undefined);
  const [resUserSuggest, setResUserSuggest] = useState(undefined); // only need for logs 
  const [tempQuery, setTempQuery] = useState(undefined); 

  const {
    searchContext,
    setSearchContext,
    setNewQuestion,
    setChatIndex,
    setWaitForNextAnswer,
    setQueries,
    userContextFilters,
    queueDone,
  } = useContext(AppContext);

  const controller = new AbortController();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const allSources = useMemo( ()=> 
    {
      let allSourcesObj= getAllSources();
      let allSources = '';

      allSourcesObj.forEach(element => {
        if (element['encrypted_url']){
          allSources = allSources + ',' +element['encrypted_url'] ;
        }
      });
      allSources = allSources.substring(1);
      return allSources;
    } ,[userContextFilters]);

  // useEffect(() => {

  //   // if(suggest_on && userContext['sources']){
  //   if(suggest_on && ( value !=="" || triggerSuggest ) ){
  //     if ( typeof value === "string") {
  //       axios({
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         url: window.BASE_URL_ASK+"suggest",
  //         withCredentials: true,
  //         signal: controller.signal,
  //         cancelToken: source.token,
  //         data: JSON.stringify({
  //           q: value,
  //           sources: allSources,
  //         }),
  //         })
  //         .then((res) => {
  //           if(typeof res.data['suggestions'] !== undefined ){
  //             let noDublicatesSuggestions = [...new Set(res.data['suggestions'])];
  //             setAcResults(noDublicatesSuggestions);
  //           }
  //         })
  //         .catch((error) => {
  //             console.log("suggestion",error);
  //         }); 

  //       axios({
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           url: window.BASE_URL_USER+"user-suggestions",
  //           withCredentials: true,
  //           signal: controller.signal,
  //           cancelToken: source.token,
  //           data: JSON.stringify({
  //             q: value,
  //             sources: allSources,
  //           })
  //         })
  //         .then((res) => {
  //             if(typeof res.data['suggestions'] !== undefined ){
  //               setResUserSuggest(res.data);
  //               if (res.data['org-suggestions'] !== undefined) {
  //                 var allSuggestions = [...new Set(res.data['suggestions'].concat(res.data['org-suggestions']))];// set do no have repeat but its an object 
  //                 setUserAcResults(allSuggestions); // return 
  //               } else {
  //                 setUserAcResults(res.data['suggestions']);
  //               }
  //             }
  //         })
  //         .catch((error) => {
  //             console.log("suggestion",error);
  //         });     
  //     }
  //   }
  //   return () => {
  //     // cancel pending request
  //     // also we need to cancel the request if ask is sent
  //     source.cancel('user-suggestions clean up');
  //   };
  // }, [value,triggerSuggest]);

  const handleInputChange = (e) => {
    // Set cursor to zero as user types, so that the cursor will not have an index to grab a value from
    setCursor(0); 
    // TODO also reset setHoveredAC ??  
    setValue(e.target.value);// trigger suggestions 
    setShadowValue(e.target.value); //change shaddow value
  };

  const handleInputClick = () => {
    if (shadowValue) {
      setValue(shadowValue);
    }
    else{
      setValue('');
      setTriggerSuggest(true);
    }
  };

  // This where the submission is. 
  // Change of "q" parameter in setSearchContext triggers the API call in context if queryFromACClick or shadowValue exists.
  // queryFromACClick is passed to AcResult.js  && AcUserResult-> We no longer update the shadowValue on each AcResult hover. Therefore, 'clicked value' needs to be passed to this function to trigger the search.
  const querySubmit = useCallback(
    (e, queryFromACClick) => {
      e.preventDefault();
      let query;
      if (queryFromACClick) {
        query = stripTags(queryFromACClick);
      } else if (shadowValue) {
        query = shadowValue;
      };
      setShadowValue('');
      setValue('');
      if (query && query.length>0) {
        query= query.trim();

        setNewQuestion(newQuestion => [...newQuestion, query]);
        setQueries(queries => [...queries, query]);

        if (queueDone) { // No queue
          setChatIndex(prev => prev + 1);
          // This state triggers the API call
          setSearchContext({
            ...searchContext,
            q: query,
            forceSearch: true,
          });
          closeAutoCompleteWrapperSubmit();
        } else { // Have queue
          setTempQuery(query);
          setWaitForNextAnswer(true);
        };
      };
    },
    // eslint-disable-next-line
    [shadowValue, searchContext, queueDone]
  );

  useEffect(() => {
    // Queue finishes
    if (queueDone && tempQuery) {
      setWaitForNextAnswer(false);
      setChatIndex(prev => prev + 1);
      // This state triggers the API call
      setSearchContext({
        ...searchContext,
        q: tempQuery,
        forceSearch: true,
      });
      closeAutoCompleteWrapperSubmit();
      setTempQuery(undefined);
    };
  }, [queueDone])

  // Close the autocomplete wrapper. Same as useOutsideClickDetector and escape button press
  const closeAutoCompleteWrapper = () => {
    setShadowValue(value);
    setValue('');
    setAcResults(undefined);
    setUserAcResults(undefined);
    setCursor(0);
    //TO DO , should setHoveredAC also be reset ?? 
  };

  // Close the autocomplete wrapper for submit
  const closeAutoCompleteWrapperSubmit = () => {
    // setShadowValue(shadowValue);
    setValue('');
    setAcResults(undefined);
    setUserAcResults(undefined);
    setCursor(0);
    //TO DO , should setHoveredAC also be reset ?? 
  };

  const clearSearch = () => {
    setValue('');
    setShadowValue('');
    setAcResults(undefined);
    setUserAcResults(undefined);
    //TO DO , should setHoveredAC also be reset ?? 

  };

  const useOutsideClickDetector = (ref) => {
    useEffect(() => {
      const handleClickOutside = (e) => {
        // Below !e.target.value condition prevents autocomplete to re-run when input field is repetitevely clicked
        if (ref.current && !ref.current.contains(e.target) && !e.target.value) {
          setAcResults(undefined);
          setUserAcResults(undefined);
          setCursor(0);
          //TO DO , should setHoveredAC also be reset ?? 
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const cleanValueSuggestion = (i, combined_array) => {
    let cleanValue ;
      if( combined_array && i<combined_array.length && combined_array[i] && typeof combined_array[i]!== "string"  
          && typeof combined_array[i]["suggestion"] === "string" ){
        console.log(combined_array[i]);
        cleanValue = stripTags(combined_array[i]["suggestion"]);
      }
      else{
        cleanValue = stripTags(combined_array[i]);
      }
    console.log ('tempCleanValue', cleanValue);
    return cleanValue;
  };


  const handleKeyDown = (e)=>{
    // console.log('key');
    let combined_array = [];
    if (acUserResults && acUserResults.length > 0){
      combined_array =acUserResults;
      // get the second array without duplicates
      let tempUserResults =  acUserResults.map(x=>x["suggestion"]  );
      if (tempUserResults && tempUserResults.length > 0 && acResults && acResults.length>0){
        let tempList = removeDuplicatesFromSecondArray(tempUserResults,acResults) ;
        combined_array =acUserResults.concat(tempList);
      }
    }
    else {
      combined_array = acResults;
    }
    let tempIndex;
    let updateDisplay= false;
    if(e.key === "ArrowDown"){
      tempIndex = (cursor +1) %  (combined_array.length+1);
      updateDisplay = true;
      setCursor(prev =>  tempIndex );
    }
    if(e.key === "ArrowUp"){
      e.preventDefault();
      updateDisplay= true;
      tempIndex= (cursor -1) %  (combined_array.length+1);
      if(tempIndex <0){
        tempIndex = tempIndex + (combined_array.length+1);
      }
      setCursor(prev => tempIndex) ;
    }
    if(e.key === "Escape"){
      setCursor(0);
      updateDisplay= true;
      closeAutoCompleteWrapper();
    } 
    if(tempIndex === 0 &&  updateDisplay === true ){
      setShadowValue(value);
    }
    else{
      if(updateDisplay === true ){
        let tempCleanValue = cleanValueSuggestion(tempIndex-1, combined_array);
        setShadowValue(tempCleanValue) ;
      }
    }
  };

  const acRef = useRef(null);
  useOutsideClickDetector(acRef);

  useEffect(() => {
    if (isColor(btnColor) ) {
      setSearchBtnColor(btnColor);
    }
    else{
      setSearchBtnColor(undefined);
    }
  }, [btnColor]);

  // useEffect(() => {
  //   setShadowValue(searchContext.q);
  // }, [searchContext.q]);

  return (
    <Styles isAtHeader={isAtHeader} isMobile= { isMobile}>
      <form onSubmit={querySubmit}>
        <div className="search_container">
          <div className="search_inputGroupWithButton">
            <div className="search_inputGroup">
              <div className="search_inputGroup--magnify" aria-label="Search">
                <BiMessageDetail title="search icon" />
              </div>
              <input
                type="text"
                className={`search_inputGroup--input ${acResults ? 'active' : '' } chatinput`} // should acUserResult alos be here ???
                value={shadowValue}
                onInput={(e) => handleInputChange(e)}
                onClick={()=>handleInputClick()}
                placeholder="Ask now..."
                ref={searchBox}
                autoFocus={!isMobile }
                onKeyDown={(e) => handleKeyDown(e)}
              />
              {(shadowValue ? shadowValue : value) && (
                <>
                  <div className="search_inputGroup--clear">
                    <FaTimes onClick={clearSearch} title="clear icon"/>
                  </div>
                </>
              )}
            </div>
            <button
              className={`search_inputGroup--button ${acResults ? 'active' : ''}`} // should acUserResult alos be here ???
              type="submit"
              style={{'backgroundColor':searchBtnColor}}
            >
              {/* <span className="search_inputGroup--button--icon">
                <BiMessageDetail title="search icon" />
              </span> */}
              <span className="search_inputGroup--button--text" >Reply</span>
            </button>    
          </div>
        </div>
      </form>
    </Styles>
  );
};

export default ChatBar;
