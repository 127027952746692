import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Modal, Col, Row } from 'react-bootstrap';
import Button from '../button';
import {StyledModal,FormFieldWrapper,TextInput,ErrorMessage} from './styles';
import OrgFieldAutosuggest from '../org-field-autosuggest';

import './bootstrap-modal.min.css';
import { H2, P, Label } from '../theme/typography';
import { ReactComponent as CloseIcon } from '../../assets/svgs/close-icon.svg';

const ModalMandantoryQuestion = ({
  question,
  orgName,
  modalFeedbackShow,
  modalFeedbackClose,
  modalFeedbackData,
  centered,
  title,
  bodyText,
  errorParam,
  setErrorParam
}) => {
  const [textareaValue, setTextareaValue] = useState('');
  // const [allValues, setAllValues] = useState([]);
  const [dataToSend, setDataToSend] = useState('');


  // This hook initiates react-hook-form lib.
  const {
    register:register,
    getValues:getValues,
    handleSubmit:handleSubmit,
    errors:errors
   } = useForm({
    mode: 'onSubmit',
  });

  // Below effect clears the textarea every time the modal is triggered
  useEffect(() => {
    console.log('org name', orgName);
  });



  return (
    <StyledModal
      show={modalFeedbackShow}
      // onHide={() => modalFeedbackClose({ param['name']:'aaaa'})}
      centered={centered}
    >
      <Modal.Header>
        <H2 h2 semibold textoffblack>
          {orgName ? 'Welcome to Medwise for ' + orgName: 'Welcome to Medwise' }
        </H2>

      
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className='py-2' >
            {/* <Col md={12}> */}
            {question && (
              <P p1 semibold textoffblack > <div  dangerouslySetInnerHTML={{__html: question}} ></div> </P>)}
            {/* </Col> */}
          </Row>
          <Row>
              <Col md={12} style={{paddingLeft: "0px", paddingRight: "0px"}}>
                <FormFieldWrapper>

                  {errorParam &&(
                  <ErrorMessage c1 regular colorError >
                    {errorParam }
                  </ErrorMessage>)}   
                </FormFieldWrapper>
              </Col>
          </Row>
          <Row>     
            <Col md={10}>
            {/* <P c1 >The information will be saved on your account.</P> */}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="action"
          colorprimary
          buttonsmall
          text="I understand"
          width="96px"
          onClick={() =>
            modalFeedbackData(true)
          }
          //TODO , check if emtpy 
          // disabled={!textareaValue && true}
        />
        {/* <Button
          type="action"
          colorprimary
          buttonsmall
          text="logout"
          width="48px"
          onClick={() =>
            modalFeedbackData(false)
          }
          //TODO , check if emtpy 
          // disabled={!textareaValue && true}
        /> */}

      </Modal.Footer>
    </StyledModal>
  );
};

export default ModalMandantoryQuestion;
