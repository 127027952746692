import React, { useContext } from 'react';
import {  Route,useLocation} from 'react-router-dom';
import AliasCheckPage from '../pages/alias-check-page';
import { AppContext } from '../context';


import { getUser } from './manageSessions';

// accessToken and user objects must be present to visit private routes
function OrgRoute({ component: Component, ...rest }) {

  const {
    userOrganisationContext
  } = useContext(AppContext);

  let  url  = useLocation()['pathname'];
  let alias;
  if( userOrganisationContext &&userOrganisationContext["alias"]){
    alias = userOrganisationContext["alias"];
  }
  let urlAlias ;
  if(typeof  url.split('/')[1] !== 'undefined' ){ //! we assume that the url has to be form ~/alias/...
    urlAlias = url.split('/')[1];
  }


  return (
    <Route
      {...rest}
      render={(props) =>
        ( getUser() && alias && urlAlias && alias ===urlAlias)  ? (
          // display landing
          <Component {...props} />

        ) : (
          // error page with login 
          <AliasCheckPage {...props}  />
        )
      }
    />
  );
}

export default OrgRoute;
