import styled from 'styled-components';
import { theme } from '../../../theme/variables';

export const TagsWrapperProfile = styled.div`
  // height: 40px;
  // overflow:hidden;
  display: flex;
  flex-flow: row wrap;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout : none
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  .selected {
    background-color: ${({ theme }) => theme.palette.backgroundPurple};
    border: 1px solid #8771DF;
  }
  .selectable {
    background-color: ${({ theme }) => theme.palette.backgourndWhite};
  }
  .coloured {
    color: ${theme.palette.greenDark}; 
    background-color: ${ theme.palette.greenLight};
    border: 1px solid ${ theme.palette.greenDark};
  }
  .inactivity_alert{
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
    color: rgb(33, 36, 82);
    height: 26px ;
  }
  .hidden_inactivity_alert{
    display:none;
  }
  .removable{
    margin: 4px 0px 10px 10px;
  }
  .remove{
      display:none;
  }
  .remove_icon{
    color:#D46666;
    width:12px;
    height:21px; 
    position: relative;
    bottom: -10%;
    margin-left:3px;
    padding: 0px 1px 1px 0px;
    cursor: pointer;
  }

`;


export const TagsWrapperProfileAddSource = styled.div`

  margin-bottom:4px;
  display: flex;
  flex-flow: row wrap;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout : none
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  .selected {
    background-color: ${({ theme }) => theme.palette.backgroundPurple};
    border: 1px solid #8771DF;
  }
  .selectable {
    background-color: ${({ theme }) => theme.palette.backgourndWhite};
  }
  .coloured {
    color: ${theme.palette.greenDark}; 
    background-color: ${ theme.palette.greenLight};
    border: 1px solid ${ theme.palette.greenDark};
  }
  .inactivity_alert{
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
    color: rgb(33, 36, 82);
    height: 26px ;
  }
  .hidden_inactivity_alert{
    display:none;
  }
  .removable{
    margin: 4px 0px 10px 10px;
  }
  .remove{
      display:none;
  }
  .remove_icon{
    color:#D46666;
    width:12px;
    height:21px; 
    position: relative;
    bottom: -10%;
    margin-left:3px;
    padding: 0px 1px 1px 0px;
    cursor: pointer;
  }

`;

export const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  // background-color: ${({ theme }) => theme.palette.backgourndPurple};
  border: 1px solid ${({ theme }) => theme.palette.textPlaceholder};
  border-radius: ${({ theme }) => theme.spacing.x6};
  margin: 4px 16px 10px 0;
  padding: 4px ${({ theme }) => theme.spacing.x2};
  cursor: pointer;

  .remove {
    color: ${({ theme }) => theme.palette.textPlaceholder};
    margin-left: ${({ theme }) => theme.spacing.x2};
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.palette.textOffblack};
    }
  }
`;

export default TagsWrapperProfile;
