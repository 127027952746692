import styled from 'styled-components';

export const SideFeedback = styled.div`
    display: flex;
    align-items: center;
    min-height: ${({ theme }) => theme.spacing.x10};
    padding: ${({ theme }) => theme.spacing.x2} 0;
    line-break: after;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #212452;
    .sideFeedback{
      a{
        cursor: pointer;
        color:#1B5EF7;
      }
    }
    @media (max-width: 767.98px) {
      display:none;
      padding: 10px;
      min-height: 40px;
    }
`;

export const SuggestedQuestion = styled.div`
  display:flex-inline; 
  // padding-bottom: 0.75rem;
  font-size:14px;
  
  .robot-icon {
    vertical-align: middle;
    padding-bottom: 3px;
  }

  .spelling-text {
    color: #212452;
    vertical-align: middle;
  }

  .suggestedQuery{
    padding: 0.2rem;
    cursor: pointer;
    color: #1B5EF7;
    vertical-align: middle;
    
    :hover{
      text-decoration: underline;
    }
  }

  p{
    display:inline;
  }

`;

export default SideFeedback;
