import styled, { css } from 'styled-components';


export const Nav = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  width: 100vw;
  height: 64px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.backgroundWhite};
  // border-bottom: 1px solid ${({ theme }) => theme.palette.backgroundStroke};
  transform: translateY(0);
  transition: transform 0.25s ease-in-out;
  z-index: 998;

  &.withBorder {
    border-bottom: 1px solid ${({ theme }) => theme.palette.backgroundStroke};
  }

    #bookmark-close:hover {
    color: #000;
  }


  &.secondRow{
    position: absolute;
    top: 56px;
    height: 44px;

  }

  #bookmark {
    // display: none;
    padding: 28px 65px 28px 60px;
    font-size: 18px;
    background-color: #fff;
    width-max: 598px;
    margin: auto;
    margin-top: 15px;
    box-shadow: 0 10px 24px 0 rgb(54 61 77 / 15%);
    z-index: 999;
    line-height: 1.5;
   button{
     position:flex;
     content-align: right;
   } 
  }

  .close {
    color: #9ea0a5 !important;
    opacity: 0.5 !important;
  }
  
  .close:focus,
  .close:active {
    outline: none !important;
  }
  

  &.hidden {
    transform: translateY(-110%);
  }
  
  .navigation_links_login_button_intermediary_screens {
    display: none;
    
    @media (min-width: 1010px) and (max-width: 1379px) {
      display: block !important;
    }
  }

  #anony-sign-up {
    @media (max-width: 1166px) {
      display: none;
    }
  }

  .navigation {
    &_logoAndToggle {
      position: absolute;
      left: 0;
      // top:20px;
      display: flex;
      flex-flow: row nowrap;
      padding: 0 5px;
      width: 35%;
      min-width:300px;
      max-height:24px;

      a.logoWithName{
        @media (max-width: 767.98px) {
          display:none;
        }
      }
      a.logoWithoutName{
        @media (min-width: 767.98px) {
          display:none;
        }
      }

      
      MedwiseLogoWithName{
        min-width:140px;
      }
      label{
        margin-left:10px;
        margin-top:1px;
        max-width:160px;
        min-height:64px;
        max-height:64px;
      }

      #redirect-dashboard {
        display: flex;
        top: -20px;
        margin-left: 35px;
        font-size: 14px;
        height: 64px;
        border-radius: 0;
        outline: none; 
        border: none;
        padding-left: 5px;
        padding-right: 5px;
        color: #212452;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background-color: transparent;
        align-items: center;

        @media (max-width: 1010px) {
          display: none;
        }
      }

      #redirect-dashboard > * {
        vertical-align: middle;
      }
      
      #redirect-dashboard .feather {
        height: 18px;
        width: 18px;
        color: #212452;
      }
      
      #redirect-dashboard::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: var(--bar-colour);
        left: 0;
        bottom: 0;
        transform: translateY(3px);
        transition: transform 0.3s ease;
      }
      
      #redirect-dashboard:hover::after {
        transform: scale(1, 1);
      }

      #redirect-dashboard-search-bar {
        display: flex;
        top: -20px;
        margin-left: 35px;
        font-size: 14px;
        height: 64px;
        border-radius: 0;
        outline: none; 
        border: none;
        padding-left: 5px;
        padding-right: 5px;
        color: #212452;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background-color: transparent;
        align-items: center;

        @media (max-width: 1275px) {
          display:none;
        }
      }

      #redirect-dashboard-search-bar > * {
        height: 64px;
        vertical-align: middle;
      }
      
      #redirect-dashboard-search-bar .feather {
        height: 18px;
        width: 18px;
        color: #212452;
      }
      
      #redirect-dashboard-search-bar::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: var(--bar-colour);
        left: 0;
        bottom: 0;
        transform: translateY(3px);
        transition: transform 0.3s ease;
      }
      
      #redirect-dashboard-search-bar:hover::after {
        transform: scale(1, 1);
      }

      #my-dashboard {
        display: flex;
        flex-direction: column;
        height: 64px;
        justify-content: center;        
      }
    }
    &_toggle{
      margin: 0px; 
      position: absolute;
      right: 4% ;
    }
    &_toggle_login{
      margin:0px 3% 0px 0px;
      position: absolute;
      right: 0;
    }
    &_afterlogoAndToggle{
      position: absolute;
      color:red;
    }

    &_organisationName{
      font-size:18px;
      padding-top:1px ;
    }
    &_cdpCredits{
      padding: 0px 0px 0px 17px;
      @media (max-width: 880px) {
        display: none;
      }
    }

    &_searchbar {
      @media (max-width: 767.98px) {
        width: 90%;
      }
    }
    &_org_logo_mobile{
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      position: relative;
      max-width: 100%;
      max-height: 100%;

    }

    &_mobileMenuOverlay {
      display: none;
      position: fixed !important;
      top: 64px !important;
      right: 0px !important;
      bottom: 0px !important;
      left: 0px !important;
      z-index: 998;
      height: calc(100vh - 64px);
      background-color: ${({ theme }) => theme.palette.backgroundGrey};

      @media (max-width: 767.98px) {
        display: block;
      }
    }

    &_links {
      // max-width: 440.5px;
      position: absolute;
      right: 0;
      display: flex;
      padding-right: 16px;
      z-index: 997;

      .red-dot {
        height: 13px;
        width: 13px;
        position: relative;
        top: 10px;
        right: -62px;
        border: 2px solid #eaf1fb;
        background-color: #b3261e;
        border-radius: 50%;
        z-index: 1000;
      }

      &_login_button_intermediary_screens {
        display: none;
        @media (min-width: 1010px) and (max-width: 1379px) {
          display: block !important;
        }
      }

      .organisationLogo{
        width:115px;
        max-height:56px;
        margin-top:5px;
        margin-right:90px;
        overflow:hidden;
      }
      .organisationLogoMagic{
        width:115px;
        max-height:56px;
        margin-top:5px;
        margin-right:90px;
        overflow:hidden;
      }

      .organisationLogoWithSearchBar{
        width:115px;
        max-height:56px;
        margin-top:5px;
        margin-right:90px;
        overflow:hidden;
        @media (max-width:1175px){
          display: none;
        }
      }
      .organisationLogoWithSearchBarMagicUser{
        width:115px;
        max-height:56px;
        margin-top:5px;
        margin-right:90px;
        overflow:hidden;
        @media (max-width:1175px){
          display: none;
        }
      }

      .organisationNameWithSearchBar{
        border: 2px solid red;
        font-family: Mulish,sans-serif;
        font-weight: 500;
        color: #212452;
        font-size: 18px;
        // width: 150px;
        height: 32px;
        text-align: right;
        line-height: 32px;
        overflow:hidden;
        margin-right:100px;

        @media (max-width: 1250px) {
          display:none;
        }
      }
      .organisationNameWithSearchBarMagicUser{
        font-family: Mulish,sans-serif;
        font-weight: 500;
        color: #212452;
        font-size: 18px;
        width: 150px;
        height: 32px;
        text-align: right;
        line-height: 32px;
        overflow:hidden;
        @media (max-width: 1070px) {
          display:none;
        }
      }
      .organisationName{
        font-family: Mulish,sans-serif;
        font-weight: 600;
        color: #212452;
        font-size: 18px;
        width: 150px;
        height: 32px;
        text-align: right;
        line-height: 32px;
        overflow:hidden;
        margin-right:100px;
      }
      .organisationNameMagicUser{
        font-family: Mulish,sans-serif;
        font-weight: 600;
        color: #212452;
        font-size: 18px;
        width: 150px;
        height: 32px;
        text-align: right;
        line-height: 32px;
        overflow:hidden;
        margin-right:90px;
      }

      .navigation_searchbar{
        @media (min-width: 768px) and (max-width: 1380px) {
          display: none;
        }
      }

      .searchbar_magic{
        margin-right:100px;
      }

      .navigation_searchbar_magic{
        margin-right:100px;
        @media (min-width: 768px) and (max-width: 1250px) {
          // display: none;
        }
      }
      
      ul {
        display: flex;
        // justify-content: center;
        align-items: center;
        //flex-flow: row wrap;
          /* This aligns items to the end line on main-axis */
        //justify-content: flex-end;

        li {
          margin-left: 16px;
          //flex: 1;

          :nth-child(3) {  
            .navigation_searchbar{
              @media (max-width:1600px) {
                display:none;
              }
            }
          }
          @media (max-width: 767.98px) {
            text-align: center;
            margin-left: 0;
            &:not(:last-child) {
              margin-bottom: 24px;
            }
          }
        }
        //we need one for small screen and one for mobile
        @media (max-width: 767.98px) {
          flex-flow: column nowrap;
        }
      }

      @media (max-width: 767.98px) {
        ul{
          display:none;
        }
        // display:none;
      }

      &_toggle{
        display: inline-flex;
        position: absolute;
        width: 100%;
        top: 64px;
        justify-content: center;
        flex-flow: column nowrap;
        padding: 40px 15px;
        background-color: ${({ theme }) => theme.palette.backgroundWhite};
        border-bottom: 1px solid
          ${({ theme }) => theme.palette.backgroundStroke};
        z-index: 998;

        ul {
          display: flex;
          li {
            margin-left: 16px;
            @media (max-width: 767.98px) {
              text-align: center;
              margin-left: 0;
  
              &:not(:last-child) {
                margin-bottom: 24px;
              }
            }
          }
  
          @media (max-width: 767.98px) {
            flex-flow: column nowrap;
          }
        }
      }
      @media (max-width: 767.98px) {
        display: none;
      }
    }

    &_account {
      display: flex;
      align-items: center;
      z-index: 999;

      &::before {
        content: '';
        border-left: 1px solid ${({ theme }) => theme.palette.backgroundStroke};
        padding-left: ${({ theme }) => theme.spacing.x3};
        height: 64px;
      }

      &--toggle {
        display: flex;
        align-items: center;
        /* margin-right: 16px; */
        cursor: pointer;

        span {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 38px;
          height: 38px;
          background-color: ${({ theme }) => theme.palette.textPlaceholder};
          color: ${({ theme }) => theme.palette.backgroundWhite};
          margin-right: ${({ theme }) => theme.spacing.x1};
        }
      }

      &--toggle1 {
        display: flex;
        align-items: center;
        cursor: pointer;

        #org_img {
          border-radius: 25px;
          overflow: hidden;
          margin-right: 5px;
          display: inline-block;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          width: 125px;
          height: 56px;
          box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
          background-color: #fff;
          text-align: center;
        }

        #org_img_search_bar {
          border-radius: 25px;
          overflow: hidden;
          margin-right: 5px;
          display: inline-block;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          width: 125px;
          height: 56px;
          box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
          background-color: #fff;
          text-align: center;

          @media (max-width: 1275px) {
            display: none;
          }
        }

        #nav_account_img {
          overflow: hidden;
          margin: auto;
          display: inline-block;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          width: 115px;
          height: 56px;
          background-color: #fff;
        }

        #org_name {
          font-weight: 600;
          color: rgb(33, 36, 82);
          font-size: 18px;
          padding-right: 19px;
          padding-top: 19.75px; 
          padding-bottom: 19.75px; 
          line-height: 64px
        }

        #org_name_search_bar_div {
          max-width: 15vw;
          display: inline-block;
          overflow: hidden;
          height: 64px;
          margin-right: 19px;
        
          @media (max-width: 1628px) {
            max-width: 10vw;
          }

          @media (max-width: 1505px) {
            max-width: 8vw;
          }

          @media (max-width: 1275px) {
            display: none;
          }
        }

        #org_name_search_bar {
          font-weight: 600;
          color: rgb(33, 36, 82);
          font-size: 18px;
          line-height: 64px;
          width: 100%; 
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: inline-block;
        }

        #nav_account_info {
          // border: 2px solid red;
          display: inline-block;
          flex-direction: column;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          margin-left: 4px;
          margin-right: 10px;
          padding-top: 11px;
          height: 64px;
          text-align: middle;
        } 

        #nav_account_info_search_bar {
          display: inline-block;
          flex-direction: column;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          margin-left: 4px;
          margin-right: 10px;
          padding-top: 11px;
          height: 64px;
          text-align: middle;

          @media (max-width: 1155px) {
            max-width: 10vw;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          @media (max-width: 1068px) {
            max-width: 8vw;
          }

          @media (max-width: 1035px) {
            max-width: 6vw;
          }

          @media (max-width: 1000px) {
            display: none;
          }
        }
        
        #nav_account_name {
          font-weight: 400;
          color: #202532;
          letter-spacing: 0.2px;
          font-size: 14px;
        }
        
        #nav_account_location {
          font-weight: 400;
          color: #50545E;
          letter-spacing: 0.2px;
          font-size: 12px;
        }

        #nav_account_svg {
          margin-bottom: -6px;
          margin-left: 3.5px;
        }
      }

      &--toggle1 > * {
        vertical-align: middle;
      }

      .feedback-dropdown-num {
        position: absolute; 
        right: 16px; 
        background-color: #b3261e;
        background-clip: padding-box;
        font-size: .6875rem;
        border-radius: 10px;
        padding: 1px 6px;
        color: #fff;
        line-height: 16.5px !important;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        appearance: none;
        border: none;
        margin: 0;
        background-color: ${({ theme }) => theme.palette.textPlaceholder};
        color: ${({ theme }) => theme.palette.backgroundWhite};
      }

      .dropdown-menu {
        display: block;
        flex-flow: column nowrap;
        border: 1px solid ${({ theme }) => theme.palette.backgroundStroke};
        border-radius: ${({ theme }) => theme.borderradius.medium};
        margin-top: ${({ theme }) => theme.spacing.x2};
        width: 154px !important;
        inset: -12px auto auto auto !important;

        // Below styles are only used in desktop > user account avatar options.
        a {
          width: 152px;
          height: 48px;
          padding-left: ${({ theme }) => theme.spacing.x3};
          align-items: center;
          justify-content: flex-start;
          color: ${({ theme }) => theme.palette.textOffblack};
          background-color: ${({ theme }) => theme.palette.backgroundWhite};

          &:hover {
            background-color: ${({ theme }) => theme.palette.backgroundDropdownHover};
          }
        }
      }
    }
  }
`;

export default Nav;
