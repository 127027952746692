import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

// Below styles are only for mobile. As of first release, modal on
// mobile is only used in "Can't find what you’re looking for?".
const StyledModal = styled(Modal)`
  overflow-x: hidden !important;
  overflow-y: hidden !important;

  .modal-header,
  .modal-body,
  .modal-footer {
    @media (max-width: 767.98px) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .modal-body {
    &-textarea {
      margin-top: ${({ theme }) => theme.spacing.x3};
    }

    @media (max-width: 767.98px) {
      padding-bottom: 24px;
    }
  }

  .modal-footer {
    @media (max-width: 767.98px) {
      justify-content: center;
    }
  }
`;

export default StyledModal;
