import styled  from 'styled-components';

export const Styles = styled.div`

  .modal-body {
    padding: 20rem; //change the padding as you want
  }

  .message_dasboard_wrapper{
    cursor: pointer;
    display: block;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    width: 100%;
    max-width: 610px;
    margin: 0 auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow:hidden;
    margin-top:16px;
    
    @media (min-width: 768px) and (max-width: 1230px) {
      width:95%;
    }
  }

  
  .message {
    &_header {
      align-content: space-between;
      display: flex;
      // background-color: #E9F0FC;
      align-self: center;
      margin: 0 auto;
      align-self: center;
      display: flex;
      width: 100%;
      max-width: 610px;
      justify-content: center;

      &_tittle{
        color: #212452;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 600;
          font-size: 18px;
        }
        font-weight: 600;
        font-size: 18px;
        align-content: space-between;
        display: flex;
        -webkit-box-align: center;
        box-align: center;
        align-items: center;
        box-pack: center;
        -webkit-box-pack: center;
        justify-content: flex-start;
        width:85%;
        margin-left: 1%;
        overflow:hidden;
        @media (max-width: 540px) {
          width:80%;
        }
      }
     
      &_date{
        align-content: space-between;
        display:flex;
        -webkit-box-align: center;
        box-align: center;
        align-items: center;
        box-pack: center;
        -webkit-box-pack: center;
        justify-content: center;
        width:20%;
        @media (max-width: 540px) {
          width:20%;
        }
      }
    }
    
    &_content{
      align-self: center;
      margin: 0 auto;
      align-self: center;
      display: inline;
      max-width: 610px;
      justify-content: left;
      padding: 16px ;
      flex:1;

    }
  }

 
`;

export default Styles;
