import React, {useState} from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Row, Col } from 'react-bootstrap';

import Button from '../../button';

import { H1, Span, Label } from '../../theme/typography';
import {
  PasswordResetFormTitleWrapper,
  FormFieldWrapper,
  TextInput,
  ErrorMessage,
} from '../styles';
import axios from 'axios'
import {getToken} from '../../../functions/manageSessions'
import Alert from '../../alert'

const PasswordResetForm = () => {
  const signupSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid e-mail address')
      .required('Please complete this field'),
  });

  const [errorMessage, setErrorMessage] = useState('')
  const [resetPasswordRequest, setResetPasswordRequest] = useState(null)

  // This hook initiates react-hook-form lib.
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(signupSchema),
  });

  // This onSubmit method is part of a react-hook-form lib.
  const onSubmit = (data) => {
    console.log('Password Reset Form Data: ', data)
    axios({
      method: 'post',
      url: `${window.BASE_URL_APP}api/account/reset_password`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        csrf_token: getToken(),
        email: data.email
      })
    }).then((res) => {
      console.log(res)
      if (res.data.success) {
        setResetPasswordRequest(true)
      }
      else {
        setErrorMessage('Could not find a user with that e-mail address.')
      }
    }).catch((error) => {
      console.log(error)
      setErrorMessage(
          'There was an error processing your request. Please contact us via e-mail if you don\'t receive a reset password e-mail in the next couple of minutes.')
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PasswordResetFormTitleWrapper>
        <H1 h1 semibold textoffblack>
          Password Reset
        </H1>
        <Span p1 regular textmainbody>
          Enter your e-mail address below to receive a password reset link
          details below
        </Span>
      </PasswordResetFormTitleWrapper>
      <Row>
        <Col md={12}>

          <Alert type="danger" show={errorMessage} text={errorMessage} />

          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
              Your e-mail address
            </Label>
            <TextInput
              type="email"
              name="email"
              placeholder="E-mail Address"
              ref={register}
              isError={errors.email}
            />
            <ErrorMessage c1 regular colorError>
              {errors.email && errors.email.message}
            </ErrorMessage>
          </FormFieldWrapper>
        </Col>
      </Row>

      {resetPasswordRequest ?
          <p>You will soon receive an e-mail with a link to reset your password.</p> :
          <Button
              type="submit"
              colorprimary
              buttonlarge
              text="Send password reset link"
              width="100%"
          />
      }
    </form>
  );
};

export default PasswordResetForm;
