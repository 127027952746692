import styled from 'styled-components';

export const Styles = styled.section`
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  padding: 88px 0 44px;  
  display: block;
  color: #6b6c6f;

  .logo-wrapper{
    margin-top: 2px;
    margin-right: 4px;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    svg{
      height:25px;}
  }

  @media (max-width: 991.98px) {
    padding: 15px 0px 52px ;
  }

  .text-center{
    text-align:center!important;
  }
  
  .text-headline {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #212452;
  }
  
  .card-title {
    margin-bottom: 0.75rem;
  }
 
  .card-home1-message{
    padding-top: 0px !important;
    margin: 0 16px 0 16px !important;
    height:420px;
    @media (max-width: 991.98px){
      height:435px;
    }
    @media (max-width: 767.98px) {
      padding: 0rem !important;;
    }
  }
  
  .text-para {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #6b6c6f;
  }
 
  .card-footer{
    padding: 0.75rem 1.25rem;
    border-top: 1px solid rgba(0,0,0,.125);
  }

  .top-card {
    height: 98px;
    
    h3{
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
    }
  }
  .highlight11 {
    background: #effafc;
    border: 1px solid #d6f0f3;
    box-sizing: border-box;
    border-radius: 100px;
    width: 90px;
    height: 90px;
  }
  .text-highlight22 {
    position: relative;
    top: 35%;
    vertical-align: middle;
    color: #02b1c8;
    font-size: 31px;
    font-weight: 700;
    line-height: 22px;
  }
  .text-messages-highlight22 {
    position: relative;
    top: 25%;
    vertical-align: middle;
    color: #02b1c8;
    font-size: 31px;
    font-weight: 700;
    line-height: 22px;
  }
  // .container-card{
  //   max-width: 1010px; 
  // }

  .card{
    min-height:410px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    // border-radius: 0.25rem;
    border-radius: 10px;

    
    @media (max-width: 991.98px)
     {
      min-height:0px;
      margin: 15px 0 15px 0;
    }

    &-home1 {
      // margin-right: 0.75rem;
      margin: 0 16px 0 16px !important;
      height:420px;
      @media (max-width: 991.98px){
        height:435px;
      }
      @media (max-width: 767.98px) {
        padding: 0rem !important;;
      }
    }
    &-body{
      flex: 1 1 auto;
      height: 180px;
      padding: 1.25rem;
      flex-flow: column wrap;
      overflow:hidden;
    }
    &-body-messages{
      flex: 1 1 auto;
      height: 180px;
      padding: 0.5rem 1.25rem ;
      flex-flow: column wrap;
      overflow: hidden;
    }
  }


  .message_wrapper{
    display: flex;
    margin-top:10px;
    flex-flow: wrap;
    overflow: hidden;
    // min-height: 60px;
    // height:75px;
    align-content: baseline;
  }

  .message {
    &_header {
      align-content: space-between;
      display: flex;
      align-self: center;
      // margin: 0 auto;
      display: flex;
      width: 100%;
      max-width: 610px;
      justify-content: left;
      text-align: left !important;

      &_tittle{
        // margin-top:20px;
        color:#212452;
        display:inline;
        font-weight: 600;
        font-size: 18px;
        align-content: space-between;
        align-items: center;
        box-pack: center;
        justify-content: flex-start;
        width:70%;

        overflow:hidden;
        white-space:nowrap;
        text-overflow: ellipsis;
        -webkit-box-align: center;
        -webkit-box-pack: center;
        @media (max-width: 540px) {
          width:80%;
        }
      }
      
      &_date{
        align-content: space-between;
        display:flex;
        -webkit-box-align: center;
        box-align: center;
        align-items: center;
        box-pack: center;
        -webkit-box-pack: center;
        justify-content: right;
        width:30%;
        @media (max-width: 990px) {
          display:none;
        }
        // @media (max-width: 540px) {
        //   width:20%;
        // }
      }
    }
    &_header2{
      margin-top:16px;
      align-content: space-between;
      display: flex;
      align-self: center;
      text-align: left !important;
      // margin: 0 auto;
      display: flex;
      width: 100%;
      max-width: 610px;
      justify-content: left;
    }
    
    &_content{
      // height:41%;
      padding-top: 10px;
      padding-left: 6px;
      align-self: center;
      margin: 0 auto;
      align-self: center;
      display: flex;
      max-width: 610px;
      justify-content: left;
      flex:1;
      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #6b6c6f;
        text-align:left;
        display: -webkit-box;
        // max-width: 200px;
        -webkit-line-clamp: 12;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  
  }
`;

export default Styles;
