import React, { useContext, useState, useEffect, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../button';
import StyledModal from './styles';
import { AppContext } from '../../context';

import './bootstrap-modal.min.css';
import { H2, P } from '../theme/typography';
import { ReactComponent as CloseIcon } from '../../assets/svgs/close-icon.svg';

const ModalPDFPreview = ({
  modalPDFShow,
  modalPDFClose,
  centered,
  result,
  isExtractiveQA
}) => {
  const { 
    userContext,
    feedbackContext,
    setFeedbackContext,
  } = useContext(
    AppContext
  );

  const [textareaValue, setTextareaValue] = useState('');

  // Below effect clears the textarea every time the modal is triggered
  useEffect(() => {
    setTextareaValue('');
  }, [modalPDFShow]);

  useEffect(() => {
    setFeedbackContext({
      ...feedbackContext,
      chunk_id: result.chunk_id,
      chunk_title:result.chunk_title,
      online_view_url: result.online_view_url,//TODO  should we add the field online_pdf_url ? 
      requested_obj: result.requested_obj,
      chunk_text: result.text,
      doc_publisher: result.doc_publisher,
      feedbackType: 'visit',
      triggerRequest: true,
    });
  }, []);
  

  const previewUrl = useMemo(
    () => {
      if (isExtractiveQA) {
        if (result.preview_url_with_toolbar) {
          return result.preview_url_with_toolbar.replace("__PUBLIC_TOKEN__", userContext.search_params);
        }
      } else {
        if (result.online_view_url) {
          return result.online_view_url.replace("__PUBLIC_TOKEN__", userContext.search_params);
        }
      }
    }, [result.preview_url_with_toolbar, result.online_view_url]
  );

  return (
    <StyledModal
      show={modalPDFShow}
      onHide={() => modalPDFClose()}
      centered={centered}
      dialogClassName="modal-90h"      
    >
      <Modal.Body>
        <CloseIcon
          className="modal-header-closeIcon"
          onClick={() => modalPDFClose()}
        />
        {typeof previewUrl === "string" && (previewUrl.endsWith('/'))  ?
          <iframe className="pdf-preview" type="application/pdf" src={previewUrl} sandbox='' ></iframe> :
          <iframe className="pdf-preview" type="application/pdf" src={previewUrl} ></iframe> 
        }
      </Modal.Body>
    </StyledModal>
  );
};

export default ModalPDFPreview;
