import { useEffect } from 'react';

export default function useScript(urlStart,urlEnd ){
  useEffect(() => {
    const htmlhead = document.querySelector("head");
    const scriptTag = document.createElement("script");
    let key =""; 
    if(window.BASE_URL_DASHBOARD==='https://cairo.medwise.ai/'){
      key = "AIzaSyCVzWh5vZqrWuAPtoLJn0RHOGIxKZhi4D0";}
    else{
      key = "AIzaSyCVzWh5vZqrWuAPtoLJn0RHOGIxKZhi4D0";} 

    scriptTag.setAttribute("src", urlStart+key+urlEnd);
    scriptTag.setAttribute( "async",true);
    htmlhead.appendChild(scriptTag);

    return () => {
      htmlhead.removeChild(scriptTag);
    };
  }, [urlStart,urlEnd]);
  return [urlStart];
};