import { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

const useIsSmallScreen = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth|| isMobile);

  useEffect(() => {
    const resize = () => {
      if(window.innerWidth <768 || isMobile ){
        setIsSmallScreen(true);
      }
      else{
        setIsSmallScreen(false);
      }
    };
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    }
  }, []);

  useEffect(() => {
    if(isMobile || window.innerWidth <768){
      setIsSmallScreen(true);
    }
    else{
      setIsSmallScreen(false);
    }
  }, [isMobile]);
  

  return isSmallScreen;
}

export default useIsSmallScreen;