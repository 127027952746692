import React, { useEffect, useContext, useState, useMemo } from 'react';
import { Container, Row, Col  } from 'react-bootstrap';
import { AppContext } from '../../context';
import Header from '../../components/header';
import { H1, Span, StyledLink } from '../../components/theme/typography';
import SearchBar from '../../components/search-bar';
import SignupCards from '../../components/features-section';
import Footer from '../../components/footer';
import Hr from '../../components/hr';
import { ReactComponent as MedwiseLogo } from '../../assets/svgs/medwise-logo.svg';
import {HeroSectionMobile , HeroSection} from './styles';
import TypewriterAnimation from '../../components/typewriter/TypewriterAnimation';
import {single_source} from '../../functions/frontend_variables'
import SummaryCards from '../../components/summary-cards';
import MessagesCards from '../../components/messages-cards';
import { getDefaultFiltersFromContext, getUser } from '../../functions/manageSessions';
import axios from 'axios';
import ModalOrgFields from '../../components/modal-org-fields';
import ModalMandantoryQuestion from '../../components/modal-madatory-question'; 
import BookmarkBanner from '../../components/bookmark-banner';
// import { ReactComponent as ImageLanding } from '../../assets/svgs/landingBackground.svg';
import imageLanding from '../../assets/svgs/landingBackground.svg';
// import { ReactComponent as ImageLandingLocal} from '../../assets/svgs/landingBackgroundLocal.png';
import LandingBottomSection from '../../components/landing-bottom-section';
import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen';

const LandingPage = ({ history, location }) => {
  const isSmallScreen= useIsSmallScreen();
  const { setRequestTrackerContext,requestTrackerContext,
         userOrganisationContext,userContext,userContextFilters,userStats,lastUpdated,
         setRequestUser,setSearchContext,anonQuery,setAnonQuery } = useContext(AppContext);
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const [displayExtraParamsModal, setDisplayExtraParamsModal] = useState(true);
  const [displayMandatoryQuestionModal,setDisplayMandatoryQuestionModal] = useState(false);
  const [extraParams ,setExtraParams] =useState (undefined);

  const [isMagic, setIsMagic] = useState(true);
  const [messages, setMessages] = useState(undefined);
  // const [organisationGreating, setOrganisationGreating] = useState('');
  const [organisationDisplaySettings, setOrganisationDisplaySettings] = useState({});
  const [organisationLandingLogo, setOrganisationLandingLogo] = useState(false);
  const [hasWelcometext, sethasWelcometext] = useState(false);
  const [errorParam, setErrorParam] = useState(undefined);
  const [errorParam2, setErrorParam2] = useState(undefined);
  const [messagesCalled, setMessagesCalled] = useState(undefined);
  const [readyForSearch, setReadyForSearch] =  useState(undefined);
  
  const getMessages = ()  => {
    setRequestTrackerContext(prev => { return {...prev, 'get-messages':true}});
    axios
    .get(`${window.BASE_URL_USER}get-messages`, {withCredentials: true})
    .then((res) => {
      setRequestTrackerContext(prev => { return {...prev, 'get-messages':false}});
      if (res.data) {
        if (res.data["messages"] ) {
          setMessagesCalled(userContext['email'])
          let magic_msg = [];
          if (isMagic){
            res.data["messages"].forEach(msg => {
              if (msg && msg['magic_allowed']){
                magic_msg.push(msg);
              }
            });
            setMessages(magic_msg);
          }
          else{
            setMessages(res.data['messages']);
          }
        }
        else {
          setMessages(undefined);
        }
      }
    })
    .catch((error) => {
      setRequestTrackerContext(prev => { return {...prev, 'get-messages':false}});
      setMessages(undefined);
      console.log(error);
    });
  }; 

  // Since the input field preserves the question after search, it needs resetting on the landing page
  useEffect(() => {
    // eslint-disable-next-line
    
    let currentTime = new Date;
    let prvTime = new Date(localStorage.getItem('lastTime'));
    if(  userContext["email"] === '' && ( currentTime - prvTime > 1000*60*30))
    {
      let lastGetTrending = new Date();
      localStorage.setItem('lastTime', lastGetTrending);
    }
  }, []);
  
  useEffect(() => {
    upadatehasWelcometext();
    if(userOrganisationContext){
      if(userOrganisationContext["display_settings"] ){
        setOrganisationDisplaySettings(userOrganisationContext["display_settings"]);
        }
      else{
        setOrganisationDisplaySettings({});
        }
      if ( userOrganisationContext && typeof userOrganisationContext!== "undefined" && userOrganisationContext['welcome_path'] !== '' 
      && userOrganisationContext['welcome_path'] !== 'name' && userOrganisationContext['logo_path'] && userOrganisationContext['logo_path'] !== null )
      {
        setOrganisationLandingLogo(true);
       }
      else{
        setOrganisationLandingLogo(false);}
      /// only call extraParam if userContextdoes not have the extra param
      if ( // if new extra param or deleted extra param 
          (userOrganisationContext["extra_param"] && userOrganisationContext["extra_param_name"]  && userContext["extra_param"] 
            && Object.keys(userContext['extra_param']) && Object.keys(userContext['extra_param'])[0] 
            && Object.values(userContext['extra_param']) && Object.values(userContext['extra_param'])[0] 
            && (Object.keys(userContext['extra_param'])[0] !== userOrganisationContext["extra_param_name"] || Object.values(userContext['extra_param'])[0].trim() === "" ) )
          || // never had extra param 
          (userOrganisationContext['extra_param'] && userOrganisationContext['extra_param_name'] && userContext['extra_param'] === undefined)){
        //callendpoint 
        // check user...
          callExtraParams();
      }      
    }
    // eslint-disable-next-line
  }, [userOrganisationContext]);


  useEffect(() => {
    let user_info =getUser();
    
    if(user_info){
      setIsLoggedIn(true);
      if(user_info['org-user-type'] && user_info['org-user-type']=== "magic"){
        setIsMagic(true);
      }else{
        setIsMagic(false);
      }
    }
    else{
      setIsLoggedIn(false);
      // setOrganisationGreating('');
    }
    // eslint-disable-next-line 
  },[userContext]);

  useEffect(() => {
    if(userContext['ask_for_details']  && !userContext['is_anonymous']  && userContext['org-user-type']!== "magic" ){
      history.push({pathname: '/signup'});
    }
    else{
      if(userContext['email']){
        if(messagesCalled || messagesCalled!== userContext['email']){
          getMessages();
        }
      }
    }
    if(typeof anonQuery === 'string' &&  anonQuery.trim() !== ''  && !userContext['is_anonymous']  ){
      // we need to wait for the correct/updates filters before doing the search
      // so we have to wait for userContextfilters to trigger a search
      // we know that getSummary always comes before get-filters
      setReadyForSearch(true);
      // setAnonQuery('');
      // setSearchContext(currentVal => 
      //   {return {...currentVal,
      //           q: anonQuery, 
      //           forceSearch: true
      //       };});
    }
    // setIsLoggedIn(false);
  },[userContext['email']]);



  useEffect(() => {
    // we know that getSummary triggers a get-filters (updates userContextFilters)
    // so if we are readyforSearch then as soon as we get 
    // new filters we can trigger the search
    if(readyForSearch){
      setAnonQuery('');
      setReadyForSearch(false);
      // we need to wait for correct filters before doing the search
      setSearchContext(currentVal => 
        {return {...currentVal,
                facets:getDefaultFiltersFromContext(userContextFilters),
                q: anonQuery, 
                forceSearch: true
            };});
    }
  }, [userContextFilters]);

  useEffect(() => {
    // setDisplayMandatoryQuestionModal(true); 
    if(userContext['additional_questions'] && userContext['additional_questions'][0] && Object.values(userContext['additional_questions'][0]).length ===1 ){

      if(Object.values(userContext['additional_questions'][0])[0] === false){

        //display modal with question
        setDisplayMandatoryQuestionModal(true); 
      }
      else{
        // setDisplayMandatoryQuestionModal(true); 
        setDisplayMandatoryQuestionModal(false); 
      }
    }
    else{
      setDisplayMandatoryQuestionModal(false); 

    }
    // setIsLoggedIn(false);
  },[userContext['additional_questions']]);

  //userStat change
  useEffect(()=>{
    if(typeof userStats === 'undefined' ){
      setRequestUser(true);
    }
  },[userStats]);

  useEffect(() => {
    // TODO change to arrays
    if (typeof extraParams !=='undefined' && userContext){
    if ( extraParams['name'] && extraParams['name'] !=='' ){
        //check user
        let nameOfParam = extraParams['name'];
        // we need to check for !== 'undefined' and !== ' '
        if ( userContext.hasOwnProperty( "extra_param") 
              && typeof userContext["extra_param"] !== 'undefined'
              && typeof userContext["extra_param"][nameOfParam] !== 'undefined'
              && userContext["extra_param"][nameOfParam].trim()
              && userContext["extra_param"][nameOfParam].trim() !== ''
              ){
          setDisplayExtraParamsModal(false);
        }
        else{
          setDisplayExtraParamsModal(true);
        }
      }
   }
    // eslint-disable-next-line 
  },[extraParams]);


  const getLandingLogo = () => {
    let logo ;
    if ( typeof userOrganisationContext!== "undefined" && userOrganisationContext['welcome_path'] !== '' 
          &&  userOrganisationContext['logo_path'] &&  userOrganisationContext['logo_path'] !== null && userOrganisationContext['welcome_path'] !== 'name' ){
      
      logo = <img
          src={userOrganisationContext['welcome_path']}
          srcSet={userOrganisationContext['welcome_path']}
          alt= { "organisation logo"}  
          width={'160px'}
          height={'160px'}/>; 
    }
    return logo; 
  };

  const upadatehasWelcometext = () => {
      if (userOrganisationContext && userOrganisationContext["display_settings"] && userOrganisationContext["display_settings"]['welcome_text']){        
        if(userOrganisationContext["display_settings"]['welcome_text'] &&
        userOrganisationContext["display_settings"]['welcome_text']!== ''  &&
        userOrganisationContext["display_settings"]['welcome_text']!== 'default')
        {
          sethasWelcometext(true);
        }
        else{
          sethasWelcometext(false);
        }
    }
    else{
      sethasWelcometext(false);
    }
 };

  const callExtraParams = () => {
    setRequestTrackerContext(prev => { return {...prev, 'get-extra-param':true}});
    axios
    .get(`${window.BASE_URL_USER}get-extra-param`, {withCredentials: true})
    .then((res) => {
      setRequestTrackerContext(prev => { return {...prev, 'get-extra-param':false}});
      if (res.data) {
        let name = res.data['name'];
        // received data
        if (name && name !==''){
          setExtraParams(res.data);
          // if (userContext[name] && userContext[name]!== ''){
          //   setUserHasRequiredParam();
          // }
        }

      } else {
        console.log(
          'The request is successful, but for some reason res.data is not received: \n',
          res
        );
      }
    })
    .catch((error) => {
      setRequestTrackerContext(prev => { return {...prev, 'get-extra-param':false}});
      console.log(error);
    });
  };

  const callModifyUser = (data) => {
    if (data.trim()!=='' && data.trim().length>0)
    {
      setErrorParam(false);
      let dataToSend ={} ;
      dataToSend[extraParams["name"]] =  data.trim(); 
      let objectToSend  = {'extra_param': dataToSend};
      // dataToSend['extra-param'] =data;
      setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':true}});
      axios({
        method: 'post',
        url:`${window.BASE_URL_USER}/modify-user-fields`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(objectToSend),
      })
        .then((res) => {
          setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':false}});
          if (res.data.num_modifications) {
            setRequestUser(true);
            setDisplayExtraParamsModal(false);
          } else {
            console.log(
              'The request is successful, but for some reason res.data.success is not received.'
            );
              if(res.data.description){
              setErrorParam('Error : '+res.data.description);}
              else{
                setErrorParam('Error Please try again');
              }
          }
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':false}});
          setErrorParam('Error Please try again');
          console.log('Error: ', error);
        })
    }
    else{
      if (extraParams && extraParams['mandatory']){
        setErrorParam('The information is required to use search in the organisation.');
        setDisplayExtraParamsModal(true);
        //set errort msg 
      }
      else{
        setDisplayExtraParamsModal(false);
      }
    }
  };

  const callModifyUserMandatoryQuestion = (boolean_val) => {
    if ( boolean_val === true)
    {
      setErrorParam2(false);
      // dataToSend[extraParams["name"]] =  data.trim(); 
      let temp = userContext['additional_questions'][0];//this is an dict
      for (const property in temp) {
        temp[property]= true;
      }
      let objectToSend  = {'additional_questions': temp};
      // dataToSend['extra-param'] =data;
      setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':true}});
      axios({
        method: 'post',
        url:`${window.BASE_URL_USER}/modify-user-fields`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(objectToSend),
      })
        .then((res) => {
          setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':false}});
            setRequestUser(true);
            setDisplayMandatoryQuestionModal(false);
          
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':false}});
          setErrorParam2('Error Please try again');
          console.log('Error: ', error);
        })
    }
    else{
        setErrorParam2('It is required this account.');
        //set errort msg
    }
  };

  const onClickArrow = () => {
    var secondHalf = document.getElementById('second-half');
    window.scrollTo({top: secondHalf.getBoundingClientRect().top - 64, behavior: 'smooth'});
  };

  var backgroundImageContainer = {
    backgroundImage: `url(${imageLanding})` ,
    position:'relative', 
    display:'block',
    top:'55px',
    left:'670px',
    width:'448px',
    height:'282px',
  };

  return (
    <>
      <Header withSearchBar={false} withAccountButtons={true} landingPageSearchBar={true} landingPage={true} />
      <main style={{backgroundColor:'#FFFFFF'}}>
        {!isSmallScreen && isLoggedIn !== undefined ?(
        <>
          <HeroSection style={isLoggedIn ? {padding: '88px 0'} : {height: 'calc(50hvh)', padding: 'calc((100vh - 159.66px) / 3 - 64px) 0 calc((100vh - 159.66px) / 3 - 64px)', position:'relative'}}>
            <Container>
              <Row>
                {!isSmallScreen && !organisationLandingLogo && (
                  <Col md={12} className="hero_logo">
                    {<MedwiseLogo />}
                  </Col>
                )}
                {!isSmallScreen && organisationLandingLogo && (
                  <Col md={12} className="hero_logo">
                    {getLandingLogo()}
                  </Col>
                )}
              </Row>
              <Row className="justify-content-md-center no-gutters">
                <Col md={10} sm={12} className="hero_title no-gutters">
                  <H1 h1 regular textoffblack>
                    {hasWelcometext &&(
                      <Span semibold className="hero_desktop_org">{organisationDisplaySettings['welcome_text']}</Span> 
                    )}
                    {/* {!hasWelcometext  && !isSmallScreen && (
                      <Span semibold className="hero_desktop">One search across</Span> 
                    )}
                    {!hasWelcometext && isSmallScreen && (
                      <Span semibold className="hero_mobile"> One search&nbsp;across</Span>
                    )} */}
                    {/* &nbsp; */}
                  </H1>
                  {((!single_source) && (!isSmallScreen)) && (!hasWelcometext) &&
                    <H1 h1 regular textoffblack className="hero_typewritter"> 
                      {/* <TypewriterAnimation />
                       */}
                     Quick answers to your clinical questions from sources you trust

                    </H1>  
                  }
                  {((single_source) && (isSmallScreen)) &&
                    <H1 h1 regular textoffblack>
                      <Span className="hero_desktop"> {single_source}</Span>
                    </H1>  
                  }
                </Col>
                {isSmallScreen && (
                  <Col md={10} sm={12} className="hero_title no-gutters">
                    {(!single_source && !hasWelcometext) && 
                      <H1 h1 regular textoffblack className="hero_typewritter pb-2 hero_typewritter_mobile">
                        {/* <TypewriterAnimation /> */}
                        Quick answers to your clinical questions from sources you trust

                      </H1>  
                    }
                    {single_source &&
                      <H1 h1 regular textoffblack>
                        <Span className="hero_desktop"> {single_source}</Span>
                      </H1> 
                    }
                  </Col>) 
                }
              </Row>
              {/* not mobile &&  isloggined not undefined */}
              <Row className="justify-content-center">
                <Col
                  md={12}
                  lg={{ span: 8 }}
                  xl={{ span: 10 }}
                >
                    <SearchBar isAtHeader={false}  btnColor={organisationDisplaySettings['search_button_color']} />
                  {/* <SearchBar isAtHeader={false}  btnColor={organisationDisplaySettings['search_button_color']}/> */}
                </Col>

              </Row >
              {/* we remove this example since it would only show for annomimous */}
              {/* {!isLoggedIn && !isSmallScreen && (
              <Row className="justify-content-center">
                <Col md={12} lg={{ span: 8 }}
                  xl={{ span: 10 }}>
                   <Span style={{color: '#393956',lineHeight: "31px", maxWidth:'610px',display:'flex',margin:'0 auto' }}> <br/>Try these searches: <br/></Span> 
                    <Span style={{color: '#393956',lineHeight: "31px", maxWidth:'610px',display:'flow', margin:'0 auto'  }} >
                      <a href="/search?q=What%20is%20the%20HbA1c%20target%20for%20diabetes"  rel="noopener noreferrer" >What is the HbA1c target for diabetes?</a> </Span>
                    <Span style={{color: '#393956',lineHeight: "31px", maxWidth:'610px',display:'flow', margin:'0 auto'  }} >
                      <a 
                        href='/search?q=When%20is%20ustekinumab%20indicated%20for%20the%20treatment%20of%20Crohn%27s%20disease'
                        rel="noopener noreferrer" >When is ustekinumab indicated for the treatment of Crohn's disease? </a>  </Span>
                </Col>
                </Row>
                ) } */}
            </Container>
          </HeroSection>
            {!isLoggedIn && (
              <LandingBottomSection></LandingBottomSection>
            )}
        </>
        ):(
        <HeroSectionMobile>
          <Container>
            <Row>
              {!isSmallScreen && !organisationLandingLogo && (
                <Col md={12} className="hero_logo">
                  {<MedwiseLogo />}
                </Col>
              )}
              {!isSmallScreen && organisationLandingLogo && (
                <Col md={12} className="hero_logo">
                  {getLandingLogo()}
                </Col>
              )}
            </Row>
            <Row className="justify-content-md-center no-gutters">
              {/* {!isSmallScreen && (!hasWelcometext) && (
                <Col sm={12} className="hero_title_small no-gutters" >
                  <H1 h1 regular textoffblack>
                    &nbsp;
                  </H1>
                </Col>
              )} */}
              <Col md={10} sm={12} className="hero_title no-gutters">
                <H1 h1 regular textoffblack>
                  {hasWelcometext &&(
                    <Span semibold className="hero_desktop_org">{organisationDisplaySettings['welcome_text']}</Span> 
                  )}
                  {/* {!hasWelcometext  && !isSmallScreen && (
                    <Span semibold className="hero_desktop">One search across</Span> 
                  )}
                  {!hasWelcometext && isSmallScreen && (
                    <Span semibold className="hero_mobile"> One search&nbsp;across</Span>
                  )}
                  &nbsp; */}
                </H1>
                {((!single_source) && (!isSmallScreen)) && (!hasWelcometext) &&
                  <H1 h1 regular textoffblack className="hero_typewritter"> 
                    {/* <TypewriterAnimation />
                     */}
                     Quick clinical answers from sources you trust
                  </H1>  
                }
                {((single_source) && (isSmallScreen)) &&
                  <H1 h1 regular textoffblack>
                    <Span className="hero_desktop"> {single_source}</Span>
                  </H1>  
                }
              </Col>
              {isSmallScreen && (
                <Col md={10} sm={12} className="hero_title no-gutters">
                  {(!single_source && !hasWelcometext) && 
                    // <H1 h1 regular textoffblack className="hero_typewritter pb-2 hero_typewritter_mobile">
                    //   <TypewriterAnimation />
                    // </H1>  
                    <H1 h1 regular style={{'paddingLeft':'9%', 'paddingRight':'9%'}}>Quick clinical answers from sources you trust</H1> 

                  }
                  {single_source &&
                    <H1 h1 regular textoffblack>
                      <Span className="hero_desktop"> {single_source}</Span>
                    </H1> 
                  }
                </Col>) 
              }
            </Row>
            {/* is Mobile  or isLoggined === undefined */}
            <Row className="justify-content-center">
              <Col
                md={12}
                lg={{ span: 8 }}
                xl={{ span: 10 }}
              >
              <SearchBar isAtHeader={false}  btnColor={organisationDisplaySettings['search_button_color']}/>
              </Col>
            </Row>
          </Container>
        </HeroSectionMobile>)}
        {/* <section style={{backgroundColor: "#FFFF"}} >
          <Row>
              <Col
                md={12}
                lg={{ span: 8, offset: 2 }}
                xl={{ span: 10, offset: 1 }}
              >
              </Col>
          </Row>
        </section>
        <Hr /> */}

        {isLoggedIn &&(
          <div id="second-half">
            <MessagesCards messages={messages} ></MessagesCards>
            <SummaryCards></SummaryCards>
          </div>
        )}
        
        {/* not loggin and messages then display messages  */}
        {isLoggedIn === false &&(
          <div id="second-half">
            {/* we still have to check for messages if user is annomimous
              but signupCards should still show   */}
             { messages && messages.length >0 && isSmallScreen && (
              <><MessagesCards messages={messages}></MessagesCards>
              <div className='pt-5'></div>
              <SignupCards />
              </>
            ) }
            
          </div>
        )}
        {/* if not loging and no messages this SignupCards  */}


      </main>
      <Footer />
      { (extraParams && displayExtraParamsModal)&& (
        <ModalOrgFields 
          listParams={[extraParams]}
          modalFeedbackShow={displayExtraParamsModal && !displayMandatoryQuestionModal}
          modalFeedbackClose={ setDisplayExtraParamsModal } 
          modalFeedbackData={callModifyUser}
          errorParam= {errorParam}
          setErrorParam = {setErrorParam}
          title={"Welcome to "+ userContext['org_name'] + "!"} 
        />
      )}
      { (displayMandatoryQuestionModal && userContext['additional_questions'] && userContext['additional_questions'].length >0 )&& (
        <ModalMandantoryQuestion 
          orgName={userContext['org_name']}
          question={Object.keys(userContext['additional_questions'][0])[0]}
          modalFeedbackShow={displayMandatoryQuestionModal}
          // modalFeedbackShow={true}

          modalFeedbackClose={ setDisplayMandatoryQuestionModal } 
          modalFeedbackData={callModifyUserMandatoryQuestion}
          errorParam= {errorParam2}
          setErrorParam = {setErrorParam2}
          title={"Welcome to Mediwse "} 
        />
      )}
      
      <BookmarkBanner />
    </>
  );
};

export default LandingPage;
