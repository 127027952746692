import React, {useState} from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Alert from '../../components/alert'
import Header from '../../components/header'
import Footer from '../../components/footer'
import {isMobile} from 'react-device-detect'
import axios from 'axios'
import {setToken} from '../../functions/manageSessions'
import Button from '../../components/button'

const UnconfirmedAccountPage = () => {

  const [resentVerificationEmail, setResentVerificationEmail] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const requestNewVerificationEmail = () => {
    const reqURL = `${window.BASE_URL_USER}api/account/resend_verification`;
    axios({
      method: 'post',
      url: reqURL
    }).then((res) => {
      const accessToken = res.data.csrf_token
      setToken(
          accessToken
              ? accessToken
              : 'IjMwMjYwYWVkODA4MDNiYTEzMzgwMzM1ZTY3MDcyZDZlMGQ1ODRmMzEi.X8IYYQ.QPSb5WPUHtemS4pcaobqT4e0DC8'
      )
      // TODO: Once the csrf_token issue is resolved, remove the above setToken func. call and use the below one.
      // setToken(accessToken);

      setResentVerificationEmail(true)

    }).catch((error) => {
      console.log(error)
      setResentVerificationEmail(false)
      setErrorMessage(
          'There was an error processing your request. Please contact us via e-mail if you don\'t receive a confirmation e-mail in the next couple of minutes.')
    })
  }

  return (
      <>
        <Header withSearchBar={false} withAccountButtons={true}/>
        <main>
          <Container>
            <Row>
              <Col lg={{span: 6, offset: 3}} className="mt-5">

                <Alert type="primary" text="We've sent you a new account verification e-mail. If the e-mail doesn't arrive in the next few minutes, please contact us." show={resentVerificationEmail} />

                <Alert type="danger" show={errorMessage} text={errorMessage} />

                <h3 className="text-center">You need to confirm your account</h3>

                <p className="mt-3">When you created an account, you should have received
                  an email from
                  us
                  with a link to confirm your email address.</p>

                <p className="mt-3 mb-2">If you haven't received a confirmation e-mail yet,
                  please make sure to also check your spam folder.</p>
                {resentVerificationEmail || errorMessage ?
                    '' :
                    <Button
                        type="action"
                        colorprimary
                        buttonsmall
                        text="Resend Verification Email"
                        onClick={() =>
                            requestNewVerificationEmail()
                        }
                         />
                    }
              </Col>
            </Row>
          </Container>
        </main>
        <Footer sticky={!isMobile ? true : false}/>
      </>
  )
}

export default UnconfirmedAccountPage
