import React, {useContext, useEffect, useState} from 'react';
import { AppContext } from '../../../context';
import Hr from '../../hr';

import { H2, H1, P, A, Span } from '../../theme/typography';
import { Article,Styles } from './styles';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import CpdNotes from '../CpdNotes';
import ModalSearchFeedback from '../../modal-search-feedback';
import axios from 'axios';
import useIsSmallScreen from '../../../useIsSmallScreen/useIsSmallScreen';


const OverideCard = (props) => {
  const { 
    feedbackContext,
    setFeedbackContext,
    searchContext,
    userContext,
  } = useContext(
    AppContext
  );
  const isSmallScreen = useIsSmallScreen();
  const [requestExpiryToken,setRequestExpiryToken] = useState('');
  const [showModalFlag, setShowModalFlag] = useState(false);
  const [cardExpanded, setCardExpanded] = useState(false);
  const folderLink =  derriveFolderLink(props.result);
  const resultLink = derriveLink(props.result);

  function derriveFolderLink(result){
    let link = '#'; 
    if (
      result.from &&
      result.from === 'private' &&
      result['requested_obj'] &&
      result['requested_obj'] === 'override'
    ) {
      link = result['parent_folder'];
      if(userContext['search_param']!==''){
        link = result['parent_folder'].replace('__PUBLIC_TOKEN__',userContext['search_params']);
      }
    }
    return link 
  }; 

  function derriveLink(result){
    let link = '#';
    if (result.online_view_url !== '') {
      if (userContext['search_params']) {
        let url_token = '';
        let s = result.online_view_url.replace('__PUBLIC_TOKEN__', userContext['search_params']);
        if (s !== result.online_view_url) {
          url_token = s;
          link = url_token ;
        } else {
          link = result.online_view_url; 
        }
      } else {
        console.log('not found ');
        link = result.online_view_url;
      }
    }
    if (result.chunk_title.includes('target="_blank"')) {
      link = result.chunk_title.split('href="')[1].split('" target')[0];
    }
    return link; 
  };
  // Important note: First result is coded inline (made use of the map method)
  const handleCardClick = (e, result, i) => {
    let cardBody = e.currentTarget.querySelector('.card_body');
    if (!cardExpanded) {
      // result.expanded = 'expanded';
      setCardExpanded(prev => {return true;});
      setFeedbackContext({
        ...feedbackContext,
        chunk_id: result.chunk_id,
        chunk_title:result.chunk_title,
        online_view_url: result.online_view_url,
        requested_obj: result.requested_obj,
        chunk_text: result.text,
        doc_publisher: result.doc_publisher,
        rank: i,
        value: 1,
        feedbackType: 'interact',
        triggerRequest: true,
      });
    } else{
      if (isSmallScreen){
        // result.expanded = undefined;
        setCardExpanded(prev => {return false;});
        setFeedbackContext({
          ...feedbackContext,
          chunk_id: result.chunk_id,
          chunk_title:result.chunk_title,
          online_view_url: result.online_view_url,
          requested_obj: result.requested_obj,
          chunk_text: result.text,
          doc_publisher: result.doc_publisher,
          rank: i,
          value: 1,
          feedbackType: 'interact',
          triggerRequest: false,
        });
      }
    }
  };

  
  const handleUnexpanded = (e, result, i) => {
    // result.expanded = undefined;
    setCardExpanded(prev => {return false;});
    result.noteExpanded = false;
    setFeedbackContext({
      ...feedbackContext,
      chunk_id: result.chunk_id,
      chunk_title:result.chunk_title,
      online_view_url: result.online_view_url,
      chunk_text:  result.text,
      doc_publisher: result.doc_publisher,
      rank: i,
      value: 1,
      feedbackType: 'interact',
      triggerRequest: false,
    });
  };
  
  useEffect (() => {
    if (requestExpiryToken !== '' )
    {
        if (userContext['search_params']){
          // setPrivateToken(userContext['search_params']);
          let url_token = '';
          window.open(url_token,'_blank','noreferrer');
        }
      setRequestExpiryToken('');
    }
  },[requestExpiryToken]);


  const handleFolderLinkClick = (result) => {
    let body = {
      online_view_url: result.online_view_url,
      interaction_type: 'visit',
    };
    if (searchContext.q) {
      body = {
        ...body,
        q: searchContext.q,
      };
    }
    // search logs
    const config = {
      method: 'post',
      url: `${window.BASE_URL_USER}log-result-interaction`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(body),
    };
    axios(config);
  };

  // When clicking on "Visit website" or card title, we want this to be logged as an interaction
  // and we want to get private token if available to load prvt link
  const handleLinkoutClick = (e, result) => {
    let body = {
      online_view_url: result.online_view_url,
      interaction_type: 'visit',
    };
    // TO DO move to useEffect
    // request should be in useEffect to allow for cancel at clean up
    if (searchContext.q) {
      body = {
        ...body,
        q: searchContext.q,
      };
    }
    const config = {
      method: 'post',
      url: `${window.BASE_URL_USER}log-result-interaction`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(body),
    };
    axios(config);
  };
  
  const callbackResultExpanded = (result) => {
    return () => {
      setCardExpanded(prev => {return true;});
      // result.expanded = 'expanded';
      // result.noteExpanded = true;
    };
  };


  return (
    <Article>
      <div className="card_override"  onClick={(e) => handleCardClick(e, props.result, props.i)}>
        <div className="card_top" >
          {cardExpanded && !isSmallScreen && ( // Expanded
            <span onClick={(e) => handleUnexpanded(e, props.result, props.i)}
              className={ cardExpanded ? 'card_expandIcon false': 'card_expandIcon undefined'}>
              <FaChevronUp />
            </span>
          )}
          {!cardExpanded && !isSmallScreen && ( // Unexpanded
            <span
              className={ !cardExpanded ?  `card_expandIcon undefined`:`card_expandIcon `}>
              <FaChevronDown />
            </span>
          )}
          
          {isSmallScreen ? (
            <div className="card_header_mobile">
              <H1 h1 regular className='mobile_title'>
                <a
                  href ={resultLink}
                  onClick={(e) => handleLinkoutClick(e, props.result)}
                  target="_blank"
                  rel="noopener noreferrer"
                  dangerouslySetInnerHTML={{ __html: props.result.chunk_title }} />
              </H1>
              <hr className='override_separator_mobile'/>
            </div> ) : (
            <div className="card_header">
              <H2 h2 semibold>
                <a
                  href ={resultLink}
                  onClick={(e) => handleLinkoutClick(e, props.result)}
                  target="_blank"
                  rel="noopener noreferrer"
                  dangerouslySetInnerHTML={{ __html: props.result.chunk_title }} />
              </H2> 
              <hr className='override_separator'/>
            </div>
          )}
            
      
          <div
            // className={!(props.hasBookmarks && props.hasBookmarks.length > 0) ? `card_body `+ props.result.expanded : (props.result.expanded) ? `card_body undefined` : `card_body expanded`}
            className={ cardExpanded? `card_body expanded` : `card_body undefined`}
            aria-expanded={props.i === -1 ? true : false}
          >
            <div className="search_card_table_wrapper"
              dangerouslySetInnerHTML={{ __html: props.result.text }} />
          </div>
          {/* TODO: FORMAT props.pusblisher and add isPrivate */}
          <div className='source_name_wrapper'>
            <a className='source_name' href={folderLink} target="_blank" rel="noopener noreferrer"  onClick={(e) => handleFolderLinkClick(e, props.result)}>{props.publisher}</a>
          </div>
        </div>

        {/* Footer */}

        {props.isSearchResult && (
          <div className="card_footer">
            <div className={cardExpanded && !isSmallScreen ?
               "card_footer_2 card_footer_2_expanded" : (props.hasBookmarks && props.hasBookmarks.length > 0) ? 
                    "card_footer_2 card_footer_2_" : (cardExpanded) ?
                       "card_footer_2 card_footer_2_expanded":"card_footer_2 card_footer_2_undefined" }>
              {!isSmallScreen && (props.isLoggedin && !props.isMagicUser) && props.bookmarkedFetched && (
                <>
                  <Hr style={{ margin: '16px -56px 0 -32px' }} />
                  <div className="cpd_container">
                    <CpdNotes isLoggedin={props.isLoggedin } bookmarks={props.hasBookmarks} updateBookmarks={props.updateBookmarks} setUpdateBookmarks={props.setUpdateBookmarks} 
                      isFavourite={props.isFavourite} updateFavourites={props.updateFavourites} favouriteID={props.favouriteID} isRemove={props.toBeRemove} 
                      search={searchContext} parentExpanded={cardExpanded} noteExpanded={props.result.noteExpanded} result={props.result} index={props.i} 
                      expandParent={callbackResultExpanded(props.result)}>
                    </CpdNotes>               
                    <div className="card_footer--feedback">
                        <div className="tooltip" 
                        onClick={(e)=>setShowModalFlag(props.i)}  
                        title="Didn't find what you were looking for? Let us know" >Feedback
                        </div>
                    </div>
                  </div>
                </>
              )}
              {!isSmallScreen && (!props.isLoggedin  || props.isMagicUser) && (
                <>
                  <Hr style={{ margin: '16px -56px 0 -32px' }} />
                  <div className="cpd_container"> 
                    <CpdNotes isLoggedin={props.isLoggedin && !props.isMagicUser} bookmarks={props.hasBookmarks} search={searchContext}
                      parentExpanded={cardExpanded} noteExpanded={props.result.noteExpanded} result={props.result}
                      index={props.i} expandParent={callbackResultExpanded(props.result)}>
                    </CpdNotes>
                    <div className="card_footer--feedback">
                        <div className="tooltip" 
                        onClick={(e)=>setShowModalFlag(props.i)}  
                        title="Didn't find what you were looking for? Let us know" >Feedback
                        </div>
                    </div>
                  </div>
                </>
              )}
              {typeof showModalFlag !== 'undefined' && showModalFlag === props.i && (
                <ModalSearchFeedback
                  modalFeedbackShow={showModalFlag=== props.i}
                  modalFeedbackClose={setShowModalFlag} 
                  title="Let us know how to improve medwise.ai"
                  result={props.result}
                  i={props.i} 
                />
              )}
            </div>
          </div>
        )}
      </div>

    </Article>
          );

};

export default OverideCard;
