import React, {useContext, useEffect, useState,useRef} from 'react';
import OverideCard from '../override-card/index.js';
import ResultCard from '../result-card/index.js';
import WebCard from '../web-card/index.js';
import { H2,P } from '../../theme/typography';
import { Article,Styles } from './styles.js';


const AnnotationWrapperCard = (props) => {
  const [result, setResult] =useState(undefined);
  const AnnotationsRef = useRef(null);

  useEffect(() => {
    if(props.annotation.title && props.annotation.online_view_url ){
      setResult ( {
        'preview':null,
        'online_pdf_url' : undefined ,
        'preview_url':undefined,
        'online_view_url':props.annotation["online_view_url"],
        'preview':undefined,
        'modified_date':undefined,
        'chunk_title':props.annotation['title'],
        'doc_publisher' :props.annotation['doc_publisher'],
        'parent_folder':false,
        'from':'generetative',
        'requested_obj':false,
        'noteExpanded':false,});
     }
     if(props.annotation.error ){

     }


  }, [props.annotation]);

  // useEffect(() => {
  //   console.log('34props.keyToScroll',props.keyToScroll);
  //   console.log('34props.keyToScroll', props.annotation_id);
  //   console.log('34props.props.keyToScroll === props.annotation_id', props.keyToScroll === props.annotation_id);

  //   if(props.elementToScroll && props.keyToScroll && props.annotation_id &&props.keyToScroll === props.annotation_id ){
  //     console.log('41',props.elementToScroll);
  //     if(typeof props.elementToScroll=== "string"){
  //     console.log('43',props.elementToScroll);

  //       const listNode = AnnotationsRef.current;
  //       const annotationCardNode = listNode.querySelectorAll(props.elementToScroll);
  //       console.log('48',annotationCardNode);
  //       console.log('48',document.querySelectorAll(props.elementToScroll));

  //       if(annotationCardNode && annotationCardNode.length>0 ){
  //         console.log('48',props.elementToScroll);
  //         annotationCardNode[0].scrollIntoView({
  //           behavior: 'smooth',
  //           block: 'center',
  //           inline: 'center'
  //         });
  //         props.setElementToScroll(undefined);
  //         props.setkeyToScroll(undefined);
  //       }
  //       else{
  //         console.log('53',annotationCardNode);
  //         console.log('54',listNode.querySelectorAll(props.elementToScroll));
  //       }
  //     }
  //   }

  // }, [props.keyToScroll])

  // useEffect(() => {
  //   console.log('72',document.querySelectorAll(props.elementToScroll));
  // }, [])
  
  

  
  return (
    <div className='p-2' ref= {AnnotationsRef}>
      {result && !props.annotation.error &&(

        <ResultCard
          elementToScroll={props.elementToScroll}
          setElementToScroll={props.setElementToScroll}
          keyToScroll= {props.keyToScroll}
          setKeyToScroll={props.setKeyToScroll}
          annotation_id={props.annotation_id}
          key={'result_references' + props.i }
          i={props.i}
          isSearchResult={true}
          result={result}
          isOnlineViewUrlPdf={false}
          publisher={props.annotation['doc_publisher']}
          publisherSource={{'url':props.annotation["online_view_url"]}}
          purifiedHtml={props.annotation["text"]}
          bookmarkedFetched={false}
          hasBookmarks={false}
          updateBookmarks={undefined}
          setUpdateBookmarks={()=>{}}
          isFavourite={false}
          toBeRemove={()=>{}}
          // favouriteID={props.resultFavouriteID[props.i]}
          isLoggedin={true}
          isMagicUser={false}
          // updateFavourites={props.updateFavourites}
          callbackResultExpanded={()=>{}}
          handleLinkoutClick={()=>{}}
        ></ResultCard>)}
        {props.annotation.error&&(
          <Article className={(props.isOnSearchPage && 'card_chat_on_search_container')}>
          <H2 h2 semibold dangerouslySetInnerHTML={{__html: props.annotation_id}}></H2>
          <P  colorError>{props.annotation.error}</P>
          </Article>
        )}
    </div>
  );
};

export default AnnotationWrapperCard;
