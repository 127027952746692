import React, { useEffect, useContext, useState, useMemo } from 'react';
import { ConsoleView, isMobile } from 'react-device-detect';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { AppContext } from '../../context';
import Header from '../../components/header';
import { Article } from './styles';
import { P,H2,StyledLink} from '../../components/theme/typography';
import ChatFlow from '../../components/chat-flow';
import StyledModal from '../../components/modal/styles';
import MoreLikeThisSection from '../../components/morelikethis-section';
import SideFiltersCard from '../../components/side-filters';
import SideFiltersCardMobile from '../../components/side-filters-Mobile';
import Hr from '../../components/hr';
import CantFindFeedback from '../../components/cant-find-feedback';
import Footer from '../../components/footer';
import BookmarkBanner from '../../components/bookmark-banner';
import { FaSearch, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import { is_single_source } from '../../functions/frontend_variables';
import TypewriterAnimationChat from '../../components/typewriter/TypewriterAnimationChat';
import { getDefaultsFromContext, getDefaultSourcesFromContext, isValidSourceForAsk, getSourceFromName, getUserFilters } from '../../functions/manageSessions';//TODO chanbfe get function to user UserContextFilters
import ChatBar from '../../components/chat-bar';

const ChatPage = ({ history, location }) => {
  const {
    fetchedResults,
    fetchDialog,
    fetchedDialog,
    fetchAnswers,
    fetchResults,
    machineResults,
    queries,
    chatIndex,
    setChangeWeb,
    fetchedSuggestedQuestion,
    possibleAnswer,
    setRequestTrackerContext,
    mobileContext,
    setMobileContext,
    chatContext,
    chatSources,
    trackers,
    setChatContext,
    setSearchContext,
    tooManyRequestsError,
    userContextFilters,
    userContext,
    requestFilters,
    setRequestFilters,
  } = useContext(AppContext);

  const [displayInvalidSource,setDisplayInvalidSource] = useState(false);
  const [displayInvalidSourceModal,setDisplayInvalidSourceModal] = useState(false);
  const [invalidSourcesNames,setInvalidSourcesNames] = useState('');
  const [sources, setSources] = useState('');

  // const [displayPage,setDisplayPage] = useState(false);
  // const [displayFilters,setDisplayFilters] = useState(false);
  
  const [waitingForSources,setWaitingForSources] = useState(false);
  const [searchParameter,setSearchParameter] = useState(false);
  const [searchSources,setSearchSources] = useState(undefined);
  const [dots, setDots] = useState(0);


  // returns srcs that match the user srcs base on name
  // input: an array of string representing the name of source
  // out:  array of src objct that are found with the user srcs
  const getValidSourcesFromNames = (names)=>{
    let validSrcs= [] ;
    let tempSrc = {
      'encrypted_url': "custom:gAAAAABiMGJJQdUGog4lZd2jqr0jk8DZmQvBh139L5aOzVrh7ZZ76xX8uSQkkGPqznXJ7jZV-sft8U8ZZfnX0xNSK804FIc9gT6ckX1xqp7fOU35M9-sKyQ=",
      'name':'Web',
      'url': "https://www.google.co.uk",
      'id': 0, 
    };
    names.forEach(element => {
      //TO DO replace hard code    
      if (element !== 'Web'){
        tempSrc = getSourceFromName(element);
      }
            //TO DO replace hard code    
      if ( tempSrc && (isValidSourceForAsk(tempSrc['encrypted_url']) || element ==='Web')  ){
          validSrcs.push(tempSrc);
      }
    });
    return validSrcs;
  };

  // returns srcs that match the user srcs  
  // input array of string representing the encrypted url
  // out array of src objct that are found with the user srcs
  const updateInvalidSources = (names)=>{

    let invalidSrcs= [] ;
    names.forEach(sourceName => {
      let tempSrc = getSourceFromName(sourceName);
      if ( tempSrc && tempSrc['encrypted_url'] ){
        if ( !isValidSourceForAsk(tempSrc['encrypted_url']) ){
          invalidSrcs.push(sourceName);
        }
      }
      else{
        invalidSrcs.push(sourceName);
      }
    });
    if (invalidSrcs && invalidSrcs.length >0 ){
      setDisplayInvalidSource(true);
      setInvalidSourcesNames(invalidSrcs)
    }
  };

  // Go to chat page
  useEffect (()=> {
    if (fetchDialog) {
      setRequestTrackerContext(prev => { return {...prev, 'dialog':true}});
    };
    const interval = setInterval(in_progress, 600);

    return () => clearInterval(interval);
  },[]);

  useEffect (()=> {
    if (displayInvalidSource){
      setDisplayInvalidSourceModal(true);
    }
  },[fetchedResults]);

  useEffect (()=> {
    // if (!fetchDialog && fetchedDialog && fetchedDialog.length > 0) {
    if (!fetchDialog && fetchedDialog) {
      setRequestTrackerContext(prev => { return {...prev, 'dialog':false}});
    }
  }, [fetchedDialog]);

  useEffect(() => {
    // console.log("location", location);
    // setDisplayTooManyRequestsModal(false);
    if (history.action === 'POP' || history.action ==='REPLACE') {
      // if (location.search === '')
      // Either page initial/re load, or through browser's forward/back buttons.
      // This eventually becomes a PUSH because context sends user back to this page with history.push
      if (location.search && location.search.substr(0, 3) === '?q=') {
        let dirtyParameter = location.search.slice(3);
        let dirtyParameterQuery = dirtyParameter.split('&sources=')[0];            
        let dirtyParameterSources = dirtyParameter.split('&sources=')[1];
        let validSrcs; 
        try {
          if (dirtyParameterSources && dirtyParameterSources.length>0) {
            validSrcs = decodeURI(dirtyParameterSources).split(',');
            setSearchSources(validSrcs);
          }
          const cleanParameter = decodeURI(dirtyParameterQuery);
          if (cleanParameter) {  
            // TO DO fix name to default sources
            let allSources = getDefaultSourcesFromContext(userContextFilters);
            // TO DO check since number of filters  maybe smaller than default sources
            if (allSources && allSources.length <=0) {
              setSearchParameter(cleanParameter);
              setWaitingForSources(true);
              setRequestFilters(true);
            } else {
              //get valid sources 
              // setMobileContext({ ...mobileContext, clear: true });
              //check if we need to add sources...
              if (dirtyParameterSources && dirtyParameterSources.length > 0 && validSrcs && validSrcs.length > 0) {
                let validSrcsForSearch = getValidSourcesFromNames(validSrcs);
                if (validSrcsForSearch.length === validSrcs.length && validSrcsForSearch.length!==0) {
                // search context will only trigger search if cleanparameter is different searchContesxt.q
                // if coming from link q='' so search
                  setChatContext(currentVal => 
                                {return {...currentVal,
                                        sources: validSrcsForSearch,
                                        q: cleanParameter,
                                        };});
                  setSearchContext(currentVal => 
                    {return {...currentVal,
                            facets: validSrcsForSearch,
                            q: cleanParameter,
                            };});
                // setMobileContext(currentVal=> {return{ ...currentVal, clear: true }});
                } else {
                  if (validSrcsForSearch.length > 0 ) {
                    setChatContext(currentVal => 
                      {return {...currentVal,
                              sources: validSrcsForSearch,
                              q: cleanParameter,
                              };});
                    setSearchContext(currentVal => 
                      {return {...currentVal,
                              facets: validSrcsForSearch,
                              q: cleanParameter,
                              };});
                      // setMobileContext(currentVal=> {return{ ...currentVal, clear: true }});
                      updateInvalidSources(validSrcs);
                  } else {
                    if(chatContext.q !== cleanParameter) {
                      setChatContext(currentVal => 
                        {return {...currentVal,
                                q: cleanParameter,
                                };});
                      setSearchContext(currentVal => 
                        {return {...currentVal,
                                q: cleanParameter,
                                };});
                      setMobileContext(currentVal=> {return{ ...currentVal, clear: true }});
                      updateInvalidSources(validSrcs);
                    }
                  }
                  updateInvalidSources(validSrcs);
                }
              }else {
                if(cleanParameter !== chatContext.q){
                  setChatContext(currentVal => 
                    {return {...currentVal,
                            q: cleanParameter,
                            };});
                  setSearchContext(currentVal => 
                    {return {...currentVal,
                            q: cleanParameter,
                            };});
                  // setMobileContext(currentVal=> {return{ ...currentVal, clear: true }});
                }
              }
            }
          } else {
            // Send user to homepage if no search param is specified
            return history.push('/');
          }
        } catch (e) {
          // URI malformed, send user to 404
          console.log("malformed uri", e);
          return history.push('/404');
        }
      } 
      else {
        // Other error cases, send user to 404 or to an Alias search
        return history.push('/404');
      }
    } else if (history.action === 'PUSH') {
      // With the help of this state, question in the searchbar remains there after search.
      // if(typeof tooManyRequestsError === 'string'){
      //   setDisplayTooManyRequestsModal(true);
      // }
      // History.push method is called from context
      return;
    }
    // eslint-disable-next-line
  }, [location, history]);

  
  useEffect (() => {
    if(waitingForSources && userContextFilters && Object.keys(userContextFilters).length > 0){
      //get the src if available ....
      let default_filters = getDefaultsFromContext(userContextFilters);
      if (searchSources && searchSources.length > 0 ){
        let validSources = getValidSourcesFromNames(searchSources) ;  
        if (validSources && searchSources && validSources.length !== searchSources.length){
          updateInvalidSources(searchSources);
        }
        if (validSources && validSources.length>0){
          default_filters = validSources;
        }
      }
      setWaitingForSources(false);
      setChatContext(currentVal=> 
        {return {
        ...currentVal,
        sources: default_filters, 
        q: searchParameter,
        }
      });
      setSearchContext(currentVal=> 
        {return {
        ...currentVal,
        facets: default_filters, 
        q: searchParameter,
        }
      });
    }
  },[waitingForSources,userContextFilters]);


  const dropdown_height =  'auto';// remove dropdwon  temporarily
  const [h, setHeight] = useState(dropdown_height);
  const [dis,setDisplay] = useState('auto');
  
  const getNumberOfFilters = useMemo(() =>{
    let usersInfo =  getUserFilters();
    let out = 0 ;
    if(usersInfo){
      let btns = usersInfo["filter-types"];
      btns.forEach(btn => {
        out = out + usersInfo[btn].length;
      });
    }
     // we start with web only
    return out ; 
  }, [userContextFilters]);

  function in_progress () {
    if (dots < 3) {
        setDots(prevState => {
          return prevState+1});
    } else {
        setDots(0);
    };
  };

  const handleChangeWeb = () => {
    setChangeWeb(true);
  };

  return (
    <>
      <Article>
        <Header withSearchBar={false} withAccountButtons={true} withChatBar={true} />
        <main>
          <Container fluid={"md"} className='px-0'>
            <Row style={{marginTop: '12px'}}>  
              <Col md={10} className=''>
                <Row className='loading-container'> 
                  <h1 className='top-msg'>
                    {chatSources && chatSources === 'Web' && (
                      <TypewriterAnimationChat string={'<span class="sources-msg">Looking for answers in sources:</span> ' + chatSources} />
                    )}
                    {chatSources && chatSources != 'Web' && (
                      <>
                        <TypewriterAnimationChat string={'<span class="sources-msg">Looking for answers in sources:</span> ' + chatSources} />
                        <div className="mt-2"><span className="web-change" onClick={handleChangeWeb}>Change to whole Web</span></div>
                      </>
                    )}
                  </h1>
                </Row>
              </Col>
              <Col md={2} className=''>
              </Col>
            </Row>
          </Container>

          {fetchedSuggestedQuestion.length === 0 && (
            <Container fluid={"md"}  className='loadingContainer px-0'>
              <h1 className='loadingMsg'>Please wait
                {dots%3 === 0 && <span>.<span style={{visibility: "hidden"}}>..</span></span>}
                {dots%3 === 1 && <span>..<span style={{visibility: "hidden"}}>.</span></span>}
                {dots%3 === 2 && <span>...</span>}
              </h1>
            </Container>
          )}
          {fetchedSuggestedQuestion && fetchedSuggestedQuestion.length > 0 && (
            <Container fluid={"md"} className='px-0'>
              <Row className='chat-container'>  
                <Col md={12} sm={12} style={{backgroundColor: isMobile ? '#f0f3f4':''}} >
                  <ChatFlow q={queries} dialogData={fetchedDialog} machineResults={machineResults} suggestedQuestion={fetchedSuggestedQuestion[0]} possibleAnswer={possibleAnswer} fetchComposeAnswer={fetchAnswers} fetchResults={fetchResults} trackers={trackers} />
                </Col>
              </Row>
            </Container>
          )} 

          {fetchedDialog && fetchedDialog[0] && chatIndex < 4 && (
            <Col md={{ span: 12 }} className='p-1' >
              <ChatBar isAtHeader={false} />
            </Col>
          )}
        </main>
        <Footer onTop mobileResultBackground={isMobile} />
      </Article>
    </>
  );
};

export default ChatPage;
