import styled, { css } from 'styled-components';
import ChevronIcon from '../../assets/svgs/chevron.svg';

export const Styles = styled.section`
  ${({ mobile }) =>
    !mobile &&
    css`
      @media (max-width: 767.98px) {
        display: none;
      }
    `}

  .covidfilter {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-top: 1px solid ${({ theme }) => theme.palette.backgroundStroke};
    padding: ${({ theme }) => theme.spacing.x2} 0;

    ${({ mobile }) =>
      mobile &&
      css`
        border-top: none;
      `}

    &_label {
      display: inline-flex;
      flex-flow: column nowrap;
    }
    /**Toggle switch code - start */
    &_switch {
      position: relative;
      display: inline-flex;
      width: 38px;
      height: 20px;
      margin-left: auto;

      &--input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .covidfilter_switch--slider {
          background-color: ${({ theme }) => theme.palette.purplePrimary};
        }

        &:focus + .covidfilter_switch--slider {
          box-shadow: 0 0 1px ${({ theme }) => theme.palette.purplePrimary};
        }

        &:checked + .covidfilter_switch--slider::before {
          transform: translateX(16px);
        }
      }

      &--slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${({ theme }) => theme.palette.textPlaceholder};
        transition: 0.4s;
        border-radius: 16px;

        &::before {
          position: absolute;
          content: '';
          height: 16px;
          width: 16px;
          left: 3px;
          top: 2px;
          background-color: ${({ theme }) => theme.palette.backgroundWhite};
          transition: 0.4s;
          border-radius: 50%;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        }
      }
    }
    /**Toggle switch code - end */
  }

  .accordion {
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.palette.backgroundStroke};

    ${({ mobile }) =>
      mobile &&
      css`
        border-bottom: none;
      `}
  }

  .accordion__item + .accordion__item {
    border-top: 1px solid ${({ theme }) => theme.palette.backgroundStroke};
  }

  .accordion__button {
    display: flex;
    align-items: center;
    padding: 16px 0;
    width: 100%;
    text-align: left;
    border: none;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      font-weight: ${({ theme }) => theme.fontweight.semibold};
      color: ${({ theme }) => theme.palette.textOffblack};
    }

    .opened,
    .closed {
      background: url(${ChevronIcon}) no-repeat;
      width: 20px;
      height: 20px;
      background-position: center;
      margin-left: auto;
      margin-right: 8px;
      transform: rotate(180deg);
    }

    .closed {
      transform: rotate(0deg);
    }
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    padding: 0 24px;
    ${({ mobile }) =>
    mobile &&
    css`
    padding: 0 12px;
    `}
  }
  

  .checkbox_item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    ${({ mobile }) =>
    mobile &&
    css`
    margin-bottom: 8px;
    `}
    user-select: none;

    label {
      cursor: pointer;
      padding-left: 12px;
      color: ${({ theme }) => theme.palette.textPlaceholder};
    }
  }
`;

export default Styles;
