import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  createRef,
} from 'react';
import { AppContext } from '../../context';
import { isColor, search, search2, stripTags } from '../../functions/utils';
import { useKeyPress } from '../../functions/keypress';
import Styles from './styles';
import { FaSearch, FaTimes } from 'react-icons/fa';
import {suggest_on, sources} from '../../functions/frontend_variables';
import { getDefaultFilters } from '../../functions/manageSessions';
import axios from 'axios';
import { Label } from '../theme/typography';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Container, Row, Col,Modal } from 'react-bootstrap';
import { H2} from '../theme/typography';





const MessageBar = (props) => {
  
  const getFormatedDate = (dateToFormat) => { 
    let formatedDate = ''; 

    if(typeof dateToFormat === 'string'){
      formatedDate = new Date(dateToFormat).toDateString().slice(4);
    }
    return formatedDate;
  };
  
  return (
    <Styles>
    { props.message &&  (
      <Container>
      <div className='message_dasboard_wrapper' >

        <div className="message_header">
          <div className='message_header_tittle' dangerouslySetInnerHTML={{__html: props.message.title}}>
          </div>
          <div className='message_header_date'>
          {getFormatedDate(props.message['modified']) }
          </div>
        </div>
        <div className='message_content' dangerouslySetInnerHTML={{__html: props.message.message}}>
        </div>
        
      </div>
          </Container>
        )}
    
    </Styles>
  );
};

export default MessageBar;
