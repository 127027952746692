import { css } from 'styled-components';

export const a = css`
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  `;

export const linkStyles = css`
  color: ${({ theme }) => theme.palette.textLink};
  cursor: pointer;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.palette.purplePrimary};
    text-decoration: underline;
  }
`;

export const linkStyles1 = css`
  color: ${({ theme }) => theme.palette.purplePrimary};
  cursor: pointer;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.palette.purplePrimary};
    text-decoration: underline;
  }
`;
