import React, { useEffect, useContext, useState } from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { getUser, setToken } from '../../functions/manageSessions';
import Footer from '../../components/footer';
import { AppContext } from '../../context';
import { P, A, StyledLink } from '../../components/theme/typography';
import {userMissingFields} from '../../functions/manageSessions';
import { ReactComponent as MailSentIcon } from '../../assets/svgs/mail-sent.svg';

import {
  FormWrapperSignupFlow,
  FormWrapper,
  SwitchFlow,
  VerifyEmailIconWrapper,
  VerifyEmailTitleWrapper,
  VerifyEmailMessageWrapper,
  VerifyEmailResendLinkWrapper,
  SignupContainer,

} from './styles';
import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen';
import SignUpFlow from '../../components/form/signup-flow';

const SignUpPage = () => {
  const [userEmail, setUserEmail] = useState('');
  const [verifyEmailSent, setVerifyEmailSent] = useState(false);
  const [step, setStep] = useState(undefined);
  const [displayTerms, setDisplayTerms] = useState(true);
  const isSmallScreen = useIsSmallScreen();
  const {
    setUserStats,
    setRequestUser,
    removeUserOrganisationContext,
    userContext,
    setUserContext,
    setRequestTrackerContext,
    setUserId,
    setSkipRequests
  } = useContext(AppContext);
  const handleLogout = () => {

    setRequestTrackerContext(prev => { return {...prev, 'logout-user':true}});
    setSkipRequests(true);
    setUserId(99);// cancel and skip request
    axios
      .get(`${window.BASE_URL_USER}logout-user`, {withCredentials: true})
      .then((res) => {
        setRequestTrackerContext(prev => { return {...prev, 'logout-user':false}});
        if (res.data.success) {
          // removeUserSession();
          // setUserContext({email:''});
          setUserContext({'email':'', 'org_name':undefined});
          setUserStats(undefined);
          removeUserOrganisationContext();
          // setUserOrganisationContext(undefined);
          setRequestUser(true);
          setUserId(0);
          setSkipRequests(false);
          sessionStorage.clear();
          localStorage.clear();
          window.location =  window.BASE_API_URL;
        } else {
          console.log(
            'The request is successful, but for some reason res.data.success is not received: \n',
            res
          );
          // removeUserSession();
          // setUserContext({email:''});
          setUserContext({'email':'', 'org_name':undefined});
          setUserStats(undefined);
          removeUserOrganisationContext();
          // setUserOrganisationContext(undefined);
          setRequestUser(true);
          setUserId(0);
          setSkipRequests(false);
          sessionStorage.clear();
          localStorage.clear();
          window.location =  window.BASE_API_URL;
        }
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'logout-user':false}});
        setUserId(0);
        setSkipRequests(false);
        console.log(error);
      });
  };
  const removeAgreement= () =>{
    setDisplayTerms(false);
  };
  useEffect(() => {
    let userInfo = getUser() ;
    if(userMissingFields() && userInfo){
      if(userInfo['org-user-type'] && userInfo['org-user-type'] === "magic"){
        setStep(1);
      }
      else{
        setStep(3);
      }
    }
    else if(userContext['ask_for_details'] && !userContext['is_anonymous'] && userContext['org-user-type']!== "magic" ){
      setStep(3);
    }
    else{
      setStep(1);
    }
    },[step]);


  return (
    <>
      {/* <Header withSearchBar={false} withAccountButtons={false} /> */}
      <main>
        <SignupContainer >
        <Container className={isSmallScreen? '':'emailContainer'}>
              <Row className={"justify-content-center "} >
                <Col md={{ span: 10 }}  lg={{span:8}}>
                  <FormWrapperSignupFlow>
                    {/* <SignUpForm
                      setUserEmail={setUserEmail}
                      defaultStep = {step}
                      isSignup={false}
                    /> */}
                    <SignUpFlow
                    setUserEmail={setUserEmail}
                    defaultStep = {step}
                    setRemoveAgreement={removeAgreement}
                    isSignup={false}
                    ></SignUpFlow>
                  </FormWrapperSignupFlow>
                </Col>
              </Row>
              <Row className={"justify-content-center"} >
            <Col >
              <SwitchFlow>
              {/* <FormDisclaimerFieldWrapper className={'checkboxContainer' } >  */}
              { (displayTerms &&( typeof step === 'undefined' || step=== 1)) ? (
              <P c1 regular textoffblack  key={'terms&Conditions'+step}>By signing up, you agree to our{' '}
                <StyledLink  textlink="true" to="/terms.html" target="_blank" rel="noopener noreferrer" style={{'display': 'inline'}}>
                  Terms and Conditions
                </StyledLink>{' '}
                and{' '}
                <StyledLink textlink="true" to="/privacy.html" target="_blank" rel="noopener noreferrer" style={{'display': 'inline'}}>
                  Privacy Policy
                </StyledLink>.
              </P>
              ):
              (<P c1 regular textoffblack  key={'terms&Conditions'+step}>
                {/* logout  */}
                <StyledLink onClick={()=>handleLogout()} to="#"  textlink="true"  rel="noopener noreferrer" style={{'display': 'inline'}}>
                  Return to homepage
                </StyledLink>
              </P>)
              }
              </SwitchFlow>
            </Col>
          </Row>
        </Container>
        </SignupContainer>

      </main>
      {/* <Footer /> */}

    </>
  );
};

export default SignUpPage;
