import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getUser } from '../../functions/manageSessions';

import Header from '../../components/header';
import SignUpFormSoarBeyond from '../../components/form/signup-form-soarBeyond/index.js';

import { P, A } from '../../components/theme/typography';
import {userMissingFields} from '../../functions/manageSessions';
import {
  FormWrapper,
  SwitchFlow,
} from './styles';

const SignUpPageSoarBeyond = () => {

  return (
    <>
      <Header withSearchBar={false} withAccountButtons={false} />
      <main>
        <Container >
              <Row className={"justify-content-center"}>
                <Col md={{ span: 10 }}  lg={{span:8}}>
                  <FormWrapper>
                    <SignUpFormSoarBeyond
                      setUserEmail={''}
                      defaultStep = {1}
                    />
                  </FormWrapper>
                </Col>
              </Row>
              <Row className={"justify-content-center"}>
                <Col >
                  <SwitchFlow>
                    <P h2 semibold textoffblack>
                      Already have an account?{' '}
                      <A  href="/login"  >
                        Log in
                      </A>
                    </P>
                  </SwitchFlow>
                </Col>
              </Row>
        </Container>
      </main>
    </>
  );
};

export default SignUpPageSoarBeyond;
