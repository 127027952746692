import React, { useContext,useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Modal,Row, Col } from 'react-bootstrap';
import {  Label} from '../theme/typography';
import Button from '../button';
import StyledModal from './styles';
import { AppContext } from '../../context/index';
import './bootstrap-modal.min.css';
import { H2, P } from '../theme/typography';
import { ReactComponent as CloseIcon } from '../../assets/svgs/close-icon.svg';

const ModalSearchFeedback = ({
  modalFeedbackShow,
  modalFeedbackClose,
  result,// true if the feeback include the answer to ask 
  i,
  centered,
  requiredId,
  fromFeedbackBar,
  title,
  resultNotFound,
  successFunction,// function to run after success of endpoint
  feedbackId
}) => {
  const { 
    feedbackContext,
    // setFeedbackContext,
    setRequestTrackerContext,
    searchContext
  } = useContext(AppContext);

  const controller = new AbortController();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const [textareaValue, setTextareaValue] = useState('');
  const [noResult,setNoResult]= useState(false);
  const [missingSource,setMissingSource]= useState(false);


  const handleChangeOne = () => {
    setNoResult(!noResult);
  };

  const handleChangeTwo = () => {
    setMissingSource(!missingSource);
  };

  // Below effect clears the textarea every time the modal is triggered
  useEffect(() => {
    setTextareaValue('');
  }, [modalFeedbackShow]);


  const handleSubmit = () => {
    let textToSend = textareaValue.trim();
    if(noResult){
      textToSend = 'Irrelevant results: ' + textToSend; 
    }
    if(missingSource){
      textToSend = 'Missing sources: ' + textToSend; 
    }
    if (textToSend && textToSend.length > 0 && textToSend.trim() !== '' && result) {
      callReportIssue({
        message: textToSend,
        query: searchContext.q,
        parsed_args: {
          // ... feedbackContext,
          chunk_id: result.chunk_id,
          chunk_title: result.chunk_title,
          chunk_text: result.text,
          online_view_url: result.online_view_url,
          requested_obj: result.requested_obj,
          doc_publisher: result.doc_publisher,
        }
      });
    };
    // if (textToSend && textToSend.length > 0 && textToSend.trim() !== '' && resultNotFound && feedbackId) {
    //   callReportIssue({
    //     message: textToSend,
    //     feedback_id: feedbackId
    //   });
    // };
    // the feedbackid  to take place inside the modal
    // feedbackId has to = undefined
    // and requireId  has to = true
    // if( textToSend && textToSend.length > 0 && textToSend.trim() !== '' && resultNotFound && requiredId ){
    //   callReportIssueIfFeedBackIssue(textToSend);
    // }
    if( textToSend && textToSend.length > 0 && textToSend.trim() !== '' && resultNotFound 
        && fromFeedbackBar && typeof  fromFeedbackBar !=='undefined' ){
        callReportIssue({
        message: textToSend ,
        query: searchContext.q,
        "feedback_bar": fromFeedbackBar
      });
    }
    if (result === false && !resultNotFound) {
      callReportIssue({
        message: textToSend  ,
        "parsed_args": {"signUpStep":"sourcesFeedback"}
      });
    };
    modalFeedbackClose();
  };


  const callReportIssue = (body) =>{
    const config = {
      method: 'post',
      url: `${window.BASE_URL_USER}report-issue`,
      withCredentials : true, 
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(body),
      signal: controller.signal,
      cancelToken: source.token,
    };
    setRequestTrackerContext(prev => { return {...prev, 'report-issue':true}});
    axios(config)
    .then((res) => {
      setRequestTrackerContext(prev => { return {...prev, 'report-issue':false}});
      if(typeof successFunction === 'function'){
      successFunction();}
    })
    .catch((error) => {
      setRequestTrackerContext(prev => { return {...prev, 'report-issue':false}});
      console.log('error: ', error);
    });
  };
  

  return (
    <StyledModal
      show={modalFeedbackShow}
      onHide={() => modalFeedbackClose()}
      centered={true}
      animation={false}
    >
      <Modal.Header>
        <H2 h18 bold >
          {title}
        </H2>
        <CloseIcon
          className="modal-header-closeIcon"
          onClick={() => modalFeedbackClose()}
        />
      </Modal.Header>
      {/* <form> */}
      <Modal.Body>
        <Container>

          <Row>
            <Col md={12}>
            <P p1 regular textoffblack  > Could not find the answer? Missing a content source?</P>
            </Col>
          </Row>

          <Row className='m-2' md={12}>
              <Col  md={6} className='mt-1'>
                  <input type="checkbox"
                    value={noResult}
                    onChange={handleChangeOne}
                    id='NoResultsCheckBox'
                    />
                  <Label c1 semibold textoffblack htmlFor="NoResultsCheckBox">
                    Irrelevant results
                  </Label>
              </Col >
              <Col md={6} className='mt-1'>
                <input type="checkbox"
                  value={missingSource}
                  onChange={handleChangeTwo}
                  id='MissingSourceCheckBox'
                />
                <Label c1 semibold textoffblack htmlFor='MissingSourceCheckBox'>
                  Missing source(s)
                </Label>
              </Col>
          </Row>

          <Row>
            <Col md={12}>
                <textarea
                className="modal-body-textarea"
                value={textareaValue}
                rows="4"
                placeholder="Please provide more details... (optional)"
                onChange={(e) => setTextareaValue(e.target.value)}
                />
            </Col>

          </Row>

        </Container>
       
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="action"
          colorprimary
          buttonsmall
          text="Submit"
          width="96px"
          centered
          onClick={() => handleSubmit()}
        />
      </Modal.Footer>
      {/* </form> */}
    </StyledModal>
  );
};

export default ModalSearchFeedback;
