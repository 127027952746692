import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

// Below styles are only for mobile. As of first release, modal on
// mobile is only used in "Can't find what you’re looking for?".
const StyledModal = styled(Modal)`
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  color: #212452;
  font-size: 14px;

  .modal-header,
  .modal-body,
  .modal-footer {
    
    @media (max-width: 767.98px) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .checkbox-row {
    margin-top: 4px;
    justify-content: space-evenly;
    align-items: bottom;
  }

  .invalid {
    width: 434px;
    position: absolute;
    color: #dc3545;
    font-size: 14px;
    bottom: 16px;
  }

  .modal-link {
    color: rgb(27, 94, 247);

    &:hover {
      color: ${({ theme }) => theme.palette.purplePrimary};
    }
  }

  .modal-body {
    &-textarea {
      margin-top: ${({ theme }) => theme.spacing.x3};
    }

    @media (max-width: 767.98px) {
      padding-bottom: 24px;
    }

    height: 500px;  
    padding-bottom: 16px;
    position: relative;
  }

  .modal-footer {
    justify-content: center;
    padding: 12px 32px;
    @media (max-width: 767.98px) {
      justify-content: center;
    }
  }
`;

export default StyledModal;
