import styled from 'styled-components';

export const ContainerSuggest = styled.div`

  font-family: Mulish,sans-serif;
  font-size: 16px;
  overflow: visble;

  input {
    width: 100%;
    margin: 3px 0px 3px 0px; 
    border: 1px solid #E2E5ED;

  }
  .react-autosuggest__suggestions-container--open {
    width:100%;
    display: block;
    position: absolute;
    z-index: 2;
    border: 1px solid #E2E5ED;

    background-color: ${({ theme }) => theme.palette.backgroundWhite};
    li:hover{
      background-color: ${({ theme }) =>
      theme.palette.backgroundDropdownHoverDarker};
    }
  }
  .react-autosuggest__container {
    position: relative;

  }
  
  .react-autosuggest__input {
    width: 240px;
    // height: 40px;
    width: 100%;
    padding: 10px 10px 10px 7px;
    font-size: 14px;
    border: 1px solid #E2E5ED;
    border-radius: 3px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 20px 0px 7px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: ${({ theme }) =>
    theme.palette.backgroundDropdownHover};
   }  

,

`;

export default ContainerSuggest;
