import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Button from '../button';
import StyledModal from './styles';
import { AppContext } from '../../context/index';
import './bootstrap-modal.min.css';
import { H2, P } from '../theme/typography';
import { ReactComponent as CloseIcon } from '../../assets/svgs/close-icon.svg';
import { getOrgData, getUser } from '../../functions/manageSessions';

import { ReactComponent as MedwiseLogoWithName } from '../../assets/svgs/medwise-logo-with-name.svg';
import { ReactComponent as MedwiseLogo } from '../../assets/svgs/medwise-logo.svg';

const ModalLoginToSearch = ({
  modalFeedbackShow,
  modalFeedbackClose,
  centered,
  title,
}) => {
  return (
    <StyledModal
      show={modalFeedbackShow}
      onHide={modalFeedbackClose}
      centered={centered}
    >
      <Modal.Header>
        <MedwiseLogoWithName/>

        {/* <CloseIcon
          className="modal-header-closeIcon"
          onClick={() => modalFeedbackClose()}
        /> */}
      </Modal.Header>
      <Modal.Body>
        <P p1 regular textoffblack>
          Log in for access to local sources, CPD entries and search history.
        </P>
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center">
        <Button
          type="link"
          colorprimary
          buttonsmall
          text="Log in or sign up"
          width="80%"
          centered
          to="/signup"
        />

      </Modal.Footer>
    </StyledModal>
  );
};

export default ModalLoginToSearch;
