import styled from 'styled-components';

export const Styles = styled.div`
  max-width: 700px;
  height: 25%;
  margin:0px;
  margin-right: auto;
  margin-left: auto;
  
  .noMagic{
    margin-top: calc(40vh - 80px);
    @media (max-width: 767.98px) {
      margin-top: calc(40vh - 80px);
    }
  }

  .isMagic{
    margin-top: 0;
    @media (max-width: 767.98px) {
      margin-top: 0px;
    }
  }
 
`;

export default Styles;
