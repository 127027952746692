import styled from 'styled-components';

export const CPDCredits = styled.div`
  // margin-top: -8px;
  text-align: center;
  color: #66677C;
  // width: 50px;
  display:block;
  // margin-left:10px;

  [data-tooltip]:before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;
    
    /* customizable */
    padding:3px;
    color: #212452;
    border: 1px solid #212452;
    border-radius:  ${({ theme }) => theme.spacing.x1};;
  }
  

  [data-tooltip]:hover:before {
      /* needed - do not touch */
      opacity: 1;
      /* customizable */
      background: ${({ theme }) => theme.palette.backgroundGrey} ;
      margin-top: 45px;
      margin-left: -50px;    
  }

  [data-tooltip]:not([data-tooltip-persistent]):before {
      pointer-events: none;
  }

  H1
  {
    font-weight: 800;
    font-size: 14px;
    line-height: 150%;
  }
  div{
    cursor: pointer;
    padding :0px;
  }
  p{
    color: #66677C;
  }
  .cdpCredit{
    margin:0;
    min-height:100%;
    font-size:12px;
    // overflow:hidden;
    // width:60px;
    @media (max-width: 880px) {
      display: none;
    }
  }

  .left{
    border-right: 0.5px solid rgba(198, 200, 222, 1);
  }    


`;

export default CPDCredits;
