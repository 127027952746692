import styled from 'styled-components'

const AlertBase = styled.div`
position: relative;
padding: .75rem 1.25rem;
margin-bottom: 1rem;
border: 1px solid #0000;
border-radius: .25rem;
transition: opacity .15s linear;`

const AlertPrimary = styled(AlertBase)`
color: #004085;
background-color: #cce5ff;
border-color: #b8daff;
display: ${props => props.show ? 'block' : 'none'};`

const AlertDanger = styled(AlertBase)`
color: #721c24;
background-color: #f8d7da;
border-color: #f5c6cb;
display: ${props => props.show ? 'block' : 'none'};`

export {AlertPrimary, AlertDanger}