import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { stripTags, stripTagsArray,removeDuplicatesFromSecondArray } from '../../functions/utils';
import { P } from '../theme/typography';

const AcResult = ({ list, list2, cursor, querySubmit, setHoveredAC }) => {
  var index_add =0; 
  var new_list = list;
  if (list2){
    index_add = list2.length;
    new_list = removeDuplicatesFromSecondArray(list2,list);
  }
  return (
      // If AC list item needs to be filtered, it can be chained here.
      new_list.map((value,index) => {
        return (
          <li
            key={index+index_add}
            onClick={(e) => querySubmit(e, value)}
          >
            <div className="ac_list--item--magnify" aria-label="Search">
              <FaSearch title="search icon" />
            </div>
            <P
              p1
              regular
              textoffblack
              dangerouslySetInnerHTML={{ __html: value }}
            ></P>
          </li>
        );
      })
  );
};

export default AcResult;
