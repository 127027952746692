import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { Row } from 'react-bootstrap';
import { getNameOfDecoded, countWords } from '../../functions/utils';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { AppContext } from '../../context/index';

const MultipleSourceFilter =(props) =>{
    const { 
        searchContext,
        setSearchContext,
        userContext,
        setIsResultsExpanded,
        setResultsFirstExpanded,
        setConfirmCouldNotFind,
        setSuspectCouldNotFind,
        setLoadMore,
        setClearLocalWebResults,
        setShowAssistedAI,
        setLocalWebResults
      } = useContext(AppContext);

    const handleCheckboxLegend = (e, filter) => {
      // e.stopPropagation();
      handleSubClick(filter["id"]);
      //check if check or not 
      let updatedFacets = [];
      let uncheckedUrl =[];
      // let allSourcesInFilter = []; 
      filter['sources'].forEach(src => {
          let url = src['encrypted_url'];
          if(!props.isCheckedDic[url]){
            uncheckedUrl.push(url);
          }
      });
      // if filter is checked 
      if (props.filterChecked[filter['id']]){
          updatedFacets = searchContext['facets'].slice();
          if (updatedFacets.length >=1 && updatedFacets[0]["name"]== "Web"){
            updatedFacets.shift();
          }
          filter['sources'].forEach(src => {
            updatedFacets = removeFacetsFromArray(updatedFacets,src['encrypted_url']);
          });
      } else {
        // filter is not checked checked everything 
        updatedFacets = searchContext['facets'].slice();
        if (updatedFacets.length >=1 && updatedFacets[0]["name"]== "Web"){
          updatedFacets.shift();
        }
        uncheckedUrl.forEach(url => {
          updatedFacets.push(getSourceFromEncryptedUrl(url));
        });
      }
      if (props.firstClick){
          updatedFacets = filter['sources'];
          // uncheckedUrl.forEach(url => {
          //   updatedFacets.push(getSourceFromEncryptedUrl(url));
          // });       
          props.setFirstClick(false);
      }
      if (searchContext.q) {
        if (countWords(searchContext.q) > 4) {
          setIsResultsExpanded(true);
          setResultsFirstExpanded(undefined);
        } else {
          setIsResultsExpanded(false);
          setResultsFirstExpanded(false);
        };
      };
      setSuspectCouldNotFind(false);
      setConfirmCouldNotFind(false);
      setLoadMore(undefined);
      setClearLocalWebResults(false);
      setShowAssistedAI(true);
      setLocalWebResults({0: [], 1: [], 2: [], 3: [], 4: []});

      setSearchContext(currentVal => 
        {return {...currentVal,
                facets: updatedFacets.slice(), 
                forceSearch: true
            };}
      );
    };

    // remove a source from an array of filters or sources 
    // returns an array of sources
    const removeFacetsFromArray =(array, encryptedUrl) =>{
        let updatedArray = [];
        array.forEach(element => {
          // if it is a source check url
          if(element['encrypted_url'] && element['encrypted_url'] !== encryptedUrl){
            updatedArray.push(element);
          }
          else{
            //loop through sources to find it
            if (element && element['sources']){
              element['sources'].forEach(src => {
                if(src['encrypted_url'] && src['encrypted_url'] !== encryptedUrl){
                  updatedArray.push(src);
                }
              });
            }
            
          }
  
        });
        return updatedArray;
    }

    const handleSubClick = (id)=> {
        var currentSubIsExpanded ={...props.isSubExpanded};
        if(!props.isSubExpanded[id]){
          currentSubIsExpanded[id]= !props.isSubExpanded[id];
        }
        props.setIsSubExpanded(currentSubIsExpanded); 
      };

    const handleSubClickExpand = (id)=> {
        var currentSubIsExpanded ={...props.isSubExpanded};
        currentSubIsExpanded[id]= !props.isSubExpanded[id];
        props.setIsSubExpanded(currentSubIsExpanded); 
      };

    const handleCheckBoxClick = (source)=> {
      let updatedFacets = [];
      let TempEncryptedUrl = source["encrypted_url"];
      // should 
      if (TempEncryptedUrl && props.isCheckedDic[TempEncryptedUrl]){
          searchContext["facets"].forEach(facet => {
          // facet is a source
          if(facet && facet['encrypted_url']  ){     
              if (facet['encrypted_url'] !== TempEncryptedUrl && facet['name']!=="Web"){
              updatedFacets.push(facet);
              }
          }
          });
      } else {
        updatedFacets = searchContext["facets"];
        if (updatedFacets.length >=1 && updatedFacets[0]["name"]== "Web"){
          updatedFacets.shift();
        }
        updatedFacets.push(source);
      };

      if (props.firstClick) {
        updatedFacets = [];
        updatedFacets.push(source)
        props.setFirstClick(false);
      };
      if (searchContext.q) {
        if (countWords(searchContext.q) > 4) {
          setIsResultsExpanded(true);
          setResultsFirstExpanded(undefined);
        } else {
          setIsResultsExpanded(false);
          setResultsFirstExpanded(false);
        };
      };
      setSuspectCouldNotFind(false);
      setConfirmCouldNotFind(false);
      setLoadMore(undefined);
      setClearLocalWebResults(false);
      setShowAssistedAI(true);
      setLocalWebResults({0: [], 1: [], 2: [], 3: [], 4: []});

      setSearchContext(currentVal => 
        {return {...currentVal,
                facets: updatedFacets.slice(), 
                forceSearch: true
            };}
      );

    };

    const handleSourceClick = (source) => {
      if ((source.private)) {
        requestTokenAndVisit(source);
      } else {
        window.open(source.url,'_blank','noreferrer');
      }
    };
    
    const requestTokenAndVisit =  (source) => {
    let new_url = ''; 
    let tempRequestToken= source.encrypted_url.slice(8); 
    if(source.requestToken){
        tempRequestToken =source.requestToken;
    }
        if (userContext['search_params']){
          if ( source.url.includes('__PUBLIC_TOKEN__') ) {
              new_url = source.url.replace('__PUBLIC_TOKEN__',userContext['search_params'] );
              }
          else{
              new_url = source.url + "?token=" + userContext['search_params'];  
              }
              window.open(new_url,'_blank','noreferrer');
          }
        else{
          window.open(new_url,'_blank','noreferrer');
        }
    };

    const getSourceFromEncryptedUrl = (url)=> {
        // let allSources =getAllSources();
        let src ;
        props.allSources.forEach(source => {
          if(source['encrypted_url'] && source["name"] && source['encrypted_url'] === url)
          {
            src= source;
          }
        });
        return src;
      };

    return(
        <li key={'subSection'+props.filter['name']}>
        <fieldset style={{ "margin": "0 0 0 0", "paddingLeft": "4px" , "display":'contents'}}>
        <Row className='p-1 m-0' >
          <legend  style={{"paddingLeft": "0px", 'fontSize':'14px', 'fontWeight':'700'}}>
            <input style={{'margin':'0','fontSize':'14px' }}  type="checkbox" 
            id={ typeof props.filter['name']!=='undefined' ? 'subSection'+props.filter['id']+ props.filter['name'].substring(0,16): 'subSection'+props.filter['id']} 
            name={ typeof props.filter['name']!=='undefined'  ? 'subSection'+props.filter['id']+ props.filter['name'].substring(0,16):'subSection'+props.filter['id'] } 
            checked={props.filterChecked[props.filter['id']]} 
            className="tittle-checkbox" onChange={(e)=>handleCheckboxLegend(e,props.filter)} ></input>
            <label className='filter-tittle'
            htmlFor={typeof props.filter['name']!=='undefined'  ? 'subSection'+props.filter['id']+ props.filter['name']:'subSection'+props.filter['id'] } 
            >
            { (typeof props.filter['name']!=='undefined' && getNameOfDecoded(props.filter['name']) )  && getNameOfDecoded(props.filter['name'])}
            </label>
          {props.isSubExpanded[props.filter["id"]] ? <FaChevronUp color='#1A6ADE' className='chevron'
          onClick={()=>handleSubClickExpand(props.filter["id"])}/>:<FaChevronDown color='#1A6ADE' className='chevron' onClick={()=>handleSubClickExpand(props.filter["id"])}/>}
          </legend>
          </Row>
        {props.isSubExpanded[props.filter["id"]]  && (
        <ul>
          {props.filter['sources'] && props.filter['sources'].length >0 && props.filter['sources'].map((source) => {
        return ( 
          <Row className='subsectionFilter' key={"userFilterSource"+source['id']+source['name']}>
              <li >
                <input type="checkbox" id={source['name']} name={source['name']}  
                checked={props.isCheckedDic[source['encrypted_url']]}  onChange={()=> { handleCheckBoxClick (source)} } >

                </input>
                <label className='subSource' htmlFor={source['name']} >
                  {getNameOfDecoded( source['name'])}
                </label>
                <a   onClick={()=>handleSourceClick(source)} target='_blank' rel="noopener noreferrer"  referrerpolicy="no-referrer" title={source['url']}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#212452" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                    className="feather feather-external-link ml-1 align-top">
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                  </a>
              </li>
          </Row>)
          ;})}
        </ul>
        )}
        </fieldset>
      </li>
    );
};
export default MultipleSourceFilter;