import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Button from '../button';
import StyledModal from './styles';
import { AppContext } from '../../context/index';
import './bootstrap-modal.min.css';
import { H2, P } from '../theme/typography';
import { ReactComponent as CloseIcon } from '../../assets/svgs/close-icon.svg';
import { getOrgData, getUser } from '../../functions/manageSessions';
import { StyledLink } from '../theme/typography';
import GoogleBtn from '../form/google-btn/index.js';
import { Row, Col, Container } from 'react-bootstrap';

const ModalSSOLogin = ({
  modalFeedbackShow,
  modalFeedbackClose,
  centered,
  title,
  link,
  isGoogle,
}) => {
  return (
    <StyledModal
      show={modalFeedbackShow}
      onHide={modalFeedbackClose}
      centered={centered}
    >
      <Modal.Header>
        <H2 h2 semibold textoffblack>
          {title}
        </H2>
        <CloseIcon
          className="modal-header-closeIcon"
          onClick={() => modalFeedbackClose()}
        />
      </Modal.Header>
      <Modal.Body>
        <P p1 regular textoffblack>By continuing, I confirm that I have read the{' '}
          <StyledLink textlink="true" to="/terms.html" target="_blank" rel="noopener noreferrer" style={{'display': 'inline'}}>
            Terms and Conditions
          </StyledLink>{' '}
          and{' '}
          <StyledLink textlink="true" to="/privacy.html" target="_blank" rel="noopener noreferrer" style={{'display': 'inline'}}>
            Privacy Policy
          </StyledLink>.
        </P>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between align-items-center">
        <Button
          type="action"
          colorprimary
          buttonsmall
          text="Cancel"
          width="96px"
          centered
          onClick={() => modalFeedbackClose()}
        />
        {isGoogle ? (
          <GoogleBtn >
          </GoogleBtn>
        ) : (
          <Button
            type="link"
            colorprimary
            buttonsmall
            text="Continue"
            width="96px"
            centered
            to={link}
          />
        )}
        
      </Modal.Footer>
    </StyledModal>
  );
};

export default ModalSSOLogin;
