import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../context';
import { Row, Col, Container } from 'react-bootstrap';
import Button from '../../../button';
import { Label, StyledLink, A } from '../../../theme/typography';
import SourcesCard from '../sources-card';
import ModalSearchFeedback from '../../../modal-search-feedback';
import { ConsoleView } from 'react-device-detect';



const SignUpStepAddSources = (props) => {
  const history = useHistory();
  const [checkedSources, setCheckedSources] =useState([]);
  const [sourcesToAdd, setSourcesToAdd] = useState([{"id":0, "name":"", "url":""}]);


  const [expandFeedback, setExpandFeedback] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [sourcesUpToDate, setSourcesUpToDate] = useState(undefined);

  const { 
    setReportIssue
  } = useContext(AppContext);
  // const [checkSourcesModified, setCheckSourcesModified] =useState([]);


  const handleOnChangeText = (id,name,url) => {
    if(sourcesToAdd && sourcesToAdd.length >0 && sourcesToAdd[id])
    {
      let tempArray =sourcesToAdd.slice(); 
      let currentElement = {...tempArray[id] };
      if(name !== ""){
        currentElement["name"] = name;
      }
      if(url !== ""){
        currentElement["url"] = url ; 
      }
      tempArray[id] = currentElement;
      setSourcesToAdd(tempArray);
    }
  };

  

  const handleStepFourNavigation = () => {
    if((sourcesToAdd && sourcesToAdd.length>1 && (sourcesToAdd[0]['name']!== "" || sourcesToAdd[0]['url']!=='' ))
        || typeof sourcesUpToDate !=='undefined'  ){

      let message = "" ;
      if(sourcesToAdd && typeof sourcesUpToDate !=="undefined"){
        message = "Are sources up to date : " + sourcesUpToDate.toString(); 
      }
      if (sourcesToAdd && sourcesToAdd.length>=1){
        message =  message +"\n Sources: \n";
        sourcesToAdd.forEach(src => {
          if (src&& src["name"]){
            message = message + " name: " + src['name']  + '\n';
          }
          else{
            message = message + " name: "  + '\n';
          }
          if (src && src["url"]){
            message = message + " url: " + src['url']  + '\n';
          }
          else{
            message = message + " url: "  + '\n';
          }
        }); 
      }
      setReportIssue( prev => {
        return {'triggerRequest':true, "message": message ,
        "parsed_args": {"signUpStep":"sourcesFeedback"}}
      });
    }
    if (checkedSources && checkedSources.length > 0 ) {
      props.addCustomSources(checkedSources);
    }
    else{
      if (props.step ===4){
        // props.setNextStep(5); //step5 has been remove
        history.push('/');
      }
      else{
        //step 5 was remove
        // if (props.step === 5 ){
        //   // window.location =  window.BASE_API_URL;
        //   history.push('/');
        // }
      }
    }
  };
  
  useEffect(() => {
    if(typeof props.suggestedSources !== 'undefined' ){
      setCheckedSources(props.suggestedSources);
    }
  },[]);


  return (
        <Container>
          {props.suggestedSources && props.suggestedSources&&(
          <Container>
            <Row style={{padding:" 0px 0px 15px 0px"}}>
              <Col md={12}>
                {props.icbName ?<Label p1 bold textoffblack  >
                  We believe your practice is in {props.icbName}. Here are the related guidelines:. Please select those you would like to add. <br/>
                  <Container className='mt-2'>
                    <Label p1 bold textoffblack >{props.suggestedSources['full_name']}</Label></Container> 
              </Label>: <Label p1 bold textoffblack  >
                We found the following knowledge resources for your area. Please select those you would like to add. <br/>
                  <Container className='mt-2'>
                    <Label p1 bold textoffblack >{props.suggestedSources['full_name']}</Label></Container> 
              </Label> }
              {/* <Label p1 bold textoffblack  >
                We found the following knowledge resources for your area. Please select the one you would like to add. <br/>
                  <Container className='mt-2'>
                    <Label p1 bold textoffblack >{props.suggestedSources['full_name']}</Label></Container> 
              </Label> */}
              </Col>
            </Row>
            <Row>
                <Col className='px-4'>
                  {props.suggestedSources.map((proposal, i) => (
                  <SourcesCard 
                  name={proposal.expanded_name} 
                  abbreviation={proposal.name}
                  key= {109}
                  id={i+110} 
                  url={proposal.url} 
                  url_encrypted ={proposal.encrypted_url}
                  index={i+110} 
                  width={"100%"} 
                  setCheckedSources={setCheckedSources}
                  checkedSources={checkedSources}
                  // preCheckedSources= {preCheckedSources}
                  withCheckBox = {true}
                  withLinks={true}
                  selected = {true}
                   ></SourcesCard>
                          ))}
              </Col>
            </Row>
            <Row>
              <Col md={12}  className={"mt-4 py-3 "}>
                  <Label p1 bold  textoffblack  >
                  Are we missing a local knowledge resource, or do you see something that is out of date?
                    <Label  p1  purpleprimary style={{"cursor":"pointer"}} onClick={()=>{setOpenModal(true)}} > Let us know </Label>
                  </Label>
              </Col>
              <ModalSearchFeedback
              modalFeedbackShow={ openModal}
              modalFeedbackClose={setOpenModal} 
              result={false}
              
              title=" Let us know how to improve local knowledge resource "
              />              
            </Row>
          </Container>
          )}
          { props.organisationSources && props.organisationSources.length >0  && (
          <Container >
            <Row>
              <Col md={12} style={{"height":"40px"}}>
                <Label p1 semibold textoffblack  >
                Your organisation has added the following sources:
                </Label>
              </Col>
              <Col md={12} >
              {props.organisationSources.map((proposal, i) => (
                <SourcesCard 
                name={proposal.name}
                id={i+220}
                key= {201+i}
                url={proposal.url}
                url_encrypted= {proposal.url_encrypted}
                index={i+220} 
                width={"100%"}  
                setCheckedSources={setCheckedSources}
                checkedSources={checkedSources}
                withCheckBox = {false}
                ></SourcesCard>
                      ))}
              </Col>
            </Row>

          </Container>)}
          <br></br>
          <Button
            id="custom_sources_submitted" 
            type="button"
            colorprimary
            buttonlarge
            text="Submit"
            width="100%"
            onClick={handleStepFourNavigation}
            /> 
        </Container>
  );
};

export default SignUpStepAddSources;
