import styled from 'styled-components';
import { theme } from '../../../components/theme/variables';


export const TagsWrapper = styled.div`
  // height: 40px;
  // overflow:hidden;
  display: flex;
  flex-flow: row wrap;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout : none
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  [data-tooltip]:before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;
    
    /* customizable */
    padding:3px;
    color: rgb(0 99 112);
    border: 1px solid ${ theme.palette.greenDark};
    border-radius:  ${({ theme }) => theme.spacing.x1};;
  }

  [data-tooltip]:hover:before {
      /* needed - do not touch */
      opacity: 1;
      /* customizable */
      background: #EFF9FB;
      margin-top: -70px;
      margin-left: -20px;    
  }

  [data-tooltip]:not([data-tooltip-persistent]):before {
      pointer-events: none;
  }

  .selected {
    background-color: ${({ theme }) => theme.palette.backgroundPurple};
    border: 1px solid #8771DF;
  }
  .selectable {
    background-color: ${({ theme }) => theme.palette.backgourndWhite};
  }
  .coloured {
    color: rgb(0 99 112);
    background-color: ${ theme.palette.greenLight};
    border: 1px solid ${ theme.palette.greenDark};
  }
  .inactivity_alert{
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 600;
    color: rgb(33, 36, 82);
    height: 26px ;
  }
  .hidden_inactivity_alert{
    display:none;
  }
`;

export const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  // background-color: ${({ theme }) => theme.palette.backgourndPurple};
  border: 1px solid ${({ theme }) => theme.palette.textPlaceholder};
  border-radius: ${({ theme }) => theme.spacing.x6};
  margin: 4px 16px 10px 0;
  padding: 4px ${({ theme }) => theme.spacing.x2};
  cursor: pointer;

  .remove {
    color: ${({ theme }) => theme.palette.textPlaceholder};
    margin-left: ${({ theme }) => theme.spacing.x2};
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.palette.textOffblack};
    }
  }
`;

export default TagsWrapper;
