import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

// Below styles are only for mobile. As of first release, modal on
// mobile is only used in "Can't find what you’re looking for?".
const StyledModal = styled(Modal)`
  overflow-x: hidden !important;
  overflow-y: hidden !important;

  .modal-dialog {
    width: 95%;
    max-width: none!important;
    height: 90vh;  
    
    @media (max-width: 767.98px) {
      height: 80vh;  
    }
  }

  .modal-content {
    position: relative;
    bottom: 45%;
    height: 90vh;
    transform: translateY(40%);

    @media (max-width: 767.98px) {
      transform: translateY(42%);
    }
  }

  .pdf-preview {
    width: 100%;
    height: 100%;
  }

  .modal-body {
    position: relative;
    padding: 24px;

    @media (max-width: 767.98px) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .modal-header-closeIcon {
    position: absolute;
    top: 8px;
    right: 8px;
  }
`;

export default StyledModal;
