import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { linkStyles } from '../../components/theme/link';

export const ContainerWithTopSpacing = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing.x5};
  margin-top: 64px;
  @media (max-width: 767.98px) {
    padding-top: 0;
  }

  .snapToEdges {
    @media (max-width: 767.98px) {
      padding: 0;
    }
  }
`;

export const AccountTabList = styled.ul`
  display: flex;
  flex-flow: column nowrap;

  @media (max-width: 767.98px) {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .separator {
    margin: 5px;
    border-bottom: solid 1px rgb(226, 229, 237);
  }
`;

export const AccountTabListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: ${({ theme }) => theme.borderradius.medium};
  margin-bottom: ${({ theme }) => theme.spacing.x1};

  @media (max-width: 767.98px) {
    width: 33%;
    margin-bottom: ${({ theme }) => theme.spacing.x2};
    justify-content: center;
    padding: ${({ theme }) => theme.spacing.x2};
    border-bottom: 4px solid
      ${({ theme }) => theme.palette.backgroundDropdownHover};
    border-radius: 0;
  }

  span {
    padding-left: 12px;
    text-decoration: underline;

    @media (max-width: 767.98px) {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;

    span {
      color: ${({ theme }) => theme.palette.textOffblack};
    }

    svg > path {
      stroke: ${({ theme }) => theme.palette.textOffblack};
    }

    svg > g > path {
      stroke: ${({ theme }) => theme.palette.textOffblack};
    }
  }

  &.active {
    background-color: ${({ theme }) => theme.palette.backgroundDropdownHover};

    @media (max-width: 767.98px) {
      background-color: ${({ theme }) => theme.palette.backgroundGrey};
      border-bottom: 4px solid ${({ theme }) => theme.palette.purplePrimary};
    }

    span {
      color: ${({ theme }) => theme.palette.textOffblack};
      font-weight: ${({ theme }) => theme.fontweight.semibold};
      text-decoration: none;
    }

    svg > path {
      stroke: ${({ theme }) => theme.palette.textOffblack};

      @media (max-width: 767.98px) {
        stroke: ${({ theme }) => theme.palette.purplePrimary};
      }
    }

    svg > g > path {
      stroke: ${({ theme }) => theme.palette.textOffblack};

      @media (max-width: 767.98px) {
        stroke: ${({ theme }) => theme.palette.purplePrimary};
      }
    }
  }
`;

export const AccountTabsFormWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${({ theme }) => theme.palette.backgroundWhite};
  border-radius: ${({ theme }) => theme.borderradius.medium};
  box-shadow: 0 5px 9px ${({ theme }) => theme.palette.backgroundStrokeHover};
  padding: ${({ theme }) => theme.spacing.x6} ${({ theme }) => theme.spacing.x9};

  @media (max-width: 767.98px) {
    padding: ${({ theme }) => theme.spacing.x3};
    box-shadow: none;
  }
`;

export const SwitchFlow = styled.div`
  text-align: center;
  margin: ${({ theme }) => theme.spacing.x5} 0;

  @media (max-width: 767.98px) {
    margin: ${({ theme }) => theme.spacing.x3} 0;
  }

  a {
    ${linkStyles}
  }
`;
