import styled, { css } from 'styled-components';

export const Styles = styled.div`
  display: flex;
  flex-flow: column nowrap;
  // position: absolute;
  // bottom: 0;
  // width: 100%;
  // z-index: 999;
  margin-bottom: 12px;
  position: fixed;
  width: 100%;
  bottom: 118px;
  // align-items: center;

  a{
    width:100%;
  }
  PublisherTag{
    width:40%;

    margin: 0;
    height: 21px;
  }

  @media (max-width: 768px){
    z-index: 995;
  }

  .search {
    &_container {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      ${({ isMobile }) =>
        !isMobile &&
        css`
          max-width: 610px;
        `}
      margin: 0 auto;
      justify-content: center;
      // z-index:999;
      ${({ isAtHeader }) =>
        isAtHeader &&
        css`
          margin: 0;
          justify-content: flex-start;
        `}
    }

    &_inputGroupWithButton {
      display: flex;
      width: 100%;
      border-radius: 10px;
      // max-width: 610px;
      ${({ isMobile }) =>
        !isMobile &&
        css`
          max-width: 610px;
        `}
    }

    &_inputGroup {
      position: relative;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      &--magnify {
        position: absolute;
        display: flex;
        color: ${({ theme }) => theme.palette.textPlaceholder};
        pointer-events: none;
        margin-left: 12px;
        font-size: 20px;

        @media (max-width: 767.98px) {
          display: none;
        }
        &-tooltip {
          position: relative;
          display: inline-block;
          border-bottom: 1px dotted black;
        }
        
        &-tooltip  {
          visibility: hidden;
          width: 300px;
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
        
          /* Position the tooltip */
          position: absolute;
          z-index: 1;
        }
        &-tooltiptext{
          visibility: hidden;
          width: 300px;
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
        
          /* Position the tooltip */
          position: absolute;
          z-index: 1;
        }
        &-tooltip:hover  {
          visibility: visible;
        }
        &-tooltiptext {
          visibility: visible;
        }
      }

      &--input {
        width: 100%;
        height: 48px;
        padding: 0 44px;
        font-size: 16px; // This is hard-coded because main search bar font size is different than global input styles (globals are in theme/input.js)
        font-weight: ${({ theme }) => theme.fontweight.regular};
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        ${({ isAtHeader }) =>
          isAtHeader &&
          css`
            background-color: ${({ theme }) => theme.palette.backgroundWhite};
            font-size: ${({ theme }) => theme.fontsize.desktop.input};
            border-radius: ${({ theme }) => theme.borderradius.medium};
            height: 40px;

            @media (max-width: 767.98px) {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }

            &.active {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }
          `}

        // &.active {
        //   border-bottom-left-radius: 0;
        // }

        @media (max-width: 767.98px) {
          font-size: ${({ theme }) => theme.fontsize.mobile.input};
          width: 100%;
          padding-left: ${({ theme }) => theme.spacing.x2};
        }
      }

      &--clear {
        display: flex;
        position: absolute;
        color: ${({ theme }) => theme.palette.textPlaceholder};
        right: ${({ theme }) => theme.spacing.x1};
        padding: ${({ theme }) => theme.spacing.x1};
        padding-top: 12px;
        padding-bottom: 11px;
        cursor: pointer;
      }

      &--button {
        z-index: 999;
        position:relative;
        left:-4px;
        font-family: ${({ theme }) => theme.fontfamily.mulish};
        font-size: 14px;
        font-weight: 400;
        color: rgb(77, 81, 86);
        background-color: #e8e8e8;
        border: none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 0 ${({ theme }) => theme.spacing.x3};
        margin: 0;
        // &.active {
        //   border-bottom-right-radius: 0;
        // }

        ${({ isAtHeader }) =>
          isAtHeader &&
          css`

            @media (max-width: 767.98px) {
              display: block;
            }
          `}

        &--icon {
          display: none;
          color: ${({ theme }) => theme.palette.backgroundWhite};

          @media (max-width: 767.98px) {
            display: flex;
          }
        }

        &--text {
          @media (max-width: 767.98px) {
            display: none;
          }
        }
      }
    }

    &_speechBubble {
      position: absolute;
      left: 40px;
      top: 64px;

      @media (max-width: 767.98px) {
        display: none;
      }

      &_content {
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          text-align: center;
        }

        &:not(:last-child) {
          margin-bottom: ${({ theme }) => theme.spacing.x1};
        }

        svg {
          min-width: 12px;
          min-height: 12px;
          max-width: 12px;
          max-height: 12px;
          margin-right: 6px;
        }

        &--checkIcon {
          margin-top: 3px;
          margin-bottom: auto;
          color: ${({ theme }) => theme.palette.greenDark};
        }

        &--timesIcon {
          color: ${({ theme }) => theme.palette.pinkDark};
        }
      }
    }

    
  }

  .chatinput:active,
  .chatinput:focus {
    border-color: #d9d9d9 !important;
  }

  .ac {
    width: 100%;
    max-width: 610px;
    // max-height: 237px;
    height: auto;
    overflow-y: scroll;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    border: 0px solid ${({ theme }) => theme.palette.backgroundStroke};

    @media (max-width: 767.98px) {
      border: 0px solid ${({ theme }) => theme.palette.backgroundStroke};
    }

    border-top: 0;
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
    
    margin: 0 auto;
    position: absolute;
    top: 48px;
    background-color: ${({ theme }) => theme.palette.backgroundWhite};
    z-index: 999;

    ${({ isAtHeader }) =>
      isAtHeader &&
      css`
        top: 40px;
      `}

    &::-webkit-scrollbar {
      display: none;
    }

    &_list{
      &--item{
        &--user{
          cursor: default;
          padding: 0;
          &.active{
            text-decoration: none;
            background-color: ${({ theme }) =>
            theme.palette.backgroundDropdownHover};
          }

           .link {
              text-decoration: none;
              background-color:  ${({ theme }) =>
              theme.palette.backgroundDropdownHover};
          }
          p{
            width:90%;
            margin-left: ${({ theme }) => theme.spacing.x1};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    &_list {
      
      &--item {
        
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.palette.textOffblack};
        padding: 12px ${({ theme }) => theme.spacing.x2};
        cursor: default;

        @media (max-width: 767.98px) {
          padding: 12px ${({ theme }) => theme.spacing.x5};
        }

        b {
          font-weight: ${({ theme }) => theme.fontweight.bold +100};
        }
        p{
          width:90%;
          margin-left: ${({ theme }) => theme.spacing.x1};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          hover:{
            text-decoration: underline;
          }
        }

        &.active {
          text-decoration: none;
          background-color: ${({ theme }) =>
            theme.palette.backgroundDropdownHover};
        }
        &--externalLink{
          width: calc(100% - 80px);
          display: inline-flex;
          font-size: ${({ theme }) => theme.fontsize.desktop.p1};
          font-weight: ${({ theme }) => theme.fontweight.regular};
          color: ${({ theme }) => theme.palette.textOffblack};
          p{
            margin-left: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            hover:{
              text-decoration: underline;
            }
          }
          svg{
            width:11px;
            height:11px;
          }
          .svgWrapper{
            width:15px;
            margin: auto 0;
          }

        }


        &--magnify {
          display: inline-flex;
          color: ${({ theme }) => theme.palette.textPlaceholder};
          // margin-right: ${({ theme }) => theme.spacing.x2};
          @media (max-width: 767.98px) {
            display: none;
          }
        }
      }
    }
  }
`;

export default Styles;
