import React, { useEffect, useContext,useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Header from '../../components/header';
import Loader from 'react-loader-spinner';
import { P, StyledLink,H1,Span } from '../../components/theme/typography';
import { FormWrapper, SwitchFlow } from './styles';
import { AppContext } from '../../context';
import { setUserSession,getUser,removeUserSession } from '../../functions/manageSessions';
import { LoginFormTitleWrapper } from '../../components/form/styles';
import { usePromiseTracker } from "react-promise-tracker";
import ConfirmLogin from '../../components/confirmLogin';


const LoginMagicPage = ({ history, location }) => {
  const { setUserStats,
          requestTrackerContext,
          setRequestTrackerContext,
          userContext,
          setUserContext,
          userOrganisationContext,
          setUserContextFilters,
          setRequestFilters} = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [askToLogout, setAskToLogout] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(undefined); 
  const [magicCode,setMagicCode] = useState('');
  const [organisation, setOrganisation] = useState(undefined);

  const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
       return (
        promiseInProgress &&
        <div
      style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
       }}
       >
        <Loader type="Oval" color="#8771DF" height="100" width="100" />
    </div>
      );
     }

  useEffect(() => {
    let user_info = getUser();
    if (user_info && user_info.hasOwnProperty('org_name')  ){
      setOrganisation(user_info['org_name']);
    }
    else{
      setOrganisation(undefined);
    }

    if (history.action === 'POP' || history.action=== 'PUSH') {
    // if ( typeof userContext !=='undefined') {

      // Either page initial/re load, or through browser's forward/back buttons.
      // This eventually becomes a PUSH because context sends user back to this page with history.push
      //code=XXX

      if (location && location.search.substr(0, 6) === '?code=' ) {
        let dirtyParameter = location.search.slice(6);
        let dirtyCode = dirtyParameter.split('&extra_param=')[0];
        let dirtyExtraParamValue = dirtyParameter.split('&extra_param=')[1];
        try {
          const cleanCode = decodeURI(dirtyCode);
          if (cleanCode) {
            setMagicCode(cleanCode);
            if(user_info){
              request_check_magic_code(cleanCode);
            }
            else{
              if (dirtyExtraParamValue && dirtyExtraParamValue.length >0 ){
                const cleanExtraParamValue = decodeURI(dirtyExtraParamValue);
                requestMagicLoginExtraParam(cleanCode,cleanExtraParamValue);
              }
              else{
                let onlyCode= cleanCode.split('&')[0];
                request_magic_login(onlyCode);
              }
            }
          } else {
            setErrorLogin(true);
            setErrorMsg('Please try with another magic link');
          }
        } catch (e) {
          // URI malformed, send user to 404
          setErrorLogin(true);
          setErrorMsg('Error please try with another magic link');
        }
      } else {
        // Other error cases, send user to 404
        setErrorLogin(true);
        setErrorMsg('Error please try with another magic link');
        // return history.push('/404');
      }
    } 

  }, [location, history]);

  const request_magic_login = (code)=>{
    setRequestTrackerContext(prev => { return {...prev, 'login-magic-user':true}});
    setIsLoading(true);
    axios({
      method: 'POST',
      url:`${window.BASE_URL_USER}login-magic-user`,
      withCredentials: true,
      headers: {'Content-Type': 'application/json' },
      data: JSON.stringify({'magic-code': code }),
    })
      .then( (res) => {
        setRequestTrackerContext(prev => { return {...prev, 'login-magic-user':false}});
        setIsLoading(false);
  
        if (res.data.success) {
          const user_info = res.data.user;
          if( setUserContextFilters   ){
            setUserContextFilters(undefined);
          }
          // setUserContextFilters(undefined);
          setUserContext(user_info);
          setRequestFilters(true);
          // removeUserSession();
          // TO DO : also get filters and sources you are erasing them 
          // setUserSession(user_info);
          setUserStats(undefined);
          history.push('/');
          // window.location =  window.BASE_API_URL;
        }
        else{
          //ERROR with code
          setErrorMsg('ERROR : Please try with another magic link');
          // setErrorMsg(res.msg);
          setErrorLogin(true);
        }
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'login-magic-user':false}});
        setIsLoading(true);
          setErrorMsg(error.msg);
          setErrorLogin(true);

          console.log(error);
      });
  };

  const requestMagicLoginExtraParam = (code,extraParamValue)=>{

    setRequestTrackerContext(prev => { return {...prev, 'login-magic-user':true}});
    setIsLoading(true);
    axios({
      method: 'POST',
      url:`${window.BASE_URL_USER}login-magic-user`,
      withCredentials: true,
      headers: {'Content-Type': 'application/json' },
      data: JSON.stringify({'magic-code': code }),
    })
      .then((res) => {
        setRequestTrackerContext(prev => { return {...prev, 'login-magic-user':false}});
        setIsLoading(false);
  
        if (res.data.success) {
          const user_info = res.data.user;
          if(setUserContextFilters){
            setUserContextFilters(undefined);
          }
          setUserContext(user_info);
          setRequestFilters(true);
          // removeUserSession();
          //TO DO  also get filters you are erasing them
          // setUserSession(user_info);
          setUserStats(undefined);
          if ((userOrganisationContext["extra_param"] && userOrganisationContext["extra_param_name"]  && userContext["extra_param"] 
          && Object.keys(userContext['extra_param']) && Object.keys(userContext['extra_param'])[0] 
          && Object.values(userContext['extra_param']) && Object.values(userContext['extra_param'])[0] 
          && (Object.keys(userContext['extra_param'])[0] !== userOrganisationContext["extra_param_name"] || Object.values(userContext['extra_param'])[0].trim() === "" ) )
          || // never had extra param 
          (userOrganisationContext['extra_param'] && userOrganisationContext['extra_param_name'] && userContext['extra_param'] === undefined) ){
            callExtraParams(extraParamValue);
          }
        }
        else{
          //ERROR with code
          setErrorMsg('ERROR : Please try with another magic link');
          // setErrorMsg(res.msg);
          setErrorLogin(true);
        }
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'login-magic-user':false}});
        setIsLoading(true);
          setErrorMsg(error.msg);
          setErrorLogin(true);

          console.log(error);
      });
  };

  const callExtraParams = (extraParamValue) => {
    setRequestTrackerContext(prev => { return {...prev, 'get-extra-param':true}});
    axios
    .get(`${window.BASE_URL_USER}get-extra-param`, {withCredentials: true})
    .then((res) => {
      setRequestTrackerContext(prev => { return {...prev, 'get-extra-param':false}});
      if (res.data) {
        
        let name = res.data['name'];
        // received data
        if (name && name !==''){
          // setExtraParams(res.data);
          let dataToSend = {} 
          dataToSend[name] =  extraParamValue.trim(); 
          let objectToSend  = {'extra_param': dataToSend};
          modifyExtraParam(objectToSend);
        }
      } else {
        console.log(
          'The request is successful, but for some reason res.data is not received: \n',
          res
        );
      }
    })
    .catch((error) => {
      setRequestTrackerContext(prev => { return {...prev, 'get-extra-param':false}});
      console.log(error);
    });
  };

  const modifyExtraParam = (objectToSend) => {
    setRequestTrackerContext(prev => { return {...prev, '/modify-user-fields':true}});
    axios({
      method: 'post',
      url:`${window.BASE_URL_USER}/modify-user-fields`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(objectToSend),
    })
      .then((res) => {
        setRequestTrackerContext(prev => { return {...prev, '/modify-user-fields':false}});
        updateUser(objectToSend); 
        history.push('/');
      })
      .catch((error) => {
        history.push('/');
        setRequestTrackerContext(prev => { return {...prev, '/modify-user-fields':false}});
        console.log('Error: ', error);
      })
  };

  const updateUser = (objectToSend) => {
    let newUser = {
      ...userContext,
      extra_param: objectToSend['extra_param']
    };
    setUserContext(newUser);
  }; 

  const request_check_magic_code= (code)=>{
    setRequestTrackerContext(prev => { return {...prev, 'check-magic-code':true}});
    setIsLoading(true);
    axios({
      method: 'POST',
      url:`${window.BASE_URL_USER}check-magic-code`,
      withCredentials: true,
      headers: {'Content-Type': 'application/json' },
      data: JSON.stringify({'magic-code': code }),
    })
      .then((res) => {
        setRequestTrackerContext(prev => { return {...prev, 'check-magic-code':false}});
        if(res.data.display){
          setAskToLogout(true);
        }
        else{
          setAskToLogout(false);
          history.push('/');
        }
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'check-magic-code':false}});
        setIsLoading(true);
        setErrorMsg(error.msg);
        setErrorLogin(true);
      });
  };

  const goHome = ()=>{
    window.location =  window.BASE_API_URL;
  };


  // todo add extra param
  const loginAsMagic = ()=>{
    request_magic_login(magicCode);
  };
  
  return (
    <>
      <Header withSearchBar={false} withAccountButtons={false} />
      <main>
        <Container>
          <Row className={"justify-content-center"} >
            <Col md={{ span: 10 }}  lg={{span:8}}>
            <Container>
            <FormWrapper>
              <LoginFormTitleWrapper>
                <H1 h1 semibold textoffblack>
                  Magic Link Login
                </H1>
              </LoginFormTitleWrapper>
              {errorLogin&&(
                <LoginFormTitleWrapper>
                  <Span p1 semibold  colorError>
                  {errorMsg} 
                  </Span>
                </LoginFormTitleWrapper>

              )}
              {askToLogout&&(
                <ConfirmLogin isMagic={true} organisation={organisation}  login={loginAsMagic} ></ConfirmLogin>
              )}
            </FormWrapper>
            </Container>
            </Col>
          </Row>
          {!askToLogout&&(
          <Row className={"justify-content-center"} >
            <Col >
              <SwitchFlow>
                <P h2 semibold textoffblack>
                  Don’t have an account?{' '}
                  <StyledLink textlink="true" to="/signup">
                    Sign up
                  </StyledLink>
                </P>
              </SwitchFlow>
            </Col>
          </Row>
          )}
        </Container>
      </main>
    </>
  );
};

export default LoginMagicPage;
