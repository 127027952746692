import styled from 'styled-components';

export const ToggleButtonStyles = styled.button`
  display: none;
  border: none;
  outline: 0;
  background-color: transparent;
  padding: 0;

  @media (max-width: 767.98px) {
    display: flex;
  }

  svg {
    color: ${({ theme }) => theme.palette.textOffblack};
    width: 24px;
    height: 24px;
  }
`;

export default ToggleButtonStyles;
