import React, {useContext, useEffect,useState,useMemo} from 'react';
import { Styles} from './styles';
import { AppContext } from '../../context';
import {H2,P } from '../theme/typography';
import { Container, Row, Col } from 'react-bootstrap';



const DifferentSearchNotification = (props) => {
  const {
    searchContext,  // need it for feedback
    replacedQuery,
  } = useContext(
    AppContext
  );
  // TODO replace with condition  fetchedResults['replaced_q']
  if( (replacedQuery) ){
    return (
      <Styles>
            <Row>
              <Col md={12} >
                <H2 h18 regular textoffblack >
                  {/* Showing results for <b> {searchContext.replaced_q} </b> */}
                  Showing results for<b> {replacedQuery} </b>
                </H2>
                <P p1 regular textoffblack>No results found for:  <b> {searchContext.q } </b></P>
                
              </Col>
            </Row>
      </Styles>
    );
  }
  else{
    return null;
  }
};

export default DifferentSearchNotification;
