import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Row, Col, Container } from 'react-bootstrap';

import Button from '../../../button';

import { H1, Label } from '../../../theme/typography';
// import mail_logo from '../../../assets/svgs/mail.svg';
// import { AppContext } from '../../../context';


import {
  FormFieldWrapper,
  TextInput,
  ErrorMessage,
} from '../../styles';



const SignUpStepTwo = ( props) => {
  const [orgCodeError, setOrgCodeError]= useState(undefined);
  const [location, setLocation] = useState("");
  
  const CODEMIN = 6 ; 
  const CODEORGMIN =4;
  const setLocationfromChild = (i)=>{
    setLocation(i);
  };

  //typeError('you must specify a number')
  var signupSchema2 = yup.object().shape({
    code: yup.string()
    .when('orgCode', {
        is: (orgCode) => !orgCode || orgCode.length < CODEORGMIN,
        then: yup.string()
        .required('Please enter your login code')
        // .matches(/^[0-9 ]+$/, "Must be only digits")
        .min(CODEMIN, 'Must be exactly 6 digits')
      }),

    orgCode: yup.string()
    
    .when('code', {
        is: (code) => !code || code.length < CODEMIN,
        then: yup.string()
        .required('Please enter your login code')
        .matches(/^[a-zA-Z0-9]+$/, "Must be only digits")
        .min(CODEORGMIN, 'Must be min 4 digits')
      })
    },['code','orgCode']);

   // This hook initiates react-hook-form lib.
   const {
    register:register2,
    getValues:getValues2,
    handleSubmit:handleSubmit2,
    errors:errors2
   } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(signupSchema2),
  });


  // Since sign up is a multipage form, we need to make sure that the first
  // page is validated before letting the user navigate the second page.
  const handleStepOneNavigation = () => {
    var value = getValues2([
    'code',
    ]);
    if (value['code']&&value['code'].length >=CODEMIN ){
      props.loginCallBack(value['code']) ;
    }
  };


  useEffect(()=>{
    let val = getValues2(['code',]);
  },);


  return (
    <Container>
    <form onSubmit={handleSubmit2(handleStepOneNavigation)} autoComplete={"off"} id='code_submitted'>
      {/* part 2 of form  */}
        {/* password row */}
        <Row>
            {/* <Col md={12}>
            <H1 regular textoffblack key={0} style={{padding:" 0px 0px 32px 0px", lineHeight:"1.5" ,fontSize:"15px" }}>
                We have sent an email with a  code to  <b>{props.email}</b>.
                Please enter the code below. If you haven't received it yet, please also <b>check your spam folder</b>.
            </H1>
            </Col> */}
            <Col md={12}>

            <FormFieldWrapper>
                <Label c0 semibold textoffblack>
                     Code
                </Label>
                <TextInput
                name="code"
                id="code_input"
                type={'text'}
                placeholder="Enter your code here"
                ref={register2}
                isError={ errors2.code && errors2.code.message || props.loginError }
                />
                {!props.loginError ?  (
                <ErrorMessage c1 regular colorError>
                    {errors2.code && errors2.code.message}
                </ErrorMessage>
                ):(<ErrorMessage c1 regular colorError>
                    {errors2.code? errors2.code.message:props.loginError}
                </ErrorMessage>
                ) }
            </FormFieldWrapper>
            </Col>
            
        </Row>
        <Button
        id='code_submitted'
        type= "submit"
        colorprimary
        buttonlarge
        text="Next"
        width="100%"
        />
    </form>
    </Container>

  );
};

export default SignUpStepTwo;
