
//Here we store varaibles that change between products 


//for main product
export const sources =['NICE CKS','NICE Guidelines','GP notebook','Patient.info','BNF','BNFC',
"Dermnet NZ","FSRH","Green Book","PCDS"];
export const max_number_sources = 20;

export const adapted_sources_ojct=  [
    {permanent:true, name: "NICE CKS"},
    {permanent:true, name: "NICE Guidelines"},
    {permanent:true, name: "GP notebook"},
    {permanent:true, name: "Patient.info"},
    {permanent:true, name: "BNF"},
    {permanent:true, name: "BNFC"},
    {permanent:true, name: "Dermnet NZ"},
    {permanent:true, name: "FSRH"},
    {permanent:true, name: "Green Book"},
    {permanent:true, name: "PCDS"}];
    
export const source_to_show = null; //leave null or do not initialized for other products
// export const sources_typewriter = ['NICE CKS','NICE Guidelines','GP notebook','Patient.info','BNF' ]; 
export const sources_typewriter = ['NICE CKS','NICE Guidelines','Local Pathways and more']; 
export const product_name = "main";
export const with_signup =true;
export const with_about_us =true;
    
export const with_logo_about_searchbar = true; 
export const with_trending = true;
export const with_social_media= true;
export const with_terms_privacy= true;
    
export const other_title_only = false; 
export const title ='';
export const is_single_source = false;
export const single_source=  ""; 
export const suggest_on = true;
export const send_logs = true;