import React, { useState, useEffect, useContext, useRef } from 'react';
import { Article, Styles } from './styles';
import { Container, Row, Col } from 'react-bootstrap';
import ChatResultCards from '../../result-cards/ChatResultCards';
import LoadingIndicatorChat from '../../loading-indicator/LoadingIndicatorChat';
import { AppContext } from '../../../context';
import { ReactComponent as ErrorImage } from '../../../assets/svgs/error-image.svg';
import { FaInfoCircle } from 'react-icons/fa';
import TypewriterAnimationChat from '../../typewriter/TypewriterAnimationChat';
import TypewriterAnimationChatSources from '../../typewriter/TypewriterAnimationChatSources';
import ModalChatTooltip from '../../modal-chat-tooltip';

function AnswerThread({ dialogData, machineResults, possibleAnswer, fetchComposeAnswer, fetchResults, tracker, index }) {
  const {
    fetchDialog,
    chatIndex,
    loadMore,
    setLoadMore,
    setQueueDone,
    setActiveChatIndex,
    setChangeWeb,
    waitForNextAnswer,
    queueDone
  } = useContext(AppContext);

  const [systemMessage, setSystemMessage] = useState(undefined);
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);

  var possible_answer = undefined;
  var machine_reply_answer = undefined;
  var machine_reply_results = undefined;

  if (possibleAnswer)
    possible_answer = possibleAnswer;
  
  if (machineResults)
    machine_reply_results = machineResults;

  if (dialogData) 
    machine_reply_answer = dialogData;

  const handleLoadMore = () => {
    setActiveChatIndex(index);
    setLoadMore(true);
    setQueueDone(false);
  };

  const handleSystemMessageChange = () => {
    setSystemMessage(true);
  };

  const deactivateTooltip1 = () => {
    setShowTooltip1(false);
  };

  const deactivateTooltip2 = () => {
    setShowTooltip2(false);
  };

  const messagesEnd1 = useRef(undefined);
  const messagesEnd2 = useRef(undefined);

  const scrollToBottom1 = () => {
    var answerThread = document.querySelector(".answer-thread" + index);
    return answerThread.scrollTop = answerThread.scrollHeight;
    // return messagesEnd1.current?.scrollIntoView({ behavior: 'smooth' })
  };
  
  const scrollToBottom2 = () => {
    return messagesEnd2.current?.scrollIntoView({ behavior: 'smooth' })
  };

  // TODO scroll to bottom of the thread
  // useEffect(() => {
  //   scrollToBottom1();
  // }, [dialogData]);

  // useEffect(() => {
  //   scrollToBottom2();
  // }, [machineResults]);

  const handleChangeWeb = () => {
    setChangeWeb(true);
  };

  useEffect(() => {
    if (systemMessage) {
      const answerThread = document.querySelector(".answer-thread" + index);
      const loadMoreBtns = answerThread.querySelectorAll(".load-more1");
      loadMoreBtns.forEach((loadMoreBtn) => {
        loadMoreBtn.addEventListener("click", handleLoadMore);
      });
    
      const changeWebBtns = answerThread.querySelectorAll(".web-change1");
      changeWebBtns.forEach((changeWebBtn) => {
        changeWebBtn.addEventListener("click", handleChangeWeb);
      });

      setSystemMessage(false);
    }
  }, [systemMessage]);

  return (
    <>          
      <Col md={10} className={'answer-thread' + index}>
        <Article>
          <p className='share-feedback'>Share feedback at <a className='share-feedback-email' href="mailto:hello@medwise.ai">hello@medwise.ai</a></p>
          {((fetchDialog === undefined || fetchDialog) && (fetchResults === undefined || fetchResults) && (index === chatIndex)) || (waitForNextAnswer && (index > chatIndex)) ? (
            <Row className='loading-container'> 
              <LoadingIndicatorChat />
            </Row>
          ) : ( // possible answer OR result answers return
            <>
            {fetchDialog === false && !possible_answer ? (
              <Row className='loading-container'> 
                <h1 className='answer-error'>Sorry, the server is currently busy. Please try again in a few minutes.</h1>
              </Row>
            ) : (
              <>
                <Row className='answer-container'> 
                  <Col md={4} className='pl-0 pt-0 answer-container-left'>
                    {machine_reply_answer.length != 0 && (machine_reply_answer[0].startsWith("Nothing was found. Consider changing sources") || machine_reply_answer[0].startsWith("An error has occurred. We are looking into it")) && (
                      <>
                        <div className='answer-title-div mb-2'>
                          <span className='answer1'>AI general answer - may be inaccurate</span>
                          <FaInfoCircle 
                            className="tooltip ml-2" 
                            title="This answer was generated using text snippets from unvetted web sources. It may be partial and/or inaccurate." 
                            onClick={(e)=>{setShowTooltip1(true)}}
                          />
                        </div>
                        <div className='answer mb-4'>
                          <TypewriterAnimationChat string={possible_answer}/>
                        </div>
                      </>
                    )}

                    {machine_reply_answer.length != 0 && (
                      <>
                        {machine_reply_answer.map((ans, i) => {
                          return (
                            <>
                              {ans.startsWith("Nothing was found. Consider changing sources") || ans.startsWith("An error has occurred. We are looking into it") ? (
                                <>
                                  <div className={"mb-2 " + (i === 0 ? '' : 'mt-4')}><span className='answer2'>System message</span></div>
                                  <div className='answer mb-1' onMouseEnter={handleSystemMessageChange}>
                                    <TypewriterAnimationChatSources string={ans} handleOnLoad={handleSystemMessageChange}/>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className={"answer-title-div1 mb-2 " + (i === 0 ? '' : 'mt-4')}>
                                    <span className='answer2'>AI summary answer - may be inaccurate</span>
                                    <FaInfoCircle 
                                      className="tooltip ml-2" 
                                      title="This answer was generated using text snippets from the last results of the search. It may be partial and/or inaccurate." 
                                      onClick={(e)=>{setShowTooltip2(true)}}
                                    />
                                    </div>
                                  <div className='answer mb-1'>
                                    <TypewriterAnimationChat string={ans}/>
                                  </div>
                                </>
                              )}
                            </>
                          )
                        })}
                      </>
                    )}
                    
                    {fetchComposeAnswer === undefined || fetchComposeAnswer && (
                      <>
                        {machine_reply_answer.length === 0 ? (
                          <div className='loading-container1'>
                            <LoadingIndicatorChat />
                          </div>
                        ) : (
                          <div className='loading-container2' style={{marginTop: '3rem'}}>
                            <LoadingIndicatorChat />
                          </div>
                        )}
                      </>
                    )}

                    <div ref={messagesEnd1} />
                  </Col>
                  <Col md={8} className='answer-container-right'>
                    
                    <div className='results-container'>
                      {(fetchResults === undefined || fetchResults) && (machine_reply_results && (machine_reply_results.length === 0)) ? (
                        <div className='loading-container1'> 
                          <LoadingIndicatorChat />
                        </div>
                      ) : (
                        <>
                          {machine_reply_results && (machine_reply_results.length === 0) ? (
                            <div className='error-image'>
                              <ErrorImage />
                            </div>
                          ) : (
                            <>
                              <ChatResultCards fetchedResults={machine_reply_results} isSearchResult={false} isOnSearchPage={false} />
                              {machine_reply_results && machine_reply_results.length > 0 && fetchResults && (
                                <div className='loading-container2' style={{marginTop: '3rem'}}>
                                  <LoadingIndicatorChat />
                                </div>
                              )}
                            </>
                          )}

                          {fetchComposeAnswer === false && tracker < 100 && !loadMore && queueDone && (
                            <div className='load-more-container mt-3'>
                              <span className='load-more' onClick={handleLoadMore}>Load more</span>
                            </div>
                          )}
                        </>
                      )}

                      <div ref={messagesEnd2} />
                    </div>
                  </Col>
                </Row>
              </>
            )}
            </>
          )}
        </Article>
      </Col>
      <Col md={2} className=''>
      </Col>

      <ModalChatTooltip
        modalFeedbackShow={showTooltip1===true}
        modalFeedbackClose={deactivateTooltip1}
        bodyText={"This answer was generated using text snippets from unvetted web sources. It may be partial and/or inaccurate."}
        title="AI general answer - may be inaccurate" 
      /> 

      <ModalChatTooltip
        modalFeedbackShow={showTooltip2===true}
        modalFeedbackClose={deactivateTooltip2}
        bodyText={"This answer was generated using text snippets from the last results of the search. It may be partial and/or inaccurate."}
        title="AI summary answer - may be inaccurate" 
      /> 
    </>
  )
}

export default AnswerThread