import React from 'react';


const LoadingPage = ({ history, location }) => {

  return (
    <>
        <p>Loading...</p> 
    </>
  );
};

export default LoadingPage;
