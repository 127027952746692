import React, { useState , useEffect, useContext} from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { default as Btn1 } from '../button';
import { Col, Container, Row, TabContent } from 'react-bootstrap';
import axios from 'axios';
import { AppContext } from '../../context';
import { FaCog } from 'react-icons/fa';
import { removeUnecessaryPTags } from '../../functions/utils';
import star from '../../assets/svgs/star.svg';
import faRegStar from '../../assets/svgs/faRegStar.svg';
import {ErrorMessage, TextInput} from './styles';
import ModalNotLoggedIn from '../../components/modal-not-logged-in'


// TOP DO : REPLACE search props by searchOCntext or replace searchCOntest with search 
const CpdNotes = ({bookmarks, parentExpanded, noteExpanded, index, result, search, expandParent, isLoggedin, updateBookmarks, setUpdateBookmarks, isFavourite, updateFavourites, favouriteID, isRemove}) => {
  const [addNote, setAddNote] = useState(false); 
  const [showAllNotes, setShowAllNotes] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [reflection, setReflection] =  useState("");
  const [learning, setLearning] =  useState("");
  const [duration, setDuration] =  useState(0.5);
  const [credit, setCredit] =  useState(0.25);
  const [url, setURL] =  useState(result.online_view_url);

  const [editTitle, setEditTitle] =  useState("");
  const [editDate, setEditDate] =  useState("");
  const [editDuration, setEditDuration] =  useState("");
  const [editNote, setEditNote] =  useState("");
  const [editCredit, setEditCredit] =  useState("");
  const [editURL, setEditURL] =  useState("");
  const [editLearning, setEditLearning] =  useState("");
  const [editReflection, setEditReflection] =  useState("");

  const [titleConfig, setTitleConfig] = useState("title");
  const [dateConfig, setDateConfig] = useState("today");
  const [durationConfig, setDurationConfig] = useState(0.5);
  const [queryCheckboxConfig, setQueryCheckboxConfig] = useState(true);
  const [titleCheckboxConfig, setTitleCheckboxConfig] = useState(false);
  const [snippetCheckboxConfig, setSnippetCheckboxConfig] = useState(true);
  const [creditConfig, setCreditConfig] = useState(0.25);
  const [urlConfig, setURLConfig] = useState("url");
  const [errorAddNote, setErrorAddNote] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorConfig, setErrorConfig] = useState("");
  const [showNotLoggedInModalFlag, setShowNotLoggedInModalFlag] = useState(false);
  const [favourite, setFavourite] = useState(isFavourite);

  const { 
    setRequestTrackerContext, 
    searchContext,
    userContext, setUserContext,
  } = useContext(
    AppContext
  );

  const getText = (html) => {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  };

  // const [title, setTitle] =  useState(getText(removeUnecessaryPTags(result.chunk_title)));
  const [title, setTitle] =  useState(undefined);
  const [note, setNote] =  useState(undefined);
  // useState("Seach query: " + searchContext.q + "\n" + "Answer snippet: " + getText(removeUnecessaryPTags(result.text)));

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yy = today.getFullYear().toString().slice(-2);
  today = dd + '/' + mm + '/' + yy;

  const [date, setDate] =  useState(today);
  
  const TIMEOUT= 3000;
  
  // ADD CPD ENTRY
  const handleClose = () => setAddNote(false);
  const handleShow = () => {
    if (isLoggedin) {
      setAddNote(true);
    } else
      setShowNotLoggedInModalFlag(index);
  }; 

  // EDIT CPD ENTRY
  const handleEditClose = () => setShowAllNotes(false);
  const handleEditShow = () => {
    if (isLoggedin) {
      setShowAllNotes(true);
      let parser =  new DOMParser();
      let titleVal = parser.parseFromString(bookmarks[0]["title"],'text/html').body.textContent;
      let privateNoteVal = parser.parseFromString(bookmarks[0]["private_note"],'text/html').body.textContent;
      let urlVal = parser.parseFromString(bookmarks[0]["url"],'text/html').body.textContent;
      let learningVal = parser.parseFromString(bookmarks[0]["learnings_note"],'text/html').body.textContent;
      let reflectionVal = parser.parseFromString(bookmarks[0]["reflections_note"],'text/html').body.textContent;
      setEditTitle(titleVal);
      setEditDate(bookmarks[0]["date_of_learning"] !== "null" ? bookmarks[0]["date_of_learning"] : "");
      setEditDuration(bookmarks[0]["duration"] !== "null" ? bookmarks[0]["duration"] : "");
      setEditNote(privateNoteVal !== "null" ? privateNoteVal : "");
      setEditCredit(bookmarks[0]["credits"] !== "null" ? bookmarks[0]["credits"] : "");
      setEditURL(urlVal !== "null" ? urlVal : "");
      setEditLearning(learningVal !== "null" ? learningVal : "");
      setEditReflection(reflectionVal !== "null" ? reflectionVal : "");
    } else {
      setShowNotLoggedInModalFlag(index);
    };
  };

  // CONFIGURATION
  const handleShowSetting = () => setShowConfig(true);
  const handleCloseSetting = () => setShowConfig(false);

  const handleLearningChange = (e) => {
    if (addNote)
      setLearning(e.target.value);
    if (showAllNotes)
      setEditLearning(e.target.value);
  };

  const handleReflectionChange = (e) => {
    if (addNote)
      setReflection(e.target.value);
    if (showAllNotes)
      setEditReflection(e.target.value);
  };

  const handleTitleChange = (e) => {
    if (addNote){
      setTitle(e.target.value);
    }
    if (showAllNotes)
      setEditTitle(e.target.value);
  };

  const handleDurationChange = (e) => {
    setErrorMsg('');
    setErrorAddNote(false);
    if (addNote)
      setDuration(e.target.value);
    if (showAllNotes)
      setEditDuration(e.target.value);
  };

  const handleNoteChange = (e) => {
    if (addNote)
      setNote(e.target.value);
    if (showAllNotes)
      setEditNote(e.target.value);
  };

  const handleDateChange = (e) => {
    setErrorMsg('');
    setErrorAddNote(false);
    if (addNote)
      setDate(e.target.value);
    if (showAllNotes)
      setEditDate(e.target.value);
  };

  const handleCreditChange = (e) => {
    setErrorAddNote(false);
    setErrorMsg('');

    if (addNote)
      setCredit(e.target.value);
    if (showAllNotes)
      setEditCredit(e.target.value);
  };

  const handleURLChange = (e) => {
    if (addNote)
      setURL(e.target.value);
    if (showAllNotes)
      setEditURL(e.target.value);
  };

  const handleTitleConfig = (e) => {
    setTitleConfig(e.target.value);
  };

  const handleDateConfig = (e) => {
    setDateConfig(e.target.value);
  };

  const handleDurationConfig = (e) => {
    setDurationConfig(e.target.value);
  };

  const handleQueryCheckboxConfig = (e) => {
    setQueryCheckboxConfig(!queryCheckboxConfig);
  };

  const handleTitleCheckboxConfig = (e) => {
    setTitleCheckboxConfig(!titleCheckboxConfig);
  };

  const handleSnippetCheckboxConfig = (e) => {
    setSnippetCheckboxConfig(!snippetCheckboxConfig);
  };

  const handleCreditConfig = (e) => {
    setCreditConfig(e.target.value);
  };

  const handleURLConfig = (e) => {
    setURLConfig(e.target.value);
  };

  const toggleBookmark = (e) => {
    e.stopPropagation();
    if (isLoggedin) {
      setFavourite(!favourite);
    };
    if (isFavourite === true) {
      if (isLoggedin) {
        if (title !== "" && search && search.q) {
          setRequestTrackerContext(prev => { return {...prev, 'delete-favourite':true}});
          axios({
            method: 'post',
            url: `${window.BASE_URL_USER}delete-favourite`,
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              id: favouriteID,
            }),
          })
          .then((res) => {
            updateFavourites(index, -1); 
            setRequestTrackerContext(prev => { return {...prev, 'delete-favourite':false}});
          })
          .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'delete-favourite':false}});
            console.log('Error: ', error);
          });
        }
      }
    } else {
      if (isLoggedin) {
        if (title !== "" && search && search.q) {
          setRequestTrackerContext(prev => { return {...prev, 'add-favourite':true}});
          axios({
            method: 'post',
            url: `${window.BASE_URL_USER}add-favourite`,
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              result: result,
              query: search.q,
            }),
          })
          .then((res) => {
            setRequestTrackerContext(prev => { return {...prev, 'add-favourite':false}});
            updateFavourites(index, res.data.favourite.id); 
          })
          .catch((error) => {
            // setEnableSave(true);
            setRequestTrackerContext(prev => { return {...prev, 'add-favourite':false}});
            console.log('Error: ', error);
          });
        };
      } else {
        setShowNotLoggedInModalFlag(index);
      }
    };
  };

  useEffect(() => {
    if ((bookmarks && bookmarks.length > 0) || isFavourite) {
      expandParent(result, index);
    };
  }, []);

  useEffect(() => {
    if ( (typeof parentExpanded === "string" &&  parentExpanded !== "expanded") || (typeof parentExpanded === "boolean" && !parentExpanded) ) {
      setAddNote(false);
      if (!noteExpanded) {
        setShowAllNotes(false);
      };
    };
    if (noteExpanded) {
      setShowAllNotes(false);
    };
  }, [parentExpanded]);


  useEffect(() => {
    if (userContext.hasOwnProperty('cpd-settings')) {
      var cpd_settings = userContext["cpd-settings"];
      if (cpd_settings.hasOwnProperty('config-title')) {
        setTitleConfig(cpd_settings["config-title"]);
        if (cpd_settings["config-title"] === "blank")
          setTitle("");
        if (cpd_settings["config-title"] === "query"){
          setTitle(search.q);
        }
      };
      
      if (cpd_settings.hasOwnProperty('config-date')) {
        setDateConfig(cpd_settings["config-date"]);
        if (cpd_settings["config-date"] === "blank")
          setDate("");
      };

      if (cpd_settings.hasOwnProperty('config-duration')) {
        setDurationConfig(cpd_settings["config-duration"]);
        setDuration(cpd_settings["config-duration"]);
      };

      if (cpd_settings.hasOwnProperty('config-query-checkbox'))
        setQueryCheckboxConfig(cpd_settings["config-query-checkbox"]);
      
      if (cpd_settings.hasOwnProperty('config-title-checkbox'))
        setTitleCheckboxConfig(cpd_settings["config-title-checkbox"]);

      if (cpd_settings.hasOwnProperty('config-snippet-checkbox'))
        setSnippetCheckboxConfig(cpd_settings["config-snippet-checkbox"]);

      if ((cpd_settings["config-query-checkbox"] === false) && (cpd_settings["config-title-checkbox"] === true) && (cpd_settings["config-snippet-checkbox"] === false)) {
        setNote("Answer title: " + getText(removeUnecessaryPTags(result.chunk_title)));
      } else if ((cpd_settings["config-query-checkbox"] === true) && (cpd_settings["config-title-checkbox"] === false) && (cpd_settings["config-snippet-checkbox"] === false)) {
        setNote("Search query: " + searchContext.q);
      } else if ((cpd_settings["config-query-checkbox"] === false) && (cpd_settings["config-title-checkbox"] === false) && (cpd_settings["config-snippet-checkbox"] === true)) {
        setNote("Answer snippet: " + getText(removeUnecessaryPTags(result.text)));
      } else if ((cpd_settings["config-query-checkbox"] === true) && (cpd_settings["config-title-checkbox"] === true) && (cpd_settings["config-snippet-checkbox"] === false)) {
        setNote("Search query: " + searchContext.q + "\n" + "Answer title: " + getText(removeUnecessaryPTags(result.chunk_title)));
      } else if ((cpd_settings["config-query-checkbox"] === false) && (cpd_settings["config-title-checkbox"] === true) && (cpd_settings["config-snippet-checkbox"] === true)) {
        setNote("Answer title: " + getText(removeUnecessaryPTags(result.chunk_title)) + "\n" + "Answer snippet: " + getText(removeUnecessaryPTags(result.text)));
      } else if ((cpd_settings["config-query-checkbox"] === true) && (cpd_settings["config-title-checkbox"] === true) && (cpd_settings["config-snippet-checkbox"] === true)) {
        setNote("Search query: " + searchContext.q + "\n" + "Answer title: " + getText(removeUnecessaryPTags(result.chunk_title)) + "\n" + "Answer snippet: " + getText(removeUnecessaryPTags(result.text)));
      } else if ((cpd_settings["config-query-checkbox"] === false) && (cpd_settings["config-title-checkbox"] === false) && (cpd_settings["config-snippet-checkbox"] === false)) {
        setNote("");
      };

      if (cpd_settings.hasOwnProperty('config-credits')) {
        setCreditConfig(cpd_settings["config-credits"]);
        setCredit(cpd_settings["config-credits"]);
      };

      if (cpd_settings.hasOwnProperty('config-url')) {
        setURLConfig(cpd_settings["config-url"]);
        if (cpd_settings["config-url"] === "blank")
          setURL("");
      };
    };
  }, [userContext]);

  
  // we need to force an update to hooks given new results
  // new result may not force a re-render of CPDEnties 
  // so we need to update hook title, note,url
   useEffect(() => {
     let hasCpdSettings = false;
     let cpdSettings ; 
     if (userContext && userContext.hasOwnProperty('cpd-settings') ){
       hasCpdSettings = true;
       cpdSettings =userContext['cpd-settings'] ;
     }

    // set or update note
    // if (typeof note === "undefined") {
      setNote( "Search query: " + searchContext.q 
      + "\n" + "Answer snippet: " + getText(removeUnecessaryPTags(result.text)));
      if ( hasCpdSettings) {
        // config-tittle
        if (( hasCpdSettings && cpdSettings["config-query-checkbox"] === false) && (cpdSettings["config-title-checkbox"] === true) 
              && (cpdSettings["config-snippet-checkbox"] === false)) {
          setNote("Answer title: " + getText(removeUnecessaryPTags(result.chunk_title)));
        } else if ((cpdSettings["config-query-checkbox"] === true) && (cpdSettings["config-title-checkbox"] === false) && (cpdSettings["config-snippet-checkbox"] === false)) {
          setNote("Search query: " + searchContext.q);
        } else if ((cpdSettings["config-query-checkbox"] === false) && (cpdSettings["config-title-checkbox"] === false) && (cpdSettings["config-snippet-checkbox"] === true)) {
          setNote("Answer snippet: " + getText(removeUnecessaryPTags(result.text)));
        } else if ((cpdSettings["config-query-checkbox"] === true) && (cpdSettings["config-title-checkbox"] === true) && (cpdSettings["config-snippet-checkbox"] === false)) {
          setNote("Search query: " + searchContext.q + "\n" + "Answer title: " + getText(removeUnecessaryPTags(result.chunk_title)));
        } else if ((cpdSettings["config-query-checkbox"] === false) && (cpdSettings["config-title-checkbox"] === true) && (cpdSettings["config-snippet-checkbox"] === true)) {
          setNote("Answer title: " + getText(removeUnecessaryPTags(result.chunk_title)) + "\n" + "Answer snippet: " + getText(removeUnecessaryPTags(result.text)));
        } else if ((cpdSettings["config-query-checkbox"] === true) && (cpdSettings["config-title-checkbox"] === true) && (cpdSettings["config-snippet-checkbox"] === true)) {
          setNote("Search query: " + searchContext.q + "\n" + "Answer title: " + getText(removeUnecessaryPTags(result.chunk_title)) + "\n" + "Answer snippet: " + getText(removeUnecessaryPTags(result.text)));
        } else if ((cpdSettings["config-query-checkbox"] === false) && (cpdSettings["config-title-checkbox"] === false) && (cpdSettings["config-snippet-checkbox"] === false)) {
          setNote("");
        };
      }
    // }
    
    // set or update  title 
    // if (typeof title === "undefined") {
      setTitle( getText(removeUnecessaryPTags(result.chunk_title)));
      if ( hasCpdSettings && cpdSettings.hasOwnProperty('config-title') ) {
        // config-tittle
        if (cpdSettings["config-title"] === "blank"){
            setTitle("");
          }
        if (cpdSettings["config-title"] === "query"){
          setTitle(search.q);
          }
      }
    // }

    // URL
    setURL( result.online_view_url ); // assuming result can not be change while modal is open
    if ( hasCpdSettings && cpdSettings.hasOwnProperty('config-url')) {
      if (cpdSettings.hasOwnProperty('config-url') === "blank"){
        setURL("");
      }
    };

    // DATE 
    setDate(today);
    if ( hasCpdSettings && cpdSettings.hasOwnProperty('config-date')) {
      if (cpdSettings["config-date"] === "blank"){
        setDate("");
      }
    };

    // DURATION
    setDuration(0.5);
    if (hasCpdSettings && cpdSettings.hasOwnProperty('config-duration')) {
      setDuration(cpdSettings["config-duration"]);
    };

    //CREDIT
    setCredit(0.25);
    if (hasCpdSettings && cpdSettings.hasOwnProperty('config-credits')) {
      setCredit(cpdSettings["config-credits"]);
    };

    //REFLECTION
    setReflection("");
    
    // LEARNING
    setLearning("");

  }, [result,searchContext]); // result and searchContext should not change while modal is on


  const handleSave = () => {
    if (isLoggedin) {
      if (title !== "" && search && search.q) {
        setRequestTrackerContext(prev => { return {...prev, 'add-cpd-entry':true}});
        axios({
          method: 'post',
          url: `${window.BASE_URL_USER}add-cpd-entry`,
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            title: title,
            duration: duration,
            reflections_note: reflection,
            learnings_note: learning,
            private_note: note,
            date_of_learning: date,
            credits: credit,
            url: url,
            q: search.q,
            online_view_url: result.online_view_url,
          }),
        })
        .then((res) => {
          setRequestTrackerContext(prev => { return {...prev, 'add-cpd-entry':false}});
          if(res.data.code === 400 ) {
            setErrorAddNote(true);
            setErrorMsg("");
            if (res.data.description){
              setErrorMsg(res.data.description);
            }
          }
          else{
            setErrorAddNote(false);
            setUpdateBookmarks(!updateBookmarks); 
            // setTimeout(() => {
            //   setDisplaySave(false);
            // }, TIMEOUT);
            setAddNote(false);
            // setShowAllNotes(true);
          }
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'add-cpd-entry':false}});
          if(error.response.data.code === 400){
            setErrorAddNote(true);
            setErrorMsg("");
            if (error.response.data.description){
              setErrorMsg(error.response.data.description);
            }
          }
          else{
          // setEnableSave(true);
          setErrorAddNote(true);
          setErrorMsg("");
          console.log('Error: ', error);
          }
        });
      }
    }
  };

  const handleEditSave = () => {
    if (isLoggedin) {
      let id = bookmarks[0]["id"];
      if (title !== "" && search && search.q) {
        setRequestTrackerContext(prev => { return {...prev, 'edit-cpd-entry':true}});
        axios({
          method: 'post',
          url: `${window.BASE_URL_USER}edit-cpd-entry`,
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            id: id,
            title: editTitle,
            duration: editDuration,
            reflections_note: editReflection,
            learnings_note: editLearning,
            private_note: editNote,
            date_of_learning: editDate,
            credits: editCredit,
            url: editURL,
          }),
        })
        .then((res) => {
          if(res.data.code === 400){
            setErrorAddNote(true);
            setErrorMsg("");
            if (res.data.description){
              setErrorMsg(res.data.description);
            }
            
          }
          else{
            setErrorAddNote(false);
            setUpdateBookmarks(!updateBookmarks); 
            // setTimeout(() => {
            //   setDisplaySave(false);
            // }, TIMEOUT);
            // setAddNote(false);
            setShowAllNotes(false);
          setRequestTrackerContext(prev => { return {...prev, 'edit-cpd-entry':false}});
          }
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'edit-cpd-entry':false}});
          if( error.response.data.code === 400 ){
            setErrorAddNote(true);
            setErrorMsg("");
            if (error.response.data.description){
              setErrorMsg(error.response.data.description);
            }
          }
          else{
            setErrorAddNote(true);
            setErrorMsg("");
          }         
        });
      }
    }
  };


  const handleSaveSetting = () => {
    if (isLoggedin) {
      if (search && search.q) {
        //check credit is a num
        //check duration is a num 
        setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':true}});
        axios({
          method: 'post',
          url: `${window.BASE_URL_USER}modify-user-fields`,
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            "cpd-settings": {
              "config-title": titleConfig,
              "config-date": dateConfig,
              "config-duration": durationConfig,
              "config-query-checkbox": queryCheckboxConfig,
              "config-title-checkbox": titleCheckboxConfig,
              "config-snippet-checkbox": snippetCheckboxConfig,
              "config-credits": creditConfig,
              "config-url": urlConfig},
          }),
        })
        .then((res) => {
          // setTimeout(() => {
          //   setDisplaySave(false);
          // }, TIMEOUT);
          setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':false}});
          setShowConfig(false);

          if (titleConfig === "blank"){
            setTitle(""); // userContext
          }
          if (titleConfig === "query"){
            setTitle(search.q);
          }
          if (titleConfig === "title"){
            setTitle(getText(removeUnecessaryPTags(result.chunk_title)));
          }
          if (dateConfig === "today"){
            setDate(today);
          }
          if (dateConfig === "blank"){
            setDate("");
          }
          setDuration(durationConfig);
          setCredit(creditConfig);

          if (urlConfig === "url"){
            setURL(result.online_view_url);}
          if (urlConfig === "blank"){
            setURL("");}

          if ((queryCheckboxConfig === false) && (titleCheckboxConfig === true) && (snippetCheckboxConfig === false)) {
            setNote("Answer title: " + getText(removeUnecessaryPTags(result.chunk_title)));
          } else if ((queryCheckboxConfig === true) && (titleCheckboxConfig === false) && (snippetCheckboxConfig === false)) {
            setNote("Search query: " + searchContext.q);
          } else if ((queryCheckboxConfig === false) && (titleCheckboxConfig === false) && (snippetCheckboxConfig === true)) {
            setNote("Answer snippet: " + getText(removeUnecessaryPTags(result.text)));
          } else if ((queryCheckboxConfig === true) && (titleCheckboxConfig === true) && (snippetCheckboxConfig === false)) {
            setNote("Search query: " + searchContext.q + "\n" + "Answer title: " + getText(removeUnecessaryPTags(result.chunk_title)));
          } else if ((queryCheckboxConfig === false) && (titleCheckboxConfig === true) && (snippetCheckboxConfig === true)) {
            setNote("Answer title: " + getText(removeUnecessaryPTags(result.chunk_title)) + "\n" + "Answer snippet: " + getText(removeUnecessaryPTags(result.text)));
          } else if ((queryCheckboxConfig === true) && (titleCheckboxConfig === true) && (snippetCheckboxConfig === true)) {
            setNote("Search query: " + searchContext.q + "\n" + "Answer title: " + getText(removeUnecessaryPTags(result.chunk_title)) + "\n" + "Answer snippet: " + getText(removeUnecessaryPTags(result.text)));
          } else if ((queryCheckboxConfig === false) && (titleCheckboxConfig === false) && (snippetCheckboxConfig === false)) {
            setNote("");
          } else if ((queryCheckboxConfig === true) && (titleCheckboxConfig === false) && (snippetCheckboxConfig === true)) {
            setNote("Search query: " + searchContext.q + "\n" + "Answer snippet: " + getText(removeUnecessaryPTags(result.text)));
          };

          if (res.data.user) {
            setUserContext(res.data.user);
          };
          if(res.data.code){
            setErrorConfig('Error');
          }
          else{
            setErrorConfig('');
          }
        })
        .catch((error) => {
          // setEnableSave(true);
          setErrorConfig('Error');
          setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':false}});
          console.log('Error: ', error);
        });
      }
    }
  };

  const goToBookmarkNote = () => {
    window.location.href = window.BASE_URL_DASHBOARD+ "dashboard/cpd_entries.html";
  };
  
  return (
    <Container style={{ paddingLeft:"0", paddingTop: "16px" }} className='notesWrapper'>
      { bookmarks && (bookmarks.length>0) &&(
        <Row> 
          {/* Has CPD entry */}
          { bookmarks && (bookmarks.length>0) && !showAllNotes &&(
            <Col>
              <Btn1
              type="action"
              colorlightpurple
              buttonsmall
              text="Edit CPD entry"
              onClick={handleEditShow}
              />
              {!favourite && (
                <img src={faRegStar} className="ml-3" style={{cursor: "pointer", verticalAlign: "text-bottom", width: "20px", height: "18px"}} onClick={toggleBookmark} />
                )} 
              {favourite && (
                <img src={star} className="ml-3" style={{cursor: "pointer", verticalAlign: "text-bottom", width: "20px", height: "18px"}} onClick={toggleBookmark} />
              )}  
            </Col>
          )}
        </Row>
      )}

      {/* Expanded and no CPD entry */}
      { (!bookmarks || (bookmarks && bookmarks.length===0)) && (parentExpanded === true || parentExpanded==="expanded" ) && (!addNote )&&(
        <Container>
          <Row>
            <Col className='p-0'>
              <Btn1
                  type="action"
                  colorgrey
                  textoffblack
                  buttonsmall
                  text="Add CPD entry"
                  disabled={bookmarks&&bookmarks.length>6}
                  onClick={handleShow}
                  className="add-cpd-entry-btn"
                />
              {!favourite && (
                <img src={faRegStar} className="ml-3" style={{cursor: "pointer", verticalAlign: "text-bottom", width: "20px", height: "18px"}} onClick={toggleBookmark} />
                )}
              {favourite && (
                <img src={star} className="ml-3" style={{cursor: "pointer", verticalAlign: "text-bottom", width: "20px", height: "18px"}} onClick={toggleBookmark} />
              )}  
            </Col>
          </Row>
        </Container>
      )}
    
      {/* ADD CPD ENTRY MODAL */}
      {bookmarks && addNote && (parentExpanded === true || parentExpanded === "expanded")  && (
        <>
          <Modal show={addNote} onHide={handleClose}>
            <Modal.Header className="px-4">
              <div className="container">
                <div className="row">
                  <Modal.Title className="py-2 m-header">Add CPD entry</Modal.Title>
                  <FaCog className="ml-3 cpd-setting" onClick={handleShowSetting} />
                  <Button className="close align-middle" onClick={handleClose}>&times;</Button>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body className="p-4">
              <Form>
                <Form.Group>
                  <Form.Label htmlFor="title" className="add-cpd-form">TITLE</Form.Label>
                  <Form.Control 
                    className="form-control title add-cpd-input" 
                    type="text" 
                    value={title} 
                    onChange={handleTitleChange}
                  />
                </Form.Group>
                <Form.Group >
                  <Form.Label htmlFor="date" className="add-cpd-form">DATE OF LEARNING</Form.Label>
                  <TextInput
                  style={{ display: 'block', width:'100%' }}
                   type="text"
                   name="DATE OF LEARNING"
                   id ="DATE_OF_LEARNING_input"
                   placeholder={dateConfig==="blank" && "DD/MM/YYYY"}
                   value={dateConfig ==='today'&& date}
                   isError={errorMsg.includes('DD/MM/YY')}
                   onChange = {handleDateChange}
                  ></TextInput>
                  {errorMsg && errorMsg.includes('DD/MM/YY') &&(
                    <ErrorMessage  p1 regular colorError>
                      {errorMsg}
                    </ErrorMessage>)
                  }
                </Form.Group>
                <Form.Group className="cpd-form">
                  <Form.Label htmlFor="duration" className="add-cpd-form">DURATION (IN HOURS)</Form.Label>
                  <TextInput
                  style={{ display: 'block' , width:'100%'}}
                   type="text"
                   name="DURATION"
                   id ="DURATION_input"
                   isError={errorMsg.includes('hours')}
                   onChange = {handleDurationChange}
                   value={duration}
                  ></TextInput>
                  { errorMsg &&errorMsg.includes('hours') &&(
                    <ErrorMessage  p1 regular colorError>
                      {errorMsg}
                    </ErrorMessage>)
                  }

                  {/* <div className="invalid-feedback">Please enter a number</div> */}
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="private" className="add-cpd-form">PRIVATE NOTES</Form.Label>
                  <textarea 
                    className="form-control private add-cpd-input" 
                    rows="6" 
                    type="text" 
                    value={note}
                    onChange={handleNoteChange}
                  >
                  </textarea>
                </Form.Group>
                <Form.Group className="cpd-form">
                  <Form.Label htmlFor="credits" className="add-cpd-form">CREDITS</Form.Label>
                  <TextInput
                   style={{ display: 'block' , width:'100%'}}
                   type="text"
                   name="CREDITS"
                   id ="CREDITS_input"
                   isError={ errorMsg && !errorMsg.includes('hours') &&!errorMsg.includes('DD/MM/YY') }
                   onChange = {handleCreditChange}
                   value={credit}
                  ></TextInput>
                  {errorMsg && !errorMsg.includes('hours') &&!errorMsg.includes('DD/MM/YY') &&(
                    <ErrorMessage  p1 regular colorError>
                      {errorMsg}
                    </ErrorMessage>)
                  }
                </Form.Group>
                <Form.Group className="cpd-form">
                  <Form.Label htmlFor="url" className="add-cpd-form">URL</Form.Label>
                  <Form.Control 
                    className="form-control url add-cpd-input" 
                    type="text" 
                    value={url}
                    onChange={handleURLChange} />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="learnings" className="add-cpd-form">LEARNING POINTS</Form.Label>
                  <textarea 
                    className="form-control learnings add-cpd-input" 
                    type="text" 
                    value={learning} 
                    onChange={handleLearningChange} 
                  ></textarea>
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="reflections" className="add-cpd-form">REFLECTION</Form.Label>
                  <textarea 
                    className="form-control reflections add-cpd-input" 
                    type="text" 
                    value={reflection}
                    onChange={handleReflectionChange} 
                  ></textarea>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between align-items-center p-4">
            {errorAddNote &&(
               <ErrorMessage  p1 regular colorError>
                Error!
              </ErrorMessage>
              )}
              <Button className="btn btn-cancel btn-lg px-4" onClick={handleClose}>
                Cancel
              </Button>

              <Btn1
                  type="action"
                  colorprimary
                  textoffblack
                  buttonmedium
                  text="Save"
                  onClick={handleSave}
                  padding={"8px 24px"}
                  className="add-cpd-entry-btn"
                />
            </Modal.Footer>
          </Modal>
        </>
      )}

      {/* EDIT CPD ENTRY MODAL */}
      {(parentExpanded === true ||  parentExpanded === "expanded")  && bookmarks && showAllNotes &&(
        <Modal show={showAllNotes} onHide={handleEditClose}>
          <Modal.Header className="px-4">
            <div className="container">
              <div className="row">
                <Modal.Title className="py-2 m-header">Edit CPD entry</Modal.Title>
                <FaCog className="ml-3 cpd-setting" onClick={handleShowSetting} />
                <Button className="close align-middle" onClick={handleEditClose}>&times;</Button>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form>
              <Form.Group>
                <Form.Label htmlFor="title" className="add-cpd-form">TITLE</Form.Label>
                <Form.Control 
                  className="form-control title add-cpd-input" 
                  type="text" 
                  value={editTitle} 
                  onChange={handleTitleChange}
                />
              </Form.Group>
              <Form.Group >
                <Form.Label htmlFor="date" className="add-cpd-form">DATE OF LEARNING</Form.Label>
                <TextInput
                  style={{ display: 'block', width:'100%' }}
                   type="text"
                   name="EDIT_DATE OF LEARNING"
                   id ="EDIT_DATE_OF_LEARNING_input"
                   value={editDate}
                   isError={errorMsg.includes('DD/MM/YY')}
                   onChange = {handleDateChange}
                  ></TextInput>
                  {errorMsg && errorMsg.includes('DD/MM/YY') &&(
                    <ErrorMessage  p1 regular colorError>
                      {errorMsg}
                    </ErrorMessage>)
                  }
              </Form.Group>
              <Form.Group className="cpd-form">
                <Form.Label htmlFor="duration" className="add-cpd-form">DURATION (IN HOURS)</Form.Label>
                <TextInput
                  style={{ display: 'block' , width:'100%'}}
                   type="text"
                   name="EDIT_DURATION"
                   id ="EDIT_DURATION_input"
                   isError={errorMsg.includes('hours')}
                   onChange = {handleDurationChange}
                   value={editDuration}
                ></TextInput>
                { errorMsg && errorMsg.includes('hours') &&(
                  <ErrorMessage  p1 regular colorError>
                    {errorMsg}
                  </ErrorMessage>)
                }
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="private" className="add-cpd-form">PRIVATE NOTES</Form.Label>
                <textarea 
                  className="form-control private add-cpd-input" 
                  rows="6" 
                  type="text" 
                  value={editNote}
                  onChange={handleNoteChange}
                >
                </textarea>
              </Form.Group>
              <Form.Group className="cpd-form">
                <Form.Label htmlFor="credits" className="add-cpd-form">CREDITS</Form.Label>
                <TextInput
                   style={{ display: 'block' , width:'100%'}}
                   type="text"
                   name="EDIT_CREDITS"
                   id ="EDIT_CREDITS_input"
                   isError={ errorMsg &&!errorMsg.includes('DD/MM/YY') }
                   onChange = {handleCreditChange}
                   value={editCredit}
                  ></TextInput>
                  {errorMsg &&!errorMsg.includes('DD/MM/YY') &&(
                    <ErrorMessage  p1 regular colorError>
                      {errorMsg}
                    </ErrorMessage>)
                  }
              </Form.Group>
              <Form.Group className="cpd-form">
                <Form.Label htmlFor="url" className="add-cpd-form">URL</Form.Label>
                <Form.Control 
                  className="form-control url add-cpd-input" 
                  type="text" 
                  value={editURL}
                  onChange={handleURLChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="learnings" className="add-cpd-form">LEARNING POINTS</Form.Label>
                <textarea 
                  className="form-control learnings add-cpd-input" 
                  type="text" 
                  value={editLearning} 
                  onChange={handleLearningChange} 
                ></textarea>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="reflections" className="add-cpd-form">REFLECTION</Form.Label>
                <textarea 
                  className="form-control reflections add-cpd-input" 
                  type="text" 
                  value={editReflection}
                  onChange={handleReflectionChange} 
                ></textarea>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between align-items-center p-4">
           {errorAddNote &&(
            <ErrorMessage  p1 regular colorError>
              Error!
            </ErrorMessage>
            )}
            <Button 
              className="btn btn-cancel btn-lg px-4" 
              onClick={handleEditClose}
            >
              Cancel
            </Button>
            <Button 
              className="btn btn-save btn-lg px-4" 
              onClick={handleEditSave}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* CONFIGURE MODAL */}
      {bookmarks && (addNote || showAllNotes) && showConfig && (        
        <Modal show={showConfig} onHide={handleCloseSetting}>
          <Modal.Header className="px-4">
            <div className="container">
              <div className="row">
                <Modal.Title className="py-2 m-header">Edit Default Configuration</Modal.Title>
                <Button className="close align-middle" onClick={handleCloseSetting}>&times;</Button>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form>
              <Form.Group as={Row}>
                <Form.Label htmlFor="title" className="add-cpd-form" column sm={5}>TITLE</Form.Label>
                <Col sm={7}>
                  <Form.Control className="form-select" as="select" value={titleConfig} onChange={handleTitleConfig}>
                    <option value="title" >Result title</option>
                    <option value="query">Search query</option>
                    <option value="blank">Blank</option>
                  </Form.Control> 
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label htmlFor="date" className="add-cpd-form" column sm={5}>DATE OF LEARNING</Form.Label>
                <Col sm={7}>
                  <Form.Control className="form-select" as="select" value={dateConfig} onChange={handleDateConfig}>
                    <option value="today">Today</option>
                    <option value="blank">Blank</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group className="cpd-form" as={Row}>
                <Form.Label htmlFor="duration" className="add-cpd-form" column sm={5}>DURATION (IN HOURS)</Form.Label>
                <Col sm={7}>
                  <Form.Control 
                    className="form-control credits add-cpd-input" 
                    type="number" 
                    placeholder="Please enter a number"
                    value={durationConfig}
                    onChange={handleDurationConfig}
                  />
                </Col>
                {/* <div className="invalid-feedback">Please enter a number</div> */}
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label htmlFor="private" className="add-cpd-form" as="legend" column sm={5}>
                  PRIVATE NOTES
                </Form.Label>
                <Col sm={7} className="cpd-note-options">
                  <Form.Check
                    type="checkbox"
                    label="Search query"
                    name="formCheckbox"
                    checked={queryCheckboxConfig}
                    onChange={handleQueryCheckboxConfig}
                    // id="formHorizontalRadios1"
                    // className="config-checkbox"
                  />
                  <Form.Check
                    type="checkbox"
                    label="Result title"
                    name="formCheckbox"
                    checked={titleCheckboxConfig}
                    onChange={handleTitleCheckboxConfig}
                    // id="formHorizontalRadios2"
                    // className="config-checkbox"
                  />
                  <Form.Check
                    type="checkbox"
                    label="Result snippet"
                    name="formCheckbox"
                    checked={snippetCheckboxConfig}
                    onChange={handleSnippetCheckboxConfig}
                    // id="formHorizontalRadios3"
                    // className="config-checkbox"
                  />
                </Col>
              </Form.Group>


              <Form.Group className="cpd-form" as={Row}>
                <Form.Label htmlFor="credits" className="add-cpd-form" column sm={5}>CREDITS</Form.Label>
                <Col sm={7}>
                  <Form.Control 
                    className="form-control credits add-cpd-input" 
                    type="number" 
                    placeholder="Please enter a number"
                    value={creditConfig}
                    onChange={handleCreditConfig}
                  />
                </Col>
                {/* <div className="invalid-feedback">Please enter a number</div> */}
              </Form.Group>

              <Form.Group className="cpd-form" as={Row}>
                <Form.Label htmlFor="url" className="add-cpd-form" column sm={5}>URL</Form.Label>
                <Col sm={7}>
                  <Form.Control className="form-select" as="select" value={urlConfig} onChange={handleURLConfig}>
                    <option value="url">URL</option>
                    <option value="blank">Blank</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between align-items-center p-4">
          {errorAddNote &&(
               <ErrorMessage  p1 regular colorError>
                Error!
              </ErrorMessage>
              )}
            <Button className="btn btn-cancel btn-lg px-4" onClick={handleCloseSetting}>
              Cancel
            </Button>
            <Button className="btn btn-save btn-lg px-4" onClick={handleSaveSetting}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {typeof showNotLoggedInModalFlag !== 'undefined' &&  showNotLoggedInModalFlag === index && (
        <ModalNotLoggedIn
          modalFeedbackShow={ showNotLoggedInModalFlag === index }
          modalFeedbackClose={setShowNotLoggedInModalFlag} 
          title="Please login or signup"
          // result = {props.result}
          i = {index} 
        />  
      )}
    </Container>
  );
};

export default CpdNotes;
