import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const colors = ({
  textplaceholder,
  textmainbody,
  textoffblack,
  textlink,
  backgroundWhite,
  colorError,
  purpleprimary,
  greendark,
  theme,
}) => {
  if (textplaceholder) {
    return css`
      color: ${theme.palette.textPlaceholder};
    `;
  }

  if (textmainbody) {
    return css`
      color: ${theme.palette.textMainbody};
    `;
  }

  if (textoffblack) {
    return css`
      color: ${theme.palette.textOffblack};
    `;
  }

  if (textlink) {
    return css`
      color: ${theme.palette.textLink};
    `;
  }

  if (backgroundWhite) {
    return css`
      color: ${theme.palette.backgroundWhite};
    `;
  }

  if (colorError) {
    return css`
      color: ${theme.palette.colorError};
    `;
  }

  if (purpleprimary) {
    return css`
      color: ${theme.palette.purplePrimary};
    `;
  }

  if (greendark) {
    return css`
      color: ${theme.palette.greenDark};
    `;
  }
};

const fontsizes = ({ h30,h1,h18, h2, p1, c1, c2, button }) => {

  if (h30) {
    return css`
      font-size: ${({ theme }) => theme.fontsize.desktop.h30};
      line-height: ${({ theme }) => theme.lineheight.desktop.h1};
      letter-spacing: -0.75px;

      @media (max-width: 767.98px) {
        font-size: ${({ theme }) => theme.fontsize.mobile.h30};
        line-height: ${({ theme }) => theme.lineheight.mobile.h1};
        letter-spacing: -0.6px;
      }
    `;
  } 
  if (h1) {
    return css`
      font-size: ${({ theme }) => theme.fontsize.desktop.h1};
      line-height: ${({ theme }) => theme.lineheight.desktop.h1};
      letter-spacing: 0.2px;

      @media (max-width: 767.98px) {
        font-size: ${({ theme }) => theme.fontsize.mobile.h1};
        line-height: ${({ theme }) => theme.lineheight.mobile.h1};
      }
    `;
  }

  if (h2) {
    return css`
      font-size: ${({ theme }) => theme.fontsize.desktop.h2};
      line-height: ${({ theme }) => theme.lineheight.desktop.h2};

      @media (max-width: 767.98px) {
        font-size: ${({ theme }) => theme.fontsize.mobile.h2};
        line-height: ${({ theme }) => theme.lineheight.mobile.h2};
      }
    `;
  }
  if(h18){
    return css`
    font-size: 18px;
    line-height: ${({ theme }) => theme.lineheight.desktop.h2};

    @media (max-width: 767.98px) {
      font-size: ${({ theme }) => theme.fontsize.mobile.h2};
      line-height: ${({ theme }) => theme.lineheight.mobile.h2};
    }
  `;
  }

  if (p1) {
    return css`
      font-size: ${({ theme }) => theme.fontsize.desktop.p1};
      line-height: ${({ theme }) => theme.lineheight.desktop.p1};

      @media (max-width: 767.98px) {
        font-size: ${({ theme }) => theme.fontsize.mobile.p1};
        line-height: ${({ theme }) => theme.lineheight.mobile.p1};
      }
    `;
  }

  if (c1) {
    return css`
      font-size: ${({ theme }) => theme.fontsize.desktop.c1};
      line-height: ${({ theme }) => theme.lineheight.desktop.c1};

      @media (max-width: 767.98px) {
        font-size: ${({ theme }) => theme.fontsize.mobile.c1};
        line-height: ${({ theme }) => theme.lineheight.mobile.c1};
      }
    `;
  }

  if (c2) {
    return css`
      font-size: ${({ theme }) => theme.fontsize.desktop.c2};
      line-height: ${({ theme }) => theme.lineheight.desktop.c2};

      @media (max-width: 767.98px) {
        font-size: ${({ theme }) => theme.fontsize.mobile.c2};
        line-height: ${({ theme }) => theme.lineheight.mobile.c2};
      }
    `;
  }

  if (button) {
    return css`
      font-size: ${({ theme }) => theme.fontsize.desktop.button};
      line-height: ${({ theme }) => theme.lineheight.desktop.button};

      @media (max-width: 767.98px) {
        font-size: ${({ theme }) => theme.fontsize.mobile.button};
        line-height: ${({ theme }) => theme.lineheight.mobile.button};
      }
    `;
  }
};

const fontweights = ({ regular, semibold, bold, extrabold }) => {
  if (regular) {
    return css`
      font-weight: ${({ theme }) => theme.fontweight.regular};
    `;
  }

  if (semibold) {
    return css`
      font-weight: ${({ theme }) => theme.fontweight.semibold};
    `;
  }

  if (bold) {
    return css`
      font-weight: ${({ theme }) => theme.fontweight.bold};
    `;
  }

  if (extrabold) {
    return css`
      font-weight: ${({ theme }) => theme.fontweight.extrabold};
    `;
  }
};

export const H1 = styled.h1`
  ${fontsizes};
  ${fontweights};
  ${colors};
`;

export const H2 = styled.h2`
  ${fontsizes};
  ${fontweights};
  ${colors};
`;

export const P = styled.p`
  ${fontsizes};
  ${fontweights};
  ${colors};
`;

export const A = styled.a`
  ${fontsizes};
  ${fontweights};
  ${colors};

  &:hover {
    color: ${({ theme }) => theme.palette.purplePrimary};
  }
`;

export const Span = styled.span`
  ${fontsizes};
  ${fontweights};
  ${colors};
`;

export const Label = styled.label`
  ${fontsizes};
  ${fontweights};
  ${colors};
`;

export const StyledLink = styled(Link)`
  ${fontsizes};
  ${fontweights};
  ${colors};

  &:hover {
    color: ${({ theme }) => theme.palette.purplePrimary};
  }
`;
