/**
 *
 * This component is both used in <FiltersDesktop /> and <FiltersMobile />
 *
 * @param mobile
 * The parameter is the only accepted prop, and only used to declare several mobile only styles:
 * See: <Styles mobile={mobile}>
 * 
 * Our accordion library doesn't collapse all items programmatically other than initial mount
 * Therefore, we use a (hacky) "updating key" method to trigger re-mount
 * This is needed on mobile clear action and when user makes a new search in the search page (so that all filter accordions would collapse)
 * Usage can be found in the <Accordion key=.... section on this page
 *
 */
import React, { useEffect, useContext, useMemo,useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { AppContext } from '../../context';

import { A, P, Span } from '../theme/typography';
import Styles from './styles';
import { sources } from '../../functions/frontend_variables';
import {getNameOfAllDisplayFilters,getDictDisplayFilters,getAllSources,  getUserFilters} from'../../functions/manageSessions';
import {parseNameOfFilters} from '../../functions/utils';
import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen';


const Filters = ({ mobile }) => {
  const {
    searchContext,
    setSearchContext,
    userContextFilters,
    mobileContext,
    setMobileContext,
  } = useContext(AppContext);
  const isSmallScreen =  useIsSmallScreen();


  const all_btn_mobile= useMemo(()=> {
    if (typeof userContextFilters!== 'undefined' && typeof userContextFilters["filter-types"]!== 'undefined' ){
      let names ;
      if(userContextFilters && userContextFilters['filter-types']){
          names =  userContextFilters['filter-types'];
      }
      if(userContextFilters && userContextFilters['sidebar-order']){
          names = userContextFilters['sidebar-order'];
          if(userContextFilters['Web'] && !names.includes('Web') ){
             names.push('Web');
          };
      }
      return names; 
    }
    return [];
    },[userContextFilters]);

  
    const getAllSourcesFromFacetEntries = (facets) =>{
      let allSources = [];
      facets.forEach(filterOrSrc => {
        //if it is src 
        if(filterOrSrc && filterOrSrc['encrypted_url']){
          allSources.push(filterOrSrc) ;
        }
        else{
          // it is a filter with maybe more than 1 src
          if(filterOrSrc && filterOrSrc['sources']){
            filterOrSrc['sources'].forEach(src => {
              allSources.push(src);
            });
          }
        }
      });
      return allSources;
    };

    const getNumberOfSelectedCheckboxes= useMemo(()=> {
      let numberOfSelectedFacets = {};
     
      all_btn_mobile.forEach(nameOfBtn => {
        let allBtnSourcesFilters = userContextFilters[nameOfBtn];
        if(allBtnSourcesFilters){
          allBtnSourcesFilters = allBtnSourcesFilters.map(tempSource =>  tempSource['sources']);
          allBtnSourcesFilters = allBtnSourcesFilters.flat();
          let  count = 0 ; // searchContext.facets 
          let urlsDetected = [] ; 
          if(allBtnSourcesFilters  && mobileContext && mobileContext.facetEntries){
            let allEncrypted = allBtnSourcesFilters.flatMap(tempSource =>  tempSource['encrypted_url']);
            let allMobileContextSources = getAllSourcesFromFacetEntries(mobileContext.facetEntries);
            allMobileContextSources= allMobileContextSources.map(tempSource =>  tempSource['encrypted_url']);

            // we loop throuhj btn Sources because we are counting the number of checkbox 
            allEncrypted.forEach(encrypted_url => {
              if(allMobileContextSources.includes(encrypted_url)){
                count = count+ 1 ;
              }
            });
          }
          numberOfSelectedFacets[nameOfBtn]=count; 
        }
      });
      return numberOfSelectedFacets;
      },[mobileContext]);
  
  const memoSourcesInBtn = useMemo (()=>{
    let sourcesInBtn = {};
    if (typeof userContextFilters !== "undefined"  && typeof userContextFilters["filter-types"] !== 'undefined' ){
    let btnNames = userContextFilters["filter-types"]; 
      btnNames.forEach(btnName => {
        let allSources = []; 
        let allFilters =  userContextFilters[btnName];
        allFilters.forEach(filter => {
          if(filter && filter['sources'] && filter['sources'].length > 0 ){
            filter['sources'].forEach(source => {
              allSources.push(source)
            });
          }
        });
        sourcesInBtn[btnName] = allSources;
      });
    }
    return sourcesInBtn;
  },[userContextFilters]);

  /// returns an object with key names and value an array of srcs 
  const memoAllGroups =  useMemo(()=>{
    let allGroups= {};
    all_btn_mobile.forEach(nameOfBtn => {
      let allBtnSourcesFilters = userContextFilters[nameOfBtn];
      // allBtnSourcesFilters = allBtnSourcesFilters.map(tempSource =>  tempSource['sources']);
      allBtnSourcesFilters.forEach(filter => {
        if(filter && filter['sources'].length >= 1 ){
          //filter is a group 
          let groupSources = [];
          let group ={} ; 
          filter['sources'].forEach(source => {
            groupSources.push(source);
          });
          allGroups[filter['name']] = groupSources ;
        }
      });
    });
    return allGroups;
  },[userContextFilters])

    /// returns an array of filters names
    const memoAllGroupsNames =  useMemo(()=>{
      let allGroups= [];
      all_btn_mobile.forEach(nameOfBtn => {
        let allBtnSourcesFilters = userContextFilters[nameOfBtn];
        // allBtnSourcesFilters = allBtnSourcesFilters.map(tempSource =>  tempSource['sources']);
        allBtnSourcesFilters.forEach(filter => {
          if(filter && filter['sources'].length >= 1 ){
            //filter is a group 
            allGroups.push (filter['name']);
          }
        });
      });
      return allGroups;
    },[userContextFilters])

  const getNumberOfSelectedSourcesInGroup= useMemo(()=> {
    let numberOfSelectedFacets = {}; 
    memoAllGroupsNames.forEach(name => {
      let sourcesInGroup =  memoAllGroups[name]; // an array of sources in name
      let encryptedUrlGroup =  sourcesInGroup.map(source => source['encrypted_url']);
      let groupIds =  sourcesInGroup.map(source => source['id']);
      let allURLSelected = [];
      if(mobileContext.facetEntries){
        mobileContext.facetEntries.forEach(source => {
          if(source && typeof source['sources'] !=='undefined'){
            source['sources'].forEach(src => {
              let srcUrl = src['encrypted_url'] ;
              if ( encryptedUrlGroup.includes(srcUrl) ){
                allURLSelected.push(srcUrl);
              }
            });
          }
          if(source && typeof source['encrypted_url'] !=='undefined'){
            let srcUrl = source['encrypted_url'] ;
            if ( encryptedUrlGroup.includes(srcUrl) ){
              allURLSelected.push(srcUrl);
            }
          }
        });
        let uniqURL = [...new Set(allURLSelected)];
        numberOfSelectedFacets[name]=uniqURL.length;
      }
    });
    return numberOfSelectedFacets;
    },[mobileContext]);

  const getSourceIdFromFilterOrSource = (filterOrSource)=>{
    if (  filterOrSource['sources'] && filterOrSource['sources'][0]['id'] ){
      // it is a filter
      return filterOrSource["sources"][0]['id'];
    }
    return filterOrSource['id']; 
  };
  // a dictionary with key source id (number)
  // and a boolean  value whether is it check or not (boolean)
  const memoCheckSources = useMemo ( ()=>{
    if ( typeof mobileContext.facetEntries !== "undefined" && mobileContext.facetEntries.length > 0 ){
      // if(tyepof mobileContext.facetEntries )
      // const keysList = mobileContext.facetEntries.map(x =>  {x['id'],true} );
      let keyList2=[];
      mobileContext.facetEntries.forEach(srcOrFilter => {
        if(srcOrFilter["sources"]){
          srcOrFilter["sources"].forEach(src => {
            let tempId = src['id'];
            keyList2.push ([tempId, true]);
          });
        }
        else{
          if (srcOrFilter && srcOrFilter['id']){
            let tempId = srcOrFilter['id'];
            keyList2.push ([tempId, true]);
            // keyList2[tempId]= true;
          }
        }
      });
      // searchContext.facetEntries.forEach(src => {
      //   console.log('196 earch',src);
      // });

      let keysList = mobileContext.facetEntries.map( x =>[getSourceIdFromFilterOrSource(x),true] );
      if ( keyList2&& keyList2.length > 0){
        let checkedSources = new Map(keyList2);
        return checkedSources;
      }
    }
    return -1;
  },[mobileContext,searchContext]);


  // input filter or array of src or array of filters
  // output number of sources in input
  const sourcesInFilter = (tempFilter)=>{
    let count = 0; 
    let allSrc = []; 
    tempFilter.forEach(srcOrFilter => {
      if(srcOrFilter &&  srcOrFilter['sources']){
        srcOrFilter['sources'].forEach(src => {
          if(src && src['id']){
            let tempId =src['id'];
            if(src && src['id']){
              count ++; 
              allSrc.puhs(src['id']);
            }
          }
       });
      }
      else{
        if(srcOrFilter &&  srcOrFilter['id']){
          let tempId =srcOrFilter['id'];
          if(srcOrFilter && srcOrFilter['id'] ){
            count ++; 
            allSrc.push(srcOrFilter['id']);
          }
        }
      }
    });
    let uniqueSrc = [...new Set(allSrc)];
    count = uniqueSrc.length ; 

    return count;
  };


    
  const all_checkbox_Sources = (btnName) =>{
    let all_info = userContextFilters;
    let allCheckboxSources = [] ;
    if(btnName && all_info &&  all_info[btnName] 
      && all_info[btnName]&& all_info[btnName].length > 0){
        all_info[btnName].forEach(filter => {
          allCheckboxSources.push(filter);
        });
    }
    return allCheckboxSources;
  };

  const getNumberOfSourcesInBtn = (nameOfBtn) =>{
    
    return  all_checkbox_Sources(nameOfBtn).length; 
  };

  const getNumberOfSelectedSources = (nameOfBtn) =>{
    let allBtnSourcesFilters = userContextFilters[nameOfBtn];
    allBtnSourcesFilters = allBtnSourcesFilters.map(tempSource =>  tempSource['sources']);
    allBtnSourcesFilters = allBtnSourcesFilters.flat();
    let  count = 0 ; // searchContext.facets 
    if(allBtnSourcesFilters && mobileContext && mobileContext.facets){
      let allEncrypted = allBtnSourcesFilters.map(tempSource =>  tempSource['encrypted_url']);
      mobileContext.facets.forEach(source => {
        if(source && typeof source['encrypted_url'] !=='undefined'){
          let srcUrl = source['encrypted_url'] ;
          if ( allEncrypted.includes(srcUrl) ){
            count = count+1; 
          }
        }
        else{
          if(source && source['sources'] !== "undefined"){
            let tempUrls = source['sources'].map(src =>  src['encrypted_url']);
            tempUrls.forEach(tempSrc => {
              if ( tempSrc && allEncrypted.includes(tempSrc) ){
                count = count+1; 
              }
            });
          }
        }
      });
    }
    return count; 
  };

  

  // returns source object from the tag name
  const getSourceObject= (name) => {
    let all_usr_src = getAllSources() ;
    let src =-1;
    all_usr_src.forEach(element => {
      if (name === element.name){
        src= element; 
      }
    });
    return src;
  };

  // Filters (facets) submission from a desktop device
  // If user is on mobile, populate the local state and submit them once mobileContext.apply is triggered
  const handleCheckbox = (e) => {
    let entries = searchContext.facets;
    let value = e.target.value;

    if(isSmallScreen){
      entries = mobileContext.facetEntries;
    }

    let index = findIndexFromSources(entries , value);//  facetEntries.findIndex((x) => x.name === value);
    // if(isSmallScreen){
    //   index = findIndexFromSources(facetEntries , value)
    // }
    if (index > -1) {
      // is in facetEntries remove
      entries.splice(index, 1);
    } else {
      let src = getSourceObject(e.target.value);
      if (src !== -1 ){
        entries.push(src);
      }
    }
    if (!isSmallScreen) {
      setSearchContext({
        ...searchContext,
        facets: entries,
        forceSearch: true
      });
    } else {

      setMobileContext({
        ...mobileContext,
        facetEntries: entries,
        // forceSearch: true
      });
    }
  };




    // Filters (facets) submission from a desktop device
  // If user is on mobile, populate the local state and submit them once mobileContext.apply is triggered
  const handleSubFilterCheckbox = (filter,e) => {
    e.stopPropagation();
    let ischeck = !isFilterchecked(searchContext.facets, filter);
    let entries = searchContext.facets;
    // let value = e.target.value;
    if(isSmallScreen){
      entries = mobileContext.facetEntries;
    }
    entries = getAllSourcesFromFacetEntries(entries);
    filter['sources'].forEach(src => {
      if(src['sources']){
        src['sources'].forEach(element => {
          let index = findIndexFromSources(entries , element);
          if (index > -1 && ischeck) {
            // is in facetEntries remove
            entries.splice(index, 1);
          } else {
            if (index === -1 ){
              entries.push(element);
            }
          }
        });
      }
      else{
        let allSourcesFromFacetEntries ; 
        let index = findSourceInArray(entries , src);
          if (index > -1 && ischeck) {
            // is in facetEntries remove
            entries.splice(index, 1);
          } else {
            if (index === -1 ){
              entries.push(src);
            }
          }
      }
    });
    if (!isSmallScreen) {
      setSearchContext({
        ...searchContext,
        facets: entries,
        forceSearch: true
      });
    } else {
      setMobileContext({
        ...mobileContext,
        facetEntries: entries,
        // forceSearch: true
      });
    }
  };


    // Filters (facets) submission from a desktop device
  // If user is on mobile, populate the local state and submit them once mobileContext.apply is triggered
  const handleGroupCheckbox = (source) => {
    let entries = searchContext.facets;
    if(isSmallScreen){
      entries = mobileContext.facetEntries;
    }
    let value = source['name'];
    let index = findIndexFromSources(entries , value);//  facetEntries.findIndex((x) => x.name === value);
    if (index > -1) {
      // is in facetEntries
      entries.splice(index, 1);
    } else {
      // it is in 
      let src = source;
      entries.push(src);
    }

    if (!isSmallScreen) {
      setSearchContext({
        ...searchContext,
        facets: entries,
        forceSearch: true
      });
    } else {
      setMobileContext({
        ...mobileContext,
        facetEntries: entries,
        // forceSearch: true
      });
    }
  };


  const getSourceNameFromFilterOrSource = (filterOrSource)=>{
    if (  filterOrSource['sources'] && filterOrSource['sources'][0]['name'] ){
      // it is a filter
      return filterOrSource["sources"][0]['name'];
    }
    return filterOrSource['name']; 
  };

  //input:  source is a string or an src obj , sources is an array of sources obj or an array of display sources
  // outputs : the index of the source 
  const findIndexFromSources = (sources, source) =>{
    // check is index
    let sources_names;
    let index ;
    if(typeof sources !== "undefined" ){
      sources_names = sources.map( (x) => getSourceNameFromFilterOrSource(x)) ;
      index = sources_names.indexOf(source);
    }
    // if not index use dic to find src 
    if (index === -1){
      //look for it in dic of sources
      let  dic_of_sources = getDictDisplayFilters();
      if ( source['encrypted_url'] && source['encrypted_url'] in  dic_of_sources){
        let encrypted_url = dic_of_sources[source];// display filter object or an src object
        // could be dic_of_sources[source]
        index =  sources_names.indexOf(encrypted_url );
      }
    } 
    return index;
  };


  // input:  source an src obj , sources is an array of sources obj 
  // outputs : the index of the source 
  const findSourceInArray = (sources, source) =>{
    // check is index
    let sources_names;
    let index ;
    if(typeof sources !== "undefined" ){
      sources_names = sources.map( (x) => x['encrypted_url']) ;
      index = sources_names.indexOf(source['encrypted_url']);
    }
    return index;
  };

  const ischecked = (sources, sourceName) => {
    let out ;
    // let srcName = source['name'];
    // let srcID = source['id']; 
    // let allSourcesId = sources.map(src => src['id']);

    if (isSmallScreen){
      out = findIndexFromSources(mobileContext.facetEntries, sourceName);
    }
    else{
      out = findIndexFromSources(sources, sourceName);
    }
    if (out !==-1){
      out = false;
    }
    else{
      out = true;
    }
    return out ; 
  };

  
  const isFilterchecked = (sources, filter) => {
    let sourcesNames = filter['sources'].map((x)=> x.name)
    let out = true;
    let entries = getAllSourcesFromFacetEntries(mobileContext.facetEntries);
    sourcesNames.forEach(name => {
      if (findIndexFromSources(entries, name)=== -1 ){
        out = -1;
      }
    });
    if (out !==-1){
      out = false;
    }
    else{
      out = true;
    }
    return out; 
  };
  

  // Filters submission from a mobile device
  // All filters (all facets and the covid switch) is submitted at once
  useEffect(() => {
    if (mobileContext.apply && isSmallScreen) { 
      setSearchContext({
        ...searchContext,
        facets: mobileContext.facetEntries,
        covid_only: mobileContext.covidSwitch,
        forceSearch: true
      });
      setMobileContext({ ...mobileContext,label: searchContext.facets.length<sources.length ? "On":"Off" ,apply: false });
    }
    // eslint-disable-next-line
  }, [mobileContext.apply]);

  // Clear all filters, update layout and retrieve unfiltered results
  useEffect(() => {
    if (mobileContext.clear) {
      // setSearchContext({
      //   ...searchContext,
      //   facets: getDefaultFilters(),
      //   covid_only: false,
      //   // forceSearch: true
      // });

      setMobileContext({
        ...mobileContext,
        clear: false,
        toggle: false,
        facetEntries: [],
        covidSwitch: false,
        // forceSearch: true
      });
    }
    // eslint-disable-next-line
  }, [mobileContext.clear]);


  return (
    <Styles mobile={mobile}>
      { all_btn_mobile && all_btn_mobile.length > 0  && all_btn_mobile.map((buttonName,id) => (
        <>
        {userContextFilters[buttonName] && buttonName!=="Web" && userContextFilters[buttonName].length >0 && (
        <Accordion allowZeroExpanded key={"ButtonName_"+buttonName} preExpanded={ mobile? '':["buttonName_Patient","buttonName_Medwise"]}>
          <AccordionItem key={id} uuid={"buttonName_"+buttonName.replaceAll(' ','_')}>
            <AccordionItemHeading>
              <AccordionItemButton data-type={"accordion_item_" + "ButtonsName".toLowerCase()}>
                <P p1 regular textoffblack>
                  {buttonName + ' ('+ getNumberOfSelectedCheckboxes[buttonName] + '/' + memoSourcesInBtn[buttonName].length+")"}
                </P>
                <AccordionItemState>
                  {({expanded}) => {
                    if (expanded) {
                      return <div className="opened"/>
                    } else {
                      return <div className="closed"/>
                    }
                  }}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            { userContextFilters[buttonName] && all_checkbox_Sources(buttonName).length>0 && all_checkbox_Sources(buttonName).map((filter) => (
              <div className="checkbox_item" key={filter["id"]}>
              {filter['sources'].length ===1  ?  (
                <>
                  <input
                  key={'subGroup'+memoCheckSources}
                  type="checkbox"
                  id={filter['sources'][0]["id"]}
                  name={filter['sources'][0]["name"]}
                  value={filter['sources'][0]["name"]}
                  // checked={!ischecked(searchContext.facets, filter['name'])}
                  checked={
                    (filter['sources'] && filter['sources'][0] && filter['sources'][0]['id'] && memoCheckSources !== -1) ?
                    
                    ((memoCheckSources !== -1)&&memoCheckSources.get(filter['sources'][0]['id'])===true ) :false }
                  onChange={handleCheckbox} />
                  <label htmlFor={filter['sources'][0]["id"]} style={{ width: "100%" }}>
                    <Span p1 regular textmainbody>
                      {filter['name'].slice(0, 27).length < 28 ? parseNameOfFilters(filter['name'].slice(0, 27)) : parseNameOfFilters(filter['name'].slice(0, 27)) + '...'}{' '}
                    </Span>
                  </label>
                </>
                ): (
                  <>
                    <Accordion allowZeroExpanded key={"Accordion_"+filter['name'].replaceAll(' ','_')} style={{ width: "100%" ,paddingTop: "4px "}} >
                      <AccordionItem key={filter["id"]} uuid={"buttonName_"+filter['name'].replaceAll(' ','_')}>
                        {/* <AccordionItemHeading> */}
                          <AccordionItemButton data-type={"accordion_item_" + 'filter'} style={{paddingTop: "4px ",paddingBottom: "4px "}}>
                            <div>
                          <input
                            type="checkbox"
                            id={filter["id"]}
                            name={filter["name"]}
                            value={filter["name"]}
                            checked={!isFilterchecked(searchContext.facets, filter)}
                            onChange={ (e)=> {handleSubFilterCheckbox(filter,e)}}
                             />
                             <label  style={{ width: "100%", paddingLeft: "12px"}} >
                              <Span p1 regular textoffblack>
                                {filter["name"].slice(0, 27).length < 28 ? 
                                parseNameOfFilters(filter["name"].slice(0, 27))+' ('+getNumberOfSelectedSourcesInGroup[filter["name"]]+'/'+sourcesInFilter(filter['sources']) +')' 
                                :
                                 parseNameOfFilters(filter["name"].slice(0, 27)) + '...' + +'('+')' }{' '}
                              </Span>
                            </label>
                            </div>
                            <AccordionItemState>
                              {({expanded}) => {
                                if (expanded) {
                                  return <div className="opened"/>
                                } else {
                                  return <div className="closed"/>
                                }
                              }}
                            </AccordionItemState>
                          </AccordionItemButton>
                        {/* </AccordionItemHeading> */}
                        <AccordionItemPanel >
                        { filter['sources'].length>0 && filter['sources'].map((source) => (
                        // 
                            <div style={{ display: 'flex' ,width: "100%"}}>
                              <input
                                key={(memoCheckSources!==-1)?'is_dict'+memoCheckSources.get(source['id']):'nothing_checked'}
                                type="checkbox"
                                id={source["id"]}
                                name={source["name"]}
                                value={source["name"]}
                                checked={(source['id'] && memoCheckSources !== -1)  ?  memoCheckSources.get(source['id']):false  }
                                onChange={ ()=> handleGroupCheckbox(source)}
                                />
                              <label htmlFor={source["id"]} style={{ width: "100%"}}>
                                <Span p1 regular textmainbody>
                                  {source["name"].slice(0, 25).length < 25 ? parseNameOfFilters(source["name"].slice(0, 24)) : parseNameOfFilters(source["name"].slice(0, 24)) + '...'}{' '}
                                </Span>
                              </label>
                            </div>
                        ))}
                      </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </>
                )}          
              </div>
              ))}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        )  }
        {buttonName==="Web" &&(
                        <div className="checkbox_item" key={"web"}>
                        <input
                            type="checkbox"
                            id={"Web"}
                            name={buttonName}
                            value={buttonName}
                            checked={!ischecked(searchContext.facets,buttonName)
                            }
                            onChange={handleCheckbox}
                        />
                        <label htmlFor={"Web"}>
                          <Span p1 regular textmainbody>
                            {"Web"}
                          </Span>
                        </label>
                      </div>)}
        {/* ) } */}
        </>
      ))}
    </Styles>
  );
};

export default Filters;
