import React, {  useEffect,useState } from 'react';
import styled from 'styled-components';
import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen';


export default function LoadingIndicatorEllipsis(){
  const [dots, setDots] = useState(0);
  const isSmallScreen =  useIsSmallScreen();
  useEffect(() => {
    setDots(0);
    const interval = setInterval(in_progress, 300);
    return () => clearInterval(interval);
  }, []);

  function in_progress() {
    if (dots < 3) {
      setDots((prevState) => {
        return prevState + 1;
      });
    } else {
      setDots(0);
    }
  };

    return (
      <Wrapper>
        <div
          className="load-more-container"
          style={{
          marginTop: isSmallScreen ? '8px' : '1rem',
          marginBottom: isSmallScreen ? '8px' : '1rem',
        }}>
        <span className="load-more-disabled dots">
          {dots % 3 === 1 && (
            <span>
              ·<span style={{ visibility: 'hidden' }}>··</span>
            </span>
          )}
          {dots % 3 === 2 && (
            <span>
              ··<span style={{ visibility: 'hidden' }}>·</span>
            </span>
          )}
          {dots % 3 === 0 && <span>···</span>}
        </span>
        </div>
      </Wrapper>
      );
    };

  const Wrapper = styled.div`

  .load-more-disabled {
    color: #8771DF;
    background-color: #F3F1FD;
    padding: 4px 8px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    box-shadow: 0 0 10px 0 #eee;
    width: 86.5px;
  }
  .dots{
    font-size: 20px;
    } 
  `