import { css } from 'styled-components';
import ChevronIcon from '../../assets/svgs/chevron.svg';

// Useful tips for <select> tag styling: https://codepen.io/chriscoyier/pen/zYYZaGP?editors=1100
export const select = css`
  font-family: ${({ theme }) => theme.fontfamily.mulish};
  font-size: ${({ theme }) => theme.fontsize.desktop.input};
  padding: 0 ${({ theme }) => theme.spacing.x1};
  padding-right: ${({ theme }) => theme.spacing.x4}; // To cover area with chevron
  color: ${({ theme }) => theme.palette.textPlaceholder};
  border: 1px solid ${({ theme }) => theme.palette.backgroundStroke};
  outline: 0;
  border-radius: ${({ theme }) => theme.borderradius.medium};
  appearance: none;
  background-image: url(${ChevronIcon}),
    linear-gradient(
      ${({ theme }) => theme.palette.backgroundGrey},
      ${({ theme }) => theme.palette.backgroundGrey}
    );
  background-repeat: no-repeat, repeat;
  background-position: right 12px bottom 15px;

  option {
    color: ${({ theme }) => theme.palette.textOffblack};
  }

  ::-ms-clear {
    display: none;
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.backgroundStrokeHover};
  }

  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.palette.purplePrimary};
    background-color: ${({ theme }) => theme.palette.backgroundWhite};
  }

  @media (max-width: 767.98px) {
    font-size: ${({ theme }) => theme.fontsize.mobile.input};
  }
`;
