import styled from 'styled-components';

export const Styles = styled.section`
  display: ${({ overlay }) => (overlay ? 'flex' : 'none')};
  z-index: 999;
  background-color: #6c6c71;
  padding-top: 56px;
  position: fixed !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;

  .mobilefilters {
    top: 100%;
    display: flex;
    flex-flow: column nowrap;
    background-color: ${({ theme }) => theme.palette.backgroundWhite};
    border-top-left-radius: ${({ theme }) => theme.borderradius.medium};
    border-top-right-radius: ${({ theme }) => theme.borderradius.medium};
    transition: all ease-in-out 0.25s;
    height: 100%;
    position: relative;

    height: 100% !important;
    max-height: 100% !important;
    width: 100vw !important;
    max-width: 100vw !important;

    &.slide {
      top: 0;
    }

    &_nav {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: ${({ theme }) => theme.spacing.x10};

      &--close {
        position: absolute;
        left: ${({ theme }) => theme.spacing.x4};
      }

      &--title {
        margin: 0;
      }

      &--clear {
        position: absolute;
        right: ${({ theme }) => theme.spacing.x4};
        text-decoration: underline;
      }
    }

    &_filters {
      display: inline-flex;
      flex-flow: column nowrap;
      padding: 0 32px 8px;
      /** Below needed to enable scroll */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      overflow-y: scroll;
      flex: 1 1 auto !important;
    }

    &_apply {
      padding: 0 ${({ theme }) => theme.spacing.x4};
      padding-bottom: ${({ theme }) => theme.spacing.x3};
    }
  }
`;

export default Styles;
