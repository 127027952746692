import styled from 'styled-components';

export const HeroSection = styled.section`
  background-color: ${({ theme }) => theme.palette.backgroundWhite};
  margin-top: 64px;

  .startBtn{
    width: 100%;
    margin-top: 12px;

    .startBtnWrapperMobile{
      width: 50%;
      margin: 0 auto;
    }
  }
  .startBtnWrapper{
    width: fit-content;
    margin: 0  auto;
  }
  
 
  .arrow-down-icon {
    // width:100%;
    display: block;
    margin: auto;
    padding-top: auto;
    position: absolute;
    bottom: 100px;
    left: calc(100vw / 2 - 24px);
    cursor: pointer;
    // text-align: center;
  }

  // .logged-in-block {
  //   height: calc(100vh - 118px);
  // }

  @media (max-width: 767.98px) {
    padding: calc((100vh - 114px) / 3 - 64px) 0 calc((100vh - 114px) / 3 * 2);
  }
  
  h1 {
    display:flex;
  } 
  
  .hero {
    &_logo {
      display: flex;
      justify-content: center;
      margin-bottom: ${({ theme }) => theme.spacing.x3};

      svg {
        width: ${({ theme }) => theme.spacing.x8};
        height: auto;
      }
    }

    &_title {
      display: flex;
      justify-content: center;
      text-align: center;
      padding:0;
      margin-bottom: ${({ theme }) => theme.spacing.x3};
      @media (max-width:767.98px){
        margin-bottom:0;
      }
      &_small{
        display:none;
        @media (max-width:767.98px){
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &_typewritter{
      // width :200px;    
      text-align: left;
      &_mobile {
        @media (max-width: 767.98px) {
          justify-content: center;
          text-align: center;
        }
      }
    }

    &_desktop{
      &_org{
        @media (max-width: 767.98px) {
          display: block;
          padding:10px;
        }
      }
    }

    &_mobile { 
      display: none ;
      @media (max-width: 767.98px) {
        display: flex;
      }
    }

    &_ignore{
      display:none;
    }
  }
`;


export const HeroSectionMobile = styled.section`
  
  background-color: ${({ theme }) => theme.palette.backgroundWhite};
  margin-top: 64px;
  display: flex;
  height: 199px; //  header height is fix at 64px (already on margin), searchbar is fix at 60px, and logo and text is limited to 3lines 29px*3 + 10px of padding can not 
  // 8px of bottom padding , 16px of padding top
  // 60+ 29*3 + 10 +8+ 16 = 173px
  align-items: end;
  position:relative;
  padding-bottom: 16px;  
  padding-top 16px;  

  .startBtn{
    width: 100%;
    margin-top: 12px;

    .startBtnWrapperMobile{
      width: fit-content;
      margin: 0 auto;
    }
  }

  
  .arrow-down-icon {
    // width:100%;
    display: block;
    margin: auto;
    padding-top: auto;
    position: absolute;
    bottom: 100px;
    left: calc(100vw / 2 - 24px);
    cursor: pointer;
    // text-align: center;
  }

  @media (max-width: 767.98px) {
    // padding: calc((100vh - 114px) / 3 - 64px) 0 calc((100vh - 114px) / 3 * 2);
  }
  
  h1 {
    display:flex;
  } 
  
  .hero {

    &_logo {
      display: flex;
      justify-content: center;
      margin-bottom: ${({ theme }) => theme.spacing.x3};

      svg {
        width: ${({ theme }) => theme.spacing.x8};
        height: auto;
      }
    }

    &_title {
      display: flex;
      justify-content: center;
      text-align: center;
      padding:0;
      margin-bottom: ${({ theme }) => theme.spacing.x3};
      @media (max-width:767.98px){
        margin-bottom:0;
      }
      &_small{
        display:none;
        @media (max-width:767.98px){
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &_typewritter{
      // width :200px;    
      text-align: left;
      &_mobile {
        @media (max-width: 767.98px) {
          justify-content: center;
          text-align: center;
        }
      }
    }

    &_desktop{
      &_org{
        @media (max-width: 767.98px) {
          display: block;
          padding:10px;
        }
      }
    }

    &_mobile { 
      
      display: none ;
      @media (max-width: 767.98px) {
        display: flex;
      }
    }

    &_ignore{
      display:none;
    }
  }
`;

// export default HeroSection;
