import styled, { css } from 'styled-components';
import { linkStyles } from '../../theme/link';
export const GoogleBtnContainer = styled.div`
  // border: 1px solid #dadce0;
  
  display: inline-block;
  width: 326px;
  font-size: ${({ theme }) => theme.fontsize.desktop.buttonlarge};
  cursor:pointer;

  @media (max-width: 767.98px) {
    font-size: ${({ theme }) => theme.fontsize.mobile.buttonlarge};
    width: 100%;
  }
}`;

