export const theme = {};

const px = (val) => `${val}px`;

// Extra small devices (portrait phones, less than 576px)
// @media (max-width: 575.98px) { ... }

// Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) and (max-width: 767.98px) { ... }

// Medium devices (tablets, 768px and up)
// @media (min-width: 768px) and (max-width: 991.98px) { ... }

// Large devices (desktops, 992px and up)
// @media (min-width: 992px) and (max-width: 1199.98px) { ... }

// Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { ... }

theme.palette = {
  purplePrimary: '#8771DF',
  purplePrimaryHover: '#6F5DB8',
  emedBlue:'#004077',
  purpleSecondary: '#E5E0FB',
  purpleSecondaryHover: '#967EFC',
  orange: '#FF960F',
  orangeHover: '#E5870d',
  greenAddSource : '#94C973', 
  greenAddSourceHover : '#B1D8B7', 


  backgroundWhite: '#FFFFFF',
  backgroundGrey: '#FBFBFD',
  backgroundStroke: '#E2E5ED',
  backgroundPurple:'#E4E0F7', 
  // backgroundStrokeHover: '#D2D5DD',
  backgroundStrokeHover: '#dadce0',
  backgroundDropdownHover: '#F1F4FB',
  backgroundDropdownHoverDarker: '##B4C4EA',

  textPlaceholder: '#97AAB6',
  textMainbody: '#4D5156',
  textOffblack: '#212452',
  textLink: '#1B5EF7',
  // textLinkMobile: '#1558d6',


  colorError: '#EB5757', // change it to a errorRed - backgroundWhite gibi olanlarin tumununden backgroundu kaldir, textOffblack gibilerden de texti kaldir.

  greenLight: '#EFF9FB',
  greenDark: '#02B1C8',

  blueLight: '#E9F0FB',
  blueDark: '#6990E5',

  pinkLight: '#FFEFEF',
  pinkDark: '#FDAAAB',

  // Example of custom button colors. See components/button for example usage.
  customBtnTextColor: '#FFF',
  customBtnBackgroundColor: '#7d0633',
  customBtnBorderColor: '#931a25',
  customBtnTextColorHover: '#7d0633',
  customBtnBackgroundColorHover: '#31112c',
  customBtnBorderColorHover: '#931a25',
};

theme.spacing = {
  x1: px(8),
  x2: px(16),
  x3: px(24),
  x4: px(32),
  x5: px(40),
  x6: px(48),
  x7: px(56),
  x8: px(64),
  x9: px(72),
  x10: px(80),
  x11: px(88),
  x12: px(96),
  x13: px(104),
  x14: px(112),
  x15: px(128),
};

theme.fontfamily = { mulish: 'Mulish, sans-serif' };

theme.fontweight = {
  regular: 400,
  semibold: 600,
  bold: 700,
  extrabold: 800,
};

theme.fontsize = {
  desktop: {
    h30: px(30),
    h1: px(22),
    h18: px(18),
    h2: px(16),
    h3: px(16),
    p1: px(14),
    c0: px(15),
    c1: px(13),
    c2: px(12),
    buttonsmall: px(14),
    buttonmedium: px(16),
    buttonlarge: px(18),
    input: px(14),
  },

  mobile: {
    h30: px(24),
    h1: px(20),
    h2: px(16),
    h3: px(18),
    p1: px(14),
    c1: px(15),
    c2: px(14),
    buttonsmall: px(18),
    buttonmedium: px(18),
    buttonlarge: px(18),
    input: px(18),
  },
};

theme.lineheight = {
  desktop: {
    h1: px(21),
    h2: px(21),
    h3: px(21),
    p1: px(21),
    c1: px(21),
    c2: px(21),
    button: px(21),
  },

  mobile: {
    h1: px(29),
    h2: px(29),
    h3: px(21),
    p1: px(23),
    c1: px(21),
    c2: px(21),
    button: px(21),
  },
};

theme.borderradius = {
  small: px(2), // Not used, all corners are medium.
  medium: px(3),
  large: px(4), // Not used, all corners are medium.
};
