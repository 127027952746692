import React, {useContext, useEffect, useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Styles from './styles';
import { ReactComponent as ErrorImage } from '../../assets/svgs/error-image.svg';
import Header from '../../components/header';
import { H2, P } from '../../components/theme/typography';
import Footer from '../../components/footer';
import CantFindFeedback from '../../components/cant-find-feedback';
import {getUser} from '../../functions/manageSessions';
import { AppContext } from '../../context';
import axios from 'axios';
import ConfirmLogin from '../../components/confirmLogin';



const ErrorPage = ({ history, location }) => {

  const { 
    setRequestTrackerContext,
    requestTrackerContext,
  } = useContext(
    AppContext
  ); 
   const [requestCheckAlias, setRequestCheckAlias] = useState(false);
   const [alias, setAlias] = useState(undefined);
   const [askToLogout, setAskToLogout] = useState(false);
   const [organisationName, setOrganisationName] = useState('');
   const [ssoLink, setSsoLink] = useState('');



   const checkAlias = (aliasName) => {
    setRequestTrackerContext(prev => { return {...prev, 'check-alias':true}});
    axios({
      method: 'post',
      url:`${window.BASE_URL_USER}check-alias`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        alias: aliasName,
      }),
    })
      .then((res) => {
        if(res.data &&res.data.code_or_url && res.data.code_or_url !== null){
          if(res.data.type && res.data.type === 'magic'){
            history.push('/magic?code='+res.data.code_or_url);
            if(res.data.user_in_aliased_org){
              history.push('/');
            }
          }
          if(res.data.type && res.data.type === 'sso'){
            if(res.data.user_in_aliased_org){
              history.push('/');
            }
            else{
              let userInfo = getUser();
              setSsoLink(res.data.code_or_url);
              if(userInfo){
                if (userInfo['org_name']){
                setOrganisationName(userInfo['org_name']);}
                setAskToLogout(true);
              }
              else{
                window.open(res.data.code_or_url,'_self');
              }
            }
            
          }
        }
        setRequestTrackerContext(prev => { return {...prev, 'check-alias':false}});
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'check-alias':false}});
        console.log("not such alias");
        console.log(error);
      });
  }

  useEffect(() => {
    if ( history && history.action && history.action === 'POP') {
      if (location && location.pathname && location.pathname.split('/').length >=2 && location.pathname.split('/')[1] !== ''  ) {
        let dirtyAlias =location.pathname.split('/')[1];
        try {
          const cleanAlias = decodeURI(dirtyAlias);
          if (cleanAlias) {
              setAlias(cleanAlias);
              if(requestTrackerContext && Object.hasOwn(requestTrackerContext,'logout-user') && requestTrackerContext['logout-user']){
                setRequestCheckAlias(false);// this will avoid the mobile bug that triggers history action when clicking logout
              }
              else{
                setRequestCheckAlias(true);
              }
            }
        } catch (e) {
          setRequestTrackerContext(prev => { return {...prev, 'check-alias':false}});
          // URI malformed, send user to 404
          // setErrorLogin(true);
          // setErrorMsg('Error please try with another magic link');
        }
      }
      else{
        setRequestTrackerContext(prev => { return {...prev, 'check-alias':false}});
      }
    }
    else{
      setRequestTrackerContext(prev => { return {...prev, 'check-alias':false}});
    } 
  }, [location, history]);

  useEffect(() => {
    if (requestCheckAlias && typeof alias ==='string' && alias!== '' ){
      checkAlias(alias);
      setRequestCheckAlias(false);
    }
  }, [requestCheckAlias ]);

  useEffect(()=>{
    removeHrTag();
      setRequestTrackerContext(prev => { return {...prev, 'check-alias':true}});
  },[]);
  
  const goToSsoLink = ()=>{
    setRequestTrackerContext(prev => { return {...prev, 'check-alias':true}});
    window.open(ssoLink,'_self');
  };

  const removeHrTag = () => {
    let myElement = document.getElementsByTagName('body')[0];
    for (let i = 0; i < myElement.children.length; i++) {
      if( myElement.children[i].tagName === 'HR' 
          || myElement.children[i].tagName === 'ADDRESS'
           || myElement.children[i].tagName === "address" ){
        myElement.children[i].remove();
      }
    }
  };
  

  return (
    <Styles>
      <Header withSearchBar={true} withAccountButtons={true} />
      { !requestTrackerContext['api/ask'] && !askToLogout&&(
      <main>
        <Container>
          <Row>
            <Col md={12} className="error">
              <ErrorImage />
              <H2 h2 regular textoffblack className="error_title">
                No results found
              </H2>
              <P p1 regular textmainbody className="error_message">
                Try adjusting your search, making sure that all words are
                spelled correctly.
                <br /> Otherwise, sorry, we don't seem to have an answer for
                this yet - we have logged it and will investigate!
              </P>
              {/* <CantFindFeedback /> */}
            </Col>
          </Row>
        </Container>
      </main>
      )}
      { askToLogout&& !requestTrackerContext['api/ask'] && (
        <ConfirmLogin organisation={organisationName} login={goToSsoLink} ></ConfirmLogin>
      )}
      <Footer sticky={ true} />

    </Styles>
  );
};

export default ErrorPage;
