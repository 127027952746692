import React, {TextInput,useContext, useEffect, useState,useCallback,useMemo} from 'react';
import { Container, Row, Col,Div, Button } from 'react-bootstrap';
import Styles from './styles';
import { ReactComponent as ErrorImage } from '../../assets/svgs/error-image.svg';
import Header from '../../components/header';
import { H1, P } from '../../components/theme/typography';
import Footer from '../../components/footer';
import { AppContext } from '../../context';
import axios from 'axios';
import { Remarkable } from 'remarkable';


const EditorPage = ({ history, location }) => {

  const { 
    setRequestTrackerContext,
    requestTrackerContext,
  } = useContext(
    AppContext
  ); 
  const jsonExample = {
    "Local": [],
    "Medwise": [
        {
            "entity_type": "Medwise",
            "id": 1,
            "is_default": true,
            "magic_allowed": true,
            "name": "NICE CKS",
            "position": 0,
            "sources": [
                {
                    "encrypted_url": "NICE CKS",
                    "id": 1,
                    "name": "NICE CKS",
                    "private": null,
                    "url": "https://cks.nice.org.uk"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 2,
            "is_default": true,
            "magic_allowed": true,
            "name": "NICE Guidelines",
            "position": 1,
            "sources": [
                {
                    "encrypted_url": "NICE Guidelines",
                    "id": 2,
                    "name": "NICE Guidelines",
                    "private": null,
                    "url": "https://www.nice.org.uk/guidance/"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 4,
            "is_default": true,
            "magic_allowed": true,
            "name": "BNFc",
            "position": 27,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABkdfnrEpfzDUkQZer59rLWfmhlxJqPb7pRzVPErz0SvtvTJWyXtVCe5wJp0yE5NlfLx4WFwiLDTm8T0N_mboo8hvONyYgMTHQ0PvMEb_1mAZNBA44=",
                    "id": 4,
                    "name": "BNFc",
                    "private": null,
                    "url": "https://bnfc.nice.org.uk"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 11,
            "is_default": true,
            "magic_allowed": true,
            "name": "DermNet NZ",
            "position": 28,
            "sources": [
                {
                    "encrypted_url": "Dermnet NZ",
                    "id": 11,
                    "name": "DermNet NZ",
                    "private": null,
                    "url": "https://dermnetnz.org"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 4237342799560798,
            "is_default": true,
            "magic_allowed": true,
            "name": "Primary Care Dermatology Society",
            "position": 29,
            "sources": [
                {
                    "encrypted_url": "PCDS",
                    "id": 12,
                    "name": "PCDS",
                    "private": null,
                    "url": "https://pcds.org.uk"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 4237555309740126,
            "is_default": true,
            "magic_allowed": true,
            "name": "FSRH",
            "position": 30,
            "sources": [
                {
                    "encrypted_url": "FSRH",
                    "id": 13,
                    "name": "FSRH",
                    "private": null,
                    "url": "https://www.fsrh.org/standards-and-guidance/"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 6,
            "is_default": true,
            "magic_allowed": true,
            "name": "Patient.info",
            "position": 32,
            "sources": [
                {
                    "encrypted_url": "Patient.info",
                    "id": 6,
                    "name": "Patient.info",
                    "private": null,
                    "url": "https://patient.info/doctor/"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 7,
            "is_default": true,
            "magic_allowed": true,
            "name": "GP Notebook",
            "position": 33,
            "sources": [
                {
                    "encrypted_url": "GP notebook",
                    "id": 7,
                    "name": "GP Notebook",
                    "private": null,
                    "url": "https://gpnotebook.com"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 5,
            "is_default": true,
            "magic_allowed": true,
            "name": "SIGN Guidelines",
            "position": 34,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABilObmOrsvO3l-V4iEE-fILic1lyVn1v3tNKIMkWNee-3kImOShGL9otB4QTdBwMTeNFiS2sx8G6moWXR2taThIyFs6-YrJeYwUJxFNdLiXuH_Ngf7DMp2DBEuaNYYlUQ8_14i",
                    "id": 5,
                    "name": "SIGN Guidelines",
                    "private": null,
                    "url": "https://www.sign.ac.uk/our-guidelines/"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 4237798049448030,
            "is_default": true,
            "magic_allowed": true,
            "name": "Scottish Palliative Care Guidelines",
            "position": 35,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABioJx5uI3h90fKn9FVB_ZeA-nSYLIZlzeOlVkNwZAHp0aGJYevS2IKoUd561GuKrBnEVjHpfVDp1WoNh13Nb_U1cppD3niTmFRJeVFtmU0mYOkVcyLDgWX6q1RrnVd3DyiRF4r2cRq48rXrjCPj56CywiI_Q==",
                    "id": 17,
                    "name": "Scottish Palliative Care",
                    "private": null,
                    "url": "https://www.palliativecareguidelines.scot.nhs.uk/"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 10,
            "is_default": true,
            "magic_allowed": true,
            "name": "GP Infant Feeding Network",
            "position": 36,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABioJ0FjSD0LB7GOD-FwiEmbG20xcJUrOqHLjxw205cDhgAWI31p4lx8LjrcFq8J2PZBHFlFUlDzATsaXS1_Lub6wrtPWUU4TInFWS0jTETL5uQD9E=",
                    "id": 10,
                    "name": "GP Infant Feeding Network",
                    "private": null,
                    "url": "https://gpifn.org.uk/"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 14,
            "is_default": true,
            "magic_allowed": true,
            "name": "British Thoracic Society",
            "position": 37,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABiVuqaDzZ-GYYmm0zAH-VYTxI0fUhCJxIb4qzi3PLtHrDH_KFusqBkPc9DkAx-lOKRjbc6ZqPeyjt9VjZzRzVVYoOAM-tr2rI_si-KFpyHuuHrXn82AqSDf8m_oGv4zPWoNwq1zwgkeOuigw5UQGVB5h8RmgsDrSWENpIXICN_FynpnUE=",
                    "id": 14,
                    "name": "British Thoracic Society",
                    "private": null,
                    "url": "https://www.brit-thoracic.org.uk/quality-improvement/guidelines/"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 8,
            "is_default": true,
            "magic_allowed": true,
            "name": "Lab Tests Online",
            "position": 38,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABioKHXxwgVcrQ6Rzs6pkuSCjAGo6Vuy9-JG6O8muQfUO-dxmAMybaAty7u7h2-yu85JkcuLbLkL-miE-dYVOdKlNAhERhQ6gd3MGOpJwVcnfe1yyk=",
                    "id": 8,
                    "name": "Lab Tests Online",
                    "private": null,
                    "url": "https://labtestsonline.org.uk/"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 16,
            "is_default": true,
            "magic_allowed": true,
            "name": "Life in the Fast Lane",
            "position": 39,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABijgOlJr4siI6iUMPJZsiaI4YgpnquE45terIsYVmNPumak5J_eylHbQVVT2N1-DScNO7UQ3k_-npNJ1mDzItbACc75sxoJNbE0evhOgb2bVinQrE=",
                    "id": 16,
                    "name": "Life in the Fast Lane",
                    "private": null,
                    "url": "https://litfl.com/"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 18,
            "is_default": true,
            "magic_allowed": true,
            "name": "NB Medical Summaries",
            "position": 40,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABioJlxo01wVz37-39mHMUh4ReexsPiz8YsUlIkY_yQ5oMBiVjRQKz6mY7snJZm42OqGwVeF62F1p19B2bovcBHzy_su-aNsS7XNo1u7G0oeuph0eo=",
                    "id": 18,
                    "name": "NB Medical Summaries",
                    "private": null,
                    "url": "https://www.nbmedical.com/kiss"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 9,
            "is_default": true,
            "magic_allowed": true,
            "name": "MD Calc",
            "position": 41,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABioJw05CF886qL5B_MOvp9YurjQYCIyE7vmECq90L7xVHVxq1F64kIRBkcpdbRESb_GrIag-nlinQ0JsIFczG7-2FURBAq15WVwmIVH8m8ZsETJKU=",
                    "id": 9,
                    "name": "MD Calc",
                    "private": null,
                    "url": "https://www.mdcalc.com/"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 19,
            "is_default": false,
            "magic_allowed": true,
            "name": "Medscape",
            "position": 42,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABiwrZccrjJWqY3gO9RFEPLd58WNECZY47G-D6hfSWZc1A5DWcDUfshgmEoP1WrVQsOU8msrRTJSFYrf3VqFGQtJmw-EVQs0-zdB6DHYMq11e7uvxE=",
                    "id": 19,
                    "name": "Medscape",
                    "private": null,
                    "url": "https://emedicine.medscape.com/"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Medwise",
            "id": 5162286106017890,
            "is_default": true,
            "magic_allowed": true,
            "name": "BNF",
            "position": 44,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABkdfhMwKGf41sOtmiJ3FrcUtVlIx6fKfFrbY4YKDnLpndi01SDmbES8QZq5gJX2lHdK-WxKMVdtzJPWq_PsKRqoUmud3OYfSgLB8qRsq5V8Q_-S0E=",
                    "id": 3,
                    "name": "BNF",
                    "private": null,
                    "url": "https://bnf.nice.org.uk"
                }
            ],
            "toggle_type": "Medwise"
        },
        {
            "entity_type": "Org",
            "id": 2128962178285662,
            "is_default": true,
            "magic_allowed": true,
            "name": "MSD Manuals",
            "position": 43,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABjwTpWbr78hyUHKcCEVq4qhNQZSxhwoxUf7jHvUkyrEUnNczxE81KWsJDPifi2ODytD3SJJcobXUinyy7GnBaBqB4KG6vls0D0rrUFoy7ASalnuGTe3M0HnpgKW7Nfsb9pjsFu",
                    "id": 2128910996373598,
                    "name": "MSD Manuals",
                    "private": null,
                    "url": "https://www.msdmanuals.com/en-gb/professional"
                }
            ],
            "toggle_type": "Medwise"
        }
    ],
    "Patient": [
        {
            "entity_type": "Org",
            "id": 4237946001129566,
            "is_default": false,
            "magic_allowed": true,
            "name": "NHS Conditions",
            "position": 0,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9Pn1GlSRfwm38jh153XLNQxJFtavwEZK9pw_iZuUqYXVQRo_2h_6ZZMkkWCsDNKJZZfuXnxEc6DpNG2ObqLxDK4fsaC2qjU_KVgt4e3-eKDql3I=",
                    "id": 4234623220121694,
                    "name": "NHS Conditions",
                    "private": null,
                    "url": "https://www.nhs.uk/conditions/"
                }
            ],
            "toggle_type": "Patient"
        },
        {
            "entity_type": "Org",
            "id": 4238080886079582,
            "is_default": false,
            "magic_allowed": true,
            "name": "NHS Inform · Illnesses and Conditions",
            "position": 1,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9PoNC36wjOMW56UeHCO1FSc9ppi46ffuMmWX1d0IoK-FBfKjFZ05K6FO_JGnRLRaIYfmjZWArbbq0UpLdR80Ror3rej9aupedhK7YMVNHzHNVOgUj1SCw_FFfWhUd1BJL3nxatz4TuhHZJFmwlSJsKtyfQ==",
                    "id": 4234702747533406,
                    "name": "NHS Inform · Illnesses and Conditions",
                    "private": null,
                    "url": "https://www.nhsinform.scot/illnesses-and-conditions"
                }
            ],
            "toggle_type": "Patient"
        },
        {
            "entity_type": "Org",
            "id": 4238117717409886,
            "is_default": false,
            "magic_allowed": true,
            "name": "NHS Inform · Self-Help Guides",
            "position": 2,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9Pog6Ku-oYUE2dBeHSnlea4jiDiNMzg5lfZOTvkp3GS7LXslA3kUA3f6iRCJ9R-V5D_lJc-blF8UwsREODNVlSYfmoEYonP5KzmNadKORYp1vlzM7Uq7MK9KWwcCZ6-Hfe_d",
                    "id": 4234764214141022,
                    "name": "NHS Inform · Self-Help Guides",
                    "private": null,
                    "url": "https://www.nhsinform.scot/self-help-guides"
                }
            ],
            "toggle_type": "Patient"
        },
        {
            "entity_type": "Org",
            "id": 5915578296729694,
            "is_default": false,
            "magic_allowed": true,
            "name": "Asthma UK",
            "position": 3,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9Poy_g9mKPnx9w2gGRM_cd9ETf4qh2GhytVRd5ChVz9qyuP2VTxTzf4k1os5YQUbBWj8riRUZeR1pQWmYhcSzM1ddkGt2a6suAZeUgVzSQvSODZpf4BccYgsBqzRIfW7Cvhj",
                    "id": 4234823857340510,
                    "name": "Asthma + Lung UK",
                    "private": null,
                    "url": "https://www.asthma.org.uk/advice/"
                }
            ],
            "toggle_type": "Patient"
        },
        {
            "entity_type": "Org",
            "id": 5915633957339230,
            "is_default": false,
            "magic_allowed": true,
            "name": "British Lung Foundation",
            "position": 4,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9PpC3h0VDvL_FAwgfFnsNfG5kjTcxvFHPyagGFFajPyme9L_yHmVj5pSDbduZSdlfABGLnp-dQsqu8FwV8lAH01eYV3CSSdEZ4fXBP-aCYq1zdpzbfpM6SnIT7xQfllf-uQF",
                    "id": 4234873912524894,
                    "name": "British Lung Foundation",
                    "private": null,
                    "url": "https://www.blf.org.uk/support-for-you"
                }
            ],
            "toggle_type": "Patient"
        },
        {
            "entity_type": "Org",
            "id": 5915675689353310,
            "is_default": false,
            "magic_allowed": true,
            "name": "Cancer Research UK",
            "position": 5,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9PpRydHSNnLTaiyQQ_T3nN9pVjcCxriUQ3i4kHNMoPcGlD53w4RHXu_233I7bVKQPRx21Pw9rNuYR-JVGvEp1wnxk2vRDBdjzxdlimSJKd4cg1Dw1Kw7vXJ2XvbDRrubq7oZ",
                    "id": 4234924353618014,
                    "name": "Cancer Research UK",
                    "private": null,
                    "url": "https://www.cancerresearchuk.org/about-cancer"
                }
            ],
            "toggle_type": "Patient"
        },
        {
            "entity_type": "Org",
            "id": 5915719877722206,
            "is_default": false,
            "magic_allowed": true,
            "name": "Breast Cancer Now",
            "position": 6,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9Pphm7n5nYQlTA2n7txmzIFOgZu0KuZ6hi6LAPHaZoHBGYgQDplVUxKxjuklDwON6gj40rUtLED4cC-W5zq-UMAXh03WBNp3GKC-Zn_EPpf25yCR_daUG_-CtGziR9yYJ0cw",
                    "id": 4234977247592542,
                    "name": "Breast Cancer Now",
                    "private": null,
                    "url": "https://breastcancernow.org/information-support"
                }
            ],
            "toggle_type": "Patient"
        },
        {
            "entity_type": "Org",
            "id": 5915765523906654,
            "is_default": false,
            "magic_allowed": true,
            "name": "Prostate Cancer UK",
            "position": 7,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9PpwIQK0_-fySLAOhY9vAG9aTlZ7MhWhKORZb0XakNWLpzUIiMdAkTc-bM2nOmHfPwAZC5aHvzeECtQ9VVyWZ2q4EQvbxLKo0IjbIW2IWniIPE2YExAQ8fmGlmP4r1akh5R39yYtYpF-6qsM8wkLJn5_Kg==",
                    "id": 4235024380100702,
                    "name": "Prostate Cancer UK",
                    "private": null,
                    "url": "https://prostatecanceruk.org/prostate-information"
                }
            ],
            "toggle_type": "Patient"
        },
        {
            "entity_type": "Org",
            "id": 5915807153029214,
            "is_default": false,
            "magic_allowed": true,
            "name": "Crohn’s & Colitis UK",
            "position": 8,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9Pp9agXbaS20GlheyXOu7PR908dmCq8MBtKx_QWH8pFBjQd1G21vKrZR1twcFIlsJRasD4omX_FarHL30mT2rAA7-NsSJwwApMHTeQqggjlugJTC9NhxZGWua_pyncoAyBkCl2nYxWLgjbUt_OblAXm2zbeOd6xI6L-k36HBEdqWw9rjYELwdUlHVGue3Tfp_4sy",
                    "id": 4235069536469086,
                    "name": "Crohn’s &amp; Colitis UK",
                    "private": null,
                    "url": "https://crohnsandcolitis.org.uk/info-support/information-about-crohns-and-colitis"
                }
            ],
            "toggle_type": "Patient"
        },
        {
            "entity_type": "Org",
            "id": 5915845225742430,
            "is_default": false,
            "magic_allowed": true,
            "name": "Epilepsy Society",
            "position": 9,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9PqNT78-U0ECtNlmZf7_D_vwqlSIC0fF917x3-pXJtMG_r0Df1hUdoexiUlUKf6rlTwfOttRi8vmdLQkRV-Z_WN4jnwSC0n5G2dRFUqbuXT3tTLgsdib1BjM-01c26ofandt",
                    "id": 4235120913776734,
                    "name": "Epilepsy Society",
                    "private": null,
                    "url": "https://epilepsysociety.org.uk/about-epilepsy"
                }
            ],
            "toggle_type": "Patient"
        },
        {
            "entity_type": "Org",
            "id": 5915888680435806,
            "is_default": false,
            "magic_allowed": true,
            "name": "InfoKID",
            "position": 10,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9Pqf-mPX8qVwHmjMJ7-mfKK3YsrpbpvIiGdihmMF3XUQVeHkGIYxVvAMdiGbhxl2abHEV1J4kAmE6U_YkI0TsBTpul8hCnPQ9nG11DB_iSXzXds=",
                    "id": 4235180995444830,
                    "name": "InfoKID",
                    "private": null,
                    "url": "https://www.infokid.org.uk/"
                }
            ],
            "toggle_type": "Patient"
        },
        {
            "entity_type": "Org",
            "id": 5915930394460254,
            "is_default": false,
            "magic_allowed": true,
            "name": "Kidney Care UK",
            "position": 11,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9Pqv5tcgpj_VPtJVIafgnaRjKvtdADAA3nMr2I2-ATHO8znhFakibx6zdPSdZXyLYXe_F327_QgFvzBMOm1aoxKK47D2SOXWmdSNzTtK6VmuJK9WX1UQK5uGRBHTGI35uyC6flgVGrHp85-NwYt7Vwxn9g==",
                    "id": 4235230511824990,
                    "name": "Kidney Care UK",
                    "private": null,
                    "url": "https://www.kidneycareuk.org/about-kidney-health/"
                }
            ],
            "toggle_type": "Patient"
        },
        {
            "entity_type": "Org",
            "id": 5915997762486366,
            "is_default": false,
            "magic_allowed": true,
            "name": "National Kidney Federation",
            "position": 12,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9Pq_6wuTBSiCONI9X7RuJqjdKlqFh8GGG-4w7bppk8uUsP6bc6KB-QEXd6rAVqz1whzAZzFalL0N1EDa3Nxs8dgeind9If9e7musO0wlCdeRXHw=",
                    "id": 4235283348684894,
                    "name": "National Kidney Federation",
                    "private": null,
                    "url": "https://www.kidney.org.uk/"
                }
            ],
            "toggle_type": "Patient"
        },
        {
            "entity_type": "Org",
            "id": 5916045021446238,
            "is_default": false,
            "magic_allowed": true,
            "name": "My Stroke Guide",
            "position": 13,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9PriHi2tVrrUUkK4riN4qT4BrBnx0ruVrB-cSwoaorkzf4QGFSa7taZ55Q9zJLzpskJTqjHeHV2PD0rTnWsPSOMO4o53ObvWJZrxTxnBSQwFqqjuZgEz4BkliWOjIWeYhz89fDDMY1QKgTqbmtNddBBxOg==",
                    "id": 4235398581682270,
                    "name": "My Stroke Guide",
                    "private": null,
                    "url": "https://mystrokeguide.com/information-and-advice"
                }
            ],
            "toggle_type": "Patient"
        },
        {
            "entity_type": "Org",
            "id": 5916090802503774,
            "is_default": false,
            "magic_allowed": true,
            "name": "European Urology Association",
            "position": 14,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABj9Prxw2mcZH8qGR-xA5iu6_kr1NNoh8FY3LzQusOATNcHR1Y37n2s7c4SgQnCx-kX_3zEEDbDVlYRxAsLWfPbbrcdX_lVKlJyYGS1ydKtQDp8JUQ=",
                    "id": 4235449904496734,
                    "name": "European Association of Urology",
                    "private": null,
                    "url": "https://patients.uroweb.org/"
                }
            ],
            "toggle_type": "Patient"
        }
    ],
    "Web": [
        {
            "id": 0,
            "is_default": true,
            "magic_allowed": true,
            "name": "Web",
            "position": -1,
            "sources": [
                {
                    "encrypted_url": "custom:gAAAAABiMGJJQdUGog4lZd2jqr0jk8DZmQvBh139L5aOzVrh7ZZ76xX8uSQkkGPqznXJ7jZV-sft8U8ZZfnX0xNSK804FIc9gT6ckX1xqp7fOU35M9-sKyQ=",
                    "id": 0,
                    "name": "Web",
                    "url": null
                }
            ],
            "toggle_type": "Web"
        }
    ],
    "filter-types": [
        "Medwise",
        "Local",
        "Patient",
        "Web"
    ],
    "reverse-mapping": {
        "Local": "Local",
        "Medwise": "Medwise",
        "Patient": "Patient",
        "Web": "Web"
    },
    "sidebar-order": [
        "Local",
        "Medwise",
        "Patient"
    ]
}
  const markdownExample = "#  Markdown ";

  const [value, setValue] = useState("Tittle");
  const [titleValue, setTitleValue] = useState("Tittle");
  const [JSONValue, setJSONValue] = useState(jsonExample);
  const [displayJSONValue, setDisplayJSONValue] = useState(JSON.stringify(JSONValue,undefined,4));
  const [markdown ,setMarkdown] = useState(markdownExample);
  const [displayMarkdown ,setDisplayMarkdown] = useState(markdownExample);

  const [computedHtml, setComputedHtml] = useState("<h1 >Sample Markdown</h1>");
  const [displayHtml, setDisplaydHtml] = useState("<h1 >display Markdown</h1>");

  const [isLoading, setIsLoading] = useState(false);

  

  const md = new Remarkable();
  

  const autofocusNoSpellcheckerOptions = useMemo(() => {
    return {
      minHeight : '300px',
      autofocus: true,
      spellChecker: false,
      sideBySideFullscreen: false,
      toolbar: ["bold", "italic", "heading", "|", "quote",'unordered-list', 'ordered-list', 'link', 'image']
    //   toolbar: false
    } ;
  }, []);
  const options2 = useMemo(() => {
    return {
      autofocus: true,
      spellChecker: false,
      // minHeight: '150px',
      toolbar: false,
    } ;
  }, []);


  useEffect(()=>{
   setDisplaydHtml(md.render(markdown));

  },[markdown,displayMarkdown]); 


  const handleUpdateHtml= () =>{
    setIsLoading(true);
    axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        url:`${window.BASE_URL_ASK}complete_html`,    
        data: JSON.stringify({
          'title':titleValue,
          "html_content": displayHtml,
          "markdown" : displayMarkdown,
        }), 
        withCredentials: true,
      })
        .then((res) => {
          setIsLoading(false);
          if (res.data['machine_generated_html'] === "string" ){
           setComputedHtml(res.data['machine_generated_html'] );
          }
  
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("error could not get html");
        });
  };

  const handleUpdateMarkdown= () =>{
    setIsLoading(true);
    axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        url:`${window.BASE_URL_USER}complete_markdown`,    
        data: JSON.stringify({
         'title':titleValue,
          "html_content": displayHtml,
          "markdown" : displayMarkdown,
        }), 
        withCredentials: true,
      })
        .then((res) => {
          setIsLoading(false);
         
  
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("error could not get html");
        });
  };

  return (
    <Styles>
      <Header withSearchBar={false} withAccountButtons={true} />
      <main>
        <Container>
          <Row  className='mt-4 pt-0' style={{"height":"4vh"}}>
            <Col md={12} className='h-100'  >
            <div style={{margin:'20px'}}> 
             <input 
                type='text' 
                value={titleValue}
                style={{"font-size":"22px",'font-weight':'600','border':'none', "backgroundColor":'#FBFBFD'}}
                onChange={(e)=>{ setTitleValue( e.target.value)}} 
                /> 
            </div>
            </Col>
          </Row>
          <Row  className='mt-3 pt-3' style={{"height":"30vh"}}>
            <Col md={12} className='h-100'  >
                <textarea 
            type='text' 
            style={{"height":"80%", "width":'100%', "backgroundColor":'#FBFBFD', }} 
            onChange={(e)=>{ setJSONValue(JSON.parse (e.target.value))}} 
            value ={JSON.stringify(JSONValue)}>
            </textarea>
            
            </Col>
          </Row>
          <Row>
            <Col md={2}><button type="button" className="close" aria-label="Close" onClick={ ()=> handleUpdateHtml()}>
            Update html 
          </button></Col>
          <Col md={2}><button type="button" className="close" aria-label="Close" onClick={ ()=> handleUpdateMarkdown()}>
            Update markdown
          </button></Col>
          
          </Row>
          <Row className='mt-4'>
            <Col md={6} className="editor">
                {/* <div style={{margin:'10px'}}><H1>MarkDown</H1></div> */}
              {/* <div style={{marginTop:'0px', 'display':'block','height':'30vh', 'border':'1px solid grey ', 'overflow':"auto" }}  >
                {markdown}
              </div> */}
              <textarea type='text' 
                style={{"height":"80%", "width":'100%', "backgroundColor":'#FBFBFD' }} 
                onChange={(e)=>{ setMarkdown( e.target.value); }} 
                value ={markdown}
                disabled={isLoading}
            ></textarea>


              {/* <SimpleMDE options={autofocusNoSpellcheckerOptions}
              value={value}
              onChange={onChangeHandler} />
               */}
              {/* <CantFindFeedback /> */}
            </Col>

            <Col md={6} className="editor">
            {/* <div style={{margin:'10px'}}><H1>HTML</H1></div> */}
              <div 
              style={{  'display':'block',
                        'marginTop':'0px',
                        'padding':'5%',
                        'height':'calc( 100% - 37px)',
                        'min-height':'366px',
                        // 'height':'auto',

                        'border':'1px solid #ced4da ',
                        'overflow':"auto", 
                        'border-radius':"3px",
                        'margin-bottom':"37px", 
                        'padding-right':"15px",
                        'padding-left':"15px",
                     }} 
              dangerouslySetInnerHTML={{__html: displayHtml}} />
              
              {/* <CantFindFeedback /> */}
            </Col>

          </Row>

        </Container>
      </main>

      <Footer sticky={ false} />

    </Styles>
  );
};

export default EditorPage;
