import styled, { css } from 'styled-components';
import { linkStyles } from '../theme/link';
import { isMobile } from 'react-device-detect';
import { Span } from '../theme/typography';

export const Article = styled.article`
&:not(:last-of-type) {
  margin-bottom: 30px;
  @media (max-width: 767.98px) {
    margin-bottom: ${({ theme }) => theme.spacing.x1};
  }
}
.error{
  background:red;
}

.chat-container {
  margin-bottom: 64px;
}

.spacing {
  margin-top: 10px;
  margin-bottom: 5px;
 }

.main-result-body {
  // display: flex;
  position: relative;
}

.pdf-preview-container {
  margin-left: 16px;
  cursor: zoom-in;
  height: 141px;
  width: 100px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  float: right;
}

.pdf-preview-container1 {
  margin-left: 16px;
  cursor: zoom-in;
  height: 100px;
  width: 141px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  float: right;
}

.pdf-mb {
  margin-bottom: 16px;
}

.pdf-preview {
  // border: 1px solid #4d5156;
  border: 0;
  pointer-events: none;
}

.list-bookmarks-item{
  border:solid 1px #E2E5ED;
  cursor: default;
}
.downloadWrapper{
  display:inline;
  // margin-left:8px
}
.mobileDate{
  color: grey;
  padding-right: 2px;
  font-size: 12px;
  line-height: 14px;
}
.card {
  
  position: relative;
  background-color: ${({ theme }) => theme.palette.backgroundWhite};
  border: 1px solid ${({ theme }) => theme.palette.backgroundStroke};
  ${isMobile ? 'border-left-width: 0.0;' : ''}
  ${isMobile ? 'border-right-width: 0.0;' : ''}
  border-radius: ${({ theme }) => theme.borderradius.medium};
  ${isMobile ? 'border-radius:0;' : ''}
  padding: 16px 35px 16px 16px;
  transition: all 0.3s;
  overflow: hidden;

  &:hover {
    border-color: ${({ theme }) => theme.palette.backgroundStrokeHover};
    box-shadow: 0 0 5px ${({ theme }) => theme.palette.backgroundStrokeHover};
    // cursor: pointer;

    & > .card_expandIcon {
      background-color: ${({ theme }) => theme.palette.blueLight};
      // cursor: pointer;
    }
  }
  .card_expandIcon:hover {
    // background-color: ${({ theme }) => theme.palette.blueLight};
    cursor: pointer;
  }

  @media (max-width: 767.98px) {
    padding: ${({ theme }) => theme.spacing.x2};
    overflow: hidden;
    ${isMobile ? 'margin:0px;' : 'margin:8px;'}
    /** overflow propery gets added on mobile because it prevents feedbackbox functionality */
  }

  &_override {
    background-color: #E9F0FC;
    border: 1px solid #1B5EF7;
    position: relative;
    padding: 24px 56px 24px 32px;
    transition: all 0.3s;
    cursor:default;
  }

  &_top {
    position:relative;

    .card_expandIcon {
      display: inline-flex;
      color: ${({ theme }) => theme.palette.textLink};
      transition: all 0.3s;
      position: absolute;
      left: calc(100% - 16px);
      top:-16px;
      bottom: -16px;
      // padding: ${({ theme }) => theme.spacing.x1};
      padding: 24px 24px 24px 6px;
      // margin: 21px ;
      margin-left: 21px;
      // border-radius: 50%;
      // height:100%;
      // color: #6447d6;

      svg{
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      &.expanded {
        display: none;
      }

      @media (max-width: 767.98px) {
        // background-color: ${({ theme }) => theme.palette.blueLight};
        padding: 6px;
        left: 97%;
        top: 2%;
      }
    }

    .card_expandIcon:hover {
      crusor:pointer;
      // color: #8e62f2 ;
      background: #E9F0FB;
      // color: ${({ theme }) => theme.palette.textLink};
    }

    .source_name{
      text-align: right;
      color: #02b1c8;
    }

    .card_footer {
      margin-top: 16px;
    }
  }

  &_header {
    margin-bottom: ${({ theme }) => theme.spacing.x2};
    &_mobile{
      margin-bottom:4px;
      a{
        display:block;
      }
    }

    @media (max-width: 767.98px) {
      // width: 92%;
    }

    a {
      ${linkStyles};
      display: inline;
    }
    .mobile_title a{
      font-size: 20px;
      line-height: 24px; 
      color: #1558d6;
    }
    .override_separator{
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 0;
      border-top:
      1px solid rgba(0,0,0,.1);
    }
  }

  &_header_left {
    width: calc(100% - 116px);
  }

  &_header_left1 {
    width: calc(100% - 157px); 
  }

  &_body {
    // min-height: ${({ theme }) => theme.spacing.x6};
    min-height: 63px;
    /** More info on line-clamp: https://css-tricks.com/almanac/properties/l/line-clamp/ */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    // margin-bottom: ${({ theme }) => theme.spacing.x2};
    max-height:50vh;

    @media (max-width: 767.98px) {
      /** Text color gradient: https://css-tricks.com/snippets/css/gradient-text/ */
      // background: -webkit-linear-gradient(
      //   ${({ theme }) => theme.palette.textOffblack},
      //   #e6e6e6
      // );
      // background-image: linear-gradient(
      //   ${({ theme }) => theme.palette.textOffblack},
      //   #e6e6e6
      // );
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      // -webkit-line-clamp: 4;
     // margin-bottom: ${({ theme }) => theme.spacing.x6};
      margin-bottom: ${({ theme }) => theme.spacing.x1};
    }

    &:hover {
      cursor: pointer;

      & > .card_expandIcon {
        background-color: ${({ theme }) => theme.palette.blueLight};
        // cursor: pointer;
      }
    }

    &.expanded {
      
      display: -webkit-box;
      -webkit-line-clamp: unset;
      display: inline;
      min-height: 63px;
      // max-height:60vh !important;
      // overflow: auto;
      // max-height: 500px !important;        

      @media (max-width: 767.98px) {
        background: unset;
        -webkit-text-fill-color: unset;
        -webkit-background-clip: unset;
      }

      .search_card_table_wrapper{
        max-height: 100%;
      }
    }
    &.undefined {

      .search_card_table_wrapper{
        max-height: 63px;
        @media (max-width: 767.98px) 
        {
          // adding max-height because line-clamp is not always respected by chrome
          max-height:88px;
          // display: -webkit-box;
          // overflow: hidden;
          // word-break: break-word;
          // -webkit-line-clamp: 3;
          // -webkit-box-orient: vertical;
        }
      }
      .search_card_table_wrapper_mobile{
        display:inline;
        @media (max-width: 767.98px) 
        {
          // adding max-height because line-clamp is not always respected by chrome
          max-height:88px;
          //added so clamp-line works on ios 
          display: -webkit-box;
        }
      }
    }
    a,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    ol,
    div,
    p,
    span {
      font-size: ${({ theme }) => theme.fontsize.desktop.p1};
      @media (max-width: 767.98px) {
        font-size: ${({ theme }) => theme.fontsize.mobile.p1};
      }
    }

    a {
      ${linkStyles};
      display: inline;
    }

    h2,
    h3 {
      font-weight: ${({ theme }) => theme.fontweight.semibold};
      color: ${({ theme }) => theme.palette.textOffblack};
    }

    ul,
    ol {
      list-style-type: initial;
      padding-left: ${({ theme }) => theme.spacing.x3};
    }      
  }

  .card_body_container {
    min-height: 63px;
  }

  .card_footer--feedback{
    padding-left:0;
    padding-top: 24px;
    display:flex;
    justify-content: end;
    color:#4D5156;
    font-family: Mulish,sans-serif;
    font-size: 12px;
    color: #71777e;
    line-height: 21px;
   
    .tooltip:hover{
      cursor: pointer;
      text-decoration: underline;
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  &_footer {
    display: flex;
    flex-flow: column nowrap;
    position: relative;

    // Button{
    //   margin-left:6px;
    // }
    &_1 {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      position: relative;
      width: 100%;

      @media (max-width: 767.98px) {
        flex-flow: column nowrap;
        align-items: flex-start;
      }

      &--external {
        position: relative;
        display: inline-flex;
        align-items: center;
        text-decoration: underline;

        @media (max-width: 767.98px) {
          margin: ${({ theme }) => theme.spacing.x1} 0 0 0;
        }
      }

      &--download {
        position: relative;
        display: inline-flex;
        align-items: center;
        text-decoration: underline;
        margin-left: 10px;
        @media (max-width: 767.98px) {
          margin: ${({ theme }) => theme.spacing.x1} 0 0 0;
        }
      }

      &--retrieved {
        margin-left: auto;

        @media (max-width: 767.98px) {
          margin: ${({ theme }) => theme.spacing.x2} 0 0 0;
        }
      }
    }
    .card_footer_2_expanded {
      display: block;
    }
    .cdp_container{
      width:90%;
      height:90%;
      display: inline-flex;
      justify-content: flex-start;
      margin-top: 24px;
      font-size: 15px;
      line-height: 19px;
      font-weight: bold;
      color: #8CA1AE;
      cursor:default;
    }
    .cpd_container{
      display:flex;
      align-items:center;
      @media (max-width: 767.98px) {
        display:none;
      }     
    }

    .share_container {
      position: relative;
      display: inline-flex;
      justify-content: flex-start;
      margin-top: 24px;
      font-size: 15px;
      line-height: 19px;
      font-weight: bold;
      color: #8CA1AE;
    }
    
    .share_container > svg {
      filter: invert(65%) sepia(8%) saturate(654%) hue-rotate(159deg) brightness(94%) contrast(92%);
    }
    
    .share_container:hover, .share_container:hover > svg {
      color: #25282B;
      filter: none !important;
    }
    
    .share_container_share_icon {
      margin-right: 5px;
    }

  .thumbs_up_vote {
    ${isMobile ? 'margin-right: 20px;' : 'margin-right: 15px;'}
  }

  .thumbs_down_vote:hover, .thumbs_up_vote:hover {
    //color: #8771DF; see https://codepen.io/sosuke/pen/Pjoqqp
    filter: invert(46%) sepia(76%) saturate(1413%) hue-rotate(221deg) brightness(93%) contrast(88%);
  }
  
  .thumbs_icon_unvoted {
  color: #97AAB6;
  filter: invert(69%) sepia(19%) saturate(106%) hue-rotate(160deg) brightness(94%) contrast(88%);
  }
  
  .share_card_popup {
    display: none;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #E2E5ED;
    box-sizing: border-box;
    color: #212452;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
    list-style: none;
    margin-left: -15px;
    margin-top: 30px;
    // padding: 15px;
    position: absolute;
    width: 154px;
    z-index: 10000;
  }
  
  
  .share_card_popup > ul > li {
    padding: 15px;
  }

    &_2 {
      display: none;

      &--feedback {
        ${isMobile ? 'width: 20%;' : 'width: 8%;'}
        float: right;
        position: relative;
        display: inline-flex;
        justify-content: flex-end;
        align-item:center;
        margin-top: ${({ theme }) => theme.spacing.x3};

        @media (max-width: 767.98px) {
          margin-top: ${({ theme }) => theme.spacing.x3};
        }
        
        @media (max-width: 1200px) {
          width: 20%;
        }

        &--chevron {
          width: 16px;
          height: 16px;
          color: ${({ theme }) => theme.palette.textPlaceholder};
          padding: 8px 0 0 8px;
        }
      }
    }
  }

}
`;