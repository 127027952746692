import axios from 'axios';

import React, {  useState, useEffect,useContext,  } from 'react';
import { getUser, setNumOfSearches, getNumOfSearches,setNumOfGroupSearches,updateUserSources, getDefaultSources, setNumOfBookmarks, setNumOfCpdEntries } from '../../functions/manageSessions';
import { AppContext } from '../../context';
import { H1,H2, P } from '../theme/typography';
import CPDCredits from './styles';
import { Col,Row ,Modal } from 'react-bootstrap';
import Button from '../button';


const CpdCreditAndTimeSave = (isAnonymous) => {
    // const [fetchedCPD,setFetchedCPD]=useState(0);
    // const [fetchedTimeSaved,setFetchedTimeSaved]=useState(undefined);
    const [fetchedNumSearches,setFetchedNumSearches]=useState(getNumOfSearches());

    // const [displayHours,setDisplayHours]=useState(true);
    const { fetchedResults ,userStats,searchContext,userId} = useContext(AppContext);
    const DELAY_SEARCHES = 1000; 
    const [timeOutId, setTimeOutId]= useState(undefined);
    const controller = new AbortController();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source(); 
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {  
        // do not run unless on search page
        let mounted = true;
        if( userStats && typeof userStats['num_searches'] !== undefined ){
        // if( userContext && typeof userContext['num_searches'] !== undefined ){  
            let timeId = setTimeout(function(){
                setFetchedNumSearches(userStats['num_searches']);
                if(userStats['num_searches'] !=0 && userStats['num_searches'] >= 2 && userStats['num_searches'] %4==0 && isAnonymous[isAnonymous]){
                    setShowModal(true);
                }
            },DELAY_SEARCHES);
            setTimeOutId(timeId);
        }
        return function cleanUp() {
            mounted = false; 
            if (typeof timeOutId !== "undefined"){
                clearTimeout(timeOutId);
            }
        }
        
    },[userStats]);


    const go_to_dashboard = () => {
        let user = getUser();
        if (user) {
            if (user['org-user-type'] === "admin") {
                window.location.replace(window.BASE_URL_DASHBOARD+'dashboard/org_summary.html');
            } else if (user['org-user-type'] === "magic") {
                window.location.replace(window.BASE_URL_DASHBOARD+'dashboard/users_feedback.html');
            } else {
                window.location.replace(window.BASE_URL_DASHBOARD+'dashboard');
            };
        } else {
            window.location.replace(window.BASE_URL_DASHBOARD+'dashboard/not_logged_in.html');
        };
    };

    const handleCloseModal = () => {
        setShowModal(false);
    }
    const handleClickModal = () => {
        setShowModal(false);
    }
    

  return (
    <CPDCredits>
        <Row className='cdpCredit'>
            <Col md={12} sm={12} data-tooltip="Go to Dashboard" data-tooltip-persistent onClick={() => go_to_dashboard()}>
               {(typeof fetchedNumSearches !== 'undefined') && ( <span>{fetchedNumSearches} </span>)}
                {/* <P  regular textoffblack className="title">
                {displayHours? 'Hours saved':'Minutes saved'}
                </P> */}
                {(fetchedNumSearches>1||fetchedNumSearches==0) && (<span>searches</span>)}
                {fetchedNumSearches==1 && (<span>search</span>)}
            </Col>  
        </Row>

      <Modal 
      show={showModal}
       onHide={handleCloseModal}
       centered
       style={{ overflow: "hidden" }}
        >
        <Modal.Header  style={{ height: "20%", border:'0px'}}>
          <Modal.Title style={{ width: "100%" , "text-align": "center"}}>
          <H2 h2>Please login or signup to keep searching: </H2> </Modal.Title>
        </Modal.Header>
       <Modal.Footer style={{padding:"12px" ,border:"0px", height: "80%"}}      
        >
        <Button
            type="link"
            className={'navigation_searchbar'}
            colorprimary
            buttonmedium
            to="/login"
            text="Log in"
            width="200px"
            onClick={handleClickModal}
        />
        <Button
            type="link"
            className={'navigation_searchbar'}
            colorprimary
            buttonmedium
            to="/signup"
            text="Sign up"
            width="200px"
            onClick={handleClickModal}

        />
        </Modal.Footer>
      </Modal>
    </CPDCredits>
  )
};

export default CpdCreditAndTimeSave;
