import React, { useMemo, useContext }from "react";
import { AppContext } from '../../context';

const PDFPreview = ({ data, isFullSize }) => {
  const { 
    userContext,
  } = useContext(
    AppContext
  );

  const previewUrl = useMemo(
    () => {
      if (data) {
        return data.replace("__PUBLIC_TOKEN__", userContext.search_params);
      }
    }, [data]
  );

  return (
    <iframe className="pdf-preview" style={{border: 'none', width: isFullSize && '100%', height: isFullSize && '100%'}} type="application/pdf" src={previewUrl} width={!isFullSize && "100"} height={!isFullSize &&"141"}></iframe>
  );
};

export default PDFPreview;