import React, { useState,useContext, useEffect, useMemo  } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import Button from '../../../button';
import { Span, Label, StyledLink,A } from '../../../theme/typography';
import {
  FormFieldWrapper,
  TextInput,
  RadioInput,
  Select,
  FormFieldRadio,
  ErrorMessage,
  FormConsentFieldWrapper,
} from '../../styles';
import { alignPropType } from 'react-bootstrap/esm/DropdownMenu';


const SignUpStepOneSoarBeyond = (props) => {

  const [roleDisable, setRoleDisable] = useState(true);
  const [showRole, setShowRole] = useState(false);
  const [showOtherInterest, setShowOtherInterest] = useState(false);
  const [showOtherSystem, setShowOtherSystem] = useState("");


  const [country, setCountry] = useState("");
  const [showSignUpLink, setShowSignUpLink] = useState(false);

  const INTERESTLIST = ["Diabetes","COPD and asthma","Dermatology","Pain management","Hypertension","CVD","Frailty in Older People","Men’s health","Women’s health","Heart Failure","Stoma","Overactive Bladder (OAB)","Anticoagulation","Other"];
  const ENGLANDCOUNTIES = ["Bedfordshire",
  "Berkshire",
  "Bristol",
  "Buckinghamshire",
  "Cambridgeshire",
  "Cheshire",
  "Cornwall",
  "Cumbria",
  "Derbyshire",
  "Devon",
  "Dorset",
  "Durham",
  "East Riding of Yorkshire",
  "East Sussex",
  "Essex",
  "Gloucestershire",
  "Greater London",
  "Greater Manchester",
  "Hampshire",
  "Herefordshire",
  "Hertfordshire",
  "Isle of Wight",
  "Kent",
  "Lancashire",
  "Leicestershire",
  "Lincolnshire",
  "London",
  "Merseyside",
  "Norfolk",
  "North Yorkshire",
  "Northamptonshire",
  "Northumberland",
  "Nottinghamshire",
  "Oxfordshire",
  "Rutland",
  "Shropshire",
  "Somerset",
  "South Yorkshire",
  "Staffordshire",
  "Suffolk",
  "Surrey",
  "Tyne and Wear",
  "Warwickshire",
  "West Midlands",
  "West Sussex",
  "West Yorkshire",
  "Wiltshire",
  "Worcestershire"];
  const WALESCOUNTIES = ["Anglesey",
  "Brecknockshire",
  "Caernarfonshire",
  "Carmarthenshire",
  "Cardiganshire",
  "Denbighshire",
  "Flintshire",
  "Glamorgan",
  "Merioneth",
  "Monmouthshire",
  "Montgomeryshire",
  "Pembrokeshire",
  "Radnorshire"];
  const SCOTLANDCOUNTIES = ["Aberdeenshire",
  "Angus",
  "Argyllshire",
  "Ayrshire",
  "Banffshire",
  "Berwickshire",
  "Buteshire",
  "Cromartyshire",
  "Caithness",
  "Clackmannanshire",
  "Dumfriesshire",
  "Dunbartonshire",
  "East Lothian",
  "Fife",
  "Inverness-shire",
  "Kincardineshire",
  "Kinross",
  "Kirkcudbrightshire",
  "Lanarkshire",
  "Midlothian",
  "Morayshire",
  "Nairnshire",
  "Orkney",
  "Peeblesshire",
  "Perthshire",
  "Renfrewshire",
  "Ross-shire",
  "Roxburghshire",
  "Selkirkshire",
  "Shetland",
  "Stirlingshire",
  "Sutherland",
  "West Lothian",
  "Wigtownshire"];
  const NORTHENIRELANDCOUNTIES = ["Antrim",
  "Armagh",
  "Down",
  "Fermanagh",
  "Londonderry",
  "Tyrone"];

  const ALLCOUNTIES =  ENGLANDCOUNTIES.concat(WALESCOUNTIES).concat(SCOTLANDCOUNTIES).concat(NORTHENIRELANDCOUNTIES)

  const getListOfCounties = (country_name)=>{
    let temCountryName= country_name;
    if (temCountryName.trim()===""){
      if(props.inputData && typeof props.inputData.country ==="string"){
        temCountryName = props.inputData.country;
      }
    }
    if(temCountryName === "England"){
      return ENGLANDCOUNTIES;
    }
    if(temCountryName === "Wales"){
      return WALESCOUNTIES;
    }
    if(temCountryName === "Scotland"){
      return SCOTLANDCOUNTIES;
    }
    if(temCountryName === "Northern Ireland"){
      return NORTHENIRELANDCOUNTIES;
    }
    return [];
  }
  
  var signupSchema = yup.object().shape({
    firstName: yup
      .string()
      .required('First name is required'),
    lastName: yup
    .string()
    .required('Last name is required'),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required(' Email address is required'),
    // telephone_number:  yup.number().typeError("That doesn't look like a phone number").positive("A phone number can't start with a minus").integer("A phone number can't include a decimal point").min(8)
    // .required('A phone number is required'),
    // alternative_email: yup
    //   .string()
    //   .email('Please enter a valid email address')
    //   .required('This field is required'),
    interest: yup.string().required('Interest area is required').oneOf(INTERESTLIST),
    telephone_number: yup.string().required('Phone number is required'),
    country: yup.string().required('is requires').oneOf(["England","Wales","Scotland","Northern Ireland"]),
    county: yup.string().required('County is required').oneOf( ALLCOUNTIES),
    pratice_organisation: yup.string().required(),
    practice_postcode : yup.string().required('Postcode is required'),
    GPhC_PSNI: yup.string().required('GPhC / PSNI number is required'),
    // pharmacist_role: yup.string().required('Role is required'),
    consent0: yup.boolean().oneOf([true]),
    consent: yup.boolean().oneOf([true]),
    consent2: yup.boolean().oneOf([true]),
    },);

  const { register, getValues, handleSubmit, errors, clearErrors } = useForm({
    mode: 'all',
    resolver: yupResolver(signupSchema),
  });


    // Since sign up is a multipage form, we need to make sure that the first
  // page is validated before letting the user navigate the second page.
  const handleStepOneNavigation = () => {
      var values = getValues([
        'email',
        'consent',
        'lastName', 
        'firstName', 
        'GPhC_PSNI',
        'interest',
        'otherInterest',
        'telephone_number',
        'country',
        'county',
        'pratice_organisation',
        'practice_postcode',
        'alternative_email',
        'it_system', // IT system
        'otherItSystem',
        'senior_pharmacist_or_clinical_pharmacist', // senior_or_clinical
        'time_in_practice',
        'role', // current_role
        'otherRole',
        'member_of_i2i'
      ]);
      let tempRole = values["role"];
      if(values["role"]=== "Other" ){
        tempRole = values["otherRole"]
      }
      let tempInterest =  values['interest'];
      if(values['interest']==="Other"){
        tempInterest = values["otherInterest"];
      }
      let tempSystem = values["it_system"]; 
      if(values['it_system'] === "Other"){
        tempSystem = values['otherItSystem'];
      }
      let tempValues = {
        'email':values['email'], 
        'consent':values['consent'],
        "lastName":values["lastName"],
        'firstName':values["firstName"],
        'pharmacy':values['GPhC_PSNI'],
        'interest':tempInterest,
        "telephone_number":values['telephone_number'],
        "country":values["country"],
        "county":values["county"],
        "pratice_organisation":values["pratice_organisation"],
        "practice_postcode":values['practice_postcode'],
        "alternative_email":values['alternative_email'],
        "it_system":tempSystem,
        "current_role":tempRole,
        "time_in_practice":values["time_in_practice"],
        "senior_pharmacist_or_clinical_pharmacist":values["senior_pharmacist_or_clinical_pharmacist"],
        "member_of_i2i":values["member_of_i2i"],

        }
      console.log("tempvalues",values);
      props.loginCallBack(tempValues);
      // props.loginCallBack(values['email'],
      //  values['consent'],values["lastName"],values["firstName"],values['GPhC_PSNI'],
      // values['interest'], values['telephone_number'],values["country"],values["county"],values["pratice_organisation"],values['practice_postcode'],
      // values['alternative_email'], 
      // values['it_system'], 
      // values["selectRole"],
      // values["time_in_practice"],
      // values["senior_pharmacist_or_clinical_pharmacist"],
      // values["role"]);
  };

  const isSeniorChecked  = useMemo(()=>{
    if ( props.inputData &&  props.inputData.senior_pharmacist_or_clinical_pharmacist === "false" ){
      return [false,true];
    }
    if ( props.inputData &&  props.inputData.senior_pharmacist_or_clinical_pharmacist === "true" ){
      return [true,false];
    }
    return [false,false];
  },[props.inputData]);

  const isi2iMember =  useMemo(()=>{
    if ( props.inputData &&  props.inputData.member_of_i2i === "false" ){
      setShowSignUpLink(true);
      return [false,true,false];
    }
    if ( props.inputData &&  props.inputData.member_of_i2i === "true" ){
      setShowSignUpLink(false);
      return [true,false,false];
    }
    if ( props.inputData &&  props.inputData.member_of_i2i === "Other" ){
      setShowSignUpLink(true);
      return [false,false,true];
    }
    return [false,false,false];
  },[props.inputData]);

  const memoRole=  useMemo(() => {
    if(props.inputData && typeof props.inputData['current_role'] === "string"  ){
      if( props.inputData.current_role !== "PCN clinical pharmacist" 
              &&  props.inputData.current_role !== "GP pharmacist" 
              &&  props.inputData.current_role !== "" 
              &&  props.inputData.current_role !== "Community pharmacist" 
              &&  props.inputData.current_role !== "Senior / Lead pharmacist" 
              &&  props.inputData.current_role !== "Secondary care pharmacist" ){
          setShowRole(true);
          return "Other";
      }
      else{
        setShowRole(false);
        console.log('something selected role',props.inputData.current_role);
        return props.inputData.current_role;
      }
    }else{
      return "";
    }
  }, [props.inputData])

  const memoInterest=  useMemo(() => {
    if(props.inputData && typeof props.inputData.interest === "string"  ){
      if( !INTERESTLIST.includes(props.inputData.interest)  ){
        setShowOtherInterest(true);
        return "Other";
      }
      else{
        setShowOtherInterest(false);
        return props.inputData.interest;
      }
    }else{
      return "";
    }
  }, [props.inputData]); 

  const memoSystem=  useMemo(() => {
    if(props.inputData && typeof props.inputData.it_system === "string"  ){
      if( props.inputData.it_system !== "EMIS" 
              &&  props.inputData.it_system !== "SystmOne" 
              &&  props.inputData.it_system !== "" 
              &&  props.inputData.it_system !== "Vision" 
              ){
          setShowOtherSystem(true);
          return "Other";
      }
      else{
        setShowOtherSystem(false);
        return props.inputData.it_system;
      }
    }else{
      return "";
    }
  }, [props.inputData])


  // useEffect (()=>{
  //   console.log('data', props.inputData);
  // },[props.inputData]);


  return (
    <div>
    <form onSubmit={handleSubmit(handleStepOneNavigation)}  id='email_org_submitted' >
    {/* part 1 of form  */}
    <div >
      {/*  Name */}
      <Row>
        <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
              First Name <span>*</span>
            </Label>
            <TextInput
              type="text"
              name="firstName"
              placeholder="Enter your first name"
              ref={register}
              isError={errors.firstName && (props.registerError && typeof props.registerError==="string" && props.registerError.toLowerCase().includes('first'))}
              defaultValue ={ props.inputData && props.inputData.firstName && typeof props.inputData.firstName === "string" ? props.inputData.firstName :''}
              key={ props.inputData && props.inputData.firstName && typeof props.inputData.firstName === "string" ?'firstName_' +props.inputData.firstName :'firstName'}
            />
            {errors.firstName && !props.registerError &&(
              <ErrorMessage c1 regular colorError>
                { errors.firstName && errors.firstName.message}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
          
        </Col>
      {/* Last Name row */}
        <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
            Last Name  <span>*</span>
            </Label>
            <TextInput
              key ={ props.inputData && props.inputData.lastName && typeof props.inputData.lastName === "string" ?"lastName"+props.inputData.lastName:'lastName'}
              type="text"
              name="lastName"
              // autoFocus 
              placeholder="Enter your last name"
              ref={register}
              isError={errors.lastName || (props.registerError && typeof props.registerError==="string" && props.registerError.toLowerCase().includes('last'))}
              defaultValue ={ props.inputData && props.inputData.lastName && typeof props.inputData.lastName === "string" ?props.inputData.lastName:''}
            />
            {errors.lastName && !props.registerError && (
              <ErrorMessage c1 regular colorError>
                { errors.lastName && errors.lastName.message}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
        </Col>
      </Row>
      {/* email row */}
      <Row>
        <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
              Email address  <span>*</span>
            </Label>
            <TextInput
              type="text"
              name="email"
              placeholder="Email address"
              ref={register}
              isError={errors.email || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('email'))}
              defaultValue ={ props.inputData && props.inputData.email && typeof props.inputData.email ?props.inputData.email:''}
            />
            {errors.email && !props.registerError && (
              <ErrorMessage c1 regular colorError>
                { errors.email && errors.email.message}
              </ErrorMessage>
           )}
            {typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('email') && (
              <ErrorMessage c1 regular colorError>
                { props.registerError}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
        </Col>
        <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
              Contact email address
            </Label>
            {/* <Label c1 textoffblack > (only required if you register with an nhs.net email address)</Label> */}

            <TextInput
              key ={props.inputData && props.inputData.alternative_email && typeof props.inputData.alternative_email ?'alternative_email'+props.inputData.alternative_email:'alternative_email'}
              type="text"
              name="alternative_email"
              // autoFocus 
              placeholder="Required with nhs.net emails"
              ref={register}
              isError={errors.alternative_email || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('alternative_email'))}
              defaultValue ={props.inputData && props.inputData.alternative_email && typeof props.inputData.alternative_email ?props.inputData.alternative_email:''}
            />
            {errors.alternative_email && !props.registerError (
              <ErrorMessage c1 regular colorError>
                { errors.alternative_email && errors.alternative_email.message}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
        </Col>
      </Row>
      {/* information */}
      <Row>
        <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
            Primary area of therapy interest:  <span>*</span>
            </Label>
            {/* <TextInput
              type="text"
              
              placeholder='Enter primary area of interest'
              ref={register}
              isError={errors.interest || props.registerError}
              defaultValue ={''}
            /> */}
            <Select 
            key = {'select_interest_'+props.inputData && typeof props.inputData.interest === "string"  && props.inputData.interest}
            className={'interest'+ props.inputData && typeof props.inputData.interest === "string" && props.inputData.interest }
            name= "interest"
            ref={register}
            // defaultValue ={ props.inputData && props.inputData.interest && typeof props.inputData.interest === "string" ? props.inputData.interest:''}
            defaultValue ={memoInterest}
            onChange={(e)=>setShowOtherInterest(e.target.value === "Other")}
            >
              <option value="" disabled>Select area of interest</option>
              <option value="Diabetes">Diabetes</option>
              <option value="COPD and asthma">COPD and asthma</option>
              <option value="Dermatology">Dermatology</option>
              <option value="Pain management">Pain management</option>
              <option value="Hypertension">Hypertension</option>
              <option value="CVD">CVD</option>
              <option value="Frailty in Older People">Frailty in Older People</option>
              <option value="Men’s health">Men’s health</option>
              <option value="Women’s health">Women’s health</option>
              <option value="Heart Failure">Heart Failure</option>
              <option value="Stoma">Stoma</option>
              <option value="Overactive Bladder (OAB)">Overactive Bladder (OAB)</option>
              <option value="Anticoagulation">Anticoagulation</option>
              <option value="Other">Other</option>

            </Select>
            {errors.interest && !props.registerError &&  (
              <ErrorMessage c1 regular colorError>
                { "Select an area of interest"}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
          {showOtherInterest &&(
          <FormFieldWrapper >
            <TextInput
            TextInput
            type="text"
            name="otherInterest"
            // autoFocus 
            placeholder="Please type your interest"
            ref={register}
            isError={errors.interest || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('interest'))}
            defaultValue ={props.inputData.interest && typeof props.inputData.interest === "string" && memoInterest === "Other" ?props.inputData.interest:''}
            key ={props.inputData.interest && typeof props.inputData.interest === "string" ?'other_interest'+props.inputData.interest+'_'+showOtherInterest:'other_interest'+showOtherInterest}
          />
            {errors.interest && !props.registerError && (
              <ErrorMessage c1 regular colorError>
                { errors.interest && errors.interest.message}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
          )}
        </Col>
        
      {/* Telephone number row */}
        <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
            Phone Number  <span>*</span>
            </Label>
            <TextInput
              type="text"
              name="telephone_number"
              key ={props.inputData && props.inputData.telephone_number && typeof props.inputData.telephone_number ?'telephone_'+props.inputData.telephone_number:'telephone'}

              // autoFocus 
              placeholder="Enter your telephone number"
              ref={register}
              isError={errors.telephone_number || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('telephone'))}
              defaultValue ={props.inputData && props.inputData.telephone_number && typeof props.inputData.telephone_number ?props.inputData.telephone_number:''}
            />
            {errors.telephone_number && !props.registerError && (
              <ErrorMessage c1 regular colorError>
                { errors.telephone_number && errors.telephone_number.message}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
        </Col>
        <Col md={6}>
          <FormFieldWrapper >
            <Label c1 semibold textoffblack>
              Country  <span>*</span>
            </Label>
            <Select 
            key ={ props.inputData  &&  props.inputData.country && typeof props.inputData.country === "string" ?'country'+props.inputData.country:'country'}
            name="country"
            defaultValue ={ props.inputData  &&  props.inputData.country && typeof props.inputData.country === "string" ?props.inputData.country:''}
            ref={register}
            onChange={(e)=>{setCountry(e.target.value)}}
            >
              <option value="" disabled>Select a Country</option>
              <option value="England">England</option>
              <option value="Wales">Wales</option>
              <option value="Scotland">Scotland</option>
              <option value="Northern Ireland">Northern Ireland</option>
            </Select>
            
            {errors.country && !props.registerError && (
              <ErrorMessage c1 regular colorError>
                { "Select a country"}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
        </Col>
      {/* county */}
        <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
            UK County or Council  <span>*</span>
            </Label>
            <Select 
            key ={props.inputData  && props.inputData.county && typeof props.inputData.county === "string" ?'county_'+props.inputData.county:'county'}
            name="county"
            defaultValue ={props.inputData  && props.inputData.county && typeof props.inputData.county === "string" ?props.inputData.county:''}
            ref={register}
            // onChange={(e)=>{setCounty(e.target.value)}}
            disabled={country === "" &&
             !(props.inputData  && props.inputData.country 
             && typeof props.inputData.country === "string" && props.inputData.country.trim() !== "")}
            >
              <option value="" disabled>Select a County</option>
              {getListOfCounties(country).map((county, i) => {
                return (
                  <option 
                    value= {county} 
                    key={"county_"+i+'_'+county }
                    >
                    {county}
                  </option>
                );
              })}
            </Select>
            {errors.country && !props.registerError && (
              <ErrorMessage c1 regular colorError>
                { "Select a County"}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
        </Col>
      {/* Pharmacist Role fields row */}
        <Col md={6}>
          <FormFieldWrapper >
            <Label c1 semibold textoffblack>
              Pharmacist Role
            </Label>
            <Select 
            name="role"
            ref={register}
            key={props.inputData && props.inputData.current_role && typeof props.inputData.current_role === "string" ?'selectRole_'+props.inputData.current_role : 'selectRole' }
            defaultValue ={memoRole }
            onChange={(e)=>{setShowRole(e.target.value === "Other") }}
            >
              <option disabled value="">Select a role</option>
              <option value="PCN clinical pharmacist">PCN clinical pharmacist</option>
              <option value="GP pharmacist">GP pharmacist</option>
              <option value="Community pharmacist">Community pharmacist</option>
              <option value="Senior / Lead pharmacist">Senior / Lead pharmacist</option>
              <option value="Secondary care pharmacist">Secondary care pharmacist</option>
              <option value="Other">Other</option>
            </Select>
            
            {errors.role && !props.registerError && (
              <ErrorMessage c1 regular colorError>
                { errors.role && errors.role.message}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
          {showRole &&(
          <FormFieldWrapper >
            <TextInput
            TextInput
            type="text"
            name="otherRole"
            // autoFocus 
            placeholder="Please type role"
            ref={register}
            isError={errors.role || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('role'))}
            defaultValue ={props.inputData.current_role && typeof props.inputData.current_role === "string" && memoRole === "Other" ?props.inputData.current_role:''}
            key ={props.inputData.current_role && typeof props.inputData.current_role === "string" ?'other_role'+props.inputData.current_role+'_'+showRole:'other_role'+showRole}
          />
            {errors.role && !props.registerError && (
              <ErrorMessage c1 regular colorError>
                { errors.role && errors.role.message}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
          )}
        </Col>
      {/* Practice or Organisation */}
        <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
            Practice or Organisation  <span>*</span>
            </Label>
            <TextInput
              type="text"
              name="pratice_organisation"
              key ={ props.inputData && props.inputData.pratice_organisation && typeof props.inputData.pratice_organisation === "string" ?'practice_'+props.inputData.pratice_organisation:'practice'}
              placeholder="Practice / Organisation"
              ref={register}
              isError={errors.pratice_organisation || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('pratice'))}
              defaultValue ={ props.inputData && props.inputData.pratice_organisation && typeof props.inputData.pratice_organisation === "string" ?props.inputData.pratice_organisation:''}
            />
            {errors.ropratice_organisationle && !props.registerError && (
              <ErrorMessage c1 regular colorError>
                { errors.pratice_organisation && errors.pratice_organisation.message}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
        </Col>
      {/* Practice Postcode */}
        <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
             Practice Postcode  <span>*</span>
            </Label>
            <TextInput
              type="text"
              name="practice_postcode"
              placeholder="Practice postcode"
              key ={props.inputData && props.inputData.practice_postcode && typeof props.inputData.practice_postcode === "string" ?'practice_postcode_'+props.inputData.practice_postcode:'practice_postcode'}
              ref={register}
              isError={errors.practice_postcode || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('postcode'))}
              defaultValue ={props.inputData && props.inputData.practice_postcode && typeof props.inputData.practice_postcode === "string" ?props.inputData.practice_postcode:''}
            />
            {errors.practice_postcode && !props.registerError && (
              <ErrorMessage c1 regular colorError>
                { errors.practice_postcode && errors.practice_postcode.message}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
        </Col>
        <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
              GPhC / PSNI Number  <span>*</span>
            </Label>
            <TextInput
              type="text"
              name="GPhC_PSNI"
              key ={ props.inputData && props.inputData.pharmacy && typeof props.inputData.pharmacy === "string" ?"pharmacy_num_"+props.inputData.pharmacy:'pharmacy_num'}
              placeholder="GPhC/PSNI Number"
              ref={register}
              isError={errors.GPhC_PSNI  || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('GPhC_PSNI'))}
              defaultValue ={ props.inputData && props.inputData.pharmacy && typeof props.inputData.pharmacy === "string" ?props.inputData.pharmacy:''}
              // value={props.email}
              // onChange={()=>clearErrors()}
            />
            {errors.GPhC_PSNI && !props.registerError &&(
              <ErrorMessage c1 regular colorError>
                { errors.GPhC_PSNI && errors.GPhC_PSNI.message}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
        </Col>
        {/* time in general pratice  */}
        <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
            Time in General Practice
            </Label>
            <Select 
            name="time_in_practice"
            ref={register}
            key ={ props.inputData && props.inputData.time_in_practice && typeof props.inputData.time_in_practice === "string" ?"time_in_practice"+props.inputData.time_in_practice:"time_in_practice"}
            defaultValue ={ props.inputData && props.inputData.time_in_practice && typeof props.inputData.time_in_practice === "string" ?props.inputData.time_in_practice:''}
            >
              <option value="" disabled>Select a time period</option>
              <option value="Less than 1 year">Less than 1 year</option>
              <option value="1 - 2 years">1 - 2 years</option>
              <option value="2 - 5 years">2 - 5 years</option>
              <option value="5 - 10 years">5 - 10 years</option>
              <option value="10 - 15 years">10 - 15 years</option>
              <option value="15 - 20 years">15 - 20 years</option>
              <option value="More than 20 years">More than 20 years</option>

            </Select>
          </FormFieldWrapper>
        </Col>
        {/* are you a senior */}
        <Col md={6}>
          <FormFieldRadio >
            <Label c1 semibold textoffblack>
             Are you a senior pharmacist? 
            </Label>
              <Label className="radio">
              <RadioInput
                key={'radioNotSeniorYes'+isSeniorChecked[0]}
                defaultChecked={ isSeniorChecked[0]}
                type="radio" name="senior_pharmacist_or_clinical_pharmacist" value={"true"}
                ref={register}
                isError={errors.radio || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('senior'))}
              />Yes
              </Label>

              <Label className="radio">
              <RadioInput
                key={'radioNotSeniorNo'+isSeniorChecked[1]}
                defaultChecked={ isSeniorChecked[1]}
                type="radio" name="senior_pharmacist_or_clinical_pharmacist" value={"false"}
                ref={register}
                isError={errors.radio || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('senior'))}
              />
                No
              </Label>
            

            {(props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('senior')) && (
              <ErrorMessage c1 regular colorError>
                { props.registerError}
              </ErrorMessage>
           )}
          </FormFieldRadio>
        </Col>
        <Col md={6}>
        <FormFieldWrapper >
            <Label c1 semibold textoffblack>
              Which IT system do you use?
            </Label>
            <Select 
             name="it_system"
             key ={ props.inputData && props.inputData.it_system && typeof props.inputData.it_system === "string" ?"it_system_"+props.inputData.it_system:'it_system'}
             defaultValue ={ memoSystem}
            ref={register}
            onChange={(e)=>{setShowOtherSystem(e.target.value === "Other") }}
            >
              <option disabled value="">Select a system</option>
              <option value="EMIS">EMIS</option>
              <option value="SystemOne">SystemOne</option>
              <option value="Vision">Vision</option>
              <option value="Other">Other</option>
            </Select>
            
            {errors.it_system && !props.registerError && (
              <ErrorMessage c1 regular colorError>
                { errors.it_system && errors.it_system.message}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
          {showOtherSystem &&(
          <FormFieldWrapper >
            <TextInput
            TextInput
            type="text"
            name="otherItSystem"
            placeholder="Please type IT system"
            ref={register}
            isError={errors.it_system || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('system'))}
            defaultValue ={props.inputData.it_system && typeof props.inputData.it_system === "string" && memoSystem === "Other" ?props.inputData.it_system:''}
            key ={props.inputData.it_system && typeof props.inputData.it_system === "string" ?'other_system'+props.inputData.it_system+'_'+showOtherInterest:'other_system'+showOtherInterest}
          />
            {errors.it_system && !props.registerError && (
              <ErrorMessage c1 regular colorError>
                { errors.it_system && errors.it_system.message}
              </ErrorMessage>
           )}
          </FormFieldWrapper>
          )}
          {/* <FormFieldWrapper>
            <Label c1 semibold textoffblack>
             Which IT system do you use?
            </Label>
            <TextInput
              type="text"
              name="it_system"
              key ={ props.inputData && props.inputData.it_system && typeof props.inputData.it_system === "string" ?"it_system_"+props.inputData.it_system:'it_system'}
              // autoFocus 
              placeholder=""
              ref={register}
              // isError={errors.role || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('role'))}
              defaultValue ={ props.inputData && props.inputData.it_system && typeof props.inputData.it_system === "string" ?props.inputData.it_system:''}
              />
          </FormFieldWrapper> */}
        </Col>
        <Col md={6}>
          <FormFieldRadio >
            <Label c1 semibold textoffblack>
             Are you already a member of i2i network? 
            </Label>
              <Label className="radioi2">
              <RadioInput
                onChange={()=>setShowSignUpLink(false)}

                key={'member_of_i2i'+isi2iMember[0]}
                defaultChecked={ isi2iMember[0]}
                type="radio" name="member_of_i2i" value={"true"}
                ref={register}
                isError={errors.radio || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('i2i'))}
              />Yes
              </Label>

              <Label className="radioi2">
              <RadioInput
                key={'member_of_i2i'+isi2iMember[1]}
                defaultChecked={ isi2iMember[1]}
                type="radio" name="member_of_i2i" value={"false"}
                ref={register}
                isError={errors.radio || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('i2i'))}
                onChange={()=>setShowSignUpLink(true)}

              />
                No
              </Label>
              <Label className="radioi2_dontknow">
              <RadioInput
                onChange={()=>setShowSignUpLink(true)}

                key={'member_of_i2i'+isi2iMember[2]}
                defaultChecked={ isi2iMember[2]}
                type="radio" name="member_of_i2i" value={"Other"}
                ref={register}
                isError={errors.radio || (props.registerError && typeof props.registerError === "string" &&  props.registerError.toLowerCase().includes('i2i'))}
              />Don't know
              </Label>
            

            {showSignUpLink &&(
              <div key={'link_'+showSignUpLink}>
               <Span
               c2
               regular
             > 
              You can sign up to i2i {' '}
               <A textlink="true" href="https://www.i2ipharmacists.co.uk/become-a-member/" target="_blank" rel="noopener noreferrer">
               here.
             </A>
             </Span>
             </div>
           )}
          </FormFieldRadio>
        </Col>
      </Row>
      {/* consent row  */}
      <Row>
        <Col md={12}>
          <FormConsentFieldWrapper style={{'marginBottom':'2px','marginTop':'0'}}> 
            <input
              type="checkbox"
              id="consent0"
              name="consent0"
              ref={register}
              key = { props.stepOneConsent?'consent0_'+props.stepOneConsent:'consent0_'}
              // defaultChecked = { props.stepConsent}
              // onChange={()=> props.setStepOneConsent()}
            />
            <label htmlFor="consent0">
              <Span
                c1
                regular
                textmainbody={!errors.consent0}
                colorError={errors.consent0}
              > 
               By signing up to
               {/* <StyledLink textlink="true" to="#"> */}
                &nbsp;  Medwise.ai &nbsp;
               {/* </StyledLink> */}
                for the i2i network, I consent that my information is being shared between Medwise AI Ltd and SoarBeyond Ltd. I agree to receive information and updates from the i2i network via email (unsubscribe at any time).
                {/* <StyledLink textlink="true" to="/terms.html" target="_blank" rel="noopener noreferrer">
                  Terms and Conditions
                </StyledLink>{' '}
                and{' '}
                <StyledLink textlink="true" to="/privacy.html" target="_blank" rel="noopener noreferrer">
                Privacy Policy
                </StyledLink> */}
              </Span>
            </label>
          </FormConsentFieldWrapper  >
        </Col>
        <Col md={12}>
          <FormConsentFieldWrapper style={{'marginBottom':'2px','marginTop':'0'}}> 
            <input
              type="checkbox"
              id="consent"
              name="consent"
              ref={register}
              key = { props.stepOneConsent?'consent_one_'+props.stepOneConsent:'consent_one'}
              defaultChecked = { props.stepOneConsent}
              onChange={()=> props.setStepOneConsent()}
            />
            <label htmlFor="consent">
              <Span
                c1
                regular
                textmainbody={!errors.consent}
                colorError={errors.consent}
              > 
              I confirm that I have read the {' '}
                <StyledLink textlink="true" to="/terms.html" target="_blank" rel="noopener noreferrer">
                  Terms and Conditions
                </StyledLink>{' '}
                and{' '}
                <StyledLink textlink="true" to="/privacy.html" target="_blank" rel="noopener noreferrer">
                Privacy Policy
                </StyledLink>
              </Span>
            </label>
          </FormConsentFieldWrapper  >
        </Col>
        <Col md={12} >
          <FormConsentFieldWrapper style={{'marginTop':'2px'}}>
            <input
              type="checkbox"
              id="consent2"
              name="consent2"
              ref={register}
              key = { props.stepTwoConsent? 'consent_2_'+props.stepTwoConsent:'consent_2' }
              defaultChecked = { props.stepTwoConsent }
              onChange={()=> props.setStepTwoConsent()}
            />
            <label htmlFor="consent2">
              <Span
                c1
                regular
                textmainbody={!errors.consent2}
                colorError={errors.consent2}
              > 
                I agree with i2iPharmacists {' '}
                <A textlink="true" href="https://www.i2ipharmacists.co.uk/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                  Terms and Conditions
                </A>{' '}
                and{' '}
                <A textlink="true" href="https://www.i2ipharmacists.co.uk/privacy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </A>
              </Span>
            </label>
          </FormConsentFieldWrapper>
        </Col>
      </Row>
    </div>
    </form>
    { props.registerError &&(
        <ErrorMessage c1 regular colorError>
            {props.registerError}
          <StyledLink textlink="true" to="/login">
          &nbsp; Log in here.
          </StyledLink>
        </ErrorMessage>
      )}
              
    <form onSubmit={handleSubmit(handleStepOneNavigation)} id='email_org_submitted' >
      <div>
        <Button
          id="email_org_submitted"
          type= "submit"
          colorprimary
          buttonlarge
          text="Next"
          width="100%"
        /> 
      </div>
    </form>
    </div>
  );
};

export default SignUpStepOneSoarBeyond;
