import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Button from '../button';
import StyledModal from './styles';
import { AppContext } from '../../context/index';
import './bootstrap-modal.min.css';
import { H2, P } from '../theme/typography';
import { ReactComponent as CloseIcon } from '../../assets/svgs/close-icon.svg';
import { getOrgData, getUser } from '../../functions/manageSessions';

const ModalNotLoggedIn = ({
  modalFeedbackShow,
  modalFeedbackClose,
  centered,
  title,
  bodyText,
}) => {
  return (
    <StyledModal
      show={modalFeedbackShow}
      onHide={modalFeedbackClose}
      centered={centered}
    >
      <Modal.Header>
        <H2 h2 semibold textoffblack>
          {title}
        </H2>
        <CloseIcon
          className="modal-header-closeIcon"
          onClick={() => modalFeedbackClose()}
        />
      </Modal.Header>
      <Modal.Body>
        <P p1 regular textoffblack>To access more advanced features, please sign up or log in.
        </P>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between align-items-center">
        <Button
          type="link"
          colorprimary
          buttonsmall
          text="Sign up"
          width="96px"
          centered
          to="/signup"
        />
        <Button
          type="link"
          colorprimary
          buttonsmall
          text="Log in"
          width="96px"
          centered
          to="/login"
        />
      </Modal.Footer>
    </StyledModal>
  );
};

export default ModalNotLoggedIn;
