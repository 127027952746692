import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

// Below styles are only for mobile. As of first release, modal on
// mobile is only used in "Can't find what you’re looking for?".
const StyledModal = styled(Modal)`
  overflow-x: hidden !important;
  overflow-y: hidden !important;

  .modal-content{
    margin: 20%;
    @media (max-width: 767.98px) {
     margin:10%;
    }
  }
  .modal-header,
  .modal-body,
  .modal-footer {
    
    @media (max-width: 767.98px) {
      padding-left: 24px;
      padding-right: 24px;
    }
    .modal-header-closeIcon{
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .modal-body {
    padding: 5% ;
    &-textarea {
      margin-top: ${({ theme }) => theme.spacing.x3};
    }

    @media (max-width: 767.98px) {
      padding-bottom: 24px;
    }
  }

  .modal-header{
    margin-left: auto;
    margin-right: auto;
    padding-top: 12px;
    padding-bottom: 12px;
    H2{
      color:#811c9e;      
    }
  }

  .modal-footer {
    justify-content: center;
    padding: 12px 32px;
    @media (max-width: 767.98px) {
      justify-content: center;
    }
  }
`;

export default StyledModal;
