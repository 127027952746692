import Header from "../../components/header";
import { Label } from "../../components/theme/typography";
import { TextInput } from "../../components/modal-org-fields/styles";
import { ButtonA, ButtonNormal } from "../../components/button/styles";
import { Container, Row, Col } from "react-bootstrap";
import { HeroSection } from "../landing-page/styles";
import { useState, useEffect, useContext } from "react";
import { getUser } from "../../functions/manageSessions";
import { AppContext } from "../../context";
import CalculatorElement from "../../components/calculator-element";
import { Article } from './styles';

const CalculatorPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const [isMagic, setIsMagic] = useState(true);
  const [dfNumDoctors, setDfNumDoctors] = useState(1000);
  const [dfNumPatients, setDfNumPatients] = useState(37);
  const [dfNumQuestions, setDfNumQuestions] = useState(0.57);
  const [dfNumDays, setDfNumDays] = useState(240);
  const [dfAvgTimeSpent, setDfAvgTimeSpent] = useState(10);
  const [dfAvgTimeSaved, setDfAvgTimeSaved] = useState(25);
  const [dfUnwarrantedCosts, setDfUnwarrantedCosts] = useState(5000000000);
  const [dfNumTotalDoctors, setDfNumTotalDoctors] = useState(140700);
  const [dfImprovement, setDfImprovement] = useState(10);

  const [numDoctors, setNumDoctors] = useState(dfNumDoctors);
  const [numPatients, setNumPatients] = useState(dfNumPatients);
  const [numQuestions, setNumQuestions] = useState(dfNumQuestions);
  const [numDays, setNumDays] = useState(dfNumDays);
  const [avgTimeSpent, setAvgTimeSpent] = useState(dfAvgTimeSpent);
  const [avgTimeSaved, setAvgTimeSaved] = useState(dfAvgTimeSaved);
  const [unwarrantedCosts, setUnwarrantedCosts] = useState(dfUnwarrantedCosts);
  const [numTotalDoctors, setNumTotalDoctors] = useState(dfNumTotalDoctors);
  const [improvement, setImprovement] = useState(dfImprovement);
  const [totalHoursSaved, setTotalHoursSaved] = useState();
  const [annualCostSaved, setAnnualCostSaved] = useState();

  const [isReset, setIsReset] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const { userContext } = useContext(AppContext);

  useEffect(() => {
    setTotalHoursSaved(Math.round(numDoctors * numPatients * numQuestions * numDays * avgTimeSpent / 60 * avgTimeSaved / 100));
    setAnnualCostSaved(Math.round(numDoctors * unwarrantedCosts / numTotalDoctors * improvement / 100));
  }, []);

  useEffect(() => {
    if (isReset) {
      setTotalHoursSaved(Math.round(numDoctors * numPatients * numQuestions * numDays * avgTimeSpent / 60 * avgTimeSaved / 100));
      setAnnualCostSaved(Math.round(numDoctors * unwarrantedCosts / numTotalDoctors * improvement / 100));
      setIsReset(false);
    }
  }, [isReset]);

  useEffect(() => {
    let user_info = getUser();
    
    if(user_info){
      setIsLoggedIn(true);
      if(user_info['org-user-type'] && user_info['org-user-type']=== "magic"){
        setIsMagic(true);
      }else{
        setIsMagic(false);
      }
    }
    else{
      setIsLoggedIn(false);
      // setOrganisationGreating('');
    }
    // eslint-disable-next-line 
  }, [userContext]);

  const calculate = () => {
    setTotalHoursSaved(Math.round(numDoctors * numPatients * numQuestions * numDays * avgTimeSpent / 60 * avgTimeSaved / 100));
    setAnnualCostSaved(Math.round(numDoctors * unwarrantedCosts / numTotalDoctors * improvement / 100));
  }

  const reset = () => {
    setNumDoctors(dfNumDoctors);
    setNumPatients(dfNumPatients);
    setNumQuestions(dfNumQuestions);
    setNumDays(dfNumDays);
    setAvgTimeSpent(dfAvgTimeSpent);
    setAvgTimeSaved(dfAvgTimeSaved);
    setUnwarrantedCosts(dfUnwarrantedCosts);
    setNumTotalDoctors(dfNumTotalDoctors);
    setImprovement(dfImprovement);
    setIsReset(true);
  }

  return (
    <>
      <Header withSearchBar={false} withAccountButtons={true} landingPageSearchBar={true} landingPage={true} />
      <HeroSection style={isLoggedIn ? {padding: '88px 0'} : {height: 'calc(50hvh)', padding: 'calc((100vh - 159.66px) / 3 - 64px) 0 calc((100vh - 159.66px) / 3 - 64px)', position:'relative'}}>
        <Article>
          <Container>
            <h2 className="header-subtitle">Impact Calculator</h2>
            <h1 className="header-title">Calculate how medwise can benefit your healthcare system</h1>

            <Label c1 semibold textoffblack className="numDoctorsLabel">
              How many doctors do you have?
            </Label>
            <TextInput
              type="text"
              name="num_doctors"
              placeholder=""
              className="numDoctors"
              value = {numDoctors}
              onChange = {(e) => setNumDoctors(e.target.value)}
            />
            <ButtonA onClick={() => calculate()} className="calculate">Calculate</ButtonA>
            <Row>
              <Col md={6} className="hero_logo result-section">
                <p className="result">{totalHoursSaved}</p>
                <p className="resultText">Total hours saved per year across doctors</p>
              </Col>
              <Col md={6} className="hero_logo result-section">
                <p className="result">£{annualCostSaved}</p>
                <p className="resultText">Annual cost savings from reduced unwarranted variation in care</p>
              </Col>
            </Row>
            <div className={"accordion-header " + (isExpanded ? 'accordion-header-border' : '')} onClick={() => setIsExpanded(!isExpanded)}>
              <div className="accordion-inner-header">
                <p className="accordionHeaderText">Adjust calculations for your healthcare system</p>
                <ButtonNormal onClick={() => reset()} className="reset">Reset values</ButtonNormal>
              </div>
              {!isExpanded && <ButtonNormal className="reset">Customise</ButtonNormal>}
            </div>
             
            <Row className="accordion-body" style={{display: isExpanded ? "flex" : "none"}}>
              <Col md={6} className="hero_logo accordion-body-header">
                Total hours saved per year across doctors
              </Col>
              <Col md={6} className="hero_logo accordion-body-header">
                Annual cost savings from reduced unwarranted variation in care
              </Col>
              <Col md={6} className="hero_logo">
                <CalculatorElement 
                  title="Number of patient encounter per doctor per day" 
                  description="See reference ↗" 
                  value={numPatients} 
                  isLink 
                  link="https://www.pulsetoday.co.uk/news/workload/40-of-gp-practices-set-limit-on-number-of-patients-seen-per-day/"
                  onChange={(e) => setNumPatients(e.target.value)} 
                />
              </Col>
              <Col md={6} className="hero_logo">
                <CalculatorElement 
                  title="NHS England unwarranted variation in care costs" 
                  description="See reference ↗" 
                  value={unwarrantedCosts} 
                  isLink 
                  link="https://www.gov.uk/government/publications/productivity-in-nhs-hospitals#:~:text=Lord%20Carter%20of%20Coles'%20final,year%20by%202020%20to%202021."
                  onChange={(e) => setUnwarrantedCosts(e.target.value)} 
                  unit="£"
                />
              </Col>

              <Col md={6} className="hero_logo">
                <CalculatorElement 
                  title="Number of questions asked per patient encounter" 
                  description="See reference ↗" 
                  value={numQuestions} 
                  isLink 
                  link="https://jamanetwork.com/journals/jamainternalmedicine/fullarticle/1846630"
                  onChange={(e) => setNumQuestions(e.target.value)} 
                />
              </Col>
              <Col md={6} className="hero_logo">
                <CalculatorElement 
                  title="Number of doctors working in NHS england" 
                  description="See reference ↗" 
                  value={numTotalDoctors} 
                  isLink 
                  link="https://www.kingsfund.org.uk/insight-and-analysis/data-and-charts/key-facts-figures-nhs#:~:text=In%20February%202024%2C%20there%20were%20140%2C700%20doctors%2C,in%20the%20NHS%20out%20of%20a%20total"
                  onChange={(e) => setNumTotalDoctors(e.target.value)} 
                />
              </Col>
              <Col md={6} className="hero_logo">
                <CalculatorElement 
                  title="Average time spent on each search" 
                  description="See reference ↗"
                  value={avgTimeSpent} 
                  isLink
                  link="https://journals.sagepub.com/doi/10.1177/1460458213478812?url_ver=Z39.88-2003&rfr_id=ori:rid:crossref.org&rfr_dat=cr_pub%20%200pubmed"
                  onChange={(e) => setAvgTimeSpent(e.target.value)} 
                  unit="min"
                />
              </Col>
              <Col md={6} className="hero_logo">
                <CalculatorElement 
                  title="Percent of improvement in implementing appropriately standardised evidence-based pathways" 
                  description 
                  value={improvement} 
                  onChange={(e) => setImprovement(e.target.value)} 
                  isLink
                  link="https://www.longtermplan.nhs.uk/online-version/chapter-6-taxpayers-investment-will-be-used-to-maximum-effect/test-4-the-nhs-will-reduce-unjustified-variation-in-performance/"
                  unit="%"
                />
              </Col>
              <Col md={6} className="hero_logo">
                <CalculatorElement 
                  title="Average time saved by using medwise" 
                  description="Doctors save a quarter of their time per search with Medwise (based on internal data)"
                  value={avgTimeSaved} 
                  onChange={(e) => setAvgTimeSaved(e.target.value)} 
                  unit="%"
                />
              </Col>
              <Col md={6} className="hero_logo">
              </Col>
              <Col md={12} className="hero_logo adjust-row">
                <ButtonNormal onClick={() => calculate()} className="reset">Adjust</ButtonNormal>
              </Col>
            </Row>
          </Container>
        </Article>
      </HeroSection>
    </>
  )
}

export default CalculatorPage;