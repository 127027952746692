/**
 *  Example usage with a custom prop:
 *  <Button
 type="link"
 colorcustom
 buttonsmall
 to="/"
 text="Contact us"
 width="96px"
 />
 */

import React from 'react'
import {AlertDanger, AlertPrimary} from './styles'


const Alert = ({
                  text,
                  type,
                  onClick,
                  className,
                  show
                }) => {
  switch (type) {
    default:
    case 'primary':
      return (
          <AlertPrimary
              onClick={onClick}
              className={className}
              show={show ? true : false}>
            {text}
          </AlertPrimary>
      );
    case 'danger':
      return (
          <AlertDanger
              onClick={onClick}
              className={className}
              show={show ? true : false}>
            {text}
          </AlertDanger>
      );
  }
}

export default Alert
