import styled from 'styled-components';

export const Styles = styled.section`
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  padding: 44px 0px 0px;  
  display: block;
  color: #6b6c6f;
  margin-bottom:-44px;
  
  Hr{
    border-top: 2px solid ${({ theme }) => theme.palette.backgroundStroke};
  }

  .logo-wrapper{
    display: flex;
    svg{
      height:25px;
      margin-top: 3px;
    } 
    @media (max-width: 990px) {
      width:20%;
      justify-content:end;
    }
  } 

  @media (max-width: 991.98px) {
    padding: 15px 0px 52px ;
  }

  @media (max-width: 767.98px) {
    padding: 15px 0px 0px !important;;
    margin-bottom: 0px;
  }

  .text-center{
    text-align:center!important;
  }
  
  .text-headline {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #212452;
  }
  
  .card-title {
    margin-bottom: 0.75rem;
  }
 
  .card-home1-message{
    padding-top: 0 1.25rem !important;
    margin: 0 1.24rem !important;
    @media (max-width: 991.98px){
      // height:435px;
    }
    @media (max-width: 767.98px) {
      padding: 0rem !important;;
    }
  }
  
  .text-para {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #6b6c6f;
  }
 
  .card-footer{
    width:120px;
    display:flex-inline; 
    font-size:14px;
    margin: 0 1.25rem;
    padding: 0.5rem 0rem;
  }

  .top-card {
    height: 98px;
    
    h3{
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
    }
  }
  .highlight11 {
    background: #effafc;
    border: 1px solid #d6f0f3;
    box-sizing: border-box;
    border-radius: 100px;
    width: 90px;
    height: 90px;
  }
  .text-highlight22 {
    position: relative;
    top: 35%;
    vertical-align: middle;
    color: #02b1c8;
    font-size: 31px;
    font-weight: 700;
    line-height: 22px;
  }
  .text-messages-highlight22 {
    position: relative;
    top: 25%;
    vertical-align: middle;
    color: #02b1c8;
    font-size: 31px;
    font-weight: 700;
    line-height: 22px;
  }


  .card{
    // min-height:410px;
    // max-height:450px;

    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    // border-radius: 0.25rem;
    border-radius: 10px;

    
    @media (max-width: 991.98px)
     {
      min-height:0px;
      margin: 15px 0 15px 0;
    }

    &-home1 {
      // margin-right: 0.75rem;
      margin: 0 16px 0 16px !important;
      max-height:420px;
      @media (max-width: 991.98px){
        max-height:435px;
      }
      @media (max-width: 767.98px) {
        padding: 0rem !important;;
      }
    }
    &-body{
      flex: 1 1 auto;
      height: 180px;
      padding: 1.25rem;
      flex-flow: column wrap;
      overflow:hidden;
    }
    &-body-messages{
      flex: 1 1 auto;
      // height: 180px;
      padding: 0.5rem 1.25rem ;
      flex-flow: column wrap;
      overflow: hidden;
    }
  }


  .message_wrapper{
    display: block;
    flex-flow: wrap;
    overflow: hidden;
    align-content: baseline;
    Hr{
      width:100%;
    }
  }

  .message {
    &_header {
      margin-top:5px;
      align-content: space-between;
      display: flex;
      align-self: center;
      display: flex;
      width: 100%;
      // find max-width for cards below if any 
      justify-content: left;
      text-align: left !important;

      &_tittle{
        padding: 8px 0 8px 6px;
        // border-bottom: solid 1px rgba(0,0,0,.125);
        color:#212452;
        display:inline;
        font-weight: 600;
        font-size: 18px;
        align-content: space-between;
        align-items: center;
        box-pack: center;
        justify-content: flex-start;
        width:80%;

        overflow:hidden;
        white-space:nowrap;
        text-overflow: ellipsis;
        -webkit-box-align: center;
        -webkit-box-pack: center;
        @media (max-width: 540px) {
          width:80%;
        }
        
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 600;
          font-size: 18px;
        }
      }
      
      &_date{
        padding: 8px 0;
        align-content: space-between;
        display:flex;
        -webkit-box-align: center;
        box-align: center;
        align-items: center;
        box-pack: center;
        -webkit-box-pack: center;
        justify-content: right;
        width:20%;
        @media (max-width: 990px) {
          display:none;
        }
      }
    }
    &_header2{
      margin-top:16px;
      align-content: space-between;
      display: flex;
      align-self: center;
      text-align: left !important;
      // margin: 0 auto;
      display: flex;
      width: 100%;
      max-width: 610px;
      justify-content: left;
    }
    &_document{
      display: flex;
      justify-content: right;
      width: 22%;
      margin-left: 4%;
    }


    &_content{
      padding-top: 15px;
      padding-bottom: 12px;
      padding-left: 6px;
      align-self: center;
      align-self: center;
      display: flex;
      justify-content: left;
      flex:1;


      .rendered_message{ 
        width:100%;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #6b6c6f;
        text-align:left;
        display: -webkit-box;
        -webkit-line-clamp: 12;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .rendered_message_document{
        width:75%;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #6b6c6f;
        text-align:left;
        display: -webkit-box;
        -webkit-line-clamp: 12;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

    }
  }
  
  }
`;

export default Styles;
