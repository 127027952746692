import React, { useState, useEffect, useContext } from 'react';
import { Container } from 'react-bootstrap';
import Styles from './styles';
import { AppContext } from '../../context';
import axios from 'axios';

const BookmarkBanner = () => {
  const [showBookmarkBanner, setShowBookmarkBanner] = useState(false);
  const [bookmarkBannerType, setBookmarkBannerType] = useState('');
  const [bookmarkBannerText, setBookmarkBannerText] = useState(<></>);
  const [requestRemoveBanner, setRequestRemoveBanner] = useState(false);

  const { userContext, setRequestTrackerContext} = useContext(AppContext);

  const removeBanner = function(){
    setShowBookmarkBanner(false);
    setRequestTrackerContext(prev => { return {...prev, 'edit-banner':true}});
    axios({
        method: 'POST',
        url: window.BASE_URL_USER+'edit-banner',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          banner_id: bookmarkBannerType,
          value: false
        })
    })
        .then((res) => {
          setRequestTrackerContext(prev => { return {...prev, 'edit-banner':false}});
          setRequestRemoveBanner(false);
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'edit-banner':false}});
            console.log(error);
        });
  };

  useEffect(() => {
    if (userContext) {
      if(userContext['banners'] && userContext['banners'].length) {
        setShowBookmarkBanner(true);
        setBookmarkBannerType(userContext['banners'][0]['banner_id']);
        setBookmarkBannerText(userContext['banners'][0]["text"]);
      } else {
        setShowBookmarkBanner(false);
      };
    };  
  },[userContext]);

  useEffect ( ()=> {
    if(requestRemoveBanner){
      removeBanner();
    }
  },[requestRemoveBanner]);

  return (
    <>
      {showBookmarkBanner &&(
        <Styles>
          <div className="fixed-bottom text-center" id="bookmark">
            <span dangerouslySetInnerHTML={{__html: bookmarkBannerText}}></span>
            <button type="button" className="close" aria-label="Close" id="bookmark-close" onClick={ ()=> setRequestRemoveBanner(true)}>
              <span aria-hidden="true" >&times;</span>
            </button>
          </div>
        </Styles>
      )}
    </>
  );
};

export default BookmarkBanner;
