import React, {useEffect, useState} from 'react'
import {isMobile} from 'react-device-detect'
import {Container, Row, Col} from 'react-bootstrap'
import Header from '../../components/header'
import {A, H2} from '../../components/theme/typography'

import {useParams} from 'react-router-dom'
import axios from 'axios'
import PublisherTag from '../../components/publisher-tag'
import {isUrlPdf} from '../../functions/utils'
import {Article} from '../../pages/shared-content-page/styles'
import FiltersMobile from '../../components/filters/filters-mobile'
import Footer from '../../components/footer'


const SharedContentPage = () => {

  let {content_id} = useParams();

  const [sharedContent, setSharedContent] = useState(null);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${window.BASE_URL_APP}api/shared-content/${content_id}`,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      console.log(res.data)
      setSharedContent(res.data.data)
    }).catch((error) => {
      console.log(error)
    })
  }, [content_id]);

  useEffect(() => {
    if(sharedContent !== null && sharedContent.title) {
      document.title = 'Medwise.ai - ' + sharedContent.title
    }
  }, [sharedContent]);

  return (<>
    <Header withSearchBar={true} withAccountButtons={true}/>
    {isMobile && <FiltersMobile /> }
    <main>
      <Container>
        <Row>
          {/*<div>{JSON.stringify(sharedContent)}</div>*/}
          <Col md={{span: 12}}>
            {sharedContent && <Article>
              <div className="card">
                <div className="card_header">
                  <H2 h2 semibold>
                    <a
                        href={sharedContent.online_view_url}
                        // onClick={(e) => handleLinkoutClick(e, result)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      {sharedContent.title}
                    </a>
                  </H2>
                </div>
                <div
                    className={`card_body `}
                    // aria-expanded={true}
                >
                  <div className="search_card_table_wrapper" dangerouslySetInnerHTML={{__html: sharedContent.body}}/>
                </div>
                <div className="card_footer">
                  <div className="card_footer_1">
                    <div>
                      <PublisherTag publisher={sharedContent.doc_publisher}/>
                      <A
                          c2
                          semibold
                          textlink
                          className="card_footer_1--external"
                          // onClick={(e) => handleLinkoutClick(e, result)}
                          href={sharedContent.online_view_url}
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                        {isUrlPdf(sharedContent.online_view_url)
                            ? 'View PDF >'
                            : 'Visit website >'}
                      </A>
                    </div>
                    <div className="card_footer_1--retrieved">
                    </div>
                  </div>
                  <div
                      className="card_footer_2"
                      style={{display: 'block'}}
                  >
                    {/*<Hr style={{margin: '24px -56px 0 -32px'}}/>*/}

                  </div>
                </div>
              </div>
            </Article>}

          </Col>
        </Row>
      </Container>
    </main>
    {isMobile ? <Footer />  : <Footer sticky="true"/> }
  </>)
}

export default SharedContentPage
