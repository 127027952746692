import React, {useContext, useEffect, useState, useMemo, useCallback, lazy, Suspense} from 'react';
import useIsSmallScreen from '../../../useIsSmallScreen/useIsSmallScreen.js';
import { AppContext } from '../../../context';
import {
  SuggestedAnswer,
  MachinResultsContainer,
  GeneratedResultsContainer,
  LineClamp,
  } from '../styles'; // we use the style sheet from results cards
import { Button, Col, Container,Row } from 'react-bootstrap';
import LoadingIndicatorEllipsis from '../../loading-indicator/LoadingIndicatorEllipsis.js';
import {
    FaChevronDown,
    FaChevronUp,
  } from 'react-icons/fa';
import Loader from 'react-loader-spinner';
import { H2,P,Label,A } from '../../theme/typography.js';
import PublisherTag from '../../publisher-tag';
// import ResultCard from '../result-card/index.js';
import UniversalCard from '../universal-card/index.js';
import {ReactComponent as Sparkles} from '../../../assets/svgs/sparkles-outline.svg';
import ExtractiveIcon from '../../../assets/svgs/audit_purple.png';
import ErrorIcon from '../../../assets/svgs/errorIcon.png';
import PDFPreview from '../PDFPreview.js';
import ModalPDFPreview from '../../modal-pdf-preview/index.js';
import { Styles } from './styles';
import { ButtonNormal } from '../../button/styles.js';

// import {ReactComponent a/s ErrorIcon }from '../../../assets/svgs/error_23dp.svg';

const ExtractiveAnswer = (props) => {
  const {
      searchContext,
      llmAnswers,
      llmQuestion,
      requestTrackerContext,
      setRetryExtractiveQA
    } = useContext(AppContext);
  const isSmallScreen = useIsSmallScreen();
  const [containerIsExpanded, setContainerIsExpanded] = useState(false);
  const [containerHaveNotExpanded, setContainerHaveNotExpanded] = useState(true);
  const [aiAnswerReady, setAiAnswerReady] = useState(false);
  const [aiQuotesReady, setAiQuotesReady] = useState(false);
  const [answerProgress, setAnswerProgress] =useState(80 );
  const [responseText, setResponseText] =useState("");
  const [isError, setIsError] =useState(false);
  const [showPDFModalFlag, setShowPDFModalFlag] = useState(false);
  
  function toggleAIResults() {
    if(llmQuestion && Object.hasOwn(llmQuestion,'length') && llmQuestion.length > 0) {
      setContainerIsExpanded(!containerIsExpanded);
      if (containerHaveNotExpanded === true) {
        setContainerHaveNotExpanded(false);
      }
    }
  }

  useEffect(() => {
    if(llmAnswers || llmQuestion ){
      setAiAnswerReady(true);
    }
    if(llmQuestion  && typeof llmQuestion === 'string' 
      && llmQuestion.startsWith('Error') && !isError){
      setIsError(true);
    }
    else{ 
      if(isError){setIsError(false);}
    }
  }, [llmAnswers, llmQuestion]);
  
  useEffect(() => {
    setContainerIsExpanded(false);
    setAiAnswerReady(false);
    setAnswerProgress(0);
    setContainerHaveNotExpanded(true);
  }, [searchContext]);

  useEffect(() => {
    if (requestTrackerContext && Object.hasOwn(requestTrackerContext,'extractive-qa') && requestTrackerContext['extractive-qa'] && aiAnswerReady){
      setAiAnswerReady(false);
    }
  }, [requestTrackerContext]);

  // Function to check if the URL ends with .pdf
  const isPdf = (url) => {
    // Create a new URL object to handle parsing
    const parsedUrl = new URL(url);
    // Get the pathname (the part before the query parameters)
    const pathname = parsedUrl.pathname;
    // Check if the pathname ends with .pdf
    return pathname.endsWith(".pdf");
  };

  return (
    <Styles>
      <GeneratedResultsContainer
        style={{
          paddingBottom: containerIsExpanded && '1px',
          marginBottom:
          containerIsExpanded && !isSmallScreen
              ? '10px'
              : containerIsExpanded && isSmallScreen && '8px',
          border:'1px solid #E2E5ED',
          // display:( ( aiAnswerReady)) && 'none',
          background: 'linear-gradient(#eae6f8,#fbfafd)',
          // backgroundColor: (question) ? '#fffff':'#fffff',
          // border:"",
          // backgroundo: (question) && '0.16',
        }}
      >
        {/* Comment/Uncomment */}
        {/* {fetchedSuggestedQuestion && fetchedSuggestedQuestion.length > 0 && !isSmallScreen && ( */}
        <SuggestedAnswer
          p1
          regular
          textoffblack
          className={aiAnswerReady ? "tittle_" : "blink"}
          onClick={toggleAIResults}
          style={{
            cursor: aiAnswerReady? 'pointer' : 'default',
            // marginBottom: isSmallScreen ? '8px' : '0.75rem',
            minHeight:'43px',
            alignItems: aiAnswerReady ? 'center' : 'start'
          }}
        >
          {/* {!isError?(<></>):(<FaChevronUp color="#fff"/>)} */}
          {(aiAnswerReady && !isError )&& ( 
            <Button
              size="sm"
              className="progress-button"
            >
              {containerIsExpanded ?  (
                <FaChevronUp color="#fff"/>
              ) : (
                <FaChevronDown color="#fff" />
              )}
            </Button>
          )}
          {(!aiAnswerReady && !isError) && (
            <div className='loader'>
              <Loader visible={true} color="#8771DF" style={{marginTop: '6px'}} height="23px" width="23px"  type="Oval" wrapperStyle={{}} wrapperClass=""/>
            </div>
          )}
          {(!aiAnswerReady && !isError && !containerIsExpanded && containerHaveNotExpanded) && (
            <Container>
              <div className={!isSmallScreen ? 'card-body-empty1' : 'card-body-empty2'}>
                <Row>
                  {!isSmallScreen ? 
                    <div id='typewriter' className='px-0 pt-0 pb-0 my-0 typewriter3' dangerouslySetInnerHTML={{__html:""}} ></div>:
                    <div id='typewriter' className='px-3 pt-0 pb-2 my-1 typewriter4' dangerouslySetInnerHTML={{__html:""}} ></div>
                  } 
                </Row>
              </div>
            </Container>
          )}
          {(isError) && ( 
            <Button
              size="sm"
              className="error-button"
            >
              {/* <ErrorIcon /> */}
              <img src={ErrorIcon} color="#8771DF" height="23px" width="23px" alt='error icon'></img>
            </Button>
          )}
          {' '}
          <Container>
            {(aiAnswerReady && llmQuestion) ? (
              <Row style={{ display:'flex', justifyContent:'space-between', alignItems: 'center'}}>
                <div 
                  style={{
                    display:'flex',
                    justifyContent:'flex-start',
                    alignItems:'center',
                    maxWidth: isSmallScreen ?'calc(90% - 35px)': 'calc(90% - 100px)'
                  }}
                >
                  <LineClamp 
                    onClick={(e)=> {!containerIsExpanded && containerHaveNotExpanded && setShowPDFModalFlag(0)}}
                    className= {containerIsExpanded? "": " truncate"}
                    dangerouslySetInnerHTML={{ __html:llmQuestion}}>
                  </LineClamp>
                </div>
                {llmQuestion.startsWith("Could not find") && (
                  <ButtonNormal className='retry-btn' colorprimary padding={"4px 8px"} onClick={() => setRetryExtractiveQA(true)}>Retry</ButtonNormal>
                )}
                <div style={{display:'flex', justifyContent:'flex-end',alignItems: 'center'}}>
                  {!isSmallScreen && (
                    <P p1 regular textoffblack > </P>
                  )}
                  {/* <Sparkles style={{margin:'12px'}} color="#8771DF" height="23px" width="23px"></Sparkles> */}
                  <img src={ExtractiveIcon} style={{margin:'12px'}} color="#8771DF" height="23px" width="23px" alt='extractive AI icon'></img>
                </div>
              </Row>
            ) : (            
              <Row style={{ display:'flex', justifyContent:'space-between'}}>
                <div 
                  style={{display:'flex', justifyContent:'flex-start', alignItems:'center', maxWidth: isSmallScreen ?'calc(90% - 35px)': 'calc(90% - 160px)'}}
                  // className={!(props.hasBookmarks && props.hasBookmarks.length > 0) ? `card_body `+ props.result.expanded : (props.result.expanded) ? `card_body undefined` : `card_body expanded`}
                  className={`card_body_generated_answer `}
                  aria-expanded={containerIsExpanded ? true : false}
                >
                  {/* <LineClamp className={containerIsExpanded ? '':'truncate'}
                    dangerouslySetInnerHTML={{ __html:question }} /> */}
                </div>
                <div style={{display:'flex', justifyContent:'flex-end',alignItems: 'center'}}>
                  {!isSmallScreen &&(
                    <P p1 regular textoffblack ></P>
                  )}
                  {/* <Sparkles style={{margin:'12px'}} color="#8771DF" height="23px" width="23px"></Sparkles> */}
                  <img src={ExtractiveIcon} style={{margin:'12px'}} color="#8771DF" height="23px" width="23px" alt='extractive AI icon'></img>
                </div>
              </Row>)}
            </Container>
        </SuggestedAnswer>

        {/* AI ASSISTED RESULTS */}
        {aiAnswerReady && ((!containerIsExpanded && containerHaveNotExpanded) || containerIsExpanded) && llmAnswers && llmAnswers.map((result,i) => {
          return (
            <div key={i}>
              <Container style={{padding: '0px 16px'}}>
                {result && Object.hasOwn(result, 'online_view_url') && isPdf(result.online_view_url) && result.display_pdf_only && (
                  <div className='pdf-preview-container1'>
                    <Suspense fallback={<div></div>}>
                      <div className='pdf-preview-inner-container'>
                        <PDFPreview data={result.preview_url_without_toolbar} isFullSize={true} />
                        {/* Transparent overlay for capturing clicks */}
                        <div
                          onClick={(e)=> {setShowPDFModalFlag(i)}}
                          className='overlay'
                        ></div>
                      </div>
                    </Suspense>
                  </div>
                )}
                {result && result.text && (!Object.hasOwn(result, 'display_pdf_only') || (Object.hasOwn(result, 'display_pdf_only') && !result.display_pdf_only)) && (
                  <Row>
                    {!isSmallScreen ? 
                      <div id='typewriter' className={'px-0 pt-0 pb-0 my-0 ' + (!containerIsExpanded && containerHaveNotExpanded ? 'typewriter3' : containerIsExpanded ? 'typewriter1' : '')} dangerouslySetInnerHTML={{__html:result.text}}></div>:
                      <div id='typewriter' className={'px-3 pt-0 pb-2 my-1 ' + (!containerIsExpanded && containerHaveNotExpanded ? 'typewriter4' : containerIsExpanded ? 'typewriter2' : '')} dangerouslySetInnerHTML={{__html:result.text}}></div>
                    } 
                  </Row>
                )}
                {result['doc_publisher'] && (
                  <Row>
                    <div className='footer-container'>
                      <div className='footer-outer-container'>
                        <PublisherTag 
                          publisher={result['doc_publisher']} 
                          isFolder={false}
                          isPrivate={false} 
                          url={result['online_view_url'] } 
                          requestToken={false} 
                          inMobileResult ={ isSmallScreen} >
                        </PublisherTag>
                        <div className='footer-inner-container'>
                          {!isSmallScreen && result['title'] &&
                            <H2 h2 semibold>
                              <a
                                href={ (Object.hasOwn(result, 'online_pdf_url') && typeof result['online_pdf_url']!=="undefined" ) ? result['online_pdf_url'] :  result.online_view_url} // cam we have no isOnlineViewUrlPdf 
                                target="_blank"
                                rel="noopener noreferrer"
                                dangerouslySetInnerHTML={{ __html: result.title }} 
                                onClick={(e) => props.handleLinkoutClick(e, result)}
                              />
                                
                            </H2> 
                          }
                          {!isSmallScreen && result['online_view_url'] &&
                            <A
                              c2
                              semibold
                              textlink
                              href={ result['online_view_url']}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => props.handleLinkoutClick(e, result)}
                            >
                              {result['online_view_url'] && result['online_view_url'].length < 50 ? (result['online_view_url']):(  result['online_view_url'].slice(0,50) +'...' ) }
                            </A> 
                          }
                        </div>
                      </div>
                      <div>
                        <ButtonNormal ButtonNormal className='retry-btn' style={{height: '100%'}} colorprimary padding={"4px 8px"} onClick={() => setRetryExtractiveQA(true)}>Retry</ButtonNormal>
                      </div>
                    </div> 
                  </Row>
                )}
                {typeof showPDFModalFlag !== 'undefined' &&  showPDFModalFlag === i &&(
                  <Suspense fallback={<div></div>}>
                    <ModalPDFPreview
                      modalPDFShow={ showPDFModalFlag === i }
                      modalPDFClose={setShowPDFModalFlag} 
                      title={result.title}
                      result = {result}
                      isExtractiveQA={true}
                      i = {i} 
                    />
                  </Suspense>
                )}
              </Container>
            </div>
          )
        })}
      </GeneratedResultsContainer>
    </Styles>
  );
};

export default ExtractiveAnswer;
