import styled from 'styled-components';
import { linkStyles } from '../../components/theme/link';
import { isMobile } from 'react-device-detect';

export const Article = styled.article`
  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.x5};

  }
  .error{
    background:red;
  }
  .spacing {
    margin-top: 10px;
    margin-bottom: 5px;

   }

  .card {

    position: relative;
    background-color: ${({ theme }) => theme.palette.backgroundWhite};
    border: 1px solid ${({ theme }) => theme.palette.backgroundStroke};
    border-radius: ${({ theme }) => theme.borderradius.medium};
    padding: 24px 56px 24px 32px;
    transition: all 0.3s;

    &:hover {
      border-color: ${({ theme }) => theme.palette.backgroundStrokeHover};
      box-shadow: 0 0 5px ${({ theme }) => theme.palette.backgroundStrokeHover};
      cursor: pointer;

      & > .card_expandIcon {
        background-color: ${({ theme }) => theme.palette.blueLight};
      }
    }

    @media (max-width: 767.98px) {
      padding: ${({ theme }) => theme.spacing.x2};
      overflow: hidden;
      /** overflow propery gets added on mobile because it prevents feedbackbox functionality */
    }

    &_expandIcon {
      display: inline-flex;
      color: ${({ theme }) => theme.palette.textLink};
      transition: all 0.3s;
      position: absolute;
      right: ${({ theme }) => theme.spacing.x2};
      top: calc(50% - 13px);
      padding: ${({ theme }) => theme.spacing.x1};
      border-radius: 50%;

      &.expanded {
        display: none;
      }

      @media (max-width: 767.98px) {
        background-color: ${({ theme }) => theme.palette.blueLight};
        padding: 6px;
        right: ${({ theme }) => theme.spacing.x1};
        top: ${({ theme }) => theme.spacing.x2};
      }
    }

    &_header {
      margin-bottom: ${({ theme }) => theme.spacing.x2};

      @media (max-width: 767.98px) {
        width: 92%;
      }

      a {
        ${linkStyles};
        display: inline;
      }
    }

    &_body {
      min-height: ${({ theme }) => theme.spacing.x6};
      /** More info on line-clamp: https://css-tricks.com/almanac/properties/l/line-clamp/ */
      display: -webkit-box;
      // -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
      margin-bottom: ${({ theme }) => theme.spacing.x2};

      @media (max-width: 767.98px) {
        /** Text color gradient: https://css-tricks.com/snippets/css/gradient-text/ */
        // background: -webkit-linear-gradient(
        //   ${({ theme }) => theme.palette.textOffblack},
        //   #e6e6e6
        // );
        // background-image: linear-gradient(
        //   ${({ theme }) => theme.palette.textOffblack},
        //   #e6e6e6
        // );
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // -webkit-line-clamp: 4;
        margin-bottom: ${({ theme }) => theme.spacing.x6};
      }

      &.expanded {
        -webkit-line-clamp: unset;

        @media (max-width: 767.98px) {
          background: unset;
          -webkit-text-fill-color: unset;
          -webkit-background-clip: unset;
        }
      }

      a,
      h2,
      h3,
      h4,
      h5,
      h6,
      ul,
      ol,
      div,
      p,
      span {
        font-size: ${({ theme }) => theme.fontsize.desktop.p1};

        @media (max-width: 767.98px) {
          font-size: ${({ theme }) => theme.fontsize.mobile.p1};
        }
      }

      a {
        ${linkStyles};
        display: inline;
      }

      h2,
      h3 {
        font-weight: ${({ theme }) => theme.fontweight.semibold};
        color: ${({ theme }) => theme.palette.textOffblack};
      }

      ul,
      ol {
        list-style-type: initial;
        padding-left: ${({ theme }) => theme.spacing.x3};
      }


    }

    &_footer {
      display: flex;
      flex-flow: column nowrap;
      position: relative;

      &_1 {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        position: relative;
        width: 100%;

        @media (max-width: 767.98px) {
          flex-flow: column nowrap;
          align-items: flex-start;
        }

        &--external {
          position: relative;
          display: inline-flex;
          align-items: center;
          text-decoration: underline;

          @media (max-width: 767.98px) {
            margin: ${({ theme }) => theme.spacing.x1} 0 0 0;
          }
        }

        &--retrieved {
          margin-left: auto;

          @media (max-width: 767.98px) {
            margin: ${({ theme }) => theme.spacing.x2} 0 0 0;
          }
        }
      }
      .card_footer_2_expanded {
        display: block;
      }
      .share_container {
        position: relative;
        display: inline-flex;
        justify-content: flex-start;
        margin-top: 24px;
        font-size: 15px;
        line-height: 19px;
        font-weight: bold;
        color: #8CA1AE;
      }

      .share_container > svg {
        filter: invert(65%) sepia(8%) saturate(654%) hue-rotate(159deg) brightness(94%) contrast(92%);
      }

      .share_container:hover, .share_container:hover > svg {
        color: #25282B;
        filter: none !important;
      }

      .share_container_share_icon {
        margin-right: 5px;
      }

    .thumbs_up_vote {
      ${isMobile ? 'margin-right: 20px;' : 'margin-right: 15px;'}
    }

    .thumbs_down_vote:hover, .thumbs_up_vote:hover {
      //color: #8771DF; see https://codepen.io/sosuke/pen/Pjoqqp
      filter: invert(46%) sepia(76%) saturate(1413%) hue-rotate(221deg) brightness(93%) contrast(88%);
    }

    .thumbs_icon_unvoted {
    // color: #97AAB6;
    filter: invert(69%) sepia(19%) saturate(106%) hue-rotate(160deg) brightness(94%) contrast(88%);
    }

    .share_card_popup {
      display: none;
      background: #fff;
      border-radius: 3px;
      border: 1px solid #E2E5ED;
      box-sizing: border-box;
      color: #212452;
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
      list-style: none;
      margin-left: -15px;
      margin-top: 30px;
      // padding: 15px;
      position: absolute;
      width: 154px;
      z-index: 10000;
    }


    .share_card_popup > ul > li {
      padding: 15px;
    }

      &_2 {
        display: none;

        &--feedback {
          ${isMobile ? 'width: 20%;' : 'width: 8%;'}
          float: right;
          position: relative;
          display: inline-flex;
          justify-content: flex-end;
          margin-top: ${({ theme }) => theme.spacing.x3};

          @media (max-width: 767.98px) {
            margin-top: ${({ theme }) => theme.spacing.x3};
          }

          @media (max-width: 1200px) {
            width: 20%;
          }

          &--chevron {
            width: 16px;
            height: 16px;
            color: ${({ theme }) => theme.palette.textPlaceholder};
            padding: 8px 0 0 8px;
          }
        }
      }
    }
  }
`;


export const Styles = styled.section`

.error {
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  padding-top:10px;

  @media (max-width: 767.98px) {
    margin-top: ${({ theme }) => theme.spacing.x5};
  }

  &_title {
    margin-top: ${({ theme }) => theme.spacing.x4};
    margin-bottom: ${({ theme }) => theme.spacing.x2};
  }

  &_message {
    margin-bottom: ${({ theme }) => theme.spacing.x5};
  }
}
`;
