import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Hr from '../hr';
import { Span, A, StyledLink } from '../theme/typography';
import { FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import Styles, { StyledHr } from './styles';
import { ReactComponent as MedwiseLogo } from '../../assets/svgs/medwise-logo.svg';

const Footer = ({ sticky, mobileResultBackground, onTop }) => {
  return (
    <Styles sticky={sticky} mobileResultBackground={mobileResultBackground} >
      <Hr />
      <Container>
        <Row className="py-3 align-items-center">
          <Col md={6}>
            <ul className="footer_leftList">
              <li>
                <Link to="/">
                  <MedwiseLogo
                    className="footer_leftList--icon"
                  />
                </Link>
              </li>
              <li>
                <A
                  textoffblack
                  target="_blank"
                  href="https://twitter.com/medwiseai/"
                  rel="noopener noreferrer"
                >
                  <FaTwitter
                    className="footer_leftList--icon"
                  />
                </A>
              </li>
              <li>
                <A
                  textoffblack
                  target="_blank"
                  href="https://www.linkedin.com/company/medwiseai/"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn
                    className="footer_leftList--icon"
                  />
                </A>
              </li>
            </ul>
          </Col>
          <Col md={6}>
            <ul className="footer_rightList">
              <li>
                <StyledLink
                  p1="true"
                  semibold="true"
                  textoffblack="true"
                  to="/terms.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms
                </StyledLink>
              </li>
              <li>
                <StyledLink
                  p1="true"
                  semibold="true"
                  textoffblack="true"
                  to="/privacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy
                </StyledLink>
              </li>
              <li>
                <StyledLink
                  p1="true"
                  semibold="true"
                  textoffblack="true"
                  to="/accessibility.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Accessibility
                </StyledLink>
              </li>
              {/* <li>
                <StyledLink
                  p1="true"
                  semibold="true"
                  textoffblack="true"
                  to="/about"
                >
                  About
                </StyledLink>
              </li> */}
              <li>
                <A
                  p1
                  semibold
                  textoffblack
                  target="_blank"
                  href="https://about.medwise.ai/"
                  rel="noopener noreferrer"
                >
                  About us
                </A>
              </li>
              <li>
                <A
                  p1
                  semibold
                  textoffblack
                  href="mailto:hello@medwise.ai"
                >
                  Contact us
                </A>
              </li>
            </ul>
          </Col>
        </Row>
        <StyledHr />
        <Row>
          <Col md={12}>
            <div className="footer_copyright">
              <Span c2 regular textoffblack>
                {new Date().getFullYear()} - Medwise.ai | All rights reserved
              </Span>
            </div>
          </Col>
        </Row>
      </Container>
    </Styles>
  );
};

export default Footer;
