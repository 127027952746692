import {createGlobalStyle} from 'styled-components'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import {a} from './link'
import {textarea} from './textarea'
import {input} from './input'
import {select} from './select'
import { isMobile } from 'react-device-detect';

export const GlobalStyle = createGlobalStyle`
    html {
        height: 100%;
        scroll-behavior: smooth !important;
    }

    body {
        font-family: ${({theme}) => theme.fontfamily.mulish};
        color: ${({theme}) => theme.palette.textMainbody};
        line-height: ${({theme}) => theme.lineheight.desktop.p1};
        padding: 0;
        margin: 0;
        background-color: ${({theme}) => theme.palette.backgroundGrey};
    }

    .modal-dialog {
        .add-cpd-input {   
            padding-left: 16px !important;
            padding-right: 16px !important;
            padding-top: 11px !important;
            padding-bottom: 11px !important;
            font-weight: 400;
            font-size: 14px !important;
            line-height: 150%;
            color: #212452 !important;
            resize: none;
            background: #fbfbfd;
            border: 1px solid rgb(226, 229, 237) !important;
            outline: 0px !important;
            box-shadow: none !important;
        }

        .form-control {
            display: block;
            width: 100%;  
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            padding: 0.375rem 0.75rem;
            line-height: 1.5;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
        }

        .add-cpd-input.url,
        .add-cpd-input.title,
        .add-cpd-input.date,
        .add-cpd-input.duration,
        .add-cpd-input.credits {
            height: 35px;
        }


        .form-group {
            margin-bottom: 1rem;
        }
    
        .add-cpd-input:focus {
            border: 1px solid #8771df !important;
        }

        .btn {
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            user-select: none;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }

        .btn-lg {
            padding: 0.5rem 1rem;
        }

        .modal-footer>* {
            margin: 0.25rem;
        }
    
        .btn-cancel {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #212452 !important;
            border: 1px solid #dadce0 !important;
            box-sizing: border-box;
            border-radius: 4px;
            background: #fff !important;
            box-shadow: none !important;
        }
    
        .btn-save {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #fff !important;
            box-sizing: border-box;
            border-radius: 4px;
            background: #8771df !important;
            box-shadow: none !important;
            border: 1px solid transparent !important;
        }
    
        .btn-save:hover {
            color: #212529 !important;
        }
    
        .add-cpd-entry-btn {
            margin-left: none !important;
        }
    
        .m-header {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: #4d5156;
        }
    
        .add-cpd-form {
            font-weight: 500;
            font-size: 13px;
            line-height: 150%;
            color: #4d5156;
            letter-spacing: 0.6px;
            display: inline-block;
            margin-bottom: 0.5rem;
        }
        
        .close {
            color: #9ea0a5 !important;
            opacity: 0.5 !important;
            background-color: transparent !important;
            outline: none !important;
            border: 0;
            font-size: 24px;
        }
        
        .close:focus,
        .close:active {
            outline: 0 !important;
            box-shadow: none !important;
        }
    
        .cpd-setting {
            margin-top: 13px;
            color: #4d5156;
            cursor: pointer;
        }
    
        .form-select {
            outline: 0 !important;
            box-shadow: none !important;
            font-size: 14px !important;
            color: #212452;
            padding-left: 16px !important;
            padding-right: 16px !important;
            font-weight: 400;
        }
    
        .form-select:focus {
            border: 1px solid #8771df !important;
        }

        .col-form-label {
            padding-top: calc(0.375rem + 1px);
            padding-bottom: calc(0.375rem + 1px);
        }
    
        .form-check {
            position: relative;
            display: block;
            padding-left: 1.25rem;
            height: 24px;
            align-items: center;
            
        }
    
        .form-check-label {
            margin-left: 7px;
            display: inline-block !important;
            font-weight: 400  !important;
            font-size: 14px !important;
            color: #212452;
            line-height: 20px !important;
        }

        .cpd-note-options {
            margin-top: 5px !important;
        }
    
        .form-check-input {
            margin-left: -1.25rem !important;
            border: 1px solid rgb(226, 229, 237) !important;
            min-height: 13px !important;
            min-width: 13px !important;
            padding-top: 3px !important;
            border: 1px solid #212425 !important;
        }
    
        .form-check-input:checked {
            border: 1px solid #0075ff !important;
            background-color: #0075ff !important;
        }
    
        .form-check-input:checked ~ label {
            font-weight: 400  !important;
            font-size: 14px !important;
            color: #212452;
        }

        button, input, select, textarea {
            font-family: inherit;
        }
    }

    main {
        margin-top: 64px;
        &.headerSearchMobile{
            margin-top:89px;
        }
    }

    div {
        outline: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        color: ${({theme}) => theme.palette.textOffblack};
    }

    a {
        ${a};
    }

    p {
        margin: 0;
    }

    input {
        ${input}
    }

    select {
        ${select}
    }

    textarea {
        ${textarea}
    }

    ul, ol {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .rendered_message ul, ol {
        list-style-type: disc;
        padding-left: 40px;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    button {
        cursor: pointer;
    }
    
    .share_card_icon {
    // color: #97AAB6;
      filter: invert(69%) sepia(19%) saturate(256%) hue-rotate(160deg) brightness(94%) contrast(88%);
    }
    
    .share_card_icon:hover {
      filter:none !important;
    }
    
    table.card_result_table { 
          margin-top: 1rem;
          margin-bottom: 1rem;
          background-color: transparent; // Reset for nesting within parents with bg-color
          table-layout: fixed;
          border-collapse: collapse; // Merge the borders between columns
          border-style: hidden;
  }
  
  ${isMobile ? `
  table.card_result_table > thead > tr > th,
  table.card_result_table > tbody > tr > td {
        word-break: keep-all; // Prevent breaking a long word into two lines
  }
  .search_card_table_wrapper {
        overflow-x: auto;
  }
  table.card_result_table {
        display: none;
  }
  table.card_result_table > tbody > tr > td {
        padding: 0.25rem !important;
  }
  .card_body :not(.expanded) { 
    overflow-y: hidden; 
  }
  .card_body.expanded > .search_card_table_wrapper > table {
       display: block;
       width: 600px;
  }` : `
  table.card_result_table { 
  width: 100%;
  max-width: 100%;
  }
    @media (max-width: 767.98px) {
        table.card_result_table > thead > tr > th,
        table.card_result_table > tbody > tr > td {
                word-break: keep-all; // Prevent breaking a long word into two lines
        }
        .search_card_table_wrapper {
                overflow-x: auto;
        }
        table.card_result_table {
                display: none;
        }
        table.card_result_table > tbody > tr > td {
                padding: 0.25rem !important;
        }
        .card_body :not(.expanded) { 
            overflow-y: hidden; 
        }
        .card_body.expanded > .search_card_table_wrapper > table {
            display: block;
            width: 600px;
        }
    }
    `}
  
    
  table.card_result_table > tbody > tr > td {
        border-bottom: 1px solid #97AAB6;
        border-left: 1px solid #97AAB6;
        border-right: 1px solid #97AAB6;
        padding: 0.75rem;
  }

  table.card_result_table > thead th {
    background-color: #F0F0F0;
    vertical-align: middle;
    border-bottom: 1px solid #97AAB6;
    border-right: 1px solid #97AAB6;  // #dee2e6;
    border-left: 1px solid #97AAB6;
  }
`;
