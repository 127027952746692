import styled, { css } from 'styled-components';
import { linkStyles } from '../../components/theme/link';

const sharedFormWrapper = css`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${({ theme }) => theme.palette.backgroundWhite};
  border-radius: ${({ theme }) => theme.borderradius.medium};
  box-shadow: 0 5px 9px ${({ theme }) => theme.palette.backgroundStrokeHover};
  padding: ${({ theme }) => theme.spacing.x5} ${({ theme }) => theme.spacing.x6};
  margin-top: ${({ theme }) => theme.spacing.x5};

  @media (max-width: 767.98px) {
    margin-top: ${({ theme }) => theme.spacing.x3};
    padding: ${({ theme }) => theme.spacing.x3};
    box-shadow: none;
    border: none;
  }
`;

export const FormWrapperSignupFlow = styled.div`
  #signupFlowContainer{
    max-width: 480px;

    ${sharedFormWrapper};
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.palette.backgroundStrokeHover};
    box-shadow: none;
    // padding: ${({ theme }) => theme.spacing.x5} ${({ theme }) => theme.spacing.x1};
    padding: ${({ theme }) => theme.spacing.x4} ${({ theme }) => theme.spacing.x5};

    @media (max-width: 767.98px) {
      padding-top:0px;
      border: none;
      font-size: 24px;
    }


  }
  p.hidden{
    display: none;
  }
  p.visible{
    font-size:16px;
    display:auto;
  }
  div.hidden{
    text-align: center;    
    display: none;
  }
  div.visible{
    text-align: center;
    font-family: ${({ theme }) => theme.fontfamily.mulish};
    font-size: 16px;
    color:#212452;
    font-weight: 600;
    display: auto; 
    cursor:default;
    &:hover{
      text-decoration: none;
    }
`;

export const FormWrapper = styled.div`
  ${sharedFormWrapper};
  p.hidden{
    display: none;
  }
  p.visible{
    font-size:16px;
    display:auto;
  }
  div.hidden{
    text-align: center;    
    display: none;
  }
  div.visible{
    text-align: center;
    font-family: ${({ theme }) => theme.fontfamily.mulish};
    font-size: 16px;
    color:#212452;
    font-weight: 600;
    display: auto; 
    cursor:default;
    &:hover{
      text-decoration: none;
    }
`;

export const VerifyEmailTitleWrapper = styled.div`
  text-align: center;
  p:first-child {
    margin-bottom: ${({ theme }) => theme.spacing.x1};
  }
  margin-bottom: ${({ theme }) => theme.spacing.x2};
`;

export const VerifyEmailIconWrapper = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.x4};
`;

export const VerifyEmailMessageWrapper = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.x4};
`;

export const VerifyEmailResendLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.x8};

  p {
    ${linkStyles}
    cursor: pointer;
  }
`;

export const SwitchFlow = styled.div`
  text-align: center;
  margin: ${({ theme }) => theme.spacing.x5} 0;

  @media (max-width: 767.98px) {
    margin: ${({ theme }) => theme.spacing.x3} 0;
  }

  a {
    ${linkStyles}
  }
`;

export const LoginContainer = styled.div`
  .emailContainer{
    min-height:80vh;
    padding-bottom: 40px;
    padding-top: 0px;
    padding-top: calc( (50vh - 406px) );
    padding-bottom: calc( (50vh - 466px) );
    @media (max-height: 845.98px) {
      padding-bottom: 40px;
      padding-top: 0px;
    }
  }
`;
