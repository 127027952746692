import styled from 'styled-components';
import { linkStyles } from '../../theme/link';
import { isMobile } from 'react-device-detect';

export const Article = styled.article`
    display:flex;
    gap : 0.3rem;
    margin-bottom: 15px;
    position: relative;
    background-color: ${({ theme }) => theme.palette.backgroundWhite};
    border: 1px solid ${({ theme }) => theme.palette.backgroundStroke};
    ${isMobile ? 'border-left-width: 0.0;' : ' @media (max-width: 767.98px) {border-left-width: 0.0;}'}
    ${isMobile ? 'border-right-width: 0.0;' : ' @media (max-width: 767.98px) {border-right-width: 0.0;}'}
    border-radius: ${({ theme }) => theme.borderradius.medium};
    ${isMobile ? 'border-radius:0;' : '@media (max-width: 767.98px) {border-radius:0;}'}
    padding: 16px 35px 16px 16px;
    overflow: hidden;
    @media (max-width: 767.98px) {
        margin-bottom: 4px;
}
`;