import React, { useEffect, useContext ,useState} from 'react';
import { AppContext } from '../../context';
import { Container, Row, Col ,Modal,Image} from 'react-bootstrap';
import { H1, Span, StyledLink } from '../../components/theme/typography';
import cyberEssential from '../../assets/svgs/cert_Cyber_Essentials.svg';
import  orcha from '../../assets/svgs/cert_Orcha_Web_App.svg';
import dspToolkit from '../../assets/svgs/cert_DSP_Toolkit.svg';
import crownCommercial from '../../assets/svgs/cert_crown_commercial_service.svg';
import dcb0129 from '../../assets/svgs/cert_DCB_0129.svg';
import dcb0160 from '../../assets/svgs/cert_DCB_0160.svg';
import Button from '../button';
import {ReactComponent as CyberEssential} from '../../assets/svgs/cert_Cyber_Essentials.svg';
import {ReactComponent as Orcha} from '../../assets/svgs/cert_Orcha_Web_App.svg';
import {ReactComponent as DspToolkit} from '../../assets/svgs/cert_DSP_Toolkit.svg';
import {ReactComponent as CrownCommercial} from '../../assets/svgs/cert_crown_commercial_service.svg';
import {ReactComponent as Dcb0129} from '../../assets/svgs/cert_DCB_0129.svg';
import {ReactComponent as Dcb0160} from '../../assets/svgs/cert_DCB_0160.svg';
import {ReactComponent as Seamless} from '../../assets/svgs/seamless.svg';
import {ReactComponent as Evidence} from '../../assets/svgs/evidence.svg';
import {ReactComponent as Fever} from '../../assets/svgs/fever.svg';
import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen';

const titleStyling = {
  'fontSize': '42px','fontStyle':'normal', 'lineHeight': "55px", 'fontWeight': '100', 'color': '#393956' 
};

const textStyling = {
  'fontSize': '20px', 'lineHeight': "29px", 'fontWeight': '300', 'color': '#4D5156'
};
const textStylingEvidence = {
  'fontSize': '21px', 'lineHeight': "29px", 'fontWeight': '300', 'color': '#4D5156','maxWidth':'500px',
};

const buttonStyling = {
  'margin':"25px 0"
}

const LandingBottomSection = () => {
 
  const isSmallScreen = useIsSmallScreen();
  return (
      // <div style={{ position: 'relative', height: '1400px',width:'1180px',margin:'auto' ,backgroundColor:'#FFFFFF'}}>
      //   <Span style={backgroundImageContainer}>   </Span>
      //   <Span textoffblack style={{ fontSize: '30px', lineHeight: "48px", fontWeight: '600', color: '#393956', position: 'absolute', top: '10px', left: '100px' }}>Seamless access to <b>clinical knowledge</b>.</Span>
      //   <Span textoffblack style={{ fontSize: '19px', lineHeight: "48px", fontWeight: '600', color: '#393956', position: 'absolute', top: '50px', left: '105px' }}>  Medwise is the next-generation search platform designed for clinicians.</Span>
      //   <Span textoffblack style={{ maxWidth: '360px', fontSize: '30px', lineHeight: "40px", fontWeight: '600', color: '#393956', position: 'absolute', top: '225px', left: '100px' }}>Find <b>actionable</b> clinical information.</Span>
      //   <Span style={{ width: '315px', height: '205px', backgroundColor: '#8e00ff', opacity: ' 0.04', borderRadius: '40px', fontSize: '30px', lineHeight: "40px", fontWeight: '600', color: '#393956', position: 'absolute', top: '316px', left: '200px' }}></Span>
      //   <Span textoffblack style={{ maxWidth: '360px', fontSize: '19px', lineHeight: "24px", fontWeight: '600', color: '#393956', position: 'absolute', top: '325px', left: '105px' }}>See <b>bite-sized answers</b>, not documents.<br /><br />
      //     Add your own sources and customize
      //     your search filters.<br /><br />
      //     Log CPD credits and stay up to date
      //     with the latest evidence.<br />
      //   </Span>
      //   <Span style={{ width: '200px', height: '130px', backgroundColor: '#8e00ff', opacity: ' 0.04', borderRadius: '20px', position: 'absolute', top: '456px', left: '830px' }}></Span>
      //   <Span style={{ width: '498px', height: '336px',backgroundImage: 'contain', backgroundImage: `url(${imageLandingLocal})`, borderRadius: '40px', position: 'absolute', top: '571px', left: '140px' }}></Span>
      //   <Span textoffblack style={{ fontSize: '30px', lineHeight: "48px", fontWeight: '600', color: '#393956', position: 'absolute', top: '600px', left: '600px' }}>Adopt <b>local best practice.</b></Span>
      //   <Span textoffblack style={{  width:'480px',fontSize: '19px', lineHeight: "31px", fontWeight: '600', color: '#393956', position: 'absolute', top: '654px', left: '622px' }}> Reduce waiting lists, unwarranted variations in care and inappropriate referrals by making <b>local pathways</b> easily accessible.</Span>
      //   <Span textoffblack style={ {width:'900px',height:'540px', backgroundImage:`url(${certifications})`,backgroundRepeat:'no-repeat', backgroundSize:'900px',position: 'absolute', top: '891px', left:'144px'}}> </Span>
      //   <Span textoffblack style={ {fontSize: '30px',fontWeight: '600', color: '#393956', lineHeight:"48px",  position: 'absolute', top: '900px', left:'522px'}}> Certified by </Span>
      
      // </div>
    <>
    { !isSmallScreen &&(
        <>
        <Container>
          <Row className='justify-content-between align-middle mb-5 pb-5'>
            <Col sm={12} md={6}>
              <div style={{ 'maxWidth': '550px','height':'100%','display':'flex','alignItems':'center' }}>
                <div>
                <Span  style={titleStyling}>Seamless access to <b style={{fontWeight:'600'}}>clinical knowledge</b>.</Span>
                <br></br>
                <br></br>
                <div style={{'maxWidth':'475px'}}>
                  <Span  style={textStyling}> Medwise.ai is the next-generation search platform designed for clinicians. We help you find answers to your clinical questions at the point of care within seconds.</Span>
                </div>
                <div style={buttonStyling}> 
                  <Button
                    type="link"
                    colororangelanding
                    buttonsmall
                    width="96px"
                    text='Try Now'
                    to='/search?q=When%20is%20ustekinumab%20indicated%20for%20the%20treatment%20of%20Crohn%27s%20disease?&sources=NHS%20Conditions,NHS%20Inform%20%C2%B7%20Illnesses%20and%20Conditions,NHS%20Inform%20%C2%B7%20Self-Help%20Guides,Patient.info,Asthma%20+%20Lung%20UK,British%20Lung%20Foundation,Cancer%20Research%20UK,Breast%20Cancer%20Now,Prostate%20Cancer%20UK,Crohn%E2%80%99s%20&amp;%20Colitis%20UK,Epilepsy%20Society,InfoKID,Kidney%20Care%20UK,National%20Kidney%20Federation,My%20Stroke%20Guide,European%20Association%20of%20Urology'
                    rel="noopener noreferrer"
                  />
                </div>
              
                </div>
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div style={{display:'flex',justifyContent:'right' }}>
                <Seamless></Seamless>
              </div>
            </Col>
          </Row>
          <Row className='justify-content-between align-middle mb-5 pb-5'>
            <Col sm={12} md={6}>
              <div style={{display:'flex',justifyContent:'left' }}>
                <Evidence></Evidence>
              </div>
              
            </Col>
            <Col sm={12} md={6}>
            <div style={{ maxWidth: '500px',height:'100%',display:'flex',alignItems:'center' }}>
              <div>
                <Span textoffblack style={titleStyling}>Adopt <b style={{fontWeight:'600'}}>local best practice</b>.</Span>
                <br></br>
                <br></br>
                <Span textoffblack style={textStyling}>Increase your awareness of local care pathways, local services and medicines advice. Adapt quickly when working in a new location. </Span>
                <div style={buttonStyling}> 
                    <Button
                      type="link"
                      colororangelanding
                      buttonsmall
                      width="110px"
                      text='Get Started'
                      to='/signup'
                      rel="noopener noreferrer"
                    />
                </div>
                </div>
               
              </div>
            </Col>
          </Row>
          <Row className='justify-content-between align-middle mb-5 pb-5'>
            <Col sm={12} md={7}>
            <div style={{ maxWidth: '575px',height:'100%',display:'flex',alignItems:'center' }}>
                <div>
                  <Span textoffblack style={titleStyling}>Free up time <b style={{fontWeight:'600'}}>to focus on your patients</b>.</Span>
                  <br></br>
                  <br></br>
                  <div style={{maxWidth:'480px'}}>
                    <Span textoffblack style={textStyling}>Medwise.ai hyperlinks to information from national knowledge resources, societies as well as local guidance and patient information all in one place. </Span>
                  </div>
                  <div style={buttonStyling}> 
                    <Button
                      type="link"
                      colororangelanding
                      buttonsmall
                      width="96px"
                      text='Sign Up'
                      to='/signup'
                      onClick={()=>{}}
                      className={"className"}
                      rel="noopener noreferrer"
                    />
                </div>
                </div>
            </div>
            </Col>
            <Col sm={12} md={5}>
              <div style={{display:'flex',justifyContent:'right' }}>
                <Fever ></Fever>
              </div>
            </Col>
          </Row>
          {/* certified  1
    445.9
    366
    812

    */}
        </Container>
        <Container style={{  'maxWidth': '950px'  }}  >
            <Row className=' justify-content-center m-4'>
              <Span textoffblack h1 semibold>Certified by</Span>
            </Row>
            <Row className=' mt-4 mb-4  justify-content-between p-0'>
              <Col className='p-0' sm={12} md={6}> 
                <div >
                  <img src={cyberEssential} style={{  'objectFit': 'contain','maxWidth':'100%' }} tittle="cyberEssentialLogo"></img>
                </div>
              </Col>

              <Col className='p-0' sm={12} md={6}>
                <div style={{ 'display':'flex','justifyContent':'flex-end'}}>
                  <img src={orcha} style={{ 'maxWidth':'100%','height':"100%",'objectFit': 'contain' }} tittle="orchaLogo"></img>
                </div>
              </Col>
            </Row>
            <Row className='m-3 '></Row>
            <Row className='p-0 mt-4 mb-5 pb-5 justify-content-between'>
              <Col className='p-0' sm={12} md={4}>
                <div>
                <img src={crownCommercial} style={{ 'maxHeight': '128px', 'objectFit': 'contain' }} tittle="crownCommercial logo"></img>
                </div>
              </Col>
              <Col className='p-0' sm={12} md={4}> 
                <div style={{ 'height':'100%' ,display:'flex',justifyContent:'flex-end'}}>
                  <img src={dspToolkit} style={{ 'maxHeight': '128px', 'maxWidth': '290px', 'objectFit': 'contain' }} tittle="dsp toolkit logo" /> 
                </div>
              </Col>
              <Col className='p-0' sm={12} md={2}>
                  <div style={{ 'height':'100%' ,display:'flex',justifyContent:'flex-end'}}>
                    <img src={dcb0129} style={{ 'maxHeight': '128px', 'maxWidth': '128px', 'objectFit': 'contain' }} tittle="dcb 0129" />
                  </div>
              </Col>
              <Col className='p-0' sm={12} md={2}>
                <div style={{ 'height':'94%' ,'display':'flex','justifyContent':'flex-end'}}>
                  <img src={dcb0160} style={{ 'paddingTop':'2px','maxHeight': '128px', 'maxWidth': '128px', 'objectFit': 'contain' }} tittle="dcb 0129" />
                </div>
              </Col>
            </Row>
          </Container>
          </>
        )}
    </>
  );
};

export default LandingBottomSection;
