import React from 'react';
import { useEffect,  useState} from 'react';
import { FaSearch } from 'react-icons/fa';
import { stripTags, stripTagsArray,removeDuplicatesFromSecondArray } from '../../functions/utils';
import { P } from '../theme/typography';

const AcResult = ({ list, list2, cursor, querySubmit, setHoveredAC }) => {
  // var index_add =0; 
  // let new_list = list;
  const [secondToDisplayList, setsecondToDisplayList] = useState(list);
  const [indexAdd, setIndexAdd] = useState(0);

  useEffect(()=>{
    if(list2 && list2.length> 0){
      let templist2 =  list2.map(x=>x["suggestion"] );
      if (templist2 && templist2.length > 0 ){
        let tempList = removeDuplicatesFromSecondArray(templist2,list) ;
        setIndexAdd(templist2.length);
        setsecondToDisplayList(tempList);
      }
    }
    else{
      setIndexAdd(0);
      setsecondToDisplayList(list);
    }
  },[list, list2]);

  return (
      // If AC list item needs to be filtered, it can be chained here.
      secondToDisplayList.map((value,index) => {
        return (
          <li
            key={index+indexAdd}
            onClick={(e) => querySubmit(e, value)}
            onMouseEnter={() => setHoveredAC(indexAdd+index+1)}
            onMouseLeave={() => setHoveredAC(0)}
            className={`ac_list--item ${(index+indexAdd + 1) === cursor ? 'active' +' ' : ''}`}
          >
            <div className="ac_list--item--magnify" aria-label="Search">
              <FaSearch title="search icon" />
            </div>
            <P
              p1
              regular
              textoffblack
              dangerouslySetInnerHTML={{ __html: value }}
            ></P>
          </li>
        );
      })
  );
};

export default AcResult;
