import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Button from '../button';
import StyledModal from './styles';
import { AppContext } from '../../context/index';
import './bootstrap-modal.min.css';
import { H2, P, StyledLink } from '../theme/typography';
import { ReactComponent as CloseIcon } from '../../assets/svgs/close-icon.svg';
import { getOrgData, getUser } from '../../functions/manageSessions';
import GoogleBtn from '../form/google-btn';

const ModalTriggerChatAnno = ({
  modalFeedbackShow,
  modalFeedbackClose,
  centered,
  title,
  bodyText,
}) => {
  const history = useHistory();
  const { searchContext, setSearchContext, setGoToChatPage, chatContext, setChatContext } = useContext(AppContext);


  const handleSubmit = () => {
    setGoToChatPage(true);
  };

  return (
    <StyledModal
      show={modalFeedbackShow}
      onHide={modalFeedbackClose}
      centered={centered}
    >
      <Modal.Header>
        <H2 h2 semibold textoffblack>
          {title}
        </H2>
        <CloseIcon
          className="modal-header-closeIcon"
          onClick={() => modalFeedbackClose()}
        />
      </Modal.Header>
      <Modal.Body>
        <P p1 regular textoffblack>
          The new deep AI search product is experimental and for healthcare professionals. It is only available to registered users.
        </P>
        <P p1 regular textoffblack style={{marginTop: '4px'}}>To sign-up for free click <a href="/signup"> here</a>.</P>

      </Modal.Body>
      {/* <Modal.Footer className="d-flex justify-content-between align-items-center">
        <Button
          type="action"
          colorsecondary
          buttonmedium
          text="Cancel"
          width="96px"
          centered
          onClick={() => modalFeedbackClose()}
        />
          <Button
                      type="link"
                      colorprimary
                      buttonmedium
                      to="/login"
                      text="Log in to continue"
                      width="156px"
                    />
        
      </Modal.Footer> */}
    </StyledModal>
  );
};

export default ModalTriggerChatAnno;
