import React from 'react';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';


export default function TypewriterAnimationChatSources({ string, handleOnLoad }){
    return(
        <Wrapper>
            <Typewriter 
                onInit={(typewriter) => {
                    typewriter.typeString(string)
                    .start()
                    .callFunction(() => {
                        document.querySelector(".Typewriter__cursor").remove();
                    })
                    // .pauseFor(200)
                    .callFunction(() => {
                        handleOnLoad();
                        // document.querySelector(".web-change").style.display = "inline";
                    });
                }}

                options={{ 
                    autoStart: true,
                    delay:50,
                    loop: false,
                    skipAddStyles: false,
                }}
            />
        </Wrapper>
    )
}


const Wrapper = styled.span`
    display: inline-block;
    text-align: left;
    span{
        text-align: left;
        opacity: 1;
    }
`
