import styled from 'styled-components';

export const Styles = styled.section`
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  padding: 36px 0 144px;

  @media (max-width: 991.98px) {
    padding: 26px 0 72px;
  }

  .title {
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing.x6};

    @media (max-width: 767.98px) {
      font-weight: ${({ theme }) => theme.fontweight.regular};
    }
  }

  .title2 {
    display:inline;
    margin-top: ${({ theme }) => theme.spacing.x4};

    @media (max-width: 767.98px) {
      font-weight: ${({ theme }) => theme.fontweight.regular};
    }
  }

  .card {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
    padding: 0 ${({ theme }) => theme.spacing.x2};

    @media (max-width: 767.98px) {
      margin-bottom: ${({ theme }) => theme.spacing.x5};
    }

    &_icon {
      margin-bottom: ${({ theme }) => theme.spacing.x2};
    }

    &_title {
      margin-bottom: ${({ theme }) => theme.spacing.x1};
    }
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacing.x6};

    @media (max-width: 767.98px) {
      margin-top: ${({ theme }) => theme.spacing.x2};
    }
  }

  .button2 {
    display: flex;
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacing.x3};

    @media (max-width: 767.98px) {
      margin-top: ${({ theme }) => theme.spacing.x2};
    }
  }
`;

export default Styles;
