import React, { useContext, useEffect, useState, useCallback } from 'react';
import DOMPurify from 'dompurify';
import { useLocation } from 'react-router-dom';
import { stripTags, isUrlPdf, removeUnecessaryPTags } from '../../functions/utils';
import { Container, Row, Col } from 'react-bootstrap';
import { ReactComponent as ErrorImage } from '../../assets/svgs/error-image.svg';
import { deviceDetect, isMobile } from 'react-device-detect';
import { AppContext } from '../../context';
import AcResult from './AcResult';
import { FaSearch } from 'react-icons/fa';
import { H2,  P, Span } from '../theme/typography';
import { Styles, SuggestedQuery } from './styles';
import { Tag } from '../filters/filters-desktop-tags/styles';
import { product_name, is_single_source } from '../../functions/frontend_variables';
import axios from 'axios';
import { getUser, getDictUserSources, getNumOfSearches, getAllSources, getUserFilters } from '../../functions/manageSessions';
import AcUserResult from './AcUserResult';
import OverideCard from './override-card/index.js';
import ResultCard from './result-card';
import WebCard from './web-card/index.js';


const ChatResultCards = ({ fetchedChatResults, isSearchResult, isOnSearchPage }) => {
  const { 
    // fetchedResults,
    feedbackContext,
    setFeedbackContext,
    searchContext,
    setSearchContext,
    setRequestTrackerContext,
    fetchedQuery,
    userContext,
    machineResults,
    webResults,
    setWebResults,
    localWebResults,
    setLocalWebResults,
    fetchedSuggestedQuestion,
    setLoadMore,
    setClearLocalWebResults
  } = useContext(
    AppContext
  );
  const location = useLocation();
  const [privateToken,setPrivateToken]= useState(''); 
  // const [showFlag, setShowFlag] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [acResults, setAcResults] = useState([]);
  const [purifyHtml,setPurifyHtml] =useState([]);
  const [acUserResults, setUserAcResults] = useState([]);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [isMagicUser, setIsMagicUser] = useState(false);
  const [bookmarks, setBookmarks] = useState(undefined);
  const [bookmarksFetched,setBookmarksFetched]= useState(false);
  const [updateBookmarks,setUpdateBookmarks] = useState(false); // TODO Change to trigger bookmark update
  const [requestExpiryToken,setRequestExpiryToken] = useState('');
  const [requestExpiryTokenOverride, setRequestExpiryTokenOverride] = useState('');
  const [requestFolderExpiryToken, setRequestFolderExpiryToken] = useState('');
  const [hasOnlinePdfUrl,setHasOnlinePdfUrl] = useState(false);
  const [urlPdf,setUrlPdf] = useState('');
  const [showModalFlag, setShowModalFlag] = useState(false);
  const [favourites, setFavourites] = useState(undefined);
  const [fetchedResults, setFetchedResults] = useState(fetchedChatResults);
  const [url,setUrl] = useState('');

  const [folderUrl, setFolderUrl] = useState('');
  
  const [hasNoSources, setHasNoSources] = useState(false);
  const [isOnlineViewUrlPdf, setIsOnlineViewUrlPdf] = useState([]);
  const [resultHasBookmark, setResultHasBookmark]=useState([]); // an array representing weather or the results is bookmarked
  const [resultHasFavourite, setResultHasFavourite]=useState([]); // an array representing weather or the results is favourite
  const [resultFavouriteID, setResultFavouriteID]=useState([]); // an array of favourite ids if the results has no favoiryt id = -1
  const [favouriteRemove, setFavouriteRemove]=useState([]); // an array of favourite to be remove
  const [allPublishersNames, setAllPublishersNames]=useState([]);
  const [publishersSources, setPublishersSources]=useState([]);
  

  const [onlineViewUrl, setOnlineViewUrl] = useState([]);

  useEffect(() => {
    setFetchedResults(fetchedChatResults);
  }, [fetchedChatResults]);

  // When clicking on "Visit website" or card title, we want this to be logged as an interaction
  // and we want to get private token if available to load prvt link 
  const handleLinkoutClick = (e, result) => {
    e.stopPropagation();
    if( result.from && result.from === 'private' && !(result['requested_obj']  && result['requested_obj']==='override')){
      // if result is private , we check if the source needs an /get-expiry-token 
          if (result.requested_obj){
            e.preventDefault();
            setUrl(result.online_view_url);
            if(result.online_pdf_url){
              setHasOnlinePdfUrl(true);
              setUrlPdf(result.online_view_url);
            }
            else{
              setHasOnlinePdfUrl(false);
            }
            setRequestExpiryToken(result.requested_obj);
          }
          // url comes form private but it is not a private src
          // so no need to request token 
          else {
            console.log("TODO");
            if(result.online_pdf_url){
              e.preventDefault();
              setHasOnlinePdfUrl(true);
              setUrlPdf(result.online_view_url);
              window.open(result.online_view_url,'_blank','noreferrer');
            }
            else{
              setHasOnlinePdfUrl(false);
            }
          }
        // }
    } else if (result['requested_obj']  && result['requested_obj']==='override') {
      e.preventDefault();
      setUrl(result.online_view_url);
      setRequestExpiryTokenOverride(result.doc_publisher.substring(8,));
    };
    // TO DO move to useEffect 
    // request should be in useEffect to allow for cancel at clean up
    // if (searchContext.q){
    //   body = {
    //     // chunk_id: result.chunk_id,
    //     // chunk_title: title, 
    //     // online_view_url: result.online_view_url,
    //     // requested_obj:result.requested_obj,
    //     // "selected-filters": facets,
    //     // doc_publisher:result.doc_publisher,
    //     // rank: result.rank,
    //     // product: product_name,
    //     // "interaction_type": "visit",
    //     ...body,
    //     q: searchContext.q
    //   }; }
    if (!isOnSearchPage) {
      let body = { 
        dialog_id:result.dialog_id,
        tracker_number:result.tracker_number,
        "interaction_type": "visit"
      };
      const config = {
        method: 'post',
        url: `${window.BASE_URL_USER}log-dialog-interaction`,
        withCredentials : true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(body),
      };
      axios(config);
    } else {
      let selectedSourcesIDs = [];
      let facets = searchContext.facets;
      if ((!is_single_source) && facets && facets.length !== 0) {
        searchContext.facets.forEach(source => {
          // if it is a src object 
          if (source && source["encrypted_url"] && (source['name'] !=="Web" || facets.length ===1 )){ 
          //check source is valid before sending a request
            if(source.hasOwnProperty('id')){
              selectedSourcesIDs.push(source['id']);
            };
          }
          // else it is not a src but an display filter add array of sources
          else {
            if(source && source['sources']&& (source['name'] !=="Web" || facets.length ===1 )){
              source['sources'].forEach(source => {
                if(source.hasOwnProperty('id')){
                  selectedSourcesIDs.push(source['id']);
                }
              });
            }
          }
        });
      };

      let body = { 
        interaction_type: "visit",
        online_view_url: result.online_view_url,
        q: searchContext.q,
        assisted_response: machineResults[0],
        suggested_question: fetchedSuggestedQuestion.at(0),
        get_filters_response: getUserFilters(),
        selected_sources: selectedSourcesIDs,
      };
      const config = {
        method: 'post',
        url: `${window.BASE_URL_USER}log-result-interaction`,
        withCredentials : true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(body),
      };
      axios(config);
    };
  };

  const handleFolderLinkClick= (e, result) => {
    e.stopPropagation();
    // let facets =  searchContext.facets.map(x=>x.name);
    // let title =  result.chunk_title
    let body = { 
      dialog_id:result.dialog_id,
      tracker_number:result.tracker_number,
      "interaction_type": "visit"
    };
    if (result.from && result.from === 'private' && result['requested_obj']  && result['requested_obj']==='override') {
      e.preventDefault();
      setFolderUrl(result['parent_folder']);
      // we are removing 'private:' why ?? 
      //TO DO : ask Aman about get expiry token
      // setRequestFolderExpiryToken(result['requested_obj']==='override');
      setRequestFolderExpiryToken(result.doc_publisher.substring(8,));
    };
    // TO DO move to useEffect 
    // request should be in useEffect  to allow for cancel at clean up
    // if (searchContext.q) {
    //   body = {
    //     ...body,
    //     // chunk_id: result.chunk_id,
    //     // chunk_title: title, 
    //     // online_view_url: result.online_view_url,
    //     // requested_obj:result.requested_obj,
    //     // "selected-filters": facets,
    //     // doc_publisher:result.doc_publisher,
    //     // rank: result.rank,
    //     // product: product_name,
    //     // event:"visited",
    //     q: searchContext.q
    //   }; 
    // }
    if (!isOnSearchPage) {
      const config = {
        method: 'post',
        url: `${window.BASE_URL_USER}log-dialog-interaction`,
        withCredentials : true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(body),
      };
      axios(config);
    };
  };

   // When clicking on "Visit website" or card title, we want this to be logged as an interaction
  // and we want to get private token if available to load prvt link 
  const handleDownload = (e, result) => {
    e.stopPropagation();
    let facets =  searchContext.facets.map(x=>x.name);
    let title =  result.chunk_title
    let body = { 
      // chunk_id: result.chunk_id,
      online_view_url:result.online_view_url,
      // requested_obj:result.requested_obj,
      // "selected-filters": facets,
      // doc_publisher:result.doc_publisher,
      // rank: result.rank,
      // product: product_name,
      "interaction_type":"visit"};
      // if result has private , we check if the source needs an /get-expiry-token 
      if( result.from && result.from === 'private'){
          if (result.requested_obj){
            e.preventDefault();
            setUrl(result.online_view_url);
            setUrlPdf(result.online_view_url);
            setRequestExpiryToken(result.requested_obj);
          }
          // url comes form private but it is not a private src
          // so no need to request token 
          else {
            if(result.online_view_url){
              setUrl(result.online_view_url);
              setUrlPdf(result.online_view_url);
              e.preventDefault();
              window.open(result.online_view_url,'_blank','noreferrer');
            }
          }
             
        // }
    }
    // TO DO move to useEffect 
    // request should be in useEffect only to allow for cancel and clean up
    if( searchContext.q){
      body = {
        ...body,
        // chunk_id: result.chunk_id,
        // chunk_title: title, 
        // online_view_url: result.online_view_url,
        // requested_obj:result.requested_obj,
        // "selected-filters": facets,
        // doc_publisher:result.doc_publisher,
        // rank: result.rank,
        // product: product_name,
        // event:"visited",
        q: searchContext.q
      }; }
    const config = {
      method: 'post',
      url: `${window.BASE_URL_USER}log-result-interaction`,
      withCredentials : true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(body),
    };
    axios(config);
  };

  const handleFeedBackClick = (e, chunkId, tittle, online_view_url, source,  score, rank) => {

    let clickedVoteIcon = e.currentTarget;

    // If this button has already been clicked, don't send the vote again
    if (clickedVoteIcon.classList.contains('thumbs_icon_voted')) {
      return;
    }

    let upVoteIcon = document.getElementById('thumbs_up_chunk_' + chunkId)
    let downVoteIcon = document.getElementById('thumbs_down_chunk_' + chunkId)

    // Reset states on both
    upVoteIcon.classList.remove('thumbs_icon_voted')
    downVoteIcon.classList.remove('thumbs_icon_voted')
    upVoteIcon.classList.add('thumbs_icon_unvoted')
    downVoteIcon.classList.add('thumbs_icon_unvoted')

    // Add voted class to change color for it, to let user know their vote has been sent
    clickedVoteIcon.classList.remove('thumbs_icon_unvoted')
    clickedVoteIcon.classList.add('thumbs_icon_voted')
    let facets =  searchContext.facets.map(x=>x.name);
    let body = {
      chunk_id: chunkId,
      chunk_title: tittle, 
      online_view_url: online_view_url,
      "selected-filters": facets,
      doc_publisher: source,
      value: score,
      rank: rank,
      product: product_name,
      event: "vote",
      q: searchContext.q};

    const config = {
      method: 'post',
      url: `${window.BASE_URL_USER}add-user-logs`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(body)
    }

    axios(config)
  }

  // Important note: First result is coded inline (made use of the map method)
  const handleCardClick = (e, result, i) => {
    let cardBody = e.currentTarget.querySelector('.card_body');
    // let cardExpandIcon = e.currentTarget.querySelector('.card_expandIcon');
    // let cardFooter2 = e.currentTarget.querySelector('.card_footer_2');
    // if card is not expanded  expanded the card
    if (!cardBody.classList.contains('expanded')) {
      result.expanded = 'expanded';
      setFeedbackContext({
        ...feedbackContext,
        chunk_id: result.chunk_id,
        chunk_title:result.chunk_title,
        online_view_url: result.online_view_url,
        requested_obj: result.requested_obj,
        chunk_text: result.text,
        doc_publisher: result.doc_publisher,
        rank: i,
        value: 1,
        feedbackType: 'interact',
        triggerRequest: true,
      });
    } else{
      if (isMobile){
        result.expanded = undefined;
        setFeedbackContext({
          ...feedbackContext,
          chunk_id: result.chunk_id,
          chunk_title:result.chunk_title,
          online_view_url: result.online_view_url,
          requested_obj: result.requested_obj,
          chunk_text: result.text,
          doc_publisher: result.doc_publisher,
          rank: i,
          value: 1,
          feedbackType: 'interact',
          triggerRequest: false,
        });
      }
    }
  };

  // input: a string representing a encrypted_url
  // output: a string with the name of the sources  
  const get_publisher_name = (val) => {
    const default_sources_dict = getDictUserSources();
    if (default_sources_dict[val] && default_sources_dict[val].name 
        &&default_sources_dict[val].name.trim()!=='' ){
      return default_sources_dict[val].name; 
    }
    return val;
  }

  // input: a string representing a encrypted_url
  // output: a object   
  const get_publisher_source = (encrypted_url) => {
    const default_sources_dict = getDictUserSources();
    let out = undefined; 
    if (default_sources_dict[encrypted_url] && default_sources_dict[encrypted_url].name 
        &&default_sources_dict[encrypted_url].name.trim()!=='' ){
      out = default_sources_dict[encrypted_url]; 
    }
    // else{
    //   const facets = searchContext.facets; 
    //   // if we dont find anything we look for search Context the name 
    //   if (searchContext['facets']){
    //     searchContext['facets'].forEach(facet => {
    //       if(facet && facet['name'] && facet['name'].toLowerCase() === encrypted_url.toLowerCase() ){
    //         out = facet;
    //       }
    //     });
    //   }
    // }
    return out;
  }

    // This where the submission is. 
  // Change of "q" parameter in setSearchContext triggers the API call in context if queryFromACClick .
  // queryFromACClick is passed from the AcResult.js. Therefore, 'clicked value' needs to be passed to this function to trigger the search.
  const querySubmit = useCallback(
    (e, queryFromACClick) => {
      e.preventDefault();
      let query;
      if (queryFromACClick) {
        query = stripTags(queryFromACClick);
      } 
      // get selected filters 
      // This state not only triggers the API call
      setSearchContext({
        ...searchContext,
        demo_q_clicked: false,
        "from-trending":false,
        q: query,
        covid_only: false,
      });
      closeAutoCompleteWrapper();
    }    
  );
  // Close the autocomplete wrapper. Same as useOutsideClickDetector and escape button press
  const closeAutoCompleteWrapper = () => {
    setAcResults(undefined);
    setUserAcResults(undefined);
  };

  const getAllSourcesForSuggest = ()=>{
    let allSourcesObj= getAllSources();
    let allSources = '';
    if ( allSourcesObj && allSourcesObj.length >0 )
    {
      allSourcesObj.forEach(element => {
        if (element['encrypted_url']){
          allSources = allSources + ',' +element['encrypted_url'] ;
        }
      });
      allSources = allSources.substring(1);
    }
    else {
      allSources =undefined;
    }
    return allSources;
  };


  const getUserSuggestions = (query) => {
    let allSources =getAllSourcesForSuggest();
    if (allSources && typeof allSources !== 'undefined' && query && query !== '' ) {   
      axios({
        method: 'post',
        url:`${window.BASE_URL_USER}user-suggestions`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          q: query.trim(),
          sources: allSources,
        }),
      })
        .then((res) => {
          if(res.data.suggestions && res.data.suggestions.length> 0 ){
            let arrayRes = res.data.suggestions;
            let filterRes = arrayRes.filter(x => x["online_view_url"]===null && x["suggestion"]===null );
            let mapRes = filterRes.map(x=>x["suggestion"]);
            if(mapRes.length>0){
              setUserAcResults(mapRes);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getSuggestions = (query) => {
    let allSources = getAllSourcesForSuggest();
    if (allSources && typeof allSources !== 'undefined') {
      if (typeof query=== 'string'){
        query = query.trim();
      }
      axios({
        method: 'post',
        url:`${window.BASE_URL_ASK}suggest`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          q: query,
          sources: allSources,
        }),
      })
        .then((res) => {
          setAcResults(res.data.suggestions);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getFooter = function(){
    setRequestTrackerContext(prev => { return {...prev, 'get-footer':true}});
    axios({
      method: 'post',
      url: `${window.BASE_URL_USER}get-footer`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        online_view_urls: onlineViewUrl,
      }),    })
      .then((res) => {
        setRequestTrackerContext(prev => { return {...prev, 'get-footer':false}});
        setFavourites(res.data["favourites"]);//TO DO if user changes this should change to false
        setBookmarks(res.data["cpd_entries"]);///TO DO if user changes this should change to false
        setBookmarksFetched(true);//TO DO if user changes this should change to false
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'get-footer':false}});
        console.log('Error: ', error);
      });
  };

  const activateFeedback =(result,i) => {
    // setCurrentIndex(result);
    // setCurrentIndex(i);
    setShowModalFlag(i);
    // setShowFlag(false);
  }


  const callbackResultExpanded = (result,i) => {
    return ()=>{ 
      result.expanded =  'expanded';
      result.noteExpanded = true;
      };
  };

  const handleUnexpanded = (e, result, i) => {
    result.expanded = undefined;
    result.noteExpanded = false;
    setFeedbackContext({
      ...feedbackContext,
      chunk_id: result.chunk_id,
      chunk_title:result.chunk_title,
      online_view_url: result.online_view_url,
      chunk_text:  result.text,
      doc_publisher: result.doc_publisher,
      rank: i,
      value: 1,
      feedbackType: 'interact',
      triggerRequest: false,
    });
  };

  // returns all result that have a bookmark and 
  // are assotiated to that result object
  // input: a result object 
  // output: an array of result object associated with input
  const hasBookmark = (result) =>{
    let out_bookmark = [];
    if (bookmarks && bookmarks.length >0 && result ){
      bookmarks.forEach(bookmark => {
        if (bookmark && bookmark['online_view_url'] && result['online_view_url'] 
            && bookmark['online_view_url'] === result['online_view_url']){
          out_bookmark.push(bookmark);
        }
      });
    }
    return out_bookmark;
  }

  const hasFavourite = (result) =>{
    let found = false;
  
    if (favourites && favourites.length>0 && result) {
      favourites.forEach(favourite => {
        if (favourite&& favourite['online_view_url'] && result['online_view_url'] 
            && favourite['online_view_url'] === result['online_view_url']){
          found = true;
        }
      });
    }
    return found;
  };

  // loops through all favorite & returns id of favorite
  // input : a result obj 
  // out: a int 
  // if no favorite -1  si return
  const getFavouriteID = (result) => {
    let id = -1;
    if (favourites && result ){
      favourites.forEach(favourite => {
        if (favourite && favourite['online_view_url'] && result['online_view_url'] 
            && favourite['online_view_url'] === result['online_view_url']){
          id = favourite['id'];
        }
      });
    }
    return id;
  };

  // update arrays keeping track of favorite results
  const updateFavourites = (i, id) => {
    let temp_favourite = resultHasFavourite;
    let temp_id = resultFavouriteID;
    let favourite_remove = favouriteRemove;
    if (temp_favourite) {
      temp_favourite[i] = !temp_favourite[i];
      temp_id[i] = id;
      setResultHasFavourite(temp_favourite);// updated hasfavority
      setResultFavouriteID(temp_id);// update favourite
      if (temp_favourite[i] === false) {
        // if we are removing a favorite we need to updte the favouriteRemove array
        favourite_remove[i] = true;
        setFavouriteRemove(favourite_remove);
      }
      // else {
      //   favourite_remove[i] = false;
      //   setFavouriteRemove(favourite_remove);
      // }
    };
  };

  useEffect(() => {
    let user_info =getUser() ; 
    if (user_info) {
      setIsLoggedin(true);
      if (user_info && user_info['org-user-type']==='magic'){
        setIsMagicUser(true);
      }
      else {
        setIsMagicUser(false);
      }
    } else {
      setIsLoggedin(false);
      setIsMagicUser(false);
    }
  },[isLoggedin]);

  useEffect (() => {
    if (searchContext.facets && searchContext.facets.length <= 0 ){
      setHasNoSources(true);
    }
    else{
      setHasNoSources(false);
    }
    // getUserSuggestions(searchContext.q);
    // getSuggestions(searchContext.q);
  },[searchContext.q, fetchedResults]);

  useEffect (() => {
    if (fetchedResults&&!isLoggedin){
      if (!getUser()  && getNumOfSearches() && getNumOfSearches()>=3 ){
        setShowModal(true);
      }
    }
  },[fetchedResults,isLoggedin,updateBookmarks]);

  useEffect (() => {
    if (fetchedResults  && onlineViewUrl && onlineViewUrl.length > 0 )
    {
      // let res = getFavourites(searchContext);
      if(!isLoggedin){
        setBookmarks([]);
      }
      else{
        let res1 = getFooter();}
    }
    else {
      if (fetchedResults&&!isLoggedin){
      if (!getUser()  && getNumOfSearches() && getNumOfSearches()>=3 ){
            setShowModal(true);
        }
      }
    }
  },[onlineViewUrl,isLoggedin,updateBookmarks]);
  
  useEffect (() => {
    if (requestExpiryToken !== '' && url !== '' )
    {
      // let temp_value =requestExpiryToken.replace(/&amp;/g, '&');
      let splited = requestExpiryToken.split('/');
      let temp_value1 =  decodeURIComponent(requestExpiryToken);
      let temp_value2 =  decodeURI(requestExpiryToken);
      let temp_value3 =  decodeURIComponent(splited[1]);
      //add axios and add-log and redirrect to link
      // const config = {
      //   method: 'post',
      //   url: `${window.BASE_URL_USER}get-expiry-token`,
      //   withCredentials : true,
      //   headers: {'Content-Type': 'application/json' },
      //   data: JSON.stringify({'requested': temp_value1}),
      // };
  
      // axios(config)
      // .then(function(res){
        if (userContext['search_params']){
          setPrivateToken(userContext['search_params']);
          let url_token = '';
          if(hasOnlinePdfUrl){
            let s =  urlPdf.replace('__PUBLIC_TOKEN__',userContext['search_params'] );
            if (s!== urlPdf){
              url_token = s; 
            }
          }
          window.open(url_token,'_blank','noreferrer');
        }
        else{
          // if 400 previous url
            console.log('not found ');
            window.open(urlPdf,'_blank','noreferrer');
        }
      setRequestExpiryToken('');
    }
  },[requestExpiryToken]);

  useEffect (() => {
    if (requestExpiryTokenOverride !== '' && url !== '' )
    {
      // //add axios and add-log and redirrect to link
      // const config = {
      //   method: 'post',
      //   url: `${window.BASE_URL_USER}get-expiry-token`,
      //   withCredentials : true,
      //   headers: {'Content-Type': 'application/json' },
      //   data: JSON.stringify({'requested': requestExpiryTokenOverride}),
      // };
  
      // axios(config)
      // .then(function(res){
        if (userContext['search_params']){
          setPrivateToken(userContext['search_params']);
          let url_token = '';
          let s =  url.replace('__PUBLIC_TOKEN__',userContext['search_params'] );
          if (s!== url){
            url_token = s; 
          };
          window.open(url_token,'_blank','noreferrer');
        } else {
          // if 400 previous url
          console.log('not found ');
          window.open(url,'_blank','noreferrer');
        };
      setRequestExpiryTokenOverride('');
    }
  },[requestExpiryTokenOverride]);

  useEffect (() => {
    if (requestFolderExpiryToken !== '' && folderUrl !== '' )
    {
    //   //add axios and add-log and redirrect to link
    //   const config = {
    //     method: 'post',
    //     url: `${window.BASE_URL_USER}get-expiry-token`,
    //     withCredentials : true,
    //     headers: {'Content-Type': 'application/json' },
    //     data: JSON.stringify({'requested': requestFolderExpiryToken}),
    //   };
  
    //   axios(config)
    //   .then(function(res){
        if (userContext['search_params']) {
          setPrivateToken(userContext['search_params']);
          let url_token = '';
          let s =  folderUrl.replace('__PUBLIC_TOKEN__',userContext['search_params'] );
          if (s!== folderUrl){
            url_token = s; 
          }
          window.open(url_token,'_blank','noreferrer');
        }
        else{
          // if 400 previous url
          console.log('not found');
          window.open(folderUrl,'_blank','noreferrer');
        }
      setRequestFolderExpiryToken('');
    }
  },[requestFolderExpiryToken]);

  // is UrlPDF should not be call at render but at the start
  useEffect(() => {
    //isUrlPdf(result.online_view_url)
    let newArrayPdf =[]; 
    let publishersNames = [];
    let favouritesIDArray = [];
    let onlineViewUrlArray = [];
    let allPublishersSources = [];
    let publishersUrl = [];
    if(fetchedResults){
      fetchedResults.forEach(result => {
        newArrayPdf.push(isUrlPdf(result.online_view_url));
        favouritesIDArray.push(getFavouriteID(result));
        publishersUrl.push(result.doc_publisher);
        let publisher = get_publisher_source(result.doc_publisher);
        allPublishersSources.push(publisher)
        let tempName =  result.doc_publisher;
        if(publisher !== undefined  && publisher['name']){
          tempName = publisher['name'];
        }
        publishersNames.push(tempName );
        onlineViewUrlArray.push(result.online_view_url);
      });
      setIsOnlineViewUrlPdf(newArrayPdf);
      setResultFavouriteID(favouritesIDArray);
      setFavouriteRemove([]);
      setAllPublishersNames(publishersNames);
      setPublishersSources (allPublishersSources);
      setOnlineViewUrl(onlineViewUrlArray);
    }
  }, [fetchedResults])

  // is UrlPDF should not be call at render but at the start
  useEffect(() => {
    //isUrlPdf(result.online_view_url)
    let hasBookmarksArray = [];
    let hasFavouritesArray = [];
    if(fetchedResults){
      fetchedResults.forEach(result => {
        hasBookmarksArray.push(hasBookmark(result));
        hasFavouritesArray.push(hasFavourite(result));
      });
      setResultHasBookmark(hasBookmarksArray);
      setResultHasFavourite(hasFavouritesArray);
    }
  }, [bookmarks])
  
  useEffect(() => {
    let hasFavouritesArray = [];
    let favouriteIDArray = [];
    let onlineViewUrlArray = [];
    if(fetchedResults){
      fetchedResults.forEach(result => {
        hasFavouritesArray.push(hasFavourite(result));
        favouriteIDArray.push(getFavouriteID(result));
        onlineViewUrlArray.push(result.online_view_url);
      });
      setResultHasFavourite(hasFavouritesArray);
      setResultFavouriteID(favouriteIDArray);
    }
  }, [favourites])

  const handleCloseModal = () => {
    setShowModal(false);
  }
  
  const goToAddSources = () => {
    if (isMagicUser || !isLoggedin){
      window.open(window.BASE_URL_DASHBOARD+"dashboard/no_accounts.html","_self");
    }
    else {
      window.open(window.BASE_URL_DASHBOARD+"dashboard/sources.html","_self");
    }
  };

  useEffect(()=>{
    let currentPurifyHtml= [];
    if(fetchedResults){
      DOMPurify.addHook('afterSanitizeElements', function (node) {
        // console.log(node);
        if (node.nodeName && node.nodeName === 'P' ) {
          // node.textContent = node.textContent.toUpperCase();
          if (node.textContent.trim().split(' ').length <= 1 && node.parentNode) {
            node.insertAdjacentHTML('afterend', node.textContent);
            node.remove();
          }
        }
      });
      fetchedResults.forEach(result => {
        if(result['text']){
          let out = DOMPurify.sanitize(result['text'], {FORBID_TAGS: ['img']});
          currentPurifyHtml.push(out);
        }
        else{
          currentPurifyHtml.push("");
        }
      });
    }
    setPurifyHtml(currentPurifyHtml);
  
  },[fetchedResults]);

  function handleLoadWebResults() {
    setFetchedResults(fetchedResults.concat(Object.values(localWebResults)[0]));
    var loadWebDiv = document.getElementById('load-web');
    loadWebDiv.style.display = "none";
    setLocalWebResults({0: [], 1: [], 2: [], 3: [], 4: []});
  };

  function handleNotLoadWebResults() {
    var loadWebDiv = document.getElementById('load-web');
    loadWebDiv.style.display = "none";
    setWebResults({0: [], 1: [], 2: [], 3: [], 4: []});
    setLocalWebResults({0: [], 1: [], 2: [], 3: [], 4: []});
    setClearLocalWebResults(true);
    setLoadMore(false);
  };

  return (
    <>
     {/* {(typeof fetchedQuery !== "undefined"  && typeof fetchedQuery !== "null" 
      && fetchedQuery.text &&  typeof fetchedQuery.text === "string" && fetchedQuery.html &&  typeof fetchedQuery.html === "string" ) &&(
        <SuggestedQuery  p1 regular textoffblack className="title">
          <div className="spelling-search" aria-label="Search"  >
          <P p1 regular textoffblack semibold  className='spelling-text'>Did you mean:</P>
              <P
                className='suggestedQuery'
                p1
                regular
                textoffblack
                onClick={(e) => querySubmit(e, fetchedQuery.text)}
                dangerouslySetInnerHTML={{ __html: fetchedQuery.html }}
              >
              </P>
              </div>
        </SuggestedQuery>
          )} */}

      {/* { (typeof fetchedQuery !== "undefined"  && typeof fetchedQuery !== "null" && typeof fetchedQuery === "object"  && fetchedQuery.length >0 )  &&(
        <SuggestedQuery  p1 regular textoffblack className="title">
        <div className="spelling-search" aria-label="Search"  >
        <P p1 regular textoffblack semibold  className='spelling-text'>Related searches:</P>
          {fetchedQuery && fetchedQuery.map( (qSuggested,i) =>
          <>
          {i !==(fetchedQuery.length-1) ?
          <P
          className='suggestedQuery'
          p1
          regular
          textoffblack
          onClick={(e) => querySubmit(e, qSuggested.text)}
          dangerouslySetInnerHTML={{ __html: qSuggested.html+',' }}
        >
        </P> : 
          <P
          className='suggestedQuery'
          p1
          regular
          textoffblack
          onClick={(e) => querySubmit(e, qSuggested.text)}
          dangerouslySetInnerHTML={{ __html: qSuggested.html }}
        >
        </P> }
        </>
            )  }
            </div>
      </SuggestedQuery>
      )} */}
      {/* {!isMobile ? (<ReactTooltip place="bottom" effect="solid" offset={{bottom: 7}} backgroundColor="#212452" textColor="#FFF"/>) : '' } */}
      {fetchedResults &&
        fetchedResults.map((result, i) => {
          return (
            result['from'] && result['requested_obj'] && result['from']==='private' && result['requested_obj']==='override' ? (
            <OverideCard key={'override_'+i+'_'+allPublishersNames[i]} i={i} isSearchResult={isSearchResult} isOnSearchPage={isOnSearchPage} result={result} 
            publisher={allPublishersNames[i]} publisherSource={publishersSources[i]} bookmarkedFetched={bookmarksFetched} hasBookmarks={resultHasBookmark[i]} 
            updateBookmarks={updateBookmarks} setUpdateBookmarks={setUpdateBookmarks} isFavourite={resultHasFavourite[i]} 
            toBeRemove={favouriteRemove[i]} favouriteID={resultFavouriteID[i]} isLoggedin={isLoggedin} isMagicUser={isMagicUser}
            updateFavourites={updateFavourites} callbackResultExpanded={callbackResultExpanded}
            handleLinkoutClick={handleLinkoutClick} handleFolderLinkClick={handleFolderLinkClick}></OverideCard>
            )
            :
            result['from'] && !result['requested_obj'] && !result['online_pdf_url'] && result['from'].includes('reranker') && result['doc_publisher']==='Web' ? (
            <WebCard key={'web_'+i+allPublishersNames[i]}  i={i} isSearchResult={isSearchResult} isOnSearchPage={isOnSearchPage} result={result} isOnlineViewUrlPdf = {isOnlineViewUrlPdf[i]} 
              publisher={allPublishersNames[i]} publisherSource={publishersSources[i]}
              purifiedHtml={purifyHtml[i]} bookmarkedFetched={bookmarksFetched} hasBookmarks={resultHasBookmark[i]} 
              updateBookmarks={updateBookmarks} setUpdateBookmarks={setUpdateBookmarks}
              isFavourite={resultHasFavourite[i]} toBeRemove={favouriteRemove[i]} favouriteID={resultFavouriteID[i]}
              isLoggedin={isLoggedin} isMagicUser={isMagicUser}
              updateFavourites={updateFavourites} callbackResultExpanded={callbackResultExpanded}
              handleLinkoutClick={handleLinkoutClick}></WebCard>
            ) 
            :
            (
            <ResultCard key={'result_'+i+allPublishersNames[i]} i={i} isSearchResult={isSearchResult} isOnSearchPage={isOnSearchPage} result={result}  isOnlineViewUrlPdf = {isOnlineViewUrlPdf[i]} 
              publisher={allPublishersNames[i]}  publisherSource={publishersSources[i]} purifiedHtml={purifyHtml[i]} bookmarkedFetched={bookmarksFetched} 
              hasBookmarks={resultHasBookmark[i]} updateBookmarks={updateBookmarks} setUpdateBookmarks={setUpdateBookmarks}
              isFavourite={resultHasFavourite[i]} toBeRemove={favouriteRemove[i]} favouriteID={resultFavouriteID[i]}
              isLoggedin={isLoggedin} isMagicUser={isMagicUser}
              updateFavourites={updateFavourites} callbackResultExpanded={callbackResultExpanded}
              handleLinkoutClick={handleLinkoutClick}></ResultCard>
            )
          );
        }
        )}
      {hasNoSources ? (
      <Styles>
          <Container>
            <Row>
              <Col md={12} className="error">
                <ErrorImage />
                <H2 h2 regular textoffblack className="error_title">
                  Please select a source to search with, or add a source below!
                </H2>
                <P p1 regular textmainbody >
                  <Tag 
                    key={-2}
                    className="coloured"
                    onClick={goToAddSources} >
                    <Span bold style={{fontSize: '16px'}}> Add a source</Span>
                  </Tag>
                </P>
                <P p1 regular textmainbody className="error_message">
                </P>
              </Col>
            </Row>
          </Container>

        </Styles>):''}
      {(location.pathname === '/deep-ai-search') && (fetchedResults && fetchedResults.length===0) && !hasNoSources && ((acResults && acResults.length > 0) || (acUserResults && acUserResults.length > 0)) ? (
        <Styles>
          <Container>
            <Row>
              <Col md={12} className="error">
                <ErrorImage />
                <H2 h2 regular textoffblack className="error_title">
                  No results found for: 
                  <P bold> {searchContext.q } </P>
                </H2>
                <P p1 regular textmainbody >
                  Try selecting different sources, or take a look at one of the following queries:
                  <br/>
                </P>
                <div className="ac" >
                  <ul className="ac_list">
                    {acUserResults && (
                      <AcUserResult
                        list={acUserResults}
                        querySubmit={querySubmit}
                      />
                    )}                
                    {acResults && (
                      <AcResult
                        list={acResults}
                        querySubmit={querySubmit}
                      />
                    )}
                  </ul>
                </div>
                <P p1 regular textmainbody className="error_message">
                </P>
              </Col>
            </Row>
          </Container>
        </Styles>
      ) : ''}
      {(location.pathname === '/deep-ai-search') && (fetchedResults && fetchedResults.length === 0) && !hasNoSources && (acUserResults && acResults && acResults.length <= 0 && acUserResults.length <= 0) ? (
        <Styles>
          <Container>
            <Row>
              <Col md={12} className="error">
                <ErrorImage />
                <H2 h2 regular textoffblack className="error_title">
                  No results found for: 
                  <P bold> {searchContext.q } </P>
                </H2>
                <P p1 regular textmainbody >
                  Sorry, we don't seem to have an answer for this yet - we have logged it and will investigate!
                  In the meantime, try adjusting your search query or selecting other sources.
                  <br/>
                </P>
                <P p1 regular textmainbody className="error_message">
                </P>
              </Col>
            </Row>
          </Container>
        </Styles>
      ) : ''}   

      {localWebResults && Object.values(localWebResults)[0] && Object.values(localWebResults)[0].length ? (
        <div id='load-web'>
          <p className='load-web-container'>No answers were found from the selected source(s). Would you like to search the web?</p>
          <div className='load-web-container' style={{marginTop: isMobile ? '8px' : '1rem', marginBottom: isMobile ? '8px' : '1rem'}}>
            <span className='load-more' onClick={handleLoadWebResults} style={{marginRight: '8px'}}>Yes</span>
            <span className='load-more' onClick={handleNotLoadWebResults}>No</span>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ChatResultCards;
