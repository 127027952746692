import styled from 'styled-components';

export const SideFilterContainer = styled.div`
    display: flex;
    align-items: center;
    // padding: ${({ theme }) => theme.spacing.x2} 0 0 0;
    padding: 0;
    margin:  0 0 0 ${({ theme }) => theme.spacing.x2} ;
    line-break: after;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #212452;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: 3px;
    overflow: hidden;

    option{
      cursor: pointer;
    }

    .greyOut{
      color: grey;
    }
    .source-link{
      svg{
        color: 212452;
      }
    }
    .filter-box{
        width:100%;
        background-color: #fff;
        margin-left:8px;
        padding-bottom:0;
    }
    .sources-filters{
      text-align: center;
      justify-content: space-between;

    }
  

  .filterGroups {

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid #97AAB6 ;

    cursor: pointer;
    
    font-size: 14px;
    &.middle{
      border-right: none;
      border-left: none;
    }
    &.active{
      // color: #FFFFFF;
      // background-color: #8771DF;
      background-color:  #E4E0F7;
    }
    &.grey {
      color: grey;
      cursor: not-allowed;
      border: 1px solid grey ;
    }
  }
        

    
`;


export default SideFilterContainer;
