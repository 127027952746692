import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { setToken } from '../../functions/manageSessions';

import Header from '../../components/header';
import LoginFormAfterSignup from '../../components/form/login-form-after-signup';

import { P, StyledLink } from '../../components/theme/typography';

import { FormWrapper, SwitchFlow } from './styles';

const LoginPageAfterSignup = () => {
  useEffect(() => {
    axios
      .get(`${window.BASE_URL_APP}api/account/login_signup_successful`)
      .then((res) => {
        const accessToken = res.data.csrf_token;
        setToken(
          accessToken
            ? accessToken
            : 'IjMwMjYwYWVkODA4MDNiYTEzMzgwMzM1ZTY3MDcyZDZlMGQ1ODRmMzEi.X8IYYQ.QPSb5WPUHtemS4pcaobqT4e0DC8'
        );
        // TODO: Once the csrf_token issue is resolved, remove the above setToken func. call and use the below one.
        // setToken(accessToken);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Header withSearchBar={false} withAccountButtons={false} />
      <main>
        <Container>
          <Row>
            <Col lg={{ span: 6, offset: 3 }}>
              <FormWrapper>
                <LoginFormAfterSignup />
              </FormWrapper>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 6, offset: 3 }}>
              <SwitchFlow>
                <P h2 semibold textoffblack>
                  Don’t have an account?{' '}
                  <StyledLink textlink="true" to="/signup">
                    Sign Up
                  </StyledLink>
                </P>
              </SwitchFlow>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};

export default LoginPageAfterSignup;
