import styled from 'styled-components';

export const Article = styled.article`
  main {
    height: calc(100vh - 182px); // 182, 254
    overflow-y: auto;
  }

  .header-subtitle {
    font-size: 18px;
    color: #b4b3b3;
    font-weight: 400;
    text-transform: uppercase;
  }

  .header-title {
    font-size: 56px;
    line-height: 58px;
    margin-bottom: 48px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .result {
    font-size: 100px;
    color: #212452;
    line-height: 100px;
  }

  .result-section {
    flex-direction: column;
  }

  .numDoctors {
    padding: 12px 20px;
    height: 58px;
    font-size: 23px;
    margin-bottom: 48px;
  }

  .numDoctorsLabel {
    display: block;
    font-size: 18px;
    margin-bottom: 8px;
  }

  .calculate {
    height: 58px;
    font-size: 23px;
    background: hsla(33.75, 100%, 52.94117647%, 1);
    color: #fff;
    padding: 16px;
  }

  .resultText {
    font-size: 22px;
    color: #212452;
    text-transform: uppercase;
  }

  .accordion-header {
    border: 1px solid #f7f2ed;
    padding: 32px 36px;
    display: flex;
    background: #f7f2ed;
    cursor: pointer;
    justify-content: space-between;
  }

  .accordion-inner-header {
    display: flex;
  }

  .accordionHeaderText {
    font-size: 20px;
    color: #212452;
  }

  .reset {
    font-size: 18px;
    color: #888888;
    border: none;
    text-decoration: underline;
    background: transparent;
    padding-top: 0;
    text-transform: uppercase;
    padding-left: 16px;
  }

  .reset:hover {
    color: #212452;
  }

  .accordion-body {
    padding: 36px 48px;
    border: 1px solid #212452;
    margin-left: 0;
    margin-right: 0;
  }

  .adjust-row {
    justify-content: end;
    margin-bottom: 0;
  }

  .accordion-body-header {
    font-size: 15px;
    color: #000;
    opacity: 0.4;
    justify-content: start;
    padding-bottom: 8px;
    // text-transform: uppercase;
    border-bottom: 1px solid #888888;
    padding-left: 0px;
    padding-right: -15px;
  }

  .col-md-8 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .accordion-header-border {
    border: 1px solid #212452;
    border-bottom: none;
    background: #fff;
  }
`;