import { useCallback, useState, useEffect } from "react"

export function useLocalStorage(key, defaultVal) {
  return useStorage(key, defaultVal, window.localStorage);
};

export function useSessionStorage(key, defaultVal) {
  return useStorage(key, defaultVal, window.sessionStorage);
};

function useStorage(key, defaultVal, storageObject) {
  const [value, setValue] = useState(() => {
    const jsonVal = storageObject.getItem(key);
    if (jsonVal != null) return JSON.parse(jsonVal)

    if (typeof defaultVal === "function") {
      return defaultVal();
    } else {
      return defaultVal;
    }
  });

  useEffect(() => {
    if (value === undefined) return storageObject.removeItem(key)
    storageObject.setItem(key, JSON.stringify(value));
  }, [key, value, storageObject]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  return [value, setValue, remove]
}