import styled, { css } from 'styled-components';

export const Styles = styled.div`
  position: fixed;
  left:0;
  bottom:0;
  right:0;

  #bookmark {
    color: #000;
    padding: 28px 65px 28px 60px;
    font-size: 18px;
    background-color: #fff;
    font-weight: 400 !important;
    text-align: center;
    box-shadow: 0px 0px 50px 0px rgb(54 61 77 / 15%);
  }

  #bookmark a {
    color: #007bff;
  }

  #bookmark a:hover {
    color: #0056b3;
  }

  

  #bookmark-close{
    margin-right: 0px;
    color: #000 !important;
    border: 0;
    background-color: transparent;
    outline: none !important;
    float: right;
    font-size: 1.7rem;
    transform: scale(1.05, 1.05);
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    opacity: .4;
    padding: 0;
    width: fit-content;
  }
`;


export default Styles;
