import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { AppContext } from '../../context';

import Header from '../../components/header';
import PasswordResetForm from '../../components/form/password-reset-form';
import Button from '../../components/button';

import { H1, P } from '../../components/theme/typography';

import {
  FormWrapper,
  PasswordResetTitleWrapper,
  PasswordResetMessageWrapper,
  PasswordResetResendLinkWrapper,
} from './styles';

const PasswordResetPage = () => {
  const { userContext, setUserContext } = useContext(AppContext);

  return (
    <>
      <Header withSearchBar={false} withAccountButtons={true} />
      <main>
        <Container>
          {!userContext.passwordResetDataSubmit ? (
            <Row>
              <Col lg={{ span: 6, offset: 3 }}>
                <FormWrapper>
                  <PasswordResetForm />
                </FormWrapper>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg={{ span: 6, offset: 3 }}>
                <FormWrapper>
                  <PasswordResetTitleWrapper>
                    <H1 h1 semibold textoffblack>
                      Password Reset
                    </H1>
                  </PasswordResetTitleWrapper>
                  <PasswordResetMessageWrapper>
                    <P p1 regular textplaceholder>
                      A reset link has been sent to <b>name@nhs.co.uk</b>
                      If you don’t receive the email within a few minutes,
                      <b>please check your spam folder </b> or click below to resend the
                      email.
                    </P>
                  </PasswordResetMessageWrapper>
                  <PasswordResetResendLinkWrapper>
                    <P
                      h2
                      semibold
                      textlink
                      onClick={() => {
                        alert('Should resend verification link');
                      }}
                    >
                      Resend Verification link
                    </P>
                  </PasswordResetResendLinkWrapper>
                  <Button
                    type="link"
                    colorprimary
                    buttonlarge
                    to="/login"
                    text="Log in"
                    width="100%"
                    onClick={() => {
                      setUserContext({
                        ...userContext,
                        passwordResetDataSubmit: false,
                      });
                    }}
                  />
                </FormWrapper>
              </Col>
            </Row>
          )}
        </Container>
      </main>
    </>
  );
};

export default PasswordResetPage;
