import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { Row,Col } from 'react-bootstrap';
import { getNameOfDecoded, countWords } from '../../functions/utils';
import { AppContext } from '../../context/index';



const SingleSourceFilter = (props ) => {

  const { 
    searchContext,
    setSearchContext,
    userContext,
    setIsResultsExpanded,
    setResultsFirstExpanded,
    setConfirmCouldNotFind,
    setSuspectCouldNotFind,
    setLoadMore,
    setClearLocalWebResults,
    setShowAssistedAI,
    setLocalWebResults
  } = useContext(AppContext);

  const handleCheckBoxClick = (source) => {
    let updatedFacets = [];
    let TempEncryptedUrl = source["encrypted_url"]; 
    if (TempEncryptedUrl && props.isCheckedDic[TempEncryptedUrl]){
      searchContext["facets"].forEach(facet => {
        // facet is a source
        if(facet && facet['encrypted_url']  ){     
          if (facet['encrypted_url'] !== TempEncryptedUrl && facet['name']!=="Web"){
            updatedFacets.push(facet);
          }
        }
      });
    }
    else{
      updatedFacets = searchContext["facets"];
      if (updatedFacets.length >=1 && updatedFacets[0]["name"]== "Web"){
        updatedFacets.shift();
      }
      updatedFacets.push(source);
    }
    if(props.firstClick){
      updatedFacets = [];
      updatedFacets.push(source);
      props.setFirstClick(false);
    }

    if (searchContext.q) {
      if (countWords(searchContext.q) > 4) {
        setIsResultsExpanded(true);
        setResultsFirstExpanded(undefined);
      } else {
        setIsResultsExpanded(false);
        setResultsFirstExpanded(false);
      };
    };
    setSuspectCouldNotFind(false);
    setConfirmCouldNotFind(false);
    setLoadMore(undefined);
    setClearLocalWebResults(false);
    setShowAssistedAI(true);
    setLocalWebResults({0: [], 1: [], 2: [], 3: [], 4: []});

    setSearchContext(currentVal => 
      {return {...currentVal,
              facets: updatedFacets.slice(), 
              forceSearch: true
          };});
  };

  const handleSourceClick = (source) => {
    if(source.private){
      requestTokenAndVisit(source);
    }
    else{
      window.open(source.url,'_blank','noreferrer');
    }
  };

  const requestTokenAndVisit =  (source) => {
    let new_url = ''; 
    let tempRequestToken= source.encrypted_url.slice(8); 
    if(source.requestToken){
      tempRequestToken =source.requestToken;
    }
    // const config = {
    //   method: 'post',
    //   url: `${window.BASE_URL_USER}get-expiry-token`,
    //   withCredentials : true,
    //   headers: {'Content-Type': 'application/json' },
    //   data: JSON.stringify({'requested': tempRequestToken}),
    // };

    // axios(config)
    // .then(function(res){
      if (userContext['search_params']){
        if ( source.url.includes('__PUBLIC_TOKEN__') ) {
          new_url = source.url.replace('__PUBLIC_TOKEN__',userContext['search_params'] );
          }
        else{
            new_url = source.url + "?token=" + userContext['search_params'];  
          }
          window.open(new_url,'_blank','noreferrer');
      }
      else{
        // if 400 previours url
        window.open(new_url,'_blank','noreferrer');
      }
  };

return ( 
    <Row className='p-1 m-0' key={props.tittle+"_singleSourceFilter_"+props.filter['id']} >
      <li key={props.tittle+"_singleSourceFilter_"+props.filter['id']}>
      <Col md={'auto'} sm={'auto'} xs={"auto"} className='p-0 my-auto'>
        <input key={"singleFilter_input_"+props.filter['id']+'_'+props.tittle} type="checkbox"  id={props.filter['name']} name={props.filter['name']} 
            checked ={ props.isCheckedDic[props.filter['sources'][0]['encrypted_url']] ?  props.isCheckedDic[props.filter['sources'][0]['encrypted_url']] : false }
            onChange={()=> { handleCheckBoxClick(props.filter['sources'][0])} } 
              ></input>
      </Col>
      <Col  lg={'9'} md={"10"} sm={"9"} xs={"10"} className='p-0 my-auto'>
        <div className='labelContainer'>
          <label htmlFor={props.filter['name']} > 
          { getNameOfDecoded(props.filter['name']) }

          </label>
            <a  onClick={()=>handleSourceClick(props.filter['sources'][0])} target='_blank' rel="noopener noreferrer"   referrerPolicy="no-referrer"
              className='source-link' title={props.filter['sources'][0]['url']} >
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#212452" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
              className="feather feather-external-link ml-1 align-top">
              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
          </a>
        </div>
        </Col>
    </li>
    </Row>
  );
};

export default SingleSourceFilter;