import styled from 'styled-components';
import { linkStyles } from '../theme/link';

export const Styles = styled.section`
  // margin-top: 40px;
  margin: 16px 16px ; 

  @media (max-width: 767.98px) {
    display: none;
  }

  .title {
    margin-bottom: ${({ theme }) => theme.spacing.x3};
  }

  .moreLikeThis {
    ${linkStyles}
    margin-bottom: ${({ theme }) => theme.spacing.x3};
  }
`;

export default Styles;
