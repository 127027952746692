import styled from 'styled-components';
import { theme } from '../../components/theme/variables';

export const CollapseFiltersContainer = styled.div`
    width:100%;
    display: block;
    line-break: after;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #212452;
    text-align: left;
    border: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    // background-color: #fff;
    
    .checkboxWrapper {
      padding-left: 0px;
      text-align:left;
      border:none;
    }

    input{
      min-width:0px;
    }

    .tittle-checkbox {
      padding-left:2px;
    }
    .tittle-checkbox .subSource{
      padding-left:0px;
    }
    FaChevronUp{
      float:right;
      width:14px;
    }
    FaChevronDown{
      float:right;
      width:14px;

    }

    ul{
      margin: 0 0 0 0.15rem;
      li{
        display:contents;
      }
    }
    svg{
      margin: 2px 8px 0 8px;

    }
    input[type="checkbox"]{
      vertical-align: middle;
      // vertical-align: top;
    } 
    
    input{
      margin-left:2px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
    }

    .labelContainer{
      height:23px; 
    }

    label{
      margin-left:2px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: calc(100% - 27px );
    }
    label.filter-tittle{
      width:calc(100% - 60px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    label.subSource{ 
      width:calc(100% - 3.5rem);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .subsectionFilter{
      padding-top:0.25rem;
      padding-bottom: 0.25rem;
      padding-left:1.8rem;
    }

    legend{
      // margin-left: 1px;
      // margin-left: -1rem;
      padding-left:4px;
      text-align:left;
      font-size: 16px;
      text-decoration: none;
      cursor: pointer;
      width: calc( 100% - 0.25rem );
      input{
        margin-bottom:0.3rem;
        margin-right:0.25rem;
      }
      .chevron{
        float:right;
      }
    }
    fieldset{
      text-align:left;
      border:none;
    }

    .sources-type{
        border-left: 1px solid rgba(0,0,0,.125);
        border-right: 1px solid rgba(0,0,0,.125);
        border-top: 1px solid rgba(0,0,0,.125);
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        text-align: center;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
    // .source-link{
    //   width:40%;
    // }
    
        
`;
export default CollapseFiltersContainer;

export const Tag = styled.div`
display: inline-flex;
align-items: center;
// background-color: ${({ theme }) => theme.palette.backgourndPurple};
margin: 0 5% 0 5% ;
padding: 0 2% 0 2%;
height : 23px;
cursor: pointer;
Span {

  &:hover{
    padding: 0px 5px;
    background-color: #E4E0F7;
    border: 1px solid ${({ theme }) => theme.palette.textPlaceholder};
    border-radius: ${({ theme }) => theme.spacing.x2};
    
  }
}

.filters_separator{
  width: 0.0625rem;
  height: 1rem;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  box-shadow: inset 0 0 0 1px #b2b2bf;
}

// Span{
//   padding-left:1rem;
// }
`;