// English spealing meaning: majority native English speaking 
// from https://www.sheffield.ac.uk/international/english-speaking-countries
// with codes from https://www.iso.org/obp/ui/#search
// Antigua and Barbuda , AG 
// Australia , AU
// The Bahamas , BS 
// Barbados , BB
// Belize , BZ
// Canada* ,CA
// Dominica ,DM 
// Grenada , GD
// Guyana , GY
// Ireland , IE
// Jamaica , JM
// Malta , MT
// New Zealand , NZ
// St Kitts and Nevis , KN
// St Lucia , LC
// St Vincent and the Grenadines, VC 
// Trinidad and Tobago , TT
// United Kingdom , GB
// United States of America , US

export const english_speaking_codes = ["AG","AU","BS","BB","BZ","CA","DM","GY","IE","JM","MT","NZ","KN","LC","VC","TT","GB","US"]