import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Header from '../../components/header';
import ProfileSettingsForm from '../../components/form/account/profile-settings-form';
// import OrgSettingsForm from '../../components/form/account/org-settings-form';
import DeleteAccountForm from '../../components/form/account/delete-account-form';
import { ReactComponent as UserIcon } from '../../assets/svgs/user-icon.svg';
import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen';
import { ReactComponent as DeleteIcon } from '../../assets/svgs/delete-icon.svg';
import {max_number_sources} from '../../functions/frontend_variables';
import { Span } from '../../components/theme/typography';
import { AppContext } from '../../context';
import {
  ContainerWithTopSpacing,
  AccountTabList,
  AccountTabListItem,
  AccountTabsFormWrapper,
} from './styles';


const AccountPage = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [fetchedUserInfo, setFetchedUserInfo] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [allowMoreSources, SetAllowMoreSources] = useState(true);
  const { setUserStats, setRequestTrackerContext, setUserContext } = useContext(AppContext);
  const isSmallScreen = useIsSmallScreen();
  const accountTabs = [
    {
      icon: <UserIcon />,
      listItem: 'Profile settings',
    },
    // {
    //   icon: <UserIcon />,
    //   listItem: 'Add a new source',
    // },
    {
      icon: <DeleteIcon />,
      listItem: 'Delete account',
    }
  ];

  const accountTabsAdmin = [
    {
      icon: <UserIcon />,
      listItem: 'Profile settings',
    },
    // {
    //   icon: <UserIcon />,
    //   listItem: 'Add a new source',
    // },
    {
      icon: <DeleteIcon />,
      listItem: 'Delete account',
    },
    // {
    //   icon: <UserIcon />,
    //   listItem: 'Organisation settings',
    // },
    // {
    //   icon: <UserIcon />,
    //   listItem: 'Add a new organisation source',
    // },
  ];

  const getAccountTabs = () => {
    let out = [];
    if (isAdmin) {
      out = accountTabsAdmin;
    }
    else {
      out = accountTabs;
    }
    return out;
  };


  let getUserInfo = useCallback(() => {
    setRequestTrackerContext(prev => { return {...prev, 'get-or-create-user':true}});
    axios({
      method: 'get',
      url: `${window.BASE_URL_USER}get-or-create-user`,
      withCredentials: true,
    })
      .then((res) => {
        setRequestTrackerContext(prev => { return {...prev, 'get-or-create-user':false}});
        // TODO hack to make sessions work, this happens when storage does not correspond with session
        let user_info = res.data.user;
        if (user_info["email"] && user_info['is_anonymous']){
          // removeUserSession();
          // setUserContext({email:''});
          setUserContext({'email':'', 'org_name':undefined});
          setUserStats(undefined);
          window.location = window.BASE_API_URL;
        }
        if (user_info['org-user-type'] && user_info['org-user-type']==="admin"){
          setIsAdmin(true);
        }
        else{
          setIsAdmin(false);
        }
        // TO DO get filters you are erasing them 
        // setUserSession(user_info);
        setUserContext(user_info);
        setFetchedUserInfo(res.data);
        if (res.data.user['custom-sources']){
          SetAllowMoreSources(res.data.user['custom-sources'].length <= max_number_sources );
        }
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'get-or-create-user':false}});
        console.log("could not load account-page");
        console.log(error);
      });
  }, []);

  const setTab = (i) => {
    setActiveTab(i);
  };

  useEffect(() => {
    getUserInfo();
    if (props.location.state){
      setActiveTab(1);
    }
    ;
  }, [getUserInfo]);

  return (
    <>
      <Header withSearchBar={false} withAccountButtons={false} />
      <main>
        <ContainerWithTopSpacing>
          <Row>
            <Col lg={3} md={2} className="snapToEdges">
              <AccountTabList>
                {getAccountTabs().map((accountTab, i) => (                  
                   <>
                   {i===accountTabs.length&& !isSmallScreen&& (<div className='separator' ></div>)}
                  <AccountTabListItem
                    key={'accountTab'+i.toString()}
                    className={activeTab === i && 'active'}
                    onClick={() => setActiveTab(i)}
                  >
                    {accountTab.icon}
                    <Span p1 regular textplaceholder>
                      {accountTab.listItem}
                    </Span> 
                  </AccountTabListItem>
                  </>
                ))}
              </AccountTabList>
            </Col>
            <Col lg={9} md={10}>
              <AccountTabsFormWrapper>
                {activeTab === 0 && (
                  <ProfileSettingsForm
                    getUserInfo={getUserInfo}
                    fetchedUserInfo={fetchedUserInfo}
                    setActiveTab = {setTab}
                  />
                )}
                {/* {activeTab === 1 &&  <AddSourceForm  allowMore= {allowMoreSources} />} */}
                {activeTab === 1 && <DeleteAccountForm suggestedSources= {props.location.state} />}
                {/* {activeTab === 2 && (
                  <OrgSettingsForm
                    getUserInfo={getUserInfo}
                    fetchedUserInfo={fetchedUserInfo}
                    setActiveTab = {setTab}
                  />
                )} */}
                {/* {activeTab === 4 &&  <AddOrgSourceForm  allowMore= {allowMoreSources}  setActiveTab = {setTab}/>} */}
              </AccountTabsFormWrapper>
            </Col>
          </Row>
        </ContainerWithTopSpacing>
      </main>
    </>
  );
};

export default AccountPage;
