import React from 'react';
import ToggleButtonStyles from './styles';
import { HiMenu, HiOutlineX } from 'react-icons/hi';

function ToggleButton({ toggle, setToggle }) {
  return (
    <ToggleButtonStyles
      type="button"
      className={toggle ? 'active' : ''}
      onClick={() => setToggle(!toggle)}
      aria-expanded={toggle}
      aria-label="Toggle navigation"
    >
      {!toggle ? <HiMenu /> : <HiOutlineX />}
    </ToggleButtonStyles>
  );
}

export default ToggleButton;
