import React, { useContext,useState, useEffect } from 'react';
import { Container, Modal,Row, Col } from 'react-bootstrap';
import {  Label} from '../theme/typography';
import Button from '../button';
import StyledModal from './styles';
import { AppContext } from '../../context/index';
import './bootstrap-modal.min.css';
import { H2, P } from '../theme/typography';
import { ReactComponent as CloseIcon } from '../../assets/svgs/close-icon.svg';

const ModalSideFeedback 
 = ({
  ref,
  modalFeedbackShow,
  modalFeedbackClose,
  feecbackCall,
  result,
  i,
  centered,
  title,
}) => {
  const { 
    // setFeedbackContext,
    searchContext
  } = useContext(AppContext);

  // const controller = new AbortController();
  // const CancelToken = axios.CancelToken;
  // const source = CancelToken.source();

  const [textareaValue, setTextareaValue] = useState('');
  const [noResult,setNoResult]= useState(false);
  const [missingSource,setMissingSource]= useState(false);


  const handleChangeOne = () => {
    console.log('changeOne');
    setNoResult(!noResult);
  };

  const handleChangeTwo = () => {
    setMissingSource(!missingSource);
  };

  // Below effect clears the textarea every time the modal is triggered
  useEffect(() => {
    setTextareaValue('');
  }, [modalFeedbackShow]);


  const handleSubmit = () => {
    let textToSend = textareaValue.trim();
    if(noResult){
      textToSend = 'Irrelevant results: ' + textToSend; 
    }
    if(missingSource){
      textToSend = 'Missing sources: ' + textToSend; 
    }
    if( textToSend && textToSend.length > 0 && textToSend.trim() !== ''
        && searchContext.q && searchContext ){
      if (result &&typeof result !=='undefined' ){
        feecbackCall({
          message: textToSend  ,
          query: searchContext.q,
          parsed_args: {
          // ... feedbackContext ,
          chunk_id: result.chunk_id,
          chunk_title: result.chunk_title,
          chunk_text: result.text,
          online_view_url: result.online_view_url,
          requested_obj: result.requested_obj,
          doc_publisher: result.doc_publisher,}
          }
        );
      }
      else{
        feecbackCall({
          message: textToSend  ,
          query: searchContext.q,
          // parsed_args: {
          // // ... feedbackContext ,
          // query: searchContext.q,
        //  }
          }
        );
      }
    }
  
    modalFeedbackClose();
  };

  

  return (
    <StyledModal
      show={modalFeedbackShow}
      onHide={modalFeedbackClose}
      centered={true}
      ref={ref}
      animation={false}
    >
      <Modal.Header>
        <H2 h18 semibold >
          {title}
        </H2>
        <CloseIcon
          className="modal-header-closeIcon"
          onClick={ modalFeedbackClose}
        />
      </Modal.Header>
      <form>
      <Modal.Body>
        <Container>

          <Row>
            <Col md={12}>
            <P p1 regular textoffblack  > Could not find the answer? Missing a content source?</P>
            </Col>
          </Row>

          <Row className='m-2' md={12}>
              <Col  md={6} className='mt-1'>
                  <input type="checkbox"
                    value={noResult}
                    onChange={handleChangeOne}
                    id='NoResultsCheckBox'
                    ></input>
                  <Label p1 regular textoffblack htmlFor="NoResultsCheckBox">
                    Irrelevant results
                  </Label>
              </Col >
              <Col md={6} className='mt-1'>
                <input type="checkbox"
                  value={missingSource}
                  onChange={handleChangeTwo}
                  id='MissingSourceCheckBox'

                />
                <Label p1 regular textoffblack htmlFor='MissingSourceCheckBox' >
                  Missing source(s)
                </Label>
              </Col>
          </Row>

          <Row>
            <Col md={12}>
                <textarea
                className="modal-body-textarea"
                value={textareaValue}
                rows="4"
                placeholder="Please provide more details... (optional)"
                onChange={(e) => setTextareaValue(e.target.value)}
                />
            </Col>
          </Row>

        </Container>
       
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="action"
          colorprimary
          buttonsmall
          text="Submit"
          width="96px"
          centered
          onClick={handleSubmit}
        />
      </Modal.Footer>
      </form>
    </StyledModal>
  );
};

export default ModalSideFeedback;
