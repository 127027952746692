import React, { useContext } from 'react';
import {  Route,useLocation,Redirect,Link } from 'react-router-dom';
import { AppContext } from '../context';
import { getUser } from './manageSessions';

// accessToken and user objects must be present to visit private routes
function SearchRoute({ component: Component, ...rest }) {
  let loc =useLocation();
  let  url  =loc['pathname'];
  let  newLoc  =  window.structuredClone (loc);
  
  const {
    userOrganisationContext
  } = useContext(AppContext);

  let alias;
  if( getUser() && userOrganisationContext && userOrganisationContext["alias"]){
    alias = userOrganisationContext["alias"];
  }
  if(url){
    if(url === '/'){
      newLoc = {...newLoc, pathname:'/'+alias }
    }
    else{
      newLoc = {...newLoc, pathname:'/'+alias+url }
    }
  }
  let urlAlias ;
  if(typeof  url.split('/')[1] !== 'undefined' ){ //! we assume that the url has to be form ~/alias/...
    urlAlias = url.split('/')[1];
  }


  return (
    <Route
      {...rest}
      render={(props) =>
        getUser() ? (
          ( alias && url&& props.location)  ? (
            // display login 
            <Redirect
              to={ newLoc }
            />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default SearchRoute;
