import styled from 'styled-components';

export const Separator = styled.hr`
  position: relative;
  margin: 0;
  border: 0;
  height: 0;
  border-top: 1px solid ${({ theme }) => theme.palette.backgroundStroke};
`;

export default Separator;
