import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Modal, Col, Row } from 'react-bootstrap';
import Button from '../button';
import {StyledModal,FormFieldWrapper,TextInput,ErrorMessage} from './styles';
import OrgFieldAutosuggest from '../org-field-autosuggest';

import './bootstrap-modal.min.css';
import { H2, P, Label } from '../theme/typography';
import { ReactComponent as CloseIcon } from '../../assets/svgs/close-icon.svg';

const ModalOrgFields = ({
  listParams,
  modalFeedbackShow,
  modalFeedbackClose,
  modalFeedbackData,
  centered,
  title,
  bodyText,
  errorParam,
  setErrorParam
}) => {
  const [textareaValue, setTextareaValue] = useState('');
  // const [allValues, setAllValues] = useState([]);
  const [dataToSend, setDataToSend] = useState('');


  // This hook initiates react-hook-form lib.
  const {
    register:register,
    getValues:getValues,
    handleSubmit:handleSubmit,
    errors:errors
   } = useForm({
    mode: 'onSubmit',
  });

  // Below effect clears the textarea every time the modal is triggered
  useEffect(() => {
    setTextareaValue('');
  }, [modalFeedbackShow]);


  const setDataForRequest= (i)=>{
    let data = i.trim();
    if ( data && data !== ''){
      setDataToSend(data);
      // setDataError(false);
    }
    // else {
    //   setDataError(true);
    // }
  };

  return (
    <StyledModal
      show={modalFeedbackShow}
      // onHide={() => modalFeedbackClose({ param['name']:'aaaa'})}
      centered={centered}
    >
      <Modal.Header>
        <H2 h2 semibold textoffblack>
          {title}
        </H2>

        {listParams && listParams[0]&& !listParams[0]['mandatory'] && (
        <CloseIcon
          className="modal-header-closeIcon"
          onClick={() => modalFeedbackClose()}
        />
        )}
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className='py-2' >
            {/* <Col md={12}> */}
              <P p1 semibold textoffblack> {listParams && listParams[0] && !listParams[0]['mandatory'] 
              ?  'Please enter your '  + listParams[0]["name"].toLowerCase() + ' below:' 
              :
              'To continue, please enter your ' + listParams[0]["name"].toLowerCase() + ' below:'} </P>
            {/* </Col> */}
          </Row>
          <Row>
          
          {listParams &&(
            listParams.map((param) => {
              return (
              <Col md={12} style={{paddingLeft: "0px", paddingRight: "0px"}}>
                <FormFieldWrapper>
                  {/* <Label c1 semibold textoffblack>
                    {param['name']}
                  </Label> */}

                <OrgFieldAutosuggest options={param['options']} data={dataToSend} 
                setData ={setDataForRequest}  error={errorParam} modifyError={setErrorParam}/>
                  {errorParam &&(
                  <ErrorMessage c1 regular colorError >
                    {errorParam }
                  </ErrorMessage>)}
                </FormFieldWrapper>
              </Col>)
              })
            )}
          </Row>
          <Row>     
            <Col md={10}>
            {/* <P c1 >The information will be saved on your account.</P> */}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="action"
          colorprimary
          buttonsmall
          text="Save"
          width="96px"
          onClick={() =>
            modalFeedbackData(dataToSend)
          }
          //TODO , check if emtpy 
          // disabled={!textareaValue && true}
        />

      </Modal.Footer>
    </StyledModal>
  );
};

export default ModalOrgFields;
