import { Row, Col } from 'react-bootstrap'
import { TextInput } from '../modal-org-fields/styles'
import { Article } from './styles';
import Button from '../button';

const CalculatorElement = ({title, description, value, isLink, onChange, link, unit}) => {
  return (
    <>
      <Article>
        <Row>
          <Col md={8}>
            <h1 className='title'>{title}</h1>
            {isLink ? (<Button
                      type="external_link"
                      className="description description-link"
                      text={description}
                      width="96px"
                      to={link}
                      target="_blank"
                    />
                    ) : (
              <p className='description'>{description}</p>
            )}
          </Col>
          <Col md={4} className='value-element'>
            <TextInput
              className={"value " + (unit === "%" ? 'value-padding' : '') + (unit === "min" ? 'value-padding1' : '')}
              value={value} 
              onChange={onChange}
            />
            {unit && <div className={unit === "£" ? 'unit-padding' : 'unit'}>{unit}</div>}
          </Col>
        </Row>
      </Article>
    </>
  )
}

export default CalculatorElement