import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { getUser,userMissingFields,userAskForDetails } from './manageSessions';

// accessToken must NOT be present to visit public routes
function PublicRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        !getUser() || userMissingFields() || userAskForDetails() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  );
}

export default PublicRoute;
