import React, {useState} from 'react'
import { Label } from '../../../theme/typography';
import { Row, Col } from 'react-bootstrap';

import {
    FormFieldWrapperSuggestedUrl,
  } from './style';

const SourcesCard = (props) => {
  const [isChecked, setIsChecked] = useState(props.selected)

  const checkboxClick = () =>{

    let oldArray = props.checkedSources;
    if (!isDupplicate(oldArray, props.url)){
      if (props.abbreviation && props.abbreviation !== "" ){
        props.setCheckedSources(oldArray => 
        [...oldArray,
          {url: props.url,
            name: props.abbreviation,
            encrypted_url: props.url_encrypted
            }]);
        setIsChecked(true);

      }
      else{
        props.setCheckedSources(oldArray => 
          [...oldArray,
            {url: props.url,
              name: props.name,
              encrypted_url: props.url_encrypted
              }]);
      }

    }
    else {
      // remove item
      let array_updated =removeItem(oldArray,props.url);
      props.setCheckedSources(array_updated);
      setIsChecked(false);
    }
  };

  const isDupplicate = (a, item)=>{
    let out = false;
    a.forEach(element => {
     if (element.url === item){
       out = true;
     }  
    });
    return out;
  };

  const removeItem = (array, item_url) =>{
    array = array.filter(function( obj ) {
      return obj.url !== item_url;
  });
  return array;
  };
  
  return (
    <div className='d-inline-flex' style={{width: props.width }}>
        <FormFieldWrapperSuggestedUrl >
          {props.withCheckBox&&(
        <Row>
            <Col md={{span:1}} className='align-self-center'>
            <input
            type="checkbox"
            // class='checkboxCustomUrl'
            key={props.id+307}
            name={props.index}
            id= {'checkbox'+props.index}
            onClick={checkboxClick}
            checked={isChecked}
            />
            </Col>
            <Col className='align-self-left' md={8} style={{paddingLeft:"6px" }}>
              <Label c1 semibold textoffblack for={'checkbox'+props.index} >
              {props.name }
              </Label>
            </Col>
            {props.withLinks && typeof props.url === "string" && (
               <Col className='align-self-center' md={3} style={{paddingLeft:"6px" }}>
                  <a  target='_blank' rel="noopener noreferrer" href= {props.url}
                      className='source-link'  >
                    <svg xmlns="http://www.w3.org/2000/svg" 
                    width="12" height="12" viewBox="0 0 24 24" fill="none" 
                    stroke="#212452" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                    className="feather feather-external-link ml-1 align-top" 
                    style={{marginTop: "0.25rem"}} >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                      <Label style={{paddingLeft:"6px" }} p1 bold textoffblack >
                    link
                  </Label>
                  </a>

                  
                </Col>
            )}
        </Row>)}
        {!props.withCheckBox &&(
               <Row>
                  <Col className='align-self-left'  md={12}>
                  <Label c1 semibold textoffblack  >
                  {props.name}
                  </Label>
                  </Col>
              </Row>
              )}
        <Row>
          {!props.withCheckBox&&(
          <Col md={{span:9}} style={{"overflow":"hidden"}}>
            <a href={props.url} target="_blank" rel="noreferrer noopener" key= {props.id+10}> 
            {props.url.replace(/^https?\:\/\/(www.)?/,'').split('/')[0]}
            </a>
          </Col>)}
          {/* {props.withCheckBox&&(
          <Col md={{span:9, offset: 1}} style={{"overflow":"hidden"}}>
            <a href={props.url} target="_blank" rel="noreferrer noopener"> 
            {props.url.replace(/^https?\:\/\/(www.)?/,'').split('/')[0]} </a>
          </Col>)} */}
        </Row>
        </FormFieldWrapperSuggestedUrl >
    </div>
  );
};

export default SourcesCard;
