import styled  from 'styled-components';
import Loader from 'react-loader-spinner';
export const ContainerSuggest = styled.div`
  font-family: Mulish,sans-serif;
  font-size: 16px;

  .loading{
    height:40px;
    background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='45px' height='25px' viewBox='0 0 105 105' fill='%238771DF' aria-label='audio-loading'%3E%3Ccircle cx='12.5' cy='12.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='0s' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite'%3E%3C/animate%3E%3C/circle%3E%3Ccircle cx='12.5' cy='52.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='100ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite'%3E%3C/animate%3E%3C/circle%3E%3Ccircle cx='52.5' cy='12.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='300ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite'%3E%3C/animate%3E%3C/circle%3E%3Ccircle cx='52.5' cy='52.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='600ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite'%3E%3C/animate%3E%3C/circle%3E%3Ccircle cx='92.5' cy='12.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='800ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite'%3E%3C/animate%3E%3C/circle%3E%3Ccircle cx='92.5' cy='52.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='400ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite'%3E%3C/animate%3E%3C/circle%3E%3Ccircle cx='12.5' cy='92.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='700ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite'%3E%3C/animate%3E%3C/circle%3E%3Ccircle cx='52.5' cy='92.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='500ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite'%3E%3C/animate%3E%3C/circle%3E%3Ccircle cx='92.5' cy='92.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='200ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite'%3E%3C/animate%3E%3C/circle%3E%3C/svg%3E%0A");
    background-position:right center;
    background-repeat: no-repeat;
  }
  input {
    width: 100%;
    margin: 3px 0px 3px 0px; 
    border: 1px solid #E2E5ED;  
    height:40px;

  }
  .react-autosuggest__suggestions-container--open {
    max-height: 60vh;
    width:100%;
    display: block;
    position: absolute;
    z-index: 2;
    border: 1px solid #E2E5ED;
    overflow-x: hidden ;
    overflow-y: auto ;

    @media (max-width: 767.98px) {
      max-height: 40vh;
    }

    background-color: ${({ theme }) => theme.palette.backgroundWhite};
    li .address{
      font-size:14px;
      max-height:150px;
      white-space: normal;
      display: inline-block;
      width: 100%;
      text-overflow: ellipsis;
      // white-space: nowrap;
      overflow: hidden;
      color:#212452;
    }

    // li:hover{
    //   .address{
    //     max-height:44px;
    //     white-space: normal;
    //   }
    //   max-width: 100%;
    //   max-height: 150px;
    // }
  }
  .react-autosuggest__container {
    position: relative;

  }
  
  .react-autosuggest__input {
    width: 240px;
    height: 40px;
    width: 100%;
    padding: 10px 10px 10px 7px;
    font-size: 14px;
    border: 1px solid #E2E5ED;
    border-radius: 3px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 20px 0px 7px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: ${({ theme }) =>
    theme.palette.backgroundDropdownHover};
   }  

,

`;

export default ContainerSuggest;
