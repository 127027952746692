import React, { useState } from 'react';
import { Article, Styles } from './styles';
import { Container, Row, Col } from 'react-bootstrap';
import { FiEdit } from "react-icons/fi";
import TypewriterAnimationChat from '../../typewriter/TypewriterAnimationChat';

function QuestionThread({ question, suggestedQuestion, index }) {
  return (
    <>
      <Col  md={8} className=''>
      </Col>
      <Col md={4} sm={12} className=''>
        {index === 0 ? (
          <Article>
            <div className='first-q'>
              <span className='question'>
                <TypewriterAnimationChat string={suggestedQuestion}/>
                <p className='q'>Original query: {question}</p>
              </span>
            </div>
          </Article>
        ) : (
          <Article>
            <span className='question'>{question}
            </span>
          </Article>
        )}
      </Col>
    </>
  )
}

export default QuestionThread