import React, { useEffect, useContext, useState,useMemo } from 'react';
import { Container, Row,Col} from 'react-bootstrap';
import { AppContext } from '../../context/index';
import {SideFilterContainer} from './styles';
import CollapseFilters from '../collapse-filters/index';
import { getUserFilters } from '../../functions/manageSessions';
import { A } from '../theme/typography';


const SideFiltersCard = ( ) => {
    const { 
        userContextFilters,
        userOrganisationContext,
      } = useContext(AppContext);

    const [firstClick, setFirstClick] = useState(true);
    // const [tittleBtn0, setTittleBtn0] = useState('Web');
    // const [tittleBtn1, setTittleBtn1] = useState('Local');
    // const [tittleBtn2, setTittleBtn2] = useState('Medwise');
    // const [tittleBtn3, setTittleBtn3] = useState('Patient');
    // const [BtnSelected, setBtnSelected] = useState([false,false,false,false]);
    // const [allBtn0Selected, setAllBtn0Selected] = useState(false); // we want to avoid checking on click for performance
    // const [allBtn1Selected, setAllBtn1Selected] = useState(false); // we want to avoid checking on click for performance
    // const [allBtn2Selected, setAllBtn2Selected] = useState(false); // we want to avoid checking on click for performance
    // const [allBtn3Selected, setAllBtn3Selected] = useState(false); // we want to avoid checking on click for performance
    const [sideFilterOrder,setSideFilterOrder] = useState([]);




    // return google filter
    const getGoogleSource =() => {
        let out =  {
            'encrypted_url': "custom:gAAAAABiMGJJQdUGog4lZd2jqr0jk8DZmQvBh139L5aOzVrh7ZZ76xX8uSQkkGPqznXJ7jZV-sft8U8ZZfnX0xNSK804FIc9gT6ckX1xqp7fOU35M9-sKyQ=",
            'name':'Web',
            'url': "https://www.google.co.uk", 
            'id':0 // id is always 0 
        };
        // let googleFilter = {'name':'Web','sources': [out]};
        return out;
    };
    
    // outputs  ann array of strings representing the names of main filters in order
    const getBtnNames = () => {
        let userFilter = userContextFilters;
        let names ;
        if(userFilter && userFilter['filter-types']){
            names =  userFilter['filter-types'];
        }
        if(userFilter && userFilter['sidebar-order']){
            names = userFilter['sidebar-order'];
            if(userFilter['Web'] && !names.includes('Web') ){
               names.push('Web');
            };
        }
        return names; 
    };


    // return google filter
    // const getFacetsForBtnName =(name) => {
    //     let tempFacetsToSend=[];
    //     if( name === 'Web'){
    //         let out =  {
    //         'encrypted_url': "custom:gAAAAABiMGJJQdUGog4lZd2jqr0jk8DZmQvBh139L5aOzVrh7ZZ76xX8uSQkkGPqznXJ7jZV-sft8U8ZZfnX0xNSK804FIc9gT6ckX1xqp7fOU35M9-sKyQ=",
    //         'name':'Web',
    //         'url': "https://www.google.co.uk",
    //         'id': 0 // id is always 0 
    //     };
    //         // facets = {'name':'Web','sources': [out]};
    //         tempFacetsToSend = [out];

    //     }
    //     else{
    //         if(  name ===tittleBtn0){
    //             return  memoAllSourceBtn0;
    //         }
    //         if(  name ===tittleBtn1){
    //             return memoAllSourceBtn1;
    //         }
    //         if(  name === tittleBtn2){
    //             return  memoAllSourceBtn2;
    //         }
    //         if(  name ===tittleBtn3){
    //             return  memoAllSourceBtn3;
    //         }
    //     }
    //     return tempFacetsToSend;
    // };

    //returns an array of arrays , each array represents all the src of a btn 
    // the array is in order 
    const memoAllSourcesBtn = useMemo( ()=>{
        let allSources =[];
        let allBtnNames =  getBtnNames();
        if(allBtnNames && allBtnNames.length >0 ){
            allBtnNames.forEach(nameBtn => {
            // loop through every filter
            let tempSources = [];
                userContextFilters[nameBtn].forEach(filter => {
                    if(nameBtn ==="Web"){
                        tempSources =[getGoogleSource()];
                    }
                    else{
                        if(filter['sources']){
                            filter['sources'].forEach(src => {
                                tempSources.push(src);
                            });
                        }
                    }
                });
                if(tempSources){
                    allSources.push(tempSources);
                }
            });
        }
        return allSources;
    }, [userContextFilters]);

    // TO DO refactor to update on filter update (first change filter update)
    const memoAllSourceBtn0 = useMemo(() => {
        let allSources = [] ;
        if( userContextFilters && userContextFilters['filter-types']){
            let btnName = userContextFilters['filter-types'][0];
            userContextFilters[btnName].forEach(filter => {
                if(filter['sources']){
                    filter['sources'].forEach(src => {
                        allSources.push(src);
                    });
                }
            });
            if(btnName ==="Web"){
                allSources =[getGoogleSource()];
            }
        }
        return allSources;
    }, [userContextFilters]);// TO DO refactor to update on filter update (first chang e filter update)
    const memoAllSourceBtn1 =  useMemo(() => {
        let allSources = [] ;
        if(userContextFilters && userContextFilters['filter-types']){
            let btnName = userContextFilters['filter-types'][1];
            userContextFilters[btnName].forEach(filter => {
                if(filter['sources']){
                    filter['sources'].forEach(src => {
                        allSources.push(src);
                    });
                }
            });
            if(btnName && btnName ==="Web"){
                allSources =[getGoogleSource()];
            }
        }
        return allSources;
    }, [userContextFilters]);// TO DO refactor to update on filter update (first chang e filter update)
    const memoAllSourceBtn2 =  useMemo(() => {
        let allSources = [] ;
        if(userContextFilters && userContextFilters['filter-types']){
            let btnName = userContextFilters['filter-types'][2];
            userContextFilters[btnName].forEach(filter => {
                if(filter['sources']){
                    filter['sources'].forEach(src => {
                        allSources.push(src);
                    });
                }
            });
            if(btnName && btnName ==="Web"){
                allSources =[getGoogleSource()];
            }
        }
        return allSources;
    }, [userContextFilters]);// TO DO refactor to update on filter update (first chang e filter update)
    const memoAllSourceBtn3 =  useMemo(() => {
        let allSources = [] ;
        if(userContextFilters && userContextFilters['filter-types'] && userContextFilters['filter-types'][3]){
            let btnName = userContextFilters['filter-types'][3];
            userContextFilters[btnName].forEach(filter => {
                if(filter['sources']){
                    filter['sources'].forEach(src => {
                        allSources.push(src);
                    });
                }
            });
            if(btnName && btnName ==="Web"){
                allSources =[getGoogleSource()];
            }
        }
       
        return allSources;
    }, [userContextFilters]);// TO DO refactor to update on filter update (first chang e filter update)

    // range value change by :  
    // if range value is change trigger and ask request
    // useEffect(() => {

    //     if(rangeValue===0){
    //         // if(filters.length !== 1){
    //         let sourcesToSend0 = getFacetsForBtnName(getBtnNames()[0]);
    //         if(sourcesToSend0 &&  sourcesToSend0.length >0 && !BtnSelected[0]){
    //             setSearchContext(currentVal => 
    //                 {return {...currentVal,
    //                         facets: sourcesToSend0.slice(), 
    //                         forceSearch: true
    //                     };});
    //             // setIsActive(1);
    //         }
    //         else{
    //             if(sourcesToSend0<=0 && memoAllSourceBtn0 && memoAllSourceBtn0.length>0 && !BtnSelected[0]){
    //                 setSearchContext(currentVal => 
    //                     {return {...currentVal,
    //                             facets: memoAllSourceBtn0.slice(), 
    //                             forceSearch: true
    //                         };});
    //             }
    //         }
    //     }
    //     if (rangeValue ===50 ){
    //         let sourcesToSend1 = getFacetsForBtnName(getBtnNames()[1]);
    //         if(sourcesToSend1 &&  sourcesToSend1.length >0 && !BtnSelected[1]){
    //             setSearchContext(currentVal => 
    //                 {return {...currentVal,
    //                         facets: sourcesToSend1.slice(), 
    //                         forceSearch: true
    //                     };});
    //             // setIsActive(1);
    //         }
    //         else{
    //             if(sourcesToSend1<=0 && memoAllSourceBtn1 && memoAllSourceBtn1.length>0 && !BtnSelected[1]){
    //                 setSearchContext(currentVal => 
    //                     {return {...currentVal,
    //                             facets: memoAllSourceBtn1.slice(), 
    //                             forceSearch: true
    //                         };});
    //             }
    //         }
    //     }
    //     if (rangeValue === 100 ){
    //         let sourcesToSend2 = getFacetsForBtnName(getBtnNames()[2]);
    //         if(sourcesToSend2 && sourcesToSend2.length >0 && !BtnSelected[2]){
    //             setSearchContext(currentVal => 
    //                 {return {...currentVal,
    //                         facets: sourcesToSend2.slice(), 
    //                         forceSearch: true
    //                     };});
    //         }
    //         else{
    //             if(sourcesToSend2<= 0 && memoAllSourceBtn2 && memoAllSourceBtn2.length>0 && !BtnSelected[2]){
    //                 setSearchContext(currentVal => 
    //                     {return {...currentVal,
    //                             facets: memoAllSourceBtn2.slice(), 
    //                             forceSearch: true
    //                         };});
    //             }
    //         }
    //     }
    //     if (rangeValue=== 150 ){
    //         let sourcesToSend3 = getFacetsForBtnName(getBtnNames()[3]);

    //         if(sourcesToSend3  && sourcesToSend3.length>0 && !BtnSelected[3] ){
    //             setSearchContext(currentVal => 
    //                 {return {...currentVal,
    //                         facets: sourcesToSend3.slice(), // there is no default option for btn3  
    //                         forceSearch: true
    //                     };});
    //         }
    //         else{
    //             if(sourcesToSend3<= 0 && memoAllSourceBtn3 && memoAllSourceBtn3.length>0 && !BtnSelected[3]){
                    
    //                 setSearchContext(currentVal => 
    //                     {return {...currentVal,
    //                             facets: memoAllSourceBtn3.slice(), 
    //                             forceSearch: true
    //                         };});
    //             }
    //         }
    //     }
    //   },[rangeValue]);



    // useEffect (()=>{
    //     let userFilters = userContextFilters;
    //     let buttons ;
    //     if(userFilters && userFilters['filter-types']){
    //       buttons = userFilters['filter-types'];
    //     }
    //     if(buttons){
    //         let Btn0Name = buttons[0];
    //         let Btn1Name = buttons[1];
    //         let Btn2Name = buttons[2];
    //         let Btn3Name = buttons[3];
    //         if (userFilters && userFilters[Btn3Name] && userFilters[Btn3Name].length > 0 )
    //         {
    //             setBtn3Filters(userFilters[Btn3Name]);
    //         }
    //         if (userFilters && userFilters[Btn2Name] && userFilters[Btn2Name].length > 0 )
    //         {
    //             setBtn2Filters(userFilters[Btn2Name]);
    //         }
    //         if ( userFilters && userFilters[Btn1Name] && userFilters[Btn1Name].length > 0){
    //             setBtn1Filters(userFilters[Btn1Name]);
    //                        }
    //         if ( userFilters && userFilters[Btn0Name] && userFilters[Btn0Name].length > 0){
    //             setBtn0Filters(userFilters[Btn0Name]);
    //         }
    //     }
    // },[userContextFilters])

    // when searchContext changes update btn
    // useEffect (()=>{
    //     let buttons ;
    //     if(userContextFilters && userContextFilters['filter-types']){
    //       buttons = userContextFilters['filter-types'];
    //     }        
    //     if(buttons){
    //         let btnNames = getBtnNames();
    //         let defaultSourcesBtn =[];// an order array of arrays, each array has a list of default sources coresponding to a btn
    //         let allBtnSourcesUrl =[]; // an order array of dicts
    //         btnNames.forEach(btnName => {
    //             let tempDefaultSourcesBtn= []; // a list of all default sources for btn
    //             let tempAllBtnSourcesUrls= {};// a dictionanry
    //             if(btnName && userContextFilters){
    //                 if(btnName ==='Web'){
    //                     let googleSrc = getGoogleSource();
    //                     tempDefaultSourcesBtn.push(googleSrc );
    //                     tempAllBtnSourcesUrls[googleSrc['encrypted_url']] =true;
    //                 }
    //                 else{
    //                     userContextFilters[btnName].forEach(filter => {
    //                         filter['sources'].forEach(src => {
    //                             // if(src['is_default']){
    //                             tempDefaultSourcesBtn.push(src );
    //                             tempAllBtnSourcesUrls[src['encrypted_url']] =true;
    //                             // }
    //                         });
    //                     });
    //                 }
    //                 defaultSourcesBtn.push(tempDefaultSourcesBtn);
    //                 allBtnSourcesUrl.push(tempAllBtnSourcesUrls);
    //             }
    //         });

    //         searchContext.facets.forEach(filter => {
    //             let tempUrl = filter["encrypted_url"];
    //             // sometime the facets is a single source and not a filter
    //             // we need 4 int bc some sources can be in >1 btn/tooggle

    //             if(filter['sources'] && !tempUrl)
    //             {
    //                 filter['sources'].forEach(src => {
    //                     // loop through dictionaries and remove url if found
    //                     let tempSourceUrl = src["encrypted_url"];
    //                     allBtnSourcesUrl.forEach(btnSourcesUrls => {
    //                         if(tempSourceUrl && btnSourcesUrls[tempSourceUrl]){
    //                             // it is in local sources
    //                             delete btnSourcesUrls[tempSourceUrl];
    //                         }
    //                     });
    //                 });
                    
    //             }
    //             // is a source 
    //             else {
    //                 if(tempUrl ){
    //                     // loop through dictionaries and remove url if found
    //                     allBtnSourcesUrl.forEach(btnSourcesUrls => {
    //                         if( btnSourcesUrls[tempUrl]){
    //                             // it is in local sources
    //                             delete btnSourcesUrls[tempUrl];
    //                         }
    //                     });
    //                 }
    //             }
    //         });

    //         allBtnSourcesUrl.forEach((BtnSourcesUrls,index) => {
    //             if(typeof index !== 'undefined' && searchContext.facets && BtnSourcesUrls[index] &&  Object.keys(BtnSourcesUrls[index]).length === 0 && memoAllSourcesBtn[index]  &&searchContext.facets.length === memoAllSourcesBtn[index].length){
    //                 // setIsActive(0);
    //                 // setRangeValue(index*50);
    //                 let selectedBtn =[];
    //                 for (let i = 0; i < allBtnSourcesUrl; i++) {
    //                     if(i=== index){
                            
    //                     }
    //                     else{
    //                         selectedBtn[i]= false;
    //                     }
    //                 }
    //                 setBtnSelected(selectedBtn);
    //             }

    //         });
            
    //         // if( searchContext.facets && Object.keys(allBtn0SourcesUrls).length === 0 && searchContext.facets.length === memoAllSourceBtn0.length){
    //         //     // setIsActive(0);
    //         //     setRangeValue(0);
    //         //     setAllBtn0Selected(true);
    //         //     setAllBtn1Selected(false);
    //         //     setAllBtn2Selected(false);
    //         //     setAllBtn3Selected(false);

    //         // }
            
    //         // else if(searchContext.facets && Object.keys(allBtn1SourcesUrls).length === 0    && searchContext.facets.length === memoAllSourceBtn1.length )
    //         //         {
    //         //             setRangeValue(50 );
    //         //             setAllBtn0Selected(false);
    //         //             setAllBtn1Selected(true);
    //         //             setAllBtn2Selected(false);
    //         //             setAllBtn3Selected(false);
    //         //             // setIsActive(1);
    //         //             //check if all local filters
    //         //         }
    //         // else if(searchContext.facets && Object.keys(allBtn2SourcesUrls).length === 0 && searchContext.facets.length === memoAllSourceBtn2.length  ) 
    //         //         {
    //         //             // setIsActive(2);
    //         //             setRangeValue(100 );
    //         //             setAllBtn0Selected(false);
    //         //             setAllBtn1Selected(false);
    //         //             setAllBtn2Selected(true);
    //         //             setAllBtn3Selected(false);
    //         //         }
    //         // else if(searchContext.facets && Object.keys(allBtn3SourcesUrls).length === 0 
    //         //        && searchContext.facets.length === memoAllSourceBtn3.length  ) 
    //         // {
    //         //     // setIsActive(2);
    //         //     setRangeValue(150);
    //         //     setAllBtn0Selected(false);
    //         //     setAllBtn1Selected(false);
    //         //     setAllBtn2Selected(false);
    //         //     setAllBtn3Selected(true);
    //         // }
    //         // else{
    //         //         setRangeValue(99 );
    //         //         setAllBtn0Selected(false);
    //         //         setAllBtn1Selected(false);
    //         //         setAllBtn2Selected(false);
    //         //         setAllBtn3Selected(false);
    //         // }
    //     }
    // },[searchContext]); 

    // when org changes  or when filters are updated
    useEffect(()=>{
        let buttons ;
        let sideOrder ;
        // let sourcesOrder= ['Medwise',"Local","Web"];
        let sourcesOrder=[];
        if(userContextFilters && userContextFilters['filter-types']){
          buttons =userContextFilters['filter-types'];
          sideOrder =userContextFilters['sidebar-order'];
          
          sideOrder.forEach(name => {
            sourcesOrder.push({'name':name,"filters":userContextFilters[name]});
          });
          setSideFilterOrder(sourcesOrder);
        }        
        // if(buttons && typeof buttons[0] === "string"){
        //     setTittleBtn0(buttons[0]);
        // }
        // if(buttons &&  typeof buttons[1] === "string"){
        //     setTittleBtn1(buttons[1]);
        // }
        // if(buttons && typeof buttons[2] === "string"){
        //     setTittleBtn2(buttons[2]);
        // }
        // if(buttons && typeof buttons[3] === "string"){
        //     setTittleBtn3(buttons[3]);
        // }

    },[userOrganisationContext,userContextFilters]);


    return (
        <SideFilterContainer>      
                <Container>
                    <Row>
                        <Col md ={12} className='p-0 m-0'>
                            { sideFilterOrder  && sideFilterOrder.map((filter) => (
                                <>
                                { filter["name"] && filter["filters"] && filter["name"]!=='Web'  && filter["filters"].length >0 &&(
                                    <CollapseFilters key={"collapsebtnFilters_"+filter["name"]} tittle={filter["name"]}  
                                    filters={  filter["filters"] } 
                                    isExpanded={true} setFirstClick={setFirstClick} firstClick={firstClick}  ></CollapseFilters> 
                                ) }
                                </>
                            ))}
                        </Col>
                    </Row> 

                </Container>
        </SideFilterContainer>
    );
};

export default SideFiltersCard;