import React, { useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { theme } from '../../../theme/variables';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Row, Col } from 'react-bootstrap';

import Button from '../../../button';

import listOfProfessions from '../../listOfProfessions.json';
import listOfSpecialities from '../../listOfSpecialities.json';
import { getAllDisplayFilters, getUser, setNumOfSearches} from '../../../../functions/manageSessions';
import { ReactComponent as CheckIcon } from '../../../../assets/svgs/check-icon.svg';
import { ReactComponent as ErrorImage } from '../../../../assets/svgs/error-image.svg';
import { FaSearch, FaTimes, FaCheck, FaNewspaper } from 'react-icons/fa';

import { Span, Label, StyledLink,A } from '../../../theme/typography';
import { Tag, TagsWrapperProfile,TagsWrapperProfileAddSource } from './styles';
import LocationSuggest from '../../../location-autosuggest';
import { AccountOrgFieldAutosuggest } from '../../../org-field-autosuggest';
import {
  FormFieldWrapperTags,
  FormFieldWrapper,
  TextInput,
  ErrorMessage,
  // Select,
  SaveChangesWrapper,
  SaveChangesMessage
} from '../../styles';
import { sources } from '../../../../functions/frontend_variables';
import LoadingIndicator from '../../../loading-indicator';
import Loader from 'react-loader-spinner';
import { AppContext } from '../../../../context';
import GoogleSuggest from '../../../google-map-suggest';
import Select  from 'react-select';



const ProfileSettingsForm = ({ fetchedUserInfo, getUserInfo, setActiveTab}) => {
  const [isSubmittedProfile, setIsSubmittedProfile] = useState(false);
  const [isSubmittedSources, setIsSubmittedSources] = useState(false);
  const [displaySaveProfile, setDisplaySaveProfile] = useState(false);
  const [displaySaveSources, setDisplaySaveSources] = useState(false);
  const [otherProfessionSelectedAndEmpty, setOtherProfessionSelectedAndEmpty] = useState(false);
  
  const [otherProfessionSelected, setOtherProfessionSelected] = useState(false);
  const [otherSpecialtySelected, setOtherSpecialtySelected] = useState(false);
  const [fetchedFirstNames, setFetchedFirstNames] = useState("");// variable updated if change in form 
  const [fetchedLastNames, setFetchedLastNames] = useState(""); // variable updated if change in form 
  const [profession, setProfession] = useState("");
  // const [fetchedProfession, setFetchedProfession] = useState("");
  const [fetchedEmail, setFetchedEmail]= useState("");
  const [fetchedParam, setFetchedParam]= useState("");
  const [extraParamName, setExtraParamName]= useState("");
  const [fetchedRegistrationNumber, setFetchedRegistrationNumber]= useState(""); // variable updated if change in form 
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [professionError, setProfessionError] = useState(false);
  const [professionOtherError, setProfessionOtherError] = useState(false);
  const [errorPracticeOther, setErrorPracticeOther] = useState(false);

  const [selectedProfession, setSelectedProfession] = useState(undefined);
  const [inProfessionList, setInProfessionList] =useState(true) ;
  const [fetchedSpecialty, setFetchedSpecialty] = useState("");
  const [organisation,setOrganisation] = useState(""); 

  const [location, setLocation] = useState("");// user location
  const [fetchedLocationOther, setFetchedLocationOther] = useState(""); //user location outside options 
  const [locationError, setLocationError] = useState(false);

  const { setRequestTrackerContext, userOrganisationContext, userContext ,removeUserOrganisationContext} = useContext(AppContext);
  const [extraParams, setExtraParams] = useState(undefined);
  const [odsCode, setOdsCode] = useState("");

  const [blockSave,setBlockSave]= useState(false);
  const [place,setPlace]= useState(undefined);
  const [blockVersion,setBlockVersion]= useState(false);
  const [loadingIcb,setLoadingIcb]= useState(false);

  const [promptVersion,setPromptVersion] = useState(false);
  const [showIcb,setShowIcb]= useState(false);

  const [defaultIcb,setDefaultIcb] = useState(undefined);
  const [userIcb,setUserIcb]=useState(undefined);
  const [defaultVersion, setDefaultVersion] = useState(undefined);
  const [userVersion, setUserVersion] = useState(undefined);
  const [versions, setVersions] = useState(undefined);
  const [icbs, setIcbs] = useState(undefined);



  useEffect(() => {
    if(userOrganisationContext){
      if (userOrganisationContext['extra_param'])
          {
        callExtraParams();
      }      
    }
    // eslint-disable-next-line
  }, [userOrganisationContext]);

  useEffect(() => {
    if(location &&  fetchedUserInfo && fetchedUserInfo.user && fetchedUserInfo.user.location 
      && location!== fetchedUserInfo.user.location ){
      setPromptVersion(true);
      setShowIcb(false);
    }
  }, [location]);


  const getListOfProfessions = ()=>{
    let list = listOfProfessions.map((x) => x);
    if(profession && typeof profession === 'string'  && profession.trim()  !== '' ){
      let num  = list.push({ "name": profession,"label":profession, "value":profession });
    } 
    return list; 
  };

  const callExtraParams = () => {
    setRequestTrackerContext(prev => { return {...prev, 'get-extra-param':true}});

    axios
    .get(`${window.BASE_URL_USER}get-extra-param`, {withCredentials: true})
    .then((res) => {
      setRequestTrackerContext(prev => { return {...prev, 'get-extra-param':false}});
      if (res.data) {
        let name = res.data['name'];
        // received data
        if (name && name !==''){
          setExtraParams(res.data);
        }
      } else {
        console.log(
          'The request is successful, but for some reason res.data is not received: \n',
          res
        );
      }
    })
    .catch((error) => {
      setRequestTrackerContext(prev => { return {...prev, 'get-extra-param':false}});
      console.log(error);
    });
  };

  //TODO: replace with actual request ?  
  const resetUser  = () => {
    getUserInfo();
  }

  const Yup = require('yup');

  Yup.addMethod(Yup.object, 'atLeastOneOf', function(list) {
    return this.test({
      name: 'atLeastOneOf',
      message: '${path} must have at least one of these keys: ${keys}',
      exclusive: true,
      params: { keys: list.join(', ') },
      test: value => value == null || list.some(f => value[f] != null)
    })
  })

  const signupSchema = yup.object()
  .shape({
    first_name: yup.string().required('Add your first name').typeError('Type your first name'),
    last_name: yup.string().required('Add your last name').typeError('Type your last name'),
    profession: yup.string().required('Add your profession').typeError('Select your profession'),
    profession_other: yup.string().typeError('Type your profession'),
    specialty: yup.string().typeError('Select Specialty')
  })
  .atLeastOneOf(['name', 'profession','profession_other','specialty','specialty_other']);


  // This onSubmit method is part of a react-hook-form lib.
  const onSubmitProfile = () => {
    setFirstNameError(false);
    setLastNameError(false);
    setProfessionError(false);
    setProfessionOtherError(false);
    setLocationError(false);
    setErrorPracticeOther(false);

    const data = {
      first_name: fetchedFirstNames,
      last_name: fetchedLastNames,
      profession_other: profession,
      practice_other: fetchedLocationOther,
      registration_number: fetchedRegistrationNumber,
    };

    let skip_request = false;  
    if (selectedProfession === 'Other') {
      if (data.profession_other && data.profession_other.length) {
        data.profession = data.profession_other;
      } else {
        skip_request = true;
        setOtherProfessionSelectedAndEmpty(true); 
      };
    } else {
      data.profession = selectedProfession;     
    };

    if (data.specialty === "Other") {
      data.specialty = data.specialty_other;
    };
    if (data.specialty == "Other" ){
      data.specialty = fetchedSpecialty;
    };

    let errorLocation = false;
    if ((odsCode === undefined && (data['practice_other'] === ''))) {
      setLocationError(true);
      errorLocation = true;
    } else {
      setLocationError(false)
      errorLocation = false;
    };
    let tempLocation; 
    if (typeof location === 'string' && location.trim()!== "" ) {
      tempLocation =  location; 
    } 

    let extraParamObject= {};
    let dataToSend = {
      "first-names": data.first_name,
      "last-names": data.last_name,
      profession: data.profession,
      speciality: data.specialty,
      'location': location,
      'location_object':typeof place!=='undefined' ? place :{'name':location},
      registration_number: data.registration_number
    };

    // if (extraParamName && fetchedParam && fetchedParam.trim()) {
    //   extraParamObject[extraParamName]= fetchedParam.trim();
    //   dataToSend = { 
    //     ...dataToSend,
    //     extra_param: extraParamObject,
    //    }
    // };

    if (dataToSend.location === "") {
      setLocationError(true);
      errorLocation = true;
    };

    // we have to check if an icb and medwise version was selected
    let icbTemp = {'name':undefined , 'ods':undefined}; 
    if(defaultIcb){
      dataToSend = { 
        ...dataToSend,
        "ods_code":defaultIcb['ods_code'],
       }
    }
    let versionTemp = {'name':undefined,'id':undefined};
    if(typeof userVersion !== "undefined"){
      // versionTemp = userVersion;
      dataToSend = { 
        ...dataToSend,
        "org_num":userVersion['id'],
       }
    }
    else{
      if(typeof defaultVersion !== "undefined"){
      // versionTemp = defaultVersion;
      dataToSend = { 
        ...dataToSend,
        "org_num": defaultVersion['id'],
       }
      }
    }


    if (!data['first_name'] || !data['last_name'] || 
        !data['profession'] || (selectedProfession === "Other" && !data['profession_other']) || 
        (data['location'] === data['practice_other'] && data['practice_other'] == '')) {
      if (locationError){
        skip_request = true;
      }
  
      if (!data.first_name) {
        setFirstNameError(true);
      };
      if (!data.last_name) {
        setLastNameError(true);
      };
      if (!data.profession) {
        setProfessionError(true);
      };
      if (selectedProfession === "Other" && !data.profession_other) {
        setProfessionError(false);
        setProfessionOtherError(true);
      };

      if (data['location'] === data['practice_other'] && data['practice_other'] == '') {
        setErrorPracticeOther(true);
        setLocationError(false);
      };
    } else if(!skip_request && !errorLocation) {
      setIsSubmittedProfile(true);
      setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':true}});
      axios({
        method: 'post',
        url: `${window.BASE_URL_USER}modify-user-fields`,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(dataToSend),
      })
      .then((res) => {
        setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':false}});
        if (res.data) {
          setIsSubmittedProfile(false);
          setDisplaySaveProfile(true);
          resetUser();
          if(typeof res.data === 'object'  && Object.hasOwn(res.data,'user') && !Object.hasOwn(res.data.user,'org_name') ){
            //remove org 
            removeUserOrganisationContext();
          }
        } else {
          console.log(
            'The request is successful, but for some reason res.data.success is not received: \n',
            res
          );
        }
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'modify-user-fields':false}});
        setIsSubmittedProfile(false);
        console.log('Error: ', error);

      });
      setTimeout(() => {
        setDisplaySaveProfile(false);
      }, 3000);
    }
  };

  // Add Other text input when Other is selected
  const updateProfession = (e) => {
    setProfessionError(false);
    setProfessionOtherError(false);
    setFetchedRegistrationNumber('');
    let newVal = e.value;
    if (newVal === "Other") {
      setOtherProfessionSelected(true);
    } else {
      setOtherProfessionSelected(false);
    };
    setSelectedProfession(newVal);
  };

  const updateProfessionOther = (e) => {
    setProfessionOtherError(false);
    let new_val = e.target.value;
    setProfession(new_val);
  };


  // fetchedUserInfo is passed from pages/account-page. It contains get_user_info data.
  useEffect(() => {
    if (fetchedUserInfo) {
      if (fetchedUserInfo.user["org_name"]) {
        setOrganisation(fetchedUserInfo.user["org_name"]);
      }
      if (fetchedUserInfo.user.location){
        setLocation(fetchedUserInfo.user.location);
      }
      if(fetchedUserInfo.user.speciality && fetchedSpecialty === ""){
        let tempSelectedSpecialty ;
        let tempInSpecialtyList ;
        setFetchedSpecialty(fetchedUserInfo.user.speciality);
        let isOtherSpecialty = true ;
        tempSelectedSpecialty ='Other';
        tempInSpecialtyList = false;
        listOfSpecialities.forEach( (specialty) =>{
          if (fetchedUserInfo.user.speciality === specialty.name){
            isOtherSpecialty= false;
            tempSelectedSpecialty = fetchedUserInfo.user.speciality;
            tempInSpecialtyList = true;
          }
        });
        setOtherSpecialtySelected(isOtherSpecialty);
      };
      if (fetchedUserInfo.user.profession && profession === "" && selectedProfession === undefined) {
        let tempSelectedProfession;
        let tempInProfessionList;
        let isOtherProfession = true;
        tempSelectedProfession = 'Other';
        tempInProfessionList = false;
        let tempList = getListOfProfessions();
        tempList.forEach((profession) => {
          if (fetchedUserInfo.user.profession === profession.name || fetchedUserInfo.user.profession === '') {
            isOtherProfession = false;
            tempSelectedProfession = fetchedUserInfo.user.profession;
            tempInProfessionList = true;
          };
        });
        setSelectedProfession(tempSelectedProfession);
        setInProfessionList(tempInProfessionList);
        setOtherProfessionSelected(isOtherProfession);
        if (tempInProfessionList) {
          setProfession('');
        } else {
          setProfession(fetchedUserInfo.user.profession);
        };
      }
      if (fetchedUserInfo.user["email"]){
        setFetchedEmail(fetchedUserInfo.user["email"])
      }

      if (fetchedUserInfo.user["first-names"]){
        setFetchedFirstNames( fetchedUserInfo.user["first-names"]);
        setFetchedLastNames( fetchedUserInfo.user["last-names"]);
      }
      if(fetchedUserInfo.user['extra_param']){
        if(userOrganisationContext && userOrganisationContext['extra_param_name'] ){
          setExtraParamName(userOrganisationContext['extra_param_name']);
        }
        setFetchedParam(fetchedUserInfo.user['extra_param'][Object.keys(fetchedUserInfo.user['extra_param'])[0]]);
        // setParamInput(fetchedUserInfo.user['extra_param'][tempNames[0]]);
      }
      if (fetchedUserInfo.user["registration_number"]){
        setFetchedRegistrationNumber( fetchedUserInfo.user["registration_number"]);
      }
    }
  },[fetchedUserInfo, inProfessionList, otherProfessionSelected, profession]);
  
  //if any of the signupoption payload is change
  // we check if we have to resend the request
  useEffect(() => {
      refresh_icb_or_version();
  }, [fetchedFirstNames,fetchedLastNames,selectedProfession]);
  
  
  const setNewExtraParam = (i)=>{
    let data = i.trim();
    if (data && data !== ''){
      setFetchedParam(data);
    }
  };

  const updateFirstName = (e) => {
    setFirstNameError(false);
    setFetchedFirstNames(e.target.value);
  };

  // if medwise version is present reload icb and medwise version 
  // signup options input have change so we need to request icbs and medwise version again
  const refresh_icb_or_version = () =>{
    if (showIcb && versions && versions.length> 0 && typeof versions === 'object'  ){
      get_medwise_version(undefined);
    }
    else {
      if ( showIcb && icbs && typeof icbs === 'object' && icbs.length >0 && defaultIcb ){
        get_medwise_version(undefined);
      }
    }
  };

  const updateLastName = (e) => {
    setLastNameError(false);
    setFetchedLastNames(e.target.value);
  };


  const updateRegistrationNumber = (e) => {
    setFetchedRegistrationNumber(e.target.value);
  };

  const dict_of_professional_num = {
    "GP/GP Partner" :"GMC number", //  
    "Locum GP" :"GMC number",
    "Physician Associate" : undefined,
    "Nurse" : "NMC / PIN number" ,
    "GP Trainee": undefined,
    "Advanced Nurse Practitioner": "NMC / PIN number",
    "Pharmacist": "GPhC / PSNI number",
    "Other": undefined
   };


  // submit for name and profession
  const get_medwise_version = (ods_code) => {
    const stepThreeValues = {
      first_name: fetchedFirstNames, // fetchedFirstNames variable updated if change in form 
      last_name: fetchedLastNames, // fetchedLast variable updated if change in form
      registration_number:fetchedRegistrationNumber , // fetchedRegistrationNumber variable updated if change in form
      email: fetchedEmail, 
    };
    if (selectedProfession === "Other" && profession && profession.trim() !== ''  ) {
      // stepThreeValues['profession'] = stepThreeValues['profession_other'];
      stepThreeValues['profession'] = profession;

    } else {
      stepThreeValues['profession'] = selectedProfession;
      stepThreeValues['profession_other'] = 'something';
    }
    let temp_odsCode  ; 
    if (typeof ods_code !== 'undefined'){
      temp_odsCode = ods_code['ods_code'];
    }else{
      temp_odsCode = undefined;
    }

    setLoadingIcb(true);
    setRequestTrackerContext(prev => { return {...prev, 'suggest-signup-options':true}});
    axios({
      method: 'post',
      url:`${window.BASE_URL_USER}suggest-signup-options`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        "first_name": stepThreeValues.first_name,
        "last_name": stepThreeValues.last_name,
        "email": stepThreeValues.email , 
        "profession":stepThreeValues.profession,
        "location_object": typeof place!== 'undefined' ? place : {'name':location},
        'ods_code':temp_odsCode,
        'non_signup':true,
      }),
    })
      .then((res) => {
        // setNotLoading(true);
        setLoadingIcb(false);
        setRequestTrackerContext(prev => { return {...prev, 'suggest-signup-options':false}});
        if (res.data) {
          // check that we have a default and that we did not input ods_code
          if(res.data.default_icb && typeof ods_code === "undefined"){
            setDefaultIcb (res.data.default_icb); 
            setUserIcb(undefined);
          }
          else{
            if( typeof ods_code !== "undefined" ){
              setDefaultIcb (ods_code); // if we input an odscode we had selected icb
            }
            else{
              setUserIcb(undefined);
              setDefaultIcb (undefined); 
            }
          }
          setDefaultVersion(res.data.default_version); 
          setUserVersion(undefined);
          setVersions(res.data.suggested_versions);
          setIcbs(res.data.suggested_icbs);
        }
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'suggest-signup-options':false}});
        setLoadingIcb(false);
        // setNotLoading(true);
        console.log('Error: ', error);
      })
  };

  const handleClickShowIcb = () => {
    setShowIcb(true)
    get_medwise_version(undefined);
  };

  const updateIcb = (e) => {
    let odsCode = e.value;
      if (odsCode !== "") {
        //place can only be undefined or have an object 
        if(place){
          get_medwise_version({'ods_code':e.value,'icb_name': e.name})}
      } 
  };

  const updateVersion = (e) => {
    setUserVersion({'name':e['name'],'id':e['value']});
  };

  return (
    <>
    {/* <form onSubmit={handleSubmit(onSubmitProfile)}> */}
    <form>
      {userContext['ask_for_details']&&(<Row>
        <Col md={12}> 
        <Label h2 semibold style={{color:"red"}}>
            Please complete your details to continue </Label>
        </Col>
      </Row>)}

      <Row>
      <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
              Email </Label>
            {/* <Label c1 semibold textoffblack className= "email_address">
                {fetchedEmail}
            </Label> */}
             <TextInput
              textoffblack
              type="text"
              name="email"
              defaultValue={fetchedEmail}
              value={fetchedEmail}
              disabled={true}
              disabledGrey={true}
            />
          </FormFieldWrapper>
        </Col>
        <Col md={6}>
        {organisation && (
                <FormFieldWrapper>
                  <Label c1 semibold textoffblack>
                    Organisation
                  </Label>
                  <Label c1 semibold textoffblack>{organisation}</Label>
                </FormFieldWrapper>
              )}
        </Col>

        
      </Row>
      <Row>
        <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
              First Name(s)
            </Label>
            <TextInput
              textoffblack
              type="text"
              name="first_name"
              defaultValue={fetchedFirstNames}
              value={fetchedFirstNames}
              isError={firstNameError}
              onChange={(e) => updateFirstName(e)}
            />
            <ErrorMessage c1 regular colorError>
              {firstNameError && 'Please enter your first name'}
            </ErrorMessage>
          </FormFieldWrapper>
        </Col>
        <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
              Last Name(s)
            </Label>
            <TextInput
              textoffblack
              type="text"
              name="last_name"
              defaultValue={fetchedLastNames}
              value={fetchedLastNames}
              onChange = {(e) => updateLastName(e)}
              isError={lastNameError}
            />
            <ErrorMessage c1 regular colorError>
              {lastNameError && 'Please enter your last name'}
            </ErrorMessage>
          </FormFieldWrapper>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormFieldWrapper>
              <Label c1 semibold textoffblack>
              Profession
            </Label>
            <Select
                    styles={{
                      singleValue: (styles) => ({ 
                        ...styles,
                         color:"#212452",
                        }),
                      // placeholder: (styles) => ({ ...styles, border:' 1px solid #E2E5ED',backgroundColor:"#FBFBFD" }),
                      placeholder: (styles) => ({ 
                        ...styles,
                         backgroundColor:"#FBFBFD",
                         color: "#97AAB6",
                         fontWeight: "400",
                         opacity: "1",
                        }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '15px',
                        border:' 1px solid #E2E5ED',
                        backgroundColor:'#fbfbfd',
                        color: "#212452",
                      }),
                      option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#212452",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#8771DF',
                      },
                    })}
                     className="profession"
                     classNamePrefix="select"
                     key={'profession'+selectedProfession}
                     unstyled ={false}
                     onChange={(e) => updateProfession(e)}
                     options={ getListOfProfessions()} 
                     defaultValue={typeof  selectedProfession=== 'string'?{'value': selectedProfession, 'name':selectedProfession,'label':selectedProfession}:'Select' }
                     isClearable={false}
                     isSearchable={false}
                    //  placeholder={  {'value': 'select', 'name':'select','label':'select'} }
                    >
                    </Select>
            <ErrorMessage c1 regular colorError>
              {professionError && 'Please select your profession'}
            </ErrorMessage>

            <TextInput
              textoffblack
              type="text"
              name="profession_other"
              key = 'input_profession_other'
              placeholder={"Type your profession"}
              defaultValue={profession}
              value={profession}
              onChange={(e) => updateProfessionOther(e)}
              isError={professionOtherError}
              style={{ display: `${otherProfessionSelected? 'block' : 'none'}` }}
            />
            
            <ErrorMessage c1 regular colorError style={{ display: `${professionOtherError? 'block' : 'none'}` }}>
              {professionOtherError && 'Please enter your profession'}
            </ErrorMessage>
       
          </FormFieldWrapper>
        </Col>
        <Col md={6}>
            {/* { extraParams &&  Object.hasOwn(extraParams,'options')&& ( */}
            <FormFieldWrapper>
              <Label c1 semibold textoffblack style={{ display: `${( typeof dict_of_professional_num[selectedProfession] !== "undefined") ? 'block' : 'none'}` }}>
                {dict_of_professional_num[selectedProfession]}
              </Label>
              <TextInput
                textoffblack
                type="text"
                name="registration_number"
                defaultValue={fetchedRegistrationNumber}
                placeholder="Optional"
                onChange={(e) => updateRegistrationNumber(e)}
                value={fetchedRegistrationNumber}
                style={{ display: `${( typeof dict_of_professional_num[selectedProfession] !== "undefined") ? 'block' : 'none'}` }}
              />
              <ErrorMessage c1 regular colorError>
              </ErrorMessage>
            </FormFieldWrapper>
          </Col>
        
        <Col md={6}>
          <FormFieldWrapper>
            <Label c1 semibold textoffblack>
              Institution
            </Label>
            <GoogleSuggest 
            setBlockNext={setBlockSave}
            setBlockGetVersion={setBlockSave} 
            setLoadingIcb={setLoadingIcb}
            blockGetVersion={blockVersion} 
            setLocation={setLocation} 
            location={location && typeof location == "string" && location !== ''?location:''} 
            setPlace={setPlace} 
            isError={locationError}
            fromAccountPage = {true}
            ></GoogleSuggest> 

          { promptVersion && !showIcb && (
              <Span c1 semibold greendark>
             <A greendark onClick={handleClickShowIcb} > Click here to select a new ICB and Medwise Version </A>
              </Span>
            )}
          </FormFieldWrapper>
        </Col>
          {/* { extraParams &&  Object.hasOwn(extraParams,'options')&& ( */}
          { extraParamName && extraParams && typeof extraParams === "object" && Object.hasOwn(extraParams,'options') && (
            <Col md={6}>
              <FormFieldWrapper>
                <Label c1 semibold textoffblack>
                  {extraParamName } 
                  {/* {extraParams} */}
                </Label>

                {extraParams && typeof extraParams === "object" && Object.hasOwn(extraParams,'options') && (
                  <Col md={12} style={{paddingLeft: "0px", paddingRight: "0px"}}>
                    <FormFieldWrapper>
                      <AccountOrgFieldAutosuggest options={extraParams['options']} value={fetchedParam} setData = {setNewExtraParam} />
                    </FormFieldWrapper>
                  </Col>
                )}
              </FormFieldWrapper>
            </Col>
          ) }
      </Row>
      <Row>
      {loadingIcb && showIcb &&(
          <Col md={6}>
            <Loader type="Oval" color="#8771DF" height="30" width="30" />
          </Col>
          )}
        {showIcb && icbs && typeof icbs === 'object' && icbs.length >0  &&  icbs[0] !== null && !loadingIcb && defaultIcb &&(
          <Col md={6} className='mb-3'>
            <Label c1 semibold textoffblack  >
              ICB 
            </Label>
            <FormFieldWrapper>
            <Select
                styles={{
                  singleValue: (styles) => ({ 
                    ...styles,
                      color:"#212452",
                    }),
                  // placeholder: (styles) => ({ ...styles, border:' 1px solid #E2E5ED',backgroundColor:"#FBFBFD" }),
                  placeholder: (styles) => ({ 
                    ...styles,
                      backgroundColor:"#FBFBFD",
                      color: "#97AAB6",
                      fontWeight: "400",
                      opacity: "1",
                    }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: '15px',
                    border:' 1px solid #E2E5ED',
                    backgroundColor:'#fbfbfd',
                    color: "#212452",
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#212452",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#8771DF',
                  },
                })}
                  className="icbs"
                  classNamePrefix="select"
                  unstyled ={false}
                  onChange={(e) => updateIcb(e)}
                  isClearable={false}
                  isSearchable={true}
                  key={defaultIcb['icb_name']}
                  defaultValue={ {'value': defaultIcb['ods_code'], 'name':defaultIcb['icb_name'],'label':defaultIcb['icb_name']} ||'Select' }
                  options={ icbs.map((x)=> {
                  if( x && x.ods_code && x.icb_name){
                  return {'value': x.ods_code , 'name':x.icb_name,"label":x.icb_name };}}  ) } 
                >
                </Select>
              
              {/* we only want to add this Span  if registry number is selected  */}

            </FormFieldWrapper>
          </Col>)}
      </Row>
      <Row>
      { showIcb && versions && versions.length> 0 && typeof versions === 'object' && !loadingIcb && (
          <Col md={6} className='mb-3 '>
            <Label c1 semibold textoffblack  >
              Medwise version 
            </Label>
            <FormFieldWrapper>
            <Select
                styles={{
                  singleValue: (styles) => ({ 
                    ...styles,
                      color:"#212452",
                    }),
                  // placeholder: (styles) => ({ ...styles, border:' 1px solid #E2E5ED',backgroundColor:"#FBFBFD" }),
                  placeholder: (styles) => ({ 
                    ...styles,
                      backgroundColor:"#FBFBFD",
                      color: "#97AAB6",
                      fontWeight: "400",
                      opacity: "1",
                    }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: '15px',
                    border:' 1px solid #E2E5ED',
                    backgroundColor:'#fbfbfd',
                    color: "#212452",
                  }),
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#212452",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#8771DF',
                  },
                })}
                  key={defaultVersion['name']}
                  className="version"
                  classNamePrefix="select"
                  unstyled ={false}
                  onChange={(e) => updateVersion(e)}
                  isClearable={false}
                  isSearchable={false}
                  defaultValue={ {'value': defaultVersion['name'], 'name':defaultVersion['name'],'label':defaultVersion['name']} || 'Select'}
                  options={ versions.map((x)=> {return {'value': x.id , 'name':x.name,'label':x.name} ;}  ) } 
                >
                </Select>
              {/* we only want to add this Span  if registry number is selected  */}
            </FormFieldWrapper>
          </Col>)}
      </Row>

      <SaveChangesWrapper>
        <Button
          type="button"
          colorprimary
          buttonmedium
          text="Save changes"
          width="140px"
          disabled={isSubmittedProfile && true}
          onClick={onSubmitProfile}
        />
        {displaySaveProfile && (
          <SaveChangesMessage>
            <CheckIcon />
            <Span p1 semibold greendark>
                Saved!
            </Span>
          </SaveChangesMessage>
        )}
        {(lastNameError || firstNameError || professionError || professionOtherError || locationError || errorPracticeOther) && (
          <ErrorMessage c1 regular colorError style={{display: 'flex', alignItems: 'center'}}>
            <FaTimes style={{marginLeft: '8px', marginRight: '4px'}} />
            <Span p1>
              Please amend the error(s) above to continue
            </Span>
          </ErrorMessage>
        )}
      </SaveChangesWrapper>
    </form>
    </>
  );
};

export default ProfileSettingsForm;
