import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Styles from './styles';
import Button from '../button';
import { H2, P } from '../theme/typography';

import PinIcon from '../../assets/svgs/pin-icon.svg';
import BookmarkIcon from '../../assets/svgs/bookmark-icon.svg';
import MessageBoxIcon from '../../assets/svgs/messagebox-icon.svg';

const SignupCards = () => {
  const featureCardsContent = [
    {
      icon: PinIcon,
      iconAlt: 'Pin icon',
      title: 'Add your local guidelines',
      text:
        'Help us provide more relevant information for you and fellow practitioners in your area',
    },
    {
      icon: BookmarkIcon,
      iconAlt: 'Bookmark icon',
      title: 'Stay up to date',
      text:
        'Get notified about the latest updates to guidelines & your topics of interest',
    },
    {
      icon: MessageBoxIcon,
      iconAlt: 'Message Box icon',
      title: 'Browse your search history',
      text:'Identify your information needs and find past searches quickly',
    },
  ];

  return (
    <Styles>
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <H2 h2 semibold textoffblack className="title">
              Register for an account to benefit from the following features:
            </H2>
          </Col>
        </Row>
        <Row>
          {featureCardsContent.map((content, i) => {
            return (
              <Col md={4} key={i}>
                <div className="card">
                  <img
                    className="card_icon"
                    src={content.icon}
                    alt={content.iconAlt}
                  />
                  <H2 h2 semibold textoffblack className="card_title">
                    {content.title}
                  </H2>
                  <P p1 regular textmainbody>
                    {content.text}
                  </P>
                </div>
              </Col>
            );
          })}
        </Row>
        {
          <Row>
            <Col md={12}>
              <div className="button">
                <Button
                  type="link"
                  colorprimary
                  buttonlarge
                  to="/signup"
                  target="_blank"
                  rel="noopener noreferrer"
                  text="Sign up now"
                  width="205px"
                />
              </div>
            </Col>
          </Row>
        }
        <Row >
          <Col className=' d-flex justify-content-center pt-3'>
            <H2 h2 semibold textoffblack className="title2">
            For secondary care clinicians, please join our waiting list here:
            </H2>
          </Col>
        </Row>
        <Row className='d-flex justify-content-start'>
          <Col md={12} >
            <div className="button2">
                <Button
                  type="external_link"
                  colororange
                  buttonlarge
                  to="https://medwise.activehosted.com/f/1"
                  target="_blank"
                  rel="noopener noreferrer"
                  text="Join the waiting list"
                  width="205px"
                />
              </div>
          </Col>
        </Row>
      </Container>
    </Styles>
  );
};

export default SignupCards;
