
import React  from 'react';
import { Span } from '../theme/typography';
import { Col,Row ,Container } from 'react-bootstrap';
import Button from '../button';
import Styles from './styles';
import { LoginFormTitleWrapper } from '../../components/form/styles';





const ConfirmLogin = (props) => {

    const goHome = ()=>{
        window.location =  window.BASE_API_URL;
      };
    
    const loginMagic = () => {
       props.magicLogin(props.magicCode);
    };
    
  return (
    <Styles>
        <div className={props.isMagic?'isMagic':'noMagic'}>
            <Container>
                <Row>
                    <Col  md ={12} sm={12}>
                    <LoginFormTitleWrapper>
                        { (props.organisation && props.organisation !== '' )&&<Span p1 semibold textmainbody>
                        You are currently logged in with a <b> {props.organisation} </b>account. 
                        </Span>}
                        <Span p1 semibold textmainbody>
                        &nbsp; Would you like to log out of this account and log in using the {props.isMagic ? 'magic':'' }  link? 
                        </Span>
                        <Row>
                        <Col  className='p-3' md={5} sm={12}>
                        {props.login ? (
                            <Button
                            type= "button"
                            colorprimary
                            buttonmedium
                            text="Log in with link"
                            width="100%"
                            onClick={props.login}
                            />):
                            (<Button
                            type= "button"
                            colorprimary
                            buttonmedium
                            text="Log in with link"
                            width="100%"
                            onClick={()=>loginMagic()}
                            />)
                            }
                        </Col>
                        <Col className='p-3' md={{ span: 5, offset: 2 }}  sm={{ span: 12 }}>
                            <Button
                            type= "button"
                            colorprimary
                            buttonmedium
                            text="Go to homepage"
                            width="100%"
                            onClick={goHome}
                            />
                        </Col>
                        </Row>
                    </LoginFormTitleWrapper>
                    </Col>
                </Row>
            </Container>

        </div>
    </Styles>
  )
};

export default ConfirmLogin;
