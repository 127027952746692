import styled from 'styled-components';

export const Article = styled.article`
  main {
    height: calc(100vh - 182px); // 182, 254
    overflow-y: auto;
  }

  .loadingContainer {
    // height: 100%;
    // display: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .loadingMsg {
    font-size: 18px;
  }

  .top-msg {
    font-size: 14px;
    font-weight: 400;
    color: #4d5156;
  }

  .max-msg {
    width: 100%;
    text-align: center;
    margin-top: 64px;
    color: #97aab6;
  }

  .web-change {
    color: #02B1C8;
    background-color: #EFF9FB;
    padding: 3px 8px;
    border-radius: 10px;
    // font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    box-shadow: 0 0 10px 0 #eee;
    border: 1px solid #EFF9FB;
    // display: none;
  }

  .web-change:hover {
    border: 1px solid #02B1C8;
  }

  .loading-container {
    display: flex;
    background: #fff;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px #eee;
    position: relative;
    min-height: 50px;
    align-items: center;
  }

  .sources-msg {
    font-weight: 600;
  }
`;

export const FiltersHeader = styled.div`
  background: blue;
  display: flex;
  align-items: center;
  min-height: ${({ theme }) => theme.spacing.x10};
  padding: ${({ theme }) => theme.spacing.x2} 0;
  line-break: after;
  p{
    cursor: pointer;
    color:#1B5EF7;
  }
  @media (max-width: 767.98px) {
    padding: 10px;
    min-height: 40px;
    
    .numberOfResults{
      color: #4D5156;
      font-size:16px;
    }
  }
`;

export const FiltersHeaderMessage = styled.div`
  margin: 0;
  flex-shrink: 0;
  margin: 12px 0 auto auto;

  span {
    font-weight: ${({ theme }) => theme.fontweight.semibold};
  }

  @media (max-width: 767.98px) {
    color: ${({ theme }) => theme.palette.textMainbody};
    margin: 0;
    margin-right: auto;
    flex-shrink: unset;
  }
`;

export const SideFeedback = styled.div`
    display: flex;
    align-items: center;
    min-height: ${({ theme }) => theme.spacing.x10};
    padding: ${({ theme }) => theme.spacing.x2} 0;
    line-break: after;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #212452;
    .sideFeedback{
      a{
        cursor: pointer;
        color:#1B5EF7;
      }
    }
    @media (max-width: 767.98px) {
      padding: 10px;
      min-height: 40px;
    }
`;


export const FiltersToggleButton = styled.span`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: ${({ theme }) => theme.spacing.x5};
  border: 1px solid ${({ theme }) => theme.palette.backgroundStroke};
  border-radius: ${({ theme }) => theme.borderradius.medium};
  font-weight: ${({ theme }) => theme.fontweight.regular};
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.textOffblack};
  background-color: ${({ theme }) => theme.palette.backgroundWhite};
  padding: 0 ${({ theme }) => theme.spacing.x1};
  margin-left: ${({ theme }) => theme.spacing.x1};
  cursor: pointer;
`;