import styled, { css } from 'styled-components';
import Hr from '../hr/styles';


export const Styles = styled.div`
  color: #212452;
  display: flex; 
  flex-wrap: wrap;
  gap: 6px;
  background: #fbfbfd;
  margin: 0px 0px 0px 4px;
  padding: 2px 0px 8px 0px;
  overflow:hidden;


  overflow-x: hidden;
  white-space: nowrap;
  font-size: 16px;
  font-family: 'Mulish';
  // border:1px solid #212452;
  // border-radius: 4px;

  @media (max-width: 767.98px) {
    border-top: 1px solid #c9d3da;
    padding: 4px 4px 4px 8px;
    margin: 0px 0px 0px 0px;
  }

    .DrugNamePill{
      color: #212452;
      display: inline-flex;
      margin: 3px;
      padding: 5px 5px 5px 5px;
      align-items: center;
      height: 35px;
      justify-content: center;
      background: #fff;
      border: 1px solid #c9d3da;
      box-sizing: border-box;
      border-radius: 5px; // 20px for pill
      cursor: pointer;
      // overflow:hidden;
      &:hover {
        // border-color: #F1F4FB;
        background-color: #F1F4FB;
        // background: #f1f3f4;
      },
    }
`;


export default Styles;
// export default DrugNamePill;
