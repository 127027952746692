import styled from 'styled-components';

export const Article = styled.article`
  .title {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.24;
  }
  .description {
    font-size: 14px;
    opacity: .4;
    color: #000;
    margin-top: 4px;
    line-height: 1.3;
    border: none;
    justify-content: flex-start;
    width: 100%;
  }
  .value {
    font-size: 18px;
    padding-right: 0;
    background-color: #f7f2ed;
    width: 100%;
    border: none;
    padding-right: 10px;
    text-align: right;
  }

  .value:hover {
    background: #efe5dd;
  }

   .value:focus {
   outline: none;
   }

  .description-link:hover {
    text-decoration: underline;
  }

  .value-element {
    position: relative;
  }

  .unit {
    position: absolute;
    right: 25px;
    top: 9px;
    font-size: 18px;
    color: #000;
  }

  .unit-padding {
    position: absolute;
    left: 25px;
    top: 9px;
    font-size: 18px;
    color: #000;
  }

  .value-padding {
    padding-right: 28px;
  }

  .value-padding1 {
    padding-right: 43px;
  }
`;