import axios from 'axios';
import React, {useEffect, useState,useContext} from 'react';
import { AppContext } from '../../context';
import { FaFolderOpen } from 'react-icons/fa';
import { getAllSources, getAllSourcesObject, getDictUserSources } from '../../functions/manageSessions';
import { Span } from '../theme/typography';
import Tag from './styles';
import useIsSmallScreen from '../../useIsSmallScreen/useIsSmallScreen';

const PublisherTag = ({ publisher, ...props }) => {
  //TODO Changer publisherNameParse to memo since it is derived
  const [publisherNameParse, setPublisherNameParse] = useState(publisher);
  const isSmallScreen = useIsSmallScreen();
  const {
    userContext,
  } = useContext(
    AppContext
  );

  useEffect (() => {
    if(publisher && publisher !== "" && publisher !== 'Unknown' ){
      var parser = new DOMParser();
      var decodedPublisherName = parser.parseFromString(publisher, 'text/html').body.textContent;
      if(decodedPublisherName){
        setPublisherNameParse(decodedPublisherName);
      }
      else{
        setPublisherNameParse(publisher);
      }
    }
  },[publisher]);

  const requestTokenAndVisit =  (url) => {
    let new_url = ''; 

    // const config = {
    //   method: 'post',
    //   url: `${window.BASE_URL_USER}get-expiry-token`,
    //   withCredentials : true,
    //   headers: {'Content-Type': 'application/json' },
    //   data: JSON.stringify({'requested': props.requestToken}),
    // };

    // axios(config)
    // .then(function(res){
      if (userContext['search_params']){
        if ( url.includes('__PUBLIC_TOKEN__') ) {
          new_url = url.replace('__PUBLIC_TOKEN__',userContext['search_params']);
          }
        else{
            new_url = url + "?token=" + userContext['search_params'];  
          }
          window.open(new_url,'_blank','noreferrer');

      }
      else{
        // if 400 previours url
        window.open(new_url,'_blank','noreferrer');
      }
  };

  //TODO optimized only call do not loop 
  const handleLinkoutClick = (e, publisherName) => {
    if(props.requestToken && props.isPrivate){
      requestTokenAndVisit(props.url);
    }
    else{
      window.open(props.url,'_blank','noreferrer');
    }
  };

  return (
    <Tag
      color={props.color}
      backgroundColor={props.backgroundColor}
      borderColor={props.borderColor}
      noMargin ={props.inSearchBar} 
      height ={props.inSearchBar || props.inMobileResult ? "21px":"unset"}
      width ={props.inSearchBar ? "77px":"auto"}

    >
      {props.isFolder &&  props.isFolder!== '' &&  (
      <FaFolderOpen className='folder'></FaFolderOpen>)}
      {publisherNameParse && typeof publisherNameParse === "string" && !props.inSearchBar && !isSmallScreen && (
      <Span c2 extrabold onClick={(e) => handleLinkoutClick(e, publisherNameParse)} >
        {publisherNameParse && publisherNameParse.length < 50 ? (publisherNameParse):(  publisherNameParse.slice(0,37) +'...' ) }
      </Span>) }
      {publisherNameParse && typeof publisherNameParse === "string" && !props.inSearchBar && isSmallScreen && (
      <Span c2 extrabold onClick={(e) => handleLinkoutClick(e, publisherNameParse)} >
        {publisherNameParse && publisherNameParse.length < 40 ? (publisherNameParse):(  publisherNameParse.slice(0,37) +'...' ) }
      </Span>) }
      {publisherNameParse && typeof publisherNameParse === "string" && props.inSearchBar &&(
      <Span className={props.inSearchBar?"noClick":"" } c2 extrabold >
        {publisherNameParse}
        {/* {publisherNameParse && publisherNameParse.length<=8 ? (publisherNameParse):(  publisherNameParse.slice(0,8) +'...' ) } */}
      </Span>) }
    </Tag>
  );
};

export default PublisherTag;
