import styled from 'styled-components';

export const Styles = styled.section`
  .error {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
    margin-top: ${({ theme }) => theme.spacing.x8};

    @media (max-width: 767.98px) {
      margin-top: ${({ theme }) => theme.spacing.x5};
    }

    &_title {
      margin-top: ${({ theme }) => theme.spacing.x4};
      margin-bottom: ${({ theme }) => theme.spacing.x2};
    }

    &_message {
      margin-bottom: ${({ theme }) => theme.spacing.x5};
    }
  }
  .loadingWrapper{
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    svg{
      margin-top : calc(50vh - 80px);
      // cal 118px
      max-width: 120px;
      max-height: 120px;
      @media (max-height:340px )  {
        display:none;
      }
      @media (max-width: 767.98px) {
        display:none;
      }
    }
  }
`;

export default Styles;
