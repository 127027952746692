import React, { useState,useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Row, Col } from 'react-bootstrap';
import * as yup from 'yup';
import {
  TextInput,
  ErrorMessage,
  LoginServerError,
  DeleteAccountMessagesWrapper,
} from '../../styles';
import { getUser, removeUserSession } from '../../../../functions/manageSessions';
import axios from 'axios';
import Button from '../../../button';
import { H2, Span } from '../../../theme/typography';
import {
  DeleteAccountFormTitleWrapper,
  DeleteAccountButtonWrapper,
  FormConsentFieldWrapper,
  SaveChangesWrapper,
} from '../../styles';
import { AppContext } from '../../../../context';

const DeleteAccountForm = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [sentCodeError,setSentCodeError] = useState(undefined);
  const [wrongCodeError,setWrongCodeError] =  useState(undefined);
  const [userEmail,setUserEmail] =useState("");
  const history = useHistory();
  const { setRequestTrackerContext, setUserContext,setUserStats,removeUserOrganisationContext } = useContext(AppContext);
  const signupSchema = yup.object().shape({
    consent: yup.boolean().oneOf([true]),
  });

  // This useForm hook initiates react-hook-form lib.
  const { register, getValues, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(signupSchema),
  });

  const signupSchema1 = yup.object().shape({
    code: yup
      .string()
      .required('Please enter your deletion code')
      .min(6, 'The code must be 6 characters in length')
      .max(6, 'The code must be 6 characters in length')
  });

  // This useForm hook initiates react-hook-form lib.
  const { register: register1, handleSubmit: handleSubmit1, getValues: getValues1, errors: errors1 } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(signupSchema1),
  });

  const sendEmail = () => {
    const user_info = getUser();
    setUserEmail(user_info['email']);
    setRequestTrackerContext(prev => { return {...prev, 'send-email-with-delete-code':true}});
    axios({
      method: 'post',
      url: `${window.BASE_URL_USER}send-email-with-delete-code`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        email: user_info['email']
      }),
    })
      .then((res) => {
        setRequestTrackerContext(prev => { return {...prev, 'send-email-with-delete-code':false}});
        if (res.data.success) {
          setEmailSent(true);
          setSentCodeError(undefined);
        } else {
          setSentCodeError(res.data.msg);
        }
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'send-email-with-delete-code':false}});

        setSentCodeError('Error sending code, please try again')
        console.log('Error: ', error);
      });
  };

  const sendErraseRequest = (values) => {
    setRequestTrackerContext(prev => { return {...prev, 'delete-user-self`':true}});
    axios({
      method: 'post',
      url: `${window.BASE_URL_USER}delete-user-self`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        email: values.email,
        code: values.code
      }),
    })
      .then((res) => {
        setRequestTrackerContext(prev => { return {...prev, 'delete-user-self`':false}});
        if (res.data.success) {
          // removeUserSession();
          // setUserContext({email:''});
          setUserContext({'email':'', 'org_name':undefined});
          removeUserOrganisationContext();
          setUserStats(undefined);
          history.push('/');
        }
        else {
          setWrongCodeError(res.data.msg);
        }
      })
      .catch((error) => {
        setRequestTrackerContext(prev => { return {...prev, 'delete-user-self`':false}});
        console.log('Error: ', error);
      });

  };

  const erraseAccount = () => {
    var values= getValues1([
      'code'
    ]);
    values['email'] = userEmail;
    console.log('Account / Delete Account Form Data: ', values);
    if (values['code']){
      handleSubmit1(sendErraseRequest(values));
    }
  };

  const do_nothing = (data) => { return false; }


  return (
    <form onSubmit={handleSubmit(do_nothing)}>
      <DeleteAccountFormTitleWrapper>
        <H2 h2 semibold textoffblack>
          Permanently delete your account{' '}
        </H2>
      </DeleteAccountFormTitleWrapper>
      <Row>
        <Col md={12}>
          <FormConsentFieldWrapper>
            <input
              type="checkbox"
              id="consent"
              name="consent"
              ref={register}
              onChange={() => setIsChecked(getValues('consent'))}
            />
            <label htmlFor="consent">
              <Span h2 regular textmainbody>
                I understand that this account will no longer be available, and
                all data in the account will be permanently deleted.
              </Span>
            </label>
          </FormConsentFieldWrapper>
        </Col>
        <Col>
        {sentCodeError&&(
          <ErrorMessage c1 regular colorError>{sentCodeError}</ErrorMessage>
        )}
        </Col>
      </Row>
      {!emailSent &&(
        <SaveChangesWrapper>
          <Button
            type="button"
            colorerror
            buttonmedium
            text="Next"
            width="198px"
            disabled={!isChecked && true}
            onClick= {sendEmail}
          />
        </SaveChangesWrapper>
      )}
      {emailSent &&(
      <Row>
        <Col md={12}>
          <DeleteAccountMessagesWrapper>
            <Span h2 regular textmainbody>
                  We've sent a code to <b>{userEmail}</b>. If you haven't received it yet, please also check your spam folder.
            </Span>
          </DeleteAccountMessagesWrapper>
        </Col>
        <Col md={12}>
          <DeleteAccountFormTitleWrapper>
            <H2 h2 semibold textoffblack>
              Deletion code
            </H2>
          </DeleteAccountFormTitleWrapper>
        </Col>
        <Col md={12}>
          <TextInput
                autoComplete='off'
                name="code"
                type={'text'}
                placeholder="Enter your code here"
                ref={register1}
                isError={errors1.code || wrongCodeError}
              />
              {errors1.code && (
              <ErrorMessage c1 regular colorError>
                {errors1.code && errors1.code.message}
              </ErrorMessage> )}
              {wrongCodeError && (
              <LoginServerError c1 regular colorError>
                {wrongCodeError }
              </LoginServerError> )}
        </Col>
      </Row>
             )}
      {emailSent &&(
      <DeleteAccountButtonWrapper>
        <Button
          type="button"
          colorerror
          buttonmedium
          text="Delete my account"
          width="198px"
          disabled={!isChecked && true}
          onClick= {handleSubmit1(erraseAccount)}
        />
      </DeleteAccountButtonWrapper>)}
    </form>
  );
};

export default DeleteAccountForm;
