import React, { useState, useEffect, useContext, useMemo } from 'react';
import { AppContext } from '../../../context';
import Filters from '../index';
import Button from '../../button';
import { H2, Span } from '../../theme/typography';
import Styles from './styles';
import CloseIcon from '../../../assets/svgs/close-icon.svg';
import Hr from '../../hr';
import {getUserFilters} from'../../../functions/manageSessions';

// use on mobile or if width < 768px
const FiltersMobile = ({numberOfFilters}) => {
  // const { mobileContext, setMobileContext } = useContext(AppContext);
  const [shouldSlideUp, setShouldSlideUp] = useState(false);

  const {
    searchContext,
    setMobileContext,
    mobileContext,
    userContextFilters,
  } = useContext(AppContext);

  const getAllSourcesFromFacetEntries = (facets) =>{
    let allSources = [];
    facets.forEach(filterOrSrc => {
      //if it is src 
      if(filterOrSrc && filterOrSrc['encrypted_url']){
        allSources.push(filterOrSrc) ;
      }
      else{
        // it is a filter with maybe more than 1 src
        if(filterOrSrc && filterOrSrc['sources']){
          filterOrSrc['sources'].forEach(src => {
            allSources.push(src);
          });
        }
      }
    });
    return allSources;
  };

  // Lock the body scroll when mobile filter is active (overflow: hidden)
  // and reset body scroll to "initial" when toggle state is false
  // Mobile filter slides up just after dark gray background loads
  useEffect(() => {
    if (mobileContext.toggle) {
      setTimeout(() => {
        document.body.style.overflow = 'hidden';
        setShouldSlideUp(true);
      }, 150);
    } else {
      document.body.style.overflow = 'initial';
      setShouldSlideUp(false);
    }
  }, [mobileContext.toggle]);

  // const numberOfSelectedFacets= useMemo(() =>  { return mobileContext.facetEntries && mobileContext.facetEntries.length } 
  // , [mobileContext])
 
  const handleClear = ()=>{
    // setSkipRequests(true);
    // setSearchContext({
    //     ...searchContext,
    //     facets:[]
    // });

    setMobileContext({
      ...mobileContext,
      facetEntries: [],
      covid_only: false,
      // forceSearch: true
    });
  };

  const handleApply = ()=>{
    // setSkipRequests(true);
    // setSearchContext({
    //     ...searchContext,
    //     facets:[]
    // });
    setMobileContext({
      ...mobileContext,
      facetEntries: [],
      covid_only: false,
      // forceSearch: true
    });
  };


  const getNumberOfSelectedSources = useMemo(() => {
    let allSources;
    let count =0; 
    if(userContextFilters && Object.hasOwn(userContextFilters,'sources') &&
      mobileContext  && Object.hasOwn(mobileContext,'facetEntries') ){
      allSources = new Set (userContextFilters['sources'].flatMap (tempSource => tempSource['encrypted_url']));
      let allMobileContextSources = getAllSourcesFromFacetEntries(mobileContext.facetEntries);
      allMobileContextSources= allMobileContextSources.map(tempSource =>  tempSource['encrypted_url']);
      allSources.forEach(url => {
        if(url && typeof url ==="string" && allMobileContextSources.includes(url)){
          count++; 
        }
      });
    }else{
      return count ;
    }
    return count;
  }, [mobileContext]);


  return (
    <Styles overlay={mobileContext.toggle ? true : false}>
      <div className={`mobilefilters ${shouldSlideUp ? 'slide' : undefined}`}>
        <div className="mobilefilters_nav">
          <img
            className="mobilefilters_nav--close"
            src={CloseIcon}
            alt="Close icon"
            onClick={() =>
              setMobileContext({ ...mobileContext,facetEntries: searchContext.facets , toggle: false })
            }
          />
          <H2 h2 regular textoffblack className="mobilefilters_nav--title" key ={mobileContext.facetEntries.length}>
             {"Sources "+ getNumberOfSelectedSources + "/" + numberOfFilters}
          </H2>
          <Span
            c1
            semibold
            textplaceholder
            className="mobilefilters_nav--clear"
            onClick={() => handleClear()}
          >
            Clear
          </Span>
        </div>
        <Hr style={{ margin: '0 -32x' }} />
        <div className="mobilefilters_filters">
          <Filters mobile key={'filters_filters' }/>
        </div>
        <div className="mobilefilters_apply">
          <Hr style={{ margin: '0 -32px 24px' }} />
          <Button
            type="action"
            colorprimary
            buttonsmall
            text="Apply"
            onClick={() => setMobileContext({ ...mobileContext, apply: true })}
          />
        </div>
      </div>
    </Styles>
  );
};

export default FiltersMobile;
