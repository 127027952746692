import { css } from 'styled-components';

export const textarea = css`
  font-family: ${({ theme }) => theme.fontfamily.mulish};
  font-size: ${({ theme }) => theme.fontsize.desktop.input};
  color: ${({ theme }) => theme.palette.textOffblack};
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.backgroundStroke};
  outline: 0;
  border-style: solid;
  appearance: none;
  border-radius: ${({ theme }) => theme.borderradius.medium};
  max-width: 100%;
  min-width: 100%;
  padding: ${({ theme }) => theme.spacing.x1};

  @media (max-width: 767.98px) {
    font-size: ${({ theme }) => theme.fontsize.mobile.input};
  }

  ::-ms-clear {
    display: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.palette.textPlaceholder};
    font-weight: ${({ theme }) => theme.fontweight.regular};
    opacity: 1;
  }
`;
