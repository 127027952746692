/**
 *  Example usage with a custom prop:
 *  <Button
      type="link"
      colorcustom
      buttonsmall
      to="/"
      text="Contact us"
      width="96px"
    />
 */

import React from 'react';
import { ButtonLink, ButtonA, ButtonAction, ButtonNormal } from './styles';
import emedLogo from '../../assets/svgs/eMed-heart-logo.png' ;
const Button = ({
  type,
  colorprimary,
  colorprimaryreverse,
  emedblue,
  colorsecondary,
  colororange,
  colorgreen,
  colorlightgreen,
  colorlightpurple,
  colorwhite,
  colorgrey,
  colorerror,
  colorcustom,
  colororangelanding,
  colornhs,
  colornhsheader,
  colornhsheadermobile,
  buttonsmall,
  buttonmedium,
  buttonlarge,
  to,
  target,
  rel,
  width,
  padding,
  text,
  onClick,
  disabled,
  className,
  id,
}) => {
  if (type === 'link') {
    return (
      <ButtonLink
        colorprimary={colorprimary ? 1 : 0} // https://github.com/styled-components/styled-components/issues/1198
        colorprimaryreverse={colorprimaryreverse ? 1 : 0}
        colorsecondary={colorsecondary ? 1 : 0}
        colororange = {colororange? 1 : 0}
        colororangelanding={colororangelanding? 1 : 0}
        colorgreen={colorgreen ? 1 : 0}      
        colorlightgreen ={colorlightgreen? 1:0}
        colorwhite={colorwhite ? 1 : 0}
        colorgrey={colorgrey ? 1 : 0}
        colorlightpurple ={colorlightpurple ? 1 : 0}
        colorerror={colorerror ? 1 : 0}
        buttonsmall={buttonsmall ? 1 : 0}
        buttonmedium={buttonmedium ? 1 : 0}
        buttonlarge={buttonlarge ? 1 : 0}
        href={to ? to : '/'}
        width={width}
        onClick={onClick}
        disabled={disabled ? 1 : 0}
        className={className}
        id={id}
      >
        {text}
      </ButtonLink>
    );
   } else if (type === 'external_link') { 
    return (
      <ButtonA
        colorprimary={colorprimary ? 1 : 0} // https://github.com/styled-components/styled-components/issues/1198
        colorprimaryreverse={colorprimaryreverse ? 1 : 0}
        colorsecondary={colorsecondary ? 1 : 0}
        colororange = {colororange? 1:0}
        colorgreen={colorgreen ? 1 : 0}
        colorlightgreen ={colorlightgreen? 1:0}
        colorwhite={colorwhite ? 1 : 0}
        colorgrey={colorgrey ? 1 : 0}
        colorlightpurple ={colorlightpurple ? 1 : 0}
        colorerror={colorerror ? 1 : 0}
        buttonsmall={buttonsmall ? 1 : 0}
        buttonmedium={buttonmedium ? 1 : 0}
        buttonlarge={buttonlarge ? 1 : 0}
        href={to}
        target={target}
        rel={rel}
        width={width}
        onClick={onClick}
        disabled={disabled ? 1 : 0}
        className={className}
        id={id}

      >
        {text}
      </ButtonA>
    );
  } else if (type === 'external_link_icon') { 
    return (
      <ButtonA
        colorprimary={colorprimary ? 1 : 0} // https://github.com/styled-components/styled-components/issues/1198
        colorprimaryreverse={colorprimaryreverse ? 1 : 0}
        emedblue={emedblue ? 1 : 0}
        colorsecondary={colorsecondary ? 1 : 0}
        colororange = {colororange? 1:0}
        colorgreen={colorgreen ? 1 : 0}
        colorlightgreen ={colorlightgreen? 1:0}
        colorwhite={colorwhite ? 1 : 0}
        colorgrey={colorgrey ? 1 : 0}
        colorlightpurple ={colorlightpurple ? 1 : 0}
        colorerror={colorerror ? 1 : 0}
        buttonsmall={buttonsmall ? 1 : 0}
        buttonmedium={buttonmedium ? 1 : 0}
        buttonlarge={buttonlarge ? 1 : 0}
        href={to}
        target={target}
        rel={rel}
        width={width}
        onClick={onClick}
        disabled={disabled ? 1 : 0}
        className={className}
        id={id}
        style={{justifyContent: "left", border:'1px solid #dadce0'}}
      >
        
        <img src={emedLogo} alt='eMED logo' style={{height: "24px"}} className="ml-3"></img>
        {/* <img src='https://www.emedgroup.co.uk/app/uploads/2023/03/logo.svg' alt='eMED logo' style={{height: "35px"}} className="ml-3"></img> */}
        <span className="mx-auto" style={{color: "3C4043", fontSize:'14px'}} >{text}</span>
      </ButtonA>
    );
  } else if (type === 'external_link_nhs') { 
    return (
      <ButtonA
        colorprimary={colorprimary ? 1 : 0} // https://github.com/styled-components/styled-components/issues/1198
        colorprimaryreverse={colorprimaryreverse ? 1 : 0}
        colorsecondary={colorsecondary ? 1 : 0}
        colororange = {colororange? 1:0}
        colorgreen={colorgreen ? 1 : 0}
        colorlightgreen ={colorlightgreen? 1:0}
        colorwhite={colorwhite ? 1 : 0}
        colorgrey={colorgrey ? 1 : 0}
        colorlightpurple ={colorlightpurple ? 1 : 0}
        colorerror={colorerror ? 1 : 0}
        colornhs={colornhs ? 1 : 0}
        colornhsheader={colornhsheader ? 1 : 0}
        colornhsheadermobile={colornhsheadermobile ? 1 : 0}
        buttonsmall={buttonsmall ? 1 : 0}
        buttonmedium={buttonmedium ? 1 : 0}
        buttonlarge={buttonlarge ? 1 : 0}
        href={to}
        target={target}
        rel={rel}
        width={width}
        onClick={onClick}
        disabled={disabled ? 1 : 0}
        className={className}
        id={id}
        style={{border: "none"}}
      >
      </ButtonA>
    );
  } else if (type === 'button') {
    return (
      <ButtonNormal
        colorprimary={colorprimary ? 1 : 0}
        colorprimaryreverse={colorprimaryreverse ? 1 : 0}
        colorsecondary={colorsecondary ? 1 : 0}
        colororange = {colororange? 1 : 0}
        colorgreen={colorgreen ? 1 : 0}
        colorlightgreen ={colorlightgreen ? 1 : 0}
        colorwhite={colorwhite ? 1 : 0}
        colorgrey={colorgrey ? 1 : 0}
        colorlightpurple ={colorlightpurple ? 1 : 0}
        colorerror={colorerror ? 1 : 0}
        colorcustom={colorcustom ? 1 : 0}
        buttonsmall={buttonsmall ? 1 : 0}
        buttonmedium={buttonmedium ? 1 : 0}
        buttonlarge={buttonlarge ? 1 : 0}
        width={width}
        padding={padding}
        onClick={onClick}
        disabled={disabled ? 1 : 0}
        id={id}
        type='button'
      >
        {text}
      </ButtonNormal>
    );
  } else {
    return (
      <ButtonAction
        colorprimary={colorprimary ? 1 : 0}
        colorprimaryreverse={colorprimaryreverse ? 1 : 0}
        colorsecondary={colorsecondary ? 1 : 0}
        colororange = {colororange? 1 : 0}
        colorgreen={colorgreen ? 1 : 0}
        colorlightgreen ={colorlightgreen ? 1 : 0}
        colorwhite={colorwhite ? 1 : 0}
        colorgrey={colorgrey ? 1 : 0}
        colorlightpurple ={colorlightpurple ? 1 : 0}
        colorerror={colorerror ? 1 : 0}
        colorcustom={colorcustom ? 1 : 0}
        buttonsmall={buttonsmall ? 1 : 0}
        buttonmedium={buttonmedium ? 1 : 0}
        buttonlarge={buttonlarge ? 1 : 0}
        width={width}
        padding={padding}
        onClick={onClick}
        disabled={disabled ? 1 : 0}
        id={id}
      >
        {text}
      </ButtonAction>
    );
  }
};

export default Button;
