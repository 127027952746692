import React, { useEffect, useContext, useState,useMemo } from 'react';
import { Container, Row,Col} from 'react-bootstrap';
import { AppContext } from '../../context/index';
import {SideFilterContainer} from './styles';
import { FaSlidersH } from 'react-icons/fa';
import { getUserFilters } from '../../functions/manageSessions';


const SideFiltersCardMobile = ( {numberOfFilters}) => {
    const { 
        searchContext,
        setSearchContext,
        userContextFilters,
        userOrganisationContext,
        setMobileContext,
        userFilters,
        mobileContext,
      } = useContext(AppContext);
    const [rangeValue, setRangeValue] = useState('99');
    const [allBtn0Selected, setAllBtn0Selected] = useState(false); // we want to avoid checking on click for performance
    const [allBtn1Selected, setAllBtn1Selected] = useState(false); // we want to avoid checking on click for performance
    const [allBtn2Selected, setAllBtn2Selected] = useState(false); // we want to avoid checking on click for performance
    const [allBtn3Selected, setAllBtn3Selected] = useState(false); // we want to avoid checking on click for performance
    const [allBtn4Selected, setAllBtn4Selected] = useState(false); // we want to avoid checking on click for performance


    const getBtnNames = () => {
        let userFilter = userContextFilters;
        let names ;
        if(userFilter && userFilter['filter-types']){
            names =  userFilter['filter-types'];
        }
        if(userFilter && userFilter['sidebar-order']){
            names = userFilter['sidebar-order'];
            if(userFilter['Web'] && !names.includes('Web') ){
               names.push('Web');
            };
        }
        return names; 
    };
    
    const getAllSourcesFromFacetEntries = (facets) =>{
        let allSources = [];
        if(facets && facets.length >0 ){
            facets.forEach(filterOrSrc => {
            //if it is src 
            if(filterOrSrc && Object.hasOwn(filterOrSrc,'encrypted_url')){
                allSources.push(filterOrSrc) ;
            }
            else{
                // it is a filter with maybe more than 1 src
                if(filterOrSrc && Object.hasOwn(filterOrSrc,'sources')){
                    filterOrSrc['sources'].forEach(src => {
                        allSources.push(src);
                    });
                }
            }
            });
        }
        return allSources;
      };

    // return google filter
    const getGoogleSource =() => {
        let out =  {
            'encrypted_url': "custom:gAAAAABiMGJJQdUGog4lZd2jqr0jk8DZmQvBh139L5aOzVrh7ZZ76xX8uSQkkGPqznXJ7jZV-sft8U8ZZfnX0xNSK804FIc9gT6ckX1xqp7fOU35M9-sKyQ=",
            'name':'Web',
            'url': "https://www.google.co.uk", 
            'id':0 // id is always 0 
        };
        // let googleFilter = {'name':'Web','sources': [out]};
        return out;
    };

    // return google filter
    const getFacetsForBtnName =(name) => {
        let tempFacetsToSend=[];
        if( name === 'Web'){
            let out =  {
            'encrypted_url': "custom:gAAAAABiMGJJQdUGog4lZd2jqr0jk8DZmQvBh139L5aOzVrh7ZZ76xX8uSQkkGPqznXJ7jZV-sft8U8ZZfnX0xNSK804FIc9gT6ckX1xqp7fOU35M9-sKyQ=",
            'name':'Web',
            'url': "https://www.google.co.uk",
            'id': 0 // id is always 0 
        };
            // facets = {'name':'Web','sources': [out]};
            tempFacetsToSend = [out];

        }
        else{
            let names = getBtnNames();
            if(names && names.length >0){
                names.forEach((temp_name,i) => {
                    if(name === temp_name ){
                        return memoAllSourcesBtn[i];
                    }
                });
            }
            if(  name === getBtnNames()[0] ){
                return  memoAllSourceBtn0;
            }
            if(  name ===getBtnNames()[1]){
                return memoAllSourceBtn1;
            }
            if(  name === getBtnNames()[2]){
                return  memoAllSourceBtn2;
            }
            if(  name ===getBtnNames()[3]){
                return  memoAllSourceBtn3;
            }
        }
        return tempFacetsToSend;
    };

    const handleOnExpandAllFilters = (e)=>{
        e.stopPropagation();
        setMobileContext(currentVal => 
            {return {...currentVal,
                    toggle: true, 
                };});
    };

    const memoAllSourceBtn0 = useMemo(() => {
        let allSources = [] ;
        if( userContextFilters && userContextFilters['filter-types']){
            let btnName = getBtnNames()[0];
            if ( Object.hasOwn(userContextFilters,btnName) && userContextFilters[btnName].length > 0 ){
                userContextFilters[btnName].forEach(filter => {
                    if(filter && Object.hasOwn(filter,'sources') ){
                        filter['sources'].forEach(src => {
                            allSources.push(src);
                        });
                    }
                });
            }
            if(btnName ==="Web"){
                allSources =[getGoogleSource()];
            }
        }
        return allSources;
    }, [userContextFilters]);// TO DO refactor to update on filter update (first chang e filter update)
    
    const memoAllSourceBtn1 =  useMemo(() => {
        let allSources = [] ;
        if(userContextFilters && userContextFilters['filter-types']){
            let btnName =getBtnNames()[1];
            if ( Object.hasOwn(userContextFilters,btnName) && userContextFilters[btnName].length > 0 ){
                userContextFilters[btnName].forEach(filter => {
                    if(filter && Object.hasOwn(filter,'sources') ){
                        filter['sources'].forEach(src => {
                            allSources.push(src);
                        });
                    }
                });
            }
            if(btnName && btnName ==="Web"){
                allSources =[getGoogleSource()];
            }
        }
        return allSources;
    }, [userContextFilters]);// TO DO refactor to update on filter update (first chang e filter update)
    
    const memoAllSourceBtn2 =  useMemo(() => {
        let allSources = [] ;
        if(userContextFilters && userContextFilters['filter-types']){
            let btnName = getBtnNames()[2];
            if ( Object.hasOwn(userContextFilters,btnName) && userContextFilters[btnName].length > 0 ){
                userContextFilters[btnName].forEach(filter => {
                    if(filter && Object.hasOwn(filter,'sources') ){
                        filter['sources'].forEach(src => {
                            allSources.push(src);
                        });
                    }
                });
            }
            if(btnName && btnName ==="Web"){
                allSources =[getGoogleSource()];
            }
        }
        return allSources;
    }, [userContextFilters]);// TO DO refactor to update on filter update (first chang e filter update)
    
    const memoAllSourceBtn3 =  useMemo(() => {
        let allSources = [] ;
        if(userContextFilters && userContextFilters['filter-types']){
            let btnName = getBtnNames()[3];
            if(Object.hasOwn(userContextFilters,btnName)){
                userContextFilters[btnName].forEach(filter => {
                    if(filter && Object.hasOwn(filter,'sources')){
                        filter['sources'].forEach(src => {
                            allSources.push(src);
                        });
                    }
                });
            }
            if(btnName && btnName ==="Web"){
                allSources =[getGoogleSource()];
            }
        }
       
        return allSources;
    }, [userContextFilters]);// TO DO refactor to update on filter update (first chang e filter update)

    //returns an array of arrays 
    // each array contains the sources corresponding to a each btn in order
    const memoAllSourcesBtn =  useMemo(() => {
        let allBtnSources = [] ;
        let names = getBtnNames();
        if(names && names.length>0){
            names.forEach(name => {
                let allSources =[] ;
                if(userContextFilters  && Object.hasOwn(userContextFilters,name) && userContextFilters['filter-types']){
                    userContextFilters[name].forEach(filter => {
                        if(filter && Object.hasOwn(filter,'sources')){
                            filter['sources'].forEach(src => {
                                allSources.push(src);
                            });
                        }
                    });
                    if(name && name ==="Web"){
                        allSources =[getGoogleSource()];
                    }
                }
                allBtnSources.push(allSources);
            });
        }
        return allBtnSources;
    }, [userContextFilters]);// TO DO refactor to update on filter update (first chang e filter update)

    // range value change by :  
    useEffect(() => {

        // if( pvtRangeValue !== rangeValue){
        if(rangeValue==='0' ){
            // if(filters.length !== 1){
            let sourcesToSend0 = getFacetsForBtnName(getBtnNames()[0]);
            if(sourcesToSend0 &&  sourcesToSend0.length >0 && !allBtn0Selected){
                setSearchContext(currentVal => 
                    {return {...currentVal,
                            facets: sourcesToSend0.slice(), 
                            forceSearch: true
                        };});
                // setIsActive(1);
            }
            else{
                if(sourcesToSend0<=0 && memoAllSourceBtn0 && memoAllSourceBtn0.length>0 && !allBtn0Selected){
                    setSearchContext(currentVal => 
                        {return {...currentVal,
                                facets: memoAllSourceBtn0.slice(), 
                                forceSearch: true
                            };});
                }
            }
        }
        if (rangeValue ==='50' ){
            let sourcesToSend1 = getFacetsForBtnName(getBtnNames()[1]);
            if(sourcesToSend1 &&  sourcesToSend1.length >0 && !allBtn1Selected){
                setSearchContext(currentVal => 
                    {return {...currentVal,
                            facets: sourcesToSend1.slice(), 
                            forceSearch: true
                        };});
                // setIsActive(1);
            }
            else{
                if(sourcesToSend1<=0 && memoAllSourceBtn1 && memoAllSourceBtn1.length>0 && !allBtn1Selected){
                    setSearchContext(currentVal => 
                        {return {...currentVal,
                                facets: memoAllSourceBtn1.slice(), 
                                forceSearch: true
                            };});
                }
            }
        }
        if (rangeValue === '100' ){
            let sourcesToSend2 = getFacetsForBtnName(getBtnNames()[2]);
            if(sourcesToSend2 && sourcesToSend2.length >0 && !allBtn2Selected){
                setSearchContext(currentVal => 
                    {return {...currentVal,
                            facets: sourcesToSend2.slice(), 
                            forceSearch: true
                        };});
            }
            else{
                if(sourcesToSend2<= 0 && memoAllSourceBtn2 && memoAllSourceBtn2.length>0 && !allBtn2Selected){
                    setSearchContext(currentVal => 
                        {return {...currentVal,
                                facets: memoAllSourceBtn2.slice(), 
                                forceSearch: true
                            };});
                }
            }
        }
        if (rangeValue=== '150' ){
            let sourcesToSend3 = getFacetsForBtnName(getBtnNames()[3]);

            if(sourcesToSend3  && sourcesToSend3.length>0 && !allBtn3Selected ){
                setSearchContext(currentVal => 
                    {return {...currentVal,
                            facets: sourcesToSend3.slice(), // there is no default option for btn3  
                            forceSearch: true
                        };});
            }
            else{
                if(sourcesToSend3<= 0 && memoAllSourceBtn3 && memoAllSourceBtn3.length>0 && !allBtn3Selected){
                    setSearchContext(currentVal => 
                        {return {...currentVal,
                                facets: memoAllSourceBtn3.slice(), 
                                forceSearch: true
                            };});
                }
            }
        }
        let names = getBtnNames();
        if(names && names.length > 0 ){
            names.forEach((name, i) => {
                let num = (i*50).toString(); 
                if (rangeValue=== num ){
                    let sourcesToSend4 = getFacetsForBtnName(getBtnNames()[i]);
        
                    if(sourcesToSend4  && sourcesToSend4.length>0 && !allBtn4Selected ){
                        setSearchContext(currentVal => 
                            {return {...currentVal,
                                    facets: sourcesToSend4.slice(), // there is no default option for btn3  
                                    forceSearch: true
                                };});
                    }
                    else{
                        if(sourcesToSend4<= 0 && memoAllSourceBtn3 && memoAllSourceBtn3.length>0 && !allBtn4Selected){
                            setSearchContext(currentVal => 
                                {return {...currentVal,
                                        facets: memoAllSourceBtn3.slice(), 
                                        forceSearch: true
                                    };});
                        }
                    }
                }
            });
        }
      },[rangeValue]);


    // should it be done before rendering ?
    useEffect (()=>{
        let userFilters = userContextFilters ;
        let defaultBtn0= [] ;
        let allSourcesBtn0 =[];
        let buttons = getBtnNames();
        if(buttons){
            let Btn0Name = buttons[0];

            if ( userFilters && Object.hasOwn(userFilters, Btn0Name) ){
                userFilters[Btn0Name].forEach(filter => {
                    if (filter && Object.hasOwn(filter,'sources') && Object.hasOwn(filter,'is_default') && filter['is_default']){
                        filter['sources'].forEach(src => {
                            defaultBtn0.push( src);
                            allSourcesBtn0.push(src);
                        });
                    }
                    else{
                        if (filter && Object.hasOwn(filter,'sources') ){
                            filter['sources'].forEach(src => {
                                defaultBtn0.push( src);
                                allSourcesBtn0.push(src);
                            });
                        }
                    }
                });
                // setDefaultBtn0Sources(defaultBtn0);
            }
        }
    },[]);


    // when searchContext changes update btn
    useEffect (()=>{
        let allUserFilter = userContextFilters;
        let buttons = getBtnNames(); 
        if(buttons && typeof searchContext !== 'undefined' && typeof searchContext.q !== 'undefined'){
            let Btn0Name = buttons[0];
            let Btn1Name = buttons[1];
            let Btn2Name = buttons[2];
            let Btn3Name = buttons[3];
            let Btn4Name = buttons[4];

            let defaultSourcesBtn0FromUser = [];
            let defaultSourcesBtn1FromUser =[];
            let defaultSourcesBtn2FromUser = [];
            let defaultSourcesBtn3FromUser = [];
            let defaultSourcesBtn4FromUser = [];

            let allBtn0SourcesUrls ={}; 
            let allBtn1SourcesUrls ={}; 
            let allBtn2SourcesUrls ={}; 
            let allBtn3SourcesUrls ={}; 
            let allBtn4SourcesUrls ={}; 

            if(Btn0Name && allUserFilter){
                if(Btn0Name ==='Web'){
                    let googleSrc = getGoogleSource();
                    defaultSourcesBtn0FromUser.push(googleSrc );
                    allBtn0SourcesUrls[googleSrc['encrypted_url']] =true;
                }
                else{
                    // defaultSourcesBtn0FromUser = allUserFilter[Btn0Name] ;
                    if(allUserFilter && Object.hasOwn(allUserFilter,Btn0Name)){
                        allUserFilter[Btn0Name].forEach(filter => {
                            if(filter && Object.hasOwn(filter, 'sources') ){
                                filter['sources'].forEach(src => {
                                    defaultSourcesBtn0FromUser.push(src );
                                    allBtn0SourcesUrls[src['encrypted_url']] =true;
                                });
                            }
                        });
                    }
                }
            }
            if(Btn1Name && allUserFilter){
                if(Btn1Name ==='Web'){
                    let googleSrc = getGoogleSource();
                    defaultSourcesBtn1FromUser.push(googleSrc );
                    allBtn1SourcesUrls[googleSrc['encrypted_url']] =true;
                }
                else{
                    if(allUserFilter && Object.hasOwn(allUserFilter,Btn1Name)){
                        allUserFilter[Btn1Name].forEach(filter => {
                            if(filter && Object.hasOwn(filter, 'sources') ){
                                filter['sources'].forEach(src => {
                                    defaultSourcesBtn1FromUser.push(src );
                                    allBtn1SourcesUrls[src['encrypted_url']] =true;
                                });
                            }
                        });
                    }
                }
                
            }
            if(Btn2Name && allUserFilter){
                if(Btn2Name ==='Web'){
                    let googleSrc = getGoogleSource();
                    defaultSourcesBtn2FromUser.push(googleSrc );
                    allBtn2SourcesUrls[googleSrc['encrypted_url']] =true;
                }
                else {
                    if(allUserFilter && Object.hasOwn(allUserFilter,Btn2Name))
                    {
                        allUserFilter[Btn2Name].forEach(filter => {
                            if(filter && Object.hasOwn(filter, 'sources') ){
                                filter['sources'].forEach(src => {
                                    defaultSourcesBtn2FromUser.push(src );
                                    allBtn2SourcesUrls[src['encrypted_url']] =true;
                                });
                            }
                        });
                    }
                }
                
            }
            if(Btn3Name && allUserFilter){
                if(Btn3Name ==='Web'){
                    let googleSrc = getGoogleSource();
                    defaultSourcesBtn3FromUser.push(googleSrc );
                    allBtn3SourcesUrls[googleSrc['encrypted_url']] =true;
                }
                else{
                    if(allUserFilter && Object.hasOwn(allUserFilter, Btn3Name)){
                        allUserFilter[Btn3Name].forEach(filter => {
                            if(filter && Object.hasOwn(filter,'sources') ){
                                filter['sources'].forEach(src => {
                                    // if(src['is_default']){
                                    defaultSourcesBtn3FromUser.push(src );
                                    allBtn3SourcesUrls[src['encrypted_url']] =true;
                                    // }
                                });
                            }
                        });
                    }
                }
            }
            if(Btn4Name && allUserFilter){
                if(Btn4Name ==='Web'){
                    let googleSrc = getGoogleSource();
                    defaultSourcesBtn4FromUser.push(googleSrc );
                    allBtn4SourcesUrls[googleSrc['encrypted_url']] =true;
                }
                else{
                    if(allUserFilter && Object.hasOwn(allUserFilter, Btn4Name)){ 
                        allUserFilter[Btn4Name].forEach(filter => {
                            if(filter && Object.hasOwn(filter,'sources' ) ){
                                filter['sources'].forEach(src => {
                                    defaultSourcesBtn4FromUser.push(src );
                                    allBtn4SourcesUrls[src['encrypted_url']] =true;
                                });
                            }
                        });
                    }
                }
            }
            if(searchContext && Object.hasOwn(searchContext,'facets') ){
                searchContext.facets.forEach(filter => {
                    let tempUrl = filter["encrypted_url"];
                    // sometime the facets is a single source and not a filter
                    // we need 4 int bc some sources can be in >1 btn/tooggle
    
                    if(filter && Object.hasOwn(filter,'sources')  && !tempUrl)
                    {
                        filter['sources'].forEach(src => {
                            let tempSourceUrl = src["encrypted_url"];
                            if(tempSourceUrl && allBtn0SourcesUrls[tempSourceUrl]){
                                // it is in local sources
                                delete allBtn0SourcesUrls[tempSourceUrl];
                            }
                            if( allBtn1SourcesUrls[tempSourceUrl] ){
                                // it is in medwise sources
                                delete allBtn1SourcesUrls[tempSourceUrl];
                            }
                            if(allBtn2SourcesUrls[tempSourceUrl]){
                                // it is in local sources
                                delete allBtn2SourcesUrls[tempSourceUrl];
                            }
                            if(allBtn3SourcesUrls[tempSourceUrl]){
                                // it is in patient sources
                                delete allBtn3SourcesUrls[tempSourceUrl];
                            }
                            if(allBtn4SourcesUrls[tempSourceUrl]){
                                // it is in patient sources
                                delete allBtn4SourcesUrls[tempSourceUrl];
                            }
                        });
                        
                    }
                    // is a source 
                    else {
                        if(tempUrl ){
                            if(tempUrl &&  allBtn0SourcesUrls[tempUrl] ){
                                delete allBtn0SourcesUrls[tempUrl];
                            }
                            if( tempUrl && allBtn1SourcesUrls[tempUrl] ){
                                delete allBtn1SourcesUrls[tempUrl];
                            }
                            if(tempUrl && allBtn2SourcesUrls[tempUrl] ){
                                delete allBtn2SourcesUrls[tempUrl];
                            }
                            if( tempUrl && allBtn3SourcesUrls[tempUrl] ){
                                delete allBtn3SourcesUrls[tempUrl];
                            }
                            if( tempUrl && allBtn4SourcesUrls[tempUrl] ){
                                delete allBtn4SourcesUrls[tempUrl];
                            }
                        }
                    }
                });
            }
            if( searchContext.facets && Object.keys(allBtn0SourcesUrls).length === 0 && searchContext.facets.length === memoAllSourceBtn0.length){
                // setIsActive(0);
                setRangeValue("0" );
                setAllBtn0Selected(true);
                setAllBtn1Selected(false);
                setAllBtn2Selected(false);
                setAllBtn3Selected(false);
                setAllBtn4Selected(false);

            }
            
            else if(searchContext.facets && Object.keys(allBtn1SourcesUrls).length === 0    && searchContext.facets.length === memoAllSourceBtn1.length )
                    {
                        setRangeValue( "50" );
                        setAllBtn0Selected(false);
                        setAllBtn1Selected(true);
                        setAllBtn2Selected(false);
                        setAllBtn3Selected(false);
                        setAllBtn4Selected(false);
                        // setIsActive(1);
                        //check if all local filters
                    }
            else if(searchContext.facets && Object.keys(allBtn2SourcesUrls).length === 0 && searchContext.facets.length === memoAllSourceBtn2.length  ) 
                    {
                        // setIsActive(2);
                        setRangeValue("100" );
                        setAllBtn0Selected(false);
                        setAllBtn1Selected(false);
                        setAllBtn2Selected(true);
                        setAllBtn3Selected(false);
                        setAllBtn4Selected(false);

                    }
            else if(searchContext.facets && Object.keys(allBtn3SourcesUrls).length === 0 
                   && searchContext.facets.length === memoAllSourceBtn3.length  ) 
            {
                // setIsActive(2);
                setRangeValue("150" );
                setAllBtn0Selected(false);
                setAllBtn1Selected(false);
                setAllBtn2Selected(false);
                setAllBtn3Selected(true);
                setAllBtn4Selected(false);

            }
            else if(searchContext.facets && Object.keys(allBtn4SourcesUrls).length === 0 
                    && searchContext.facets.length === memoAllSourcesBtn[4].length  ) 
            {
                // setIsActive(2);
                setRangeValue("200" );
                setAllBtn0Selected(false);
                setAllBtn1Selected(false);
                setAllBtn2Selected(false);
                setAllBtn3Selected(false);
                setAllBtn4Selected(true);

            }
            else{
                    setRangeValue( "99" );
                    setAllBtn0Selected(false);
                    setAllBtn1Selected(false);
                    setAllBtn2Selected(false);
                    setAllBtn3Selected(false);
                    setAllBtn4Selected(false);

            }
        }
    },[searchContext]); 


    // when org changes  or when filters are updated
    useEffect(()=>{
        let buttons ;
        let sideOrder ;
        // let sourcesOrder= ['Medwise',"Local","Web"];
        let sourcesOrder=[];
        if(userContextFilters && userContextFilters['filter-types']){
          buttons =userContextFilters['filter-types'];
          sideOrder =userContextFilters['sidebar-order'];
          if(sideOrder && sideOrder.length>0 ){
            sideOrder.forEach(name => {
                sourcesOrder.push({'name':name,"filters":userContextFilters[name]});
              });
          }
        }        
    },[userOrganisationContext,userContextFilters]);

    const all_btn_mobile= useMemo(()=> {
        if (typeof userContextFilters!== 'undefined' && typeof userContextFilters["filter-types"]!== 'undefined' ){
          return userContextFilters["filter-types"];
        }
        return [];
        },[userFilters]);

    // returns an array of arrays
    // each array contains the sources of each btn 
    const btnFilters =  useMemo(()=>{
        let btnNames = getBtnNames();
        let output = [];
        if(btnNames && btnNames.length > 0 ){
            btnNames.forEach(name => {
                if ( userContextFilters && userContextFilters[name] ){
                    output.push(userContextFilters[name]);
                }
            });
        }
        return output;
    });
    
    const getNumberOfSelectedSources = useMemo(() => {
        let allSources;
        let count =0; 
        if(userContextFilters && Object.hasOwn(userContextFilters,'sources') &&
          mobileContext  && Object.hasOwn(mobileContext,'facetEntries') ){
          allSources = new Set (userContextFilters['sources'].flatMap (tempSource => tempSource['encrypted_url']));
          let allMobileContextSources = getAllSourcesFromFacetEntries(mobileContext.facetEntries);
          allMobileContextSources= allMobileContextSources.map(tempSource =>  tempSource['encrypted_url']);
          allSources.forEach(url => {
            if(url && typeof url ==="string" && allMobileContextSources.includes(url)){
              count++; 
            }
          });
        }else{
          return count ;
        }
        return count;
      }, [mobileContext]);

    const getNumberOfSelectedCheckboxes= useMemo(()=> {
        let numberOfSelectedFacets = {};
        let totalChecked = 0; 
        // add checked for empty
        if(all_btn_mobile && all_btn_mobile.length>0 ){
            all_btn_mobile.forEach(nameOfBtn => {
            let allBtnSourcesFilters = userContextFilters[nameOfBtn];
            if(allBtnSourcesFilters){
                allBtnSourcesFilters = allBtnSourcesFilters.map(tempSource =>  tempSource['sources']);
                allBtnSourcesFilters = allBtnSourcesFilters.flat();
                let  count = 0 ; // searchContext.facets 
                let urlsDetected = [] ; 
                if(allBtnSourcesFilters  && mobileContext && mobileContext.facetEntries){
                    let allEncrypted = allBtnSourcesFilters.flatMap(tempSource =>  tempSource['encrypted_url']);
                    let allMobileContextSources = getAllSourcesFromFacetEntries(mobileContext.facetEntries);
                    allMobileContextSources= allMobileContextSources.map(tempSource =>  tempSource['encrypted_url']);
                    if(allEncrypted && allEncrypted.length>0){
                        // we loop throuhj btn Sources because we are counting the number of checkbox 
                        allEncrypted.forEach(encrypted_url => {
                            if(allMobileContextSources.includes(encrypted_url)){
                            count = count+ 1 ;
                            }
                        });
                    }
                }
                numberOfSelectedFacets[nameOfBtn]=count; 
                totalChecked = totalChecked + count ;
            }
            });
        }
        return totalChecked;
        },[mobileContext]);

    return (
        <SideFilterContainer>      
            <Container style={ {"maxWidth": "100%" }} >
                <Row>
                    <Col md ={12} className='p-0 m-0 ' >
                        <div className='sources-filters'  >
                            <Row className='m-0' >
                                <Col xs={3}  className='p-0 m-0 ' >

                                    <div className='filters-btn' onClick={(e)=>handleOnExpandAllFilters(e)}>
                                    {}
                                    <FaSlidersH  style={{"margin":"auto 7px"}} size={'18px'}></FaSlidersH>
                                        {searchContext.facets && searchContext.facets.length && userContextFilters &&(
                                            <>{"("+getNumberOfSelectedSources +"/"+numberOfFilters +")"} </>
                                        )}
                                    </div>
                                </Col>
                                {getBtnNames() && getBtnNames().length &&
                                getBtnNames().map((name, i) => {
                                return (
                                    <>
                                    {(btnFilters && btnFilters[i].length >0 || name ==="Web")&&(
                                    <Col xs={3} className='p-0 m-0' onClick={()=>{setRangeValue(val => (i*50).toString())}}  >
                                    
                                        <div className={(rangeValue === (i*50).toString()) ? 'filterGroups active' : 'filterGroups'}>
                                            <div className={'btn-text-wrapper-mobile'}>
                                                {name }
                                            </div>
                                        </div>
                                   
                                    </Col> )}
                                    </>
                                );}
                                )}
                            </Row>

                        </div>
                    </Col>
                </Row>

            </Container>
        </SideFilterContainer>
    );
};

export default SideFiltersCardMobile;