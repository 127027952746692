import React from 'react';
import { Modal } from 'react-bootstrap';
import StyledModal from './styles';
import './bootstrap-modal.min.css';
import { H2, P } from '../theme/typography';
import { ReactComponent as CloseIcon } from '../../assets/svgs/close-icon.svg';
import { ReactComponent as MedwiseLogoWithName } from '../../assets/svgs/medwise-logo-with-name.svg';

const ModalMedicalQuery = ({
  modalFeedbackShow,
  modalFeedbackClose,
  centered,
  title,
}) => {
  return (
    <StyledModal
      show={modalFeedbackShow}
      onHide={modalFeedbackClose}
      centered={centered}
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
    >
      <Modal.Header>
        <MedwiseLogoWithName/>
        <CloseIcon
          className="modal-header-closeIcon"
          onClick={() => modalFeedbackClose()}
        />
      </Modal.Header>
      <Modal.Body>
        <P p1 regular textoffblack>
          We've identified that your query might be searching for a generated answer. <a href='https://hai.stanford.edu/news/generating-medical-errors-genai-and-erroneous-medical-references' target="_blank" rel="noopener noreferrer">Recent tests</a> have found that state of the art generative AI is only 27% accurate for some medical queries. Our AI does not generate text and thus does not return case by case results. To find answers, we look in trusted sources which professionals can access for further context, in order to make their own judgment.
        </P>
      </Modal.Body>
      {/* <Modal.Footer className="d-flex align-items-center">
      </Modal.Footer> */}
    </StyledModal>
  );
};

export default ModalMedicalQuery;
