import React, { useEffect, useContext, useState,useDebugValue, useSyncExternalStore } from 'react';
import { Container, Row,Col} from 'react-bootstrap';
import { AppContext } from '../../context/index';
import {  Span } from '../theme/typography';
import {CollapseFiltersContainer,Tag} from './styles';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import SingleSourceFilter from './SingleSourceFilter';
import MultipleSourceFilter from './multipleSourcesFilter';
import { countWords } from '../../functions/utils';

const CollapseFilters = (props ) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSubExpanded, setIsSubExpanded] = useState([]);
    const [onHoverTitle, setOnHoverTitle] = useState(false);
    const [allEncryptedUrls, setAllEncryptedUrls ] = useState([]);
    const [allSources, setAllSources ] = useState([]);
    const [isCheckedDic, setIsCheckedDic ] = useState({});
    const [filterChecked, setFilterChecked] = useState([]);
    const [filtersSingleSource, setFiltersSingleSource] = useState([]);
    const [filtersMultipleSources, setFiltersMultipleSources] = useState([]);
    const { 
        searchContext,
        setSearchContext,
        setIsResultsExpanded,
        setResultsFirstExpanded,
        setConfirmCouldNotFind,
        setSuspectCouldNotFind,
        setLoadMore,
        setClearLocalWebResults,
        setShowAssistedAI,
        setLocalWebResults
      } = useContext(AppContext);
      // const windowDimensions = useWindowDimensions.get('window').width;
      // const [dimensions, setDimensions] = useState({
      //   window: window,
      // });

      // useEffect(() => {
      //   // const dim = { , window.innerHeight } ;

      //   console.log('dimensions inner width',window.innerWidth);
      //   // const subscription = useWindowDimensions.addEventListener(
      //   //   'change',
      //   //   ({window}) => {
      //   //     console.log('37 window', window);
      //   //     if(window.width >="780" && isExpanded){
      //   //       setIsExpanded(false);
      //   //     }
      //   //     setDimensions({window});
      //   //   },
      //   // );
      //   // return () => subscription?.remove();
      // });


    // remove a source from an array of filters or sources 
    // returns an array of sources
    const removeFacetsFromArray =(array, encryptedUrl) =>{
      let updatedArray = [];
      array.forEach(element => {
        // if it is a source check url
        if(element['encrypted_url'] && element['encrypted_url'] !== encryptedUrl){
          updatedArray.push(element);
        }
        else{
          //loop through sources to find it
          if (element && element['sources']){
            element['sources'].forEach(src => {
              if(src['encrypted_url'] && src['encrypted_url'] !== encryptedUrl){
                updatedArray.push(src);
              }
            });
          }
          
        }

      });
      return updatedArray;
    }


    const handlenOnHoverTitle = ()=>{
      setOnHoverTitle(true);
    };

    const handleOnLeaveTitle = ()=>{
      setOnHoverTitle(false);
    };


    const handleSelectedAll =  () => {
      let updatedFacets = searchContext.facets.slice();
      // web source is in 
      if (updatedFacets.length >=1 && updatedFacets[0]["name"]== "Web"){
        updatedFacets.shift();
      }
      let notSelected = [] ;
      allSources.forEach(facet => {
        if (facet && facet['encrypted_url'] && facet['id'] ){
          let url = facet['encrypted_url'] ;
          let id = facet['id'];
          if (! isCheckedDic[url] && !filterChecked[id]){
            notSelected.push(facet);
          }
        }
      });
      updatedFacets = updatedFacets.concat(notSelected);
      props.setFirstClick(false);
      if (searchContext.q) {
        if (countWords(searchContext.q) > 4) {
          setIsResultsExpanded(true);
          setResultsFirstExpanded(undefined);
        } else {
          setIsResultsExpanded(false);
          setResultsFirstExpanded(false);
        };
      };
      setLoadMore(undefined);
      setSuspectCouldNotFind(false);
      setConfirmCouldNotFind(false);
      setClearLocalWebResults(false);
      setShowAssistedAI(true);
      setLocalWebResults({0: [], 1: [], 2: [], 3: [], 4: []});
      // only force a search if something is selected
      // if( updatedFacets && updatedFacets.length > 0 ){
        setSearchContext(currentVal => 
          {return {...currentVal,
                  facets: updatedFacets.slice(), 
                  forceSearch: true
              };});
      // }

    };

    const handleClearAll =  () => {
      let updatedFacets = searchContext.facets;
      // remove webfilter 
      if (updatedFacets.length >=1 && updatedFacets[0]["name"]== "Web") {
        updatedFacets.shift();
      }
      allSources.forEach(source => {
        if (source && source['encrypted_url'] && source['id']) {
          let url = source['encrypted_url'];
          if (isCheckedDic[url]) {
            updatedFacets = removeFacetsFromArray(updatedFacets,url);
          }
        }
      });
      props.setFirstClick(false);
      if (searchContext.q) {
        if (countWords(searchContext.q) > 4) {
          setIsResultsExpanded(true);
          setResultsFirstExpanded(undefined);
        } else {
          setIsResultsExpanded(false);
          setResultsFirstExpanded(false);
        };
      };
      setSuspectCouldNotFind(false);
      setConfirmCouldNotFind(false);
      setLoadMore(undefined);
      setClearLocalWebResults(false);
      setShowAssistedAI(true);
      setLocalWebResults({0: [], 1: [], 2: [], 3: [], 4: []});
      // only force a search if something is selected
      // if(updatedFacets &&  updatedFacets.slice() > 0 ){
        setSearchContext(currentVal => 
          {return {...currentVal,
                  facets: updatedFacets.slice(), 
                  forceSearch: true
              };});
      // }
     
    };


    useEffect(() => {
      setIsExpanded(props.isExpanded);
      var allFilter = props.filters;
      var currentIsSubExpaneded =[];
      allFilter.forEach((filter) => {
        let tempId = filter['id'];
        currentIsSubExpaneded[tempId]=false;
      });
      setIsSubExpanded(currentIsSubExpaneded);

    },[]);

    useEffect(() => {
      let filtersWithOneSource=[];
      let multipleSource=[];
      let encryptedUrls=[];
      let sources =[];

      if(props.filters.length > 0 ){
        props.filters.forEach(filter => {
          if(filter['sources'].length >1){
            multipleSource.push(filter);
            filter['sources'].forEach(current_src => {
              sources.push(current_src);
              encryptedUrls.push(current_src["encrypted_url"]);
            });
          }
          else{
            if(filter['sources'].length ===1){
              filtersWithOneSource.push(filter);
              encryptedUrls.push(filter['sources'][0]['encrypted_url']);
              sources.push(filter['sources'][0]);
          }
          }
        });
      }
      setAllEncryptedUrls(encryptedUrls);
      setFiltersMultipleSources(multipleSource);
      setFiltersSingleSource(filtersWithOneSource);
      setAllSources(sources);
    },[props.filters]);
    
    useEffect (()=>{
      let dic={};
      let IsInGroup={};
      if(allEncryptedUrls && allEncryptedUrls.length> 0 ){
        allEncryptedUrls.forEach(url => {
          dic[url]=false;
          IsInGroup[url]=true;
        });
  
        if (searchContext && searchContext['facets']){
          searchContext['facets'].forEach(src => {
            if(src && src['encrypted_url']){
              let tempUrl =src['encrypted_url'];
              if(tempUrl && IsInGroup[tempUrl]){
                dic[tempUrl]=true;
              }
            }
            else{
              if(src  && src['sources']){
                src['sources'].forEach(source => {
                  if(source){    
                    let urlTemp = source['encrypted_url'];  
                    if(urlTemp && IsInGroup[urlTemp]){
                      dic[urlTemp]=true;
                    }
                  }
                });
              }
            }
          });
        }
        let isFilterChecked =[];
        filtersMultipleSources.forEach(filter => {
          let tempFilterId = filter['id'];
          filter['sources'].forEach(sourceOfFilter => {
            let tempEncryptedUrlFilter = sourceOfFilter['encrypted_url'];
            if(tempEncryptedUrlFilter && dic[tempEncryptedUrlFilter]){
              isFilterChecked[tempFilterId] = true;  
            }
            });
        });
  
        setFilterChecked(isFilterChecked);
        setIsCheckedDic(dic);
      }
      
    },[searchContext,allEncryptedUrls])


  
    return (
    <CollapseFiltersContainer key={"collapseFilters_"+props.tittle}> 
        <div className='checkboxWrapper' key={"collapseFiltersWrapper_"+props.tittle}>
          <legend onClick={ ()=> setIsExpanded(!isExpanded)}
          onMouseEnter={()=>handlenOnHoverTitle()}
          onMouseLeave={()=>handleOnLeaveTitle() }
          > 
          {props.tittle}
        
          {isExpanded ? <FaChevronUp color='#1A6ADE' className='chevron'/>:<FaChevronDown color='#1A6ADE' className='chevron'/>}
          </legend>
          {isExpanded && (
          <Row>
            <Col>
              <Tag >
                <Span  c2  textoffblack  onClick={()=>{handleSelectedAll()}}>
                Select all  
                </Span>
                <div className='filters_separator'></div>

                <Span  c2  textoffblack  onClick={()=>{handleClearAll()}}>
                  Clear all   
                </Span>
              </Tag>
              {/* <Tag >
                
              </Tag> */}
            </Col>
          </Row>)}
          {isExpanded && (
          <ul>
            <Container className='p-1'>
              {props.filters && props.filters.length >0 && props.filters.map((filter)=> {
                return(
                  (filter['sources'] && filter['sources'].length!== 1 )? (
                    <MultipleSourceFilter  key={"multipleSourceFilter_"+filter['name']+'_'+filter['id']} firstClick={props.firstClick}  setFirstClick={props.setFirstClick}  filter={filter} isSubExpanded={isSubExpanded} setIsSubExpanded={setIsSubExpanded} filterChecked={filterChecked} isCheckedDic={isCheckedDic} allSources={allSources} ></MultipleSourceFilter>
                  ):(
                      <SingleSourceFilter key={"singleSourceFilter_"+filter['name']+'_'+filter['id']}  firstClick={props.firstClick}  setFirstClick={props.setFirstClick}   isCheckedDic={isCheckedDic} filter={filter}></SingleSourceFilter>
                  ) 
                );
              })}
            </Container>
            </ul>
          )}
          </div>
      {/* </fieldset> */}
    </CollapseFiltersContainer>
    );
};

export default CollapseFilters;