import styled, { css } from 'styled-components';
import Hr from '../hr/styles';

export const Styles = styled.footer`
  /* This sticky prop is passed from the error-message component */
  ${(props) =>
    props.sticky &&
    css`
      position: absolute;
      width: 100%;
      bottom: 0;
    `}

  ${(props) =>
      props.mobileResultBackground &&
      css`
        background-color: #f0f3f4;
      `}

  ${(props) =>
  props.onTop &&
  css`
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 999;
  `}
  
  .footer {
    &_leftList {
      display: flex;
      align-items: baseline;

      @media (max-width: 767.98px) {
        justify-content: center;
        padding-top: ${({ theme }) => theme.spacing.x2};
      }

      li:not(:last-child) {
        margin-right: ${({ theme }) => theme.spacing.x4};
      }

      &--icon {
        height: 25px;
        min-width: 25px;
        width: auto;
      }
    }

    &_rightList {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;

      li {
        a {
          @media (max-width: 767.98px) {
            display: inline-flex;
            padding: 12px 0;
          }
        }

        &:not(:first-child) {
          margin-left: ${({ theme }) => theme.spacing.x7};

          @media (min-width: 768px) and (max-width: 991.98px) {
            margin-left: ${({ theme }) => theme.spacing.x5};
          }

          @media (max-width: 767.98px) {
            margin-left: 0;
          }
        }
      }

      @media (max-width: 767.98px) {
        justify-content: space-around;
        align-items: center;
        margin-top: ${({ theme }) => theme.spacing.x2};
      }
    }

    &_copyright {
      text-align: center;
      margin: ${({ theme }) => theme.spacing.x2} 0;
    }
  }
`;

export const StyledHr = styled(Hr)`
  @media (max-width: 767.98px) {
    margin: 0 -15px;
  }
`;

export default Styles;
