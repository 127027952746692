import React, { useState, useEffect, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { Modal, Row, Col } from 'react-bootstrap';
import Button from '../button';
import axios from 'axios';
import StyledModal from './styles';
import { AppContext } from '../../context/index';
import './bootstrap-modal.min.css';
import { H2, P } from '../theme/typography';
import { ReactComponent as CloseIcon } from '../../assets/svgs/close-icon.svg';
import { getOrgData, getUser } from '../../functions/manageSessions';
import AnswerThread from '../chat-flow/answer-thread';
// import ModalAiTest from '../modal-ai-test';

const ModalTriggerChat = ({
  modalFeedbackShow,
  modalFeedbackClose,
  centered,
  title,
  bodyText,
}) => {
  const { 
    setRequestTrackerContext, 
    setGoToChatPage
  } = useContext(AppContext);

  const [answers, setAnswers] = useState({'Q1': undefined, 'Q2': undefined, 'Q3': undefined});
  const [isInvalid, setIsInvalid] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [msg, setMsg] = useState(undefined);
  const controller = new AbortController();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const handleFirstQuestionCheckbox = e => {
    e.persist();
    setShowMessage(false);
    setAnswers(prevState => ({
      ...prevState,
      'Q1': e.target.value
    }));
  };

  const handleSecondQuestionCheckbox = e => {
    e.persist();
    setShowMessage(false);
    setAnswers(prevState => ({
      ...prevState,
      'Q2': e.target.value
    }));
  };

  const handleThirdQuestionCheckbox = e => {
    e.persist();
    setShowMessage(false);
    setAnswers(prevState => ({
      ...prevState,
      'Q3': e.target.checked
    }));
  };

  useEffect(() => {
    if (answers['Q1'] && answers['Q2'] && answers['Q3']) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  }, [answers]);

  const handleSubmit = () => {
    if (answers['Q1'] && answers['Q2'] && answers['Q3']) {
      setRequestTrackerContext(prev => { return {...prev, 'confirm-dialog-test':true}});

      var body = {
        'This is an experimental product to surface clinical information quickly and not intended for clinical decision making.': answers['Q1'],
        'You understand that the summarised answers may be inaccurate.': answers['Q2']
      };

      axios({
        method: 'POST',
        url: `${window.BASE_URL_USER}confirm-dialog-test`,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        data: JSON.stringify(body),
        signal: controller.signal,
        cancelToken: source.token,
      })
        .then((res) => {
          setRequestTrackerContext(prev => { return {...prev, 'confirm-dialog-test':false}});
          if (res.data.success) {
            setGoToChatPage(true);
          } else {
            setShowMessage(true);
            setMsg(res.data.msg);
          };
        })
        .catch((error) => {
          setRequestTrackerContext(prev => { return {...prev, 'confirm-dialog-test':false}});    
          console.log("confirm dialog test", error);
        });
    };
  };

  return (
    <StyledModal
      show={modalFeedbackShow}
      onHide={modalFeedbackClose}
      centered={centered}
    >
      <Modal.Header>
        <H2 h2 semibold textoffblack>
          {title}
        </H2>
        <CloseIcon
          className="modal-header-closeIcon"
          onClick={() => modalFeedbackClose()}
        />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <P p1 regular textoffblack style={{marginBottom: '0.5rem'}}>
            Thank you for using our product. You are now entering a new experimental product which is not meant to be used for clinical practice. 
            This new product uses Large Language Models to summarise guidelines and extract answers from guidelines already indexed by our search 
            engine. <span style={{fontWeight: 'bold'}}>Please always consult the linked content source to make informed decisions.</span> Please answer the following questions to continue to the answer screen:
          </P>
          <Form.Group controlId="formQ1" style={{position: 'relative', marginBottom: '0.5rem'}}>
            <Form.Label>This is an experimental product to surface clinical information quickly and not intended for clinical decision making.</Form.Label>
            <Row className='checkbox-row'>
              <Col md={6}>
                <Form.Check 
                  type="radio" 
                  label="Yes" 
                  value="Yes"
                  name="firstQuestionRadios" 
                  id="firstQuestionRadios1"
                  // checked={firstQuestionCheckbox}
                  onChange={handleFirstQuestionCheckbox} 
                />
              </Col>
              <Col md={6}>
                <Form.Check 
                  type="radio" 
                  label="No" 
                  value="No" 
                  name="firstQuestionRadios" 
                  id="firstQuestionRadios2" 
                  onChange={handleFirstQuestionCheckbox} 
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Group controlId="formQ2" style={{marginBottom: '0.5rem'}}>
            <Form.Label>You understand that the summarised answers may be inaccurate.</Form.Label>
            <Row className='checkbox-row'>
              <Col md={6}>
                <Form.Check 
                  type="radio" 
                  label="Yes" 
                  value="Yes"
                  name="secondQuestionRadios" 
                  id="secondQuestionRadios1"
                  // checked={secondQuestionCheckbox}
                  onChange={handleSecondQuestionCheckbox} 
                />
              </Col>
              <Col md={6}>
                <Form.Check 
                  type="radio" 
                  label="No" 
                  value="No" 
                  name="secondQuestionRadios" 
                  id="secondQuestionRadios2" 
                  onChange={handleSecondQuestionCheckbox} 
                />
              </Col>
            </Row>
          </Form.Group>
          <P p1 regular textoffblack style={{marginBottom: '4px'}}>
            By using this product, you certify that you are a qualified clinician practicing in the UK.
          </P>
          <Form.Group controlId="formQ3" style={{marginBottom: '0.5rem'}}>
            <Form.Check 
              type="checkbox" 
              label="I understand" 
              value="Yes"
              name="thirdQuestionRadios" 
              id="thirdQuestionRadios1"
              // checked={secondQuestionCheckbox}
              onChange={handleThirdQuestionCheckbox} 
            />
          </Form.Group>
          <P p1 regular textoffblack>
            We'd love to get your feedback on this new product, please email us at <a href="mailto:hello@medwise.ai">hello@medwise.ai</a> with any thoughts for improvement 
            or if you want to join our internal beta testing.
          </P>
          <Form.Text className="invalid" style={{display: showMessage ? 'inline-block' : 'none'}}>
            {msg}
          </Form.Text>
        </Form>

        {/*  */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isInvalid ? 'disabled' : ''}
          type="action"
          colorprimary
          buttonsmall
          text="Continue"
          width="96px"
          centered
          onClick={() => handleSubmit()}
        />
      </Modal.Footer>

      {/* <ModalAiTest
        modalFeedbackShow={showModalChat===true}
        modalFeedbackClose={deactivateFeedback}
        title="Final Confirmation" 
      />  */}
    </StyledModal>
  );
};

export default ModalTriggerChat;
