import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';
import { P, Span, Label } from '../theme/typography';


// Below styles are only for mobile. As of first release, modal on
// mobile is only used in "Can't find what you’re looking for?".
export const StyledModal = styled(Modal)`
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  
  .error input{
    border: 1px solid red;
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 12px 34px;
    @media (max-width: 767.98px) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .modal-body {
    &-textarea {
      margin-top: ${({ theme }) => theme.spacing.x3};
    }

    @media (max-width: 767.98px) {
      padding-bottom: 24px;
    }
  }

  .modal-footer {
    justify-content: center;
    @media (max-width: 767.98px) {
      justify-content: center;
    }
  }
`;



// export default StyledModal;

export const FormFieldWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.x1};

  ${Label} {
    display: inline-flex;
    margin-bottom: 4px;
  }
  .email_address{
    display: inline-flex;
    padding : 4px 0px 4px 0px;
  }
  .tag{
    display: inline-flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.palette.textPlaceholder};
    margin: 16px;
    padding: 4px ${({ theme }) => theme.spacing.x2};
    cursor: pointer;
  }
  .checkboxCustomUrl
  {
    margin:3px 10px 0px 10px ;
  }
  .separator {
    margin: 25px 0px 10px 0px;
    border-bottom: solid 1px rgb(226, 229, 237);
  }


`;

export const TextInput = styled.input`
  height: ${({ theme }) => theme.spacing.x5};
  padding-right: ${({ theme }) => theme.spacing.x6};
  margin-bottom: 4px;

  ${({ isError }) =>
    isError &&
    css`
      border-color: ${({ theme }) => theme.palette.colorError} !important;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${({ theme }) => theme.palette.textOffblack} !important;
    `}
`;

// This gives a minimum height to the error message.
// It is needed to eliminate the jump effect when there is no error.
export const ErrorMessage = styled(Span)`
  min-height: 21px;
`;