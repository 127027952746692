import React from 'react';
import { FaHistory } from 'react-icons/fa';
import { P } from '../theme/typography';

const AcUserResult = ({ list, querySubmit }) => {
  
  return (
      // If AC list item needs to be filtered, it can be chained here.
      list.map((value,index) => {
        return (
          <li
            key={index}
            onClick={(e) => querySubmit(e, value)}
          >
            <div className="ac_list--item--magnify" aria-label="Search">
              <FaHistory title="history icon" />
            </div>
            <P
              p1
              regular
              textoffblack
              dangerouslySetInnerHTML={{ __html: value }}
            ></P>
          </li>
        );
      })
  );
};

export default AcUserResult;
