import styled, { css } from 'styled-components';
import Hr from '../hr/styles';

//  const DrugNamePill = styled.span`
//     display: inline-flex;
//     padding: 5px;
//     align-items: center;
//     color: ${({ theme }) => theme.palette.textMainbody};
//     height: 40px;
//     justify-content: center;
//     background: #fff;
//     border: 1px solid #dadce0;
//     box-sizing: border-box;
//     border-radius: 5px; // 20px for pill
//     cursor: pointer;
//     // overflow:hidden;
//     &:hover {
//       background: #f1f3f4;
//     },
// `;

export const Styles = styled.div`
  color: #212452;
  display: flex; 
  flex-wrap: wrap;
  gap: 6px;
  background: #fbfbfd;
  margin: 0px 0px 10px 0px;
  padding: 2px 0px 2px 0px;
  // background-color: rgba(133, 113, 223, 0.16);
  // background-color: #ddeee2;
  // height:420px;
  max-height:95px;
  overflow:hidden;

  .tree{
    --spacing : 1.5rem;
    --radius  : 10px;
     
  } 
  .tree li{
    cursor: pointer;
    display      : block;
    position     : relative;
    padding-left : calc(2 * var(--spacing) - var(--radius) - 2px);
  }
  .selected{
    color: #8771DF;
  }
  
  
  .tree ul{
    margin-left  : calc(var(--radius) - var(--spacing));
    padding-left : 0;
  }

  .tree ul li{
    border-left : 2px solid #ddd;
  }
  
  .tree ul li:last-child{
    border-color : transparent;
  }
  // .tree ul li::before{
  //   content      : '';
  //   display      : block;
  //   position     : absolute;
  //   top          : calc(var(--spacing) / -2);
  //   left         : -2px;
  //   width        : calc(var(--spacing) + 2px);
  //   height       : calc(var(--spacing) + 1px);
  //   border       : solid #ddd;
  //   border-width : 0 0 2px 2px;
  // }

  .selected ul li::before{
    content      : '';
    display      : block;
    position     : absolute;
    top          : calc(var(--spacing) / -2);
    left         : -2px;
    width        : calc(var(--spacing) + 2px);
    height       : calc(var(--spacing) + 1px);
    border       : solid red;
    border-width : 0 0 2px 2px;
  }
  overflow-x: hidden;
  white-space: nowrap;
  font-size: 16px;
  font-family: 'Mulish';
  // border:1px solid #212452;
  // border-radius: 4px;

  @media (max-width: 767.98px) {
    border-top: 1px solid #c9d3da;
    padding: 4px;
    margin: 0px;
  }

    .DrugNamePill{
      color: #212452;
      display: inline-flex;
      margin: 3px;
      padding: 5px 5px 5px 5px;
      align-items: center;
      height: 35px;
      justify-content: center;
      background: #fff;
      border: 1px solid #c9d3da;
      box-sizing: border-box;
      border-radius: 5px; // 20px for pill
      cursor: pointer;
      // overflow:hidden;
      &:hover {
        // border-color: #F1F4FB;
        background-color: #F1F4FB;
        // background: #f1f3f4;
      },
    }
`;


export default Styles;
// export default DrugNamePill;
