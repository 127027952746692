import styled from 'styled-components';

export const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  width: auto;
  color: ${({ theme, color }) => (color ? color : theme.palette.greenDark)};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.palette.greenLight};
  border: 1px solid
    ${({ theme, borderColor }) =>
      borderColor ? borderColor : theme.palette.greenDark};
  border-radius: ${ ({ theme }) => theme.borderradius.medium};
  margin-right: ${({ theme,noMargin }) =>
      noMargin? 0 : theme.spacing.x3};
  padding: 4px ${({ theme }) => theme.spacing.x1};
  height:  ${({ theme, height }) =>
  height ? height : ""};
  .folder{
    margin-right: 5px;
  }

  @media (max-width: 767.98px) {
    margin-right: ${({ theme }) => theme.spacing.x2};
  }
  Span:hover{
    cursor:pointer;
    text-decoration: underline;
  }
  Span.noClick:hover{
      text-decoration: none;
  }
  .noClick {
    width: 59px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
  }
`;

export default Tag;
