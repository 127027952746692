// import { adapted_sources_ojct, sources } from "./frontend_variables";

// return the user data from the session storage only if 
// the user_info is not anonymous 
export const getUser = () => {
    var user_info = localStorage.getItem('user_info');
    let tmp_user = {};
    if (user_info){
      tmp_user = JSON.parse(user_info); 
      if (tmp_user && !tmp_user['is_anonymous']) {
        if (tmp_user['email']){
          return tmp_user;
        } else if (tmp_user['org-user-type']){
          return tmp_user;
        }
      }
    }
};


// return the user data from the session storage only if 
// the user_info is not anonymous 
export const getUserFilters = () => {
  var user_filters = localStorage.getItem('user_filters');
  user_filters = JSON.parse(user_filters);
  if (user_filters){
    return user_filters;
  }
};

export const getResultSources = () => {
  var result_sources = localStorage.getItem('result_sources');
  result_sources = JSON.parse(result_sources);
  if (result_sources){
    return result_sources;
  }
};

export const getResultSourceNames = () => {
  var result_source_names = localStorage.getItem('result_source_names');
  result_source_names = JSON.parse(result_source_names);
  if (result_source_names){
    return result_source_names;
  }
};

export const getUserSourceIds = () => {
  var user_source_ids = localStorage.getItem('user_source_ids');
  user_source_ids = JSON.parse(user_source_ids);
  if (user_source_ids){
    return user_source_ids;
  }
};

// return the org data from the session storage only if 
// the user is not anonymous 
export const getOrgData = () => {

  var org_info = localStorage.getItem('orgInfo');
  let tmp_org = {};
  if (org_info){
      tmp_org =JSON.parse(org_info);
      return tmp_org;
  }
};

//set the org info in localstorage
export const setOrganisationSession = (orgInfo) => {
  localStorage.setItem('orgInfo', JSON.stringify(orgInfo));
};

// //set the org info in localstorage
// export const removeOrganisationSession = () => {
//   localStorage.removeItem('orgInfo');
// };

// return the user data from the session storage even if user is anonymous
export const getUserData = () => {
  var user_info = localStorage.getItem('user_info');
  let tmp_user = {};
  if (user_info){
      tmp_user =JSON.parse(user_info);
      if( tmp_user && tmp_user['email']){
          return tmp_user;
      }
  }
}

export const userHasSources = () => {
  var user_info = localStorage.getItem('user_info');
  let tmp_user = {};
  if (user_info){
      tmp_user =JSON.parse(user_info);
      if( tmp_user && tmp_user['sources']){
          return tmp_user;
      }
  }
  
}

export const getAnonymousUser = () => {
  var user_info = localStorage.getItem('user_info');
  let tmp_user = {};
  if (user_info){
      tmp_user =JSON.parse(user_info);
      if( tmp_user && tmp_user['is_anonymous']){
          return tmp_user;
      }
  }
};


//checks weather or not a user is missing first/last name and profession 
// also if user is magic there are not possible missing fields 
export const userMissingFields = () => {
  const user_info = getUser();
  let out = false;
  if (user_info){
    // if name or last name is missing  
    // and we have an email 
    // and we have an org-user type && org-user-type ! equal magic 
    if( (!user_info["first-names"] || !user_info["last-names"] )  && user_info['email']  && user_info['is_anonymous']===false)
      { 
        if (user_info.hasOwnProperty('org-user-type') && user_info['org-user-type']!=='magic'){
          out= false;
        }
        else{
          out= true;
        }
      }
    else{  
      out= false; }
   }
  else{
     out= true;
   }
   return out;
};

//checks weather or not a user has ask_for_details === true
export const userAskForDetails= () => {
  const user_info = getUser();
  let out = false;
  if (user_info){
    // if name or last name is missing  
    // and we have an email 
    // and we have an org-user type && org-user-type ! equal magic 
    if( user_info['is_anonymous']===false && user_info['ask_for_details'])
      { 
        out= true;
      }
    else{  
      out= false; 
    }
  }

   return out;
};


// input: a string with the doc publisher name
// we assume the source name is a valid source name
// output : weather or not teh source is private
export const isSourcePrivate = (source_name)=> {
  // let allSources = getAllSources();
  let allSources = getAllDisplayFilters();
  let dicAllSources = getDictCustomSources() ;
  let isPrivate = false;
  allSources.forEach(element => {
    if(element.name === source_name){
      // element can be an array url if that is the case we need to get src obj from ur
      // let array_src ; 
      if(element['sources']){
        element['sources'].forEach(url => {
          if(url in dicAllSources){
            if (dicAllSources[url].private){           
              isPrivate = true;
            }
          }
        });
      } 
      if ( typeof element.private !== 'undefined' && element.private ){
        isPrivate = true;
      }
    }
  });
  return isPrivate;
};


// returns the Medwise default sources, 
// if there are no default filters then it returns all filters
export const getMedwiseDefaultSources = () => {
  let user_filters = getUserFilters();
  let default_sources = [];
  let buttons ;
  if(user_filters && user_filters['filter-types']){
    buttons = user_filters['filter-types'];
  }
  if(buttons){
    let btn0Name = buttons[0];
    if(user_filters && user_filters[btn0Name] && user_filters[btn0Name].length>0){
      user_filters[btn0Name].forEach(element => {
        if (element && element['is_default']===true){
            if(element['sources']){
              element['sources'].forEach(src => {
                if(src['encrypted_url']){
                  default_sources.push(src);
                }
              });
            }
        }
      });
    }
    if (default_sources.length<=0){
      if(user_filters && user_filters[btn0Name] ){
        default_sources = user_filters[btn0Name];
      }
    }
  }
  return default_sources;
};

// returns the default filters, 
// if there are no default filters then it returns all sources
// filters != sources list of prefer sources is need it for side filter
export const getDefaultFiltersFromContext = (user_filters) => {
  // let user_info = getUser();
  let buttons ;
  if(user_filters && user_filters['filter-types']){
    buttons = user_filters['filter-types'];
  }
  let default_filters = [];
  if(buttons){
    buttons.forEach(btn => {
      if( btn !== "Web" && user_filters && user_filters[btn] && user_filters[btn].length >0   ){
        user_filters[btn].forEach(element => {
          if (element && element['is_default']=== true && element['name']!=="Web"){
            default_filters.push(element);
          }
        });
      }
    });
  }
  // incase we have no default src all srcs are the default
  if (default_filters.length<=0){
    if(user_filters && user_filters['sources'] && user_filters['sources'].length>0){
      default_filters = user_filters['sources'];
    }
  }
  return default_filters;
};

// returns the default filters, 
// if there are no default filters then it returns all sources
// filters != sources list of prefer sources is need it for side filter
export const getDefaultFilters = () => {
  // let user_info = getUser();
  let user_filters = getUserFilters();
  let buttons ;
  if(user_filters && user_filters['filter-types']){
    buttons = user_filters['filter-types'];
  }
  let default_filters = [];
  if(buttons){

    let btn0Name = buttons[0];
    let btn1Name = buttons[1];
    let btn2Name = buttons[2];
    let btn3Name = buttons[3];
  
    if(user_filters && user_filters[btn0Name] && user_filters[btn0Name].length >0   ){
      user_filters[btn0Name].forEach(element => {
        if (element && element['is_default']=== true && element['name']!=="Web"){
          default_filters.push(element);
        }
      });
    }
    if(user_filters && user_filters[btn1Name] && user_filters[btn1Name].length >0 ){
      user_filters[btn1Name].forEach(element => {
        if (element && element['is_default']===true && element['name']!=="Web" ){
          default_filters.push(element);
        }
      });
    }

    if(user_filters && user_filters[btn2Name] && user_filters[btn2Name].length >0 ){
      user_filters[btn2Name].forEach(element => {
        if (element && element['is_default']===true && element['name']!=="Web"){
          default_filters.push(element);
        }
      });
    }

    if(user_filters && user_filters[btn3Name]  && user_filters[btn3Name].length >0){
      user_filters[btn3Name].forEach(element => {
        if (element && element['is_default']===true && element['name']!=="Web"){
          default_filters.push(element);
        }
      });
    }
  }
  // incase we have no default src all srcs are the default
  if (default_filters.length<=0){
    if(user_filters && user_filters['sources'] && user_filters['sources'].length>0){
      default_filters = user_filters['sources'];
    }
  }
  return default_filters;
};

// input: filters information from get-filter + sources
// output: an array of default filters (not default sources !) 
// if there are no default filters then it returns all sources
export const getDefaultsFromContext = (user_filters) => {
  let default_filters = [];
  let buttons ;
  if(user_filters && user_filters['filter-types']){
    buttons = user_filters['filter-types'];
  }
  if(buttons){
    let localBtnName = buttons[1];
    let MedwiseBtnName = buttons[0];
    let BtnPatientName = buttons[2];
    if(user_filters && user_filters[MedwiseBtnName] && user_filters[MedwiseBtnName].length>0){
      user_filters[MedwiseBtnName].forEach(element => {
        if (element && element['is_default']===true){
          default_filters.push(element);
        }
      });
    }
  }
      // incase we have no default src all srcs are the default
  if (default_filters.length<=0){
    if(user_filters && user_filters['sources'] && user_filters['sources'].length>0){
      default_filters = user_filters['sources'];
    }
  }
  return default_filters;
};


// returns the default sources, 
// if there are no default filters then it returns all sources
// filters != sources list of prefer sources is need it for side filter
export const getDefaultSources = () => {

  let user_filters = getUserFilters();
  let default_filters = [];
  let buttons ;
  if(user_filters && user_filters['filter-types']){
    buttons = user_filters['filter-types'];
  }
  if(buttons){
    let localBtnName = buttons[1];
    let MedwiseBtnName = buttons[0];
    let BtnPatientName = buttons[2];
  
    // as of now only medwise filter are default
    if(user_filters && user_filters[MedwiseBtnName] && user_filters[MedwiseBtnName].length>0){
      user_filters[MedwiseBtnName].forEach(element => {
        if (element && element['is_default']===true){
          if(element['sources']){
            element['sources'].forEach(src => {
              default_filters.push(src);
            });
          }
        }
      });
    }
        // as of now only medwise filter are default
    if(user_filters && user_filters[localBtnName] && user_filters[localBtnName].length>0){
      user_filters[localBtnName].forEach(element => {
        if (element && element['is_default']===true){
          if(element['sources']){
            element['sources'].forEach(src => {
              default_filters.push(src);
            });
          }
        }
      });
    }

    // as of now only medwise filter are default
    if(user_filters && user_filters[BtnPatientName] && user_filters[BtnPatientName].length>0){
      user_filters[BtnPatientName].forEach(element => {
        if (element && element['is_default']===true){
          if(element['sources']){
            element['sources'].forEach(src => {
              default_filters.push(src);
            });
          }
        }
      });
    }
  }
  // incase we have no default src all srcs are the default
  if (default_filters.length<=0){
    if(user_filters && user_filters['sources'] && user_filters['sources'].length>0){
      default_filters = user_filters['sources'];
    }
  }
  return default_filters;
};


// input: filters information from get-filter + sources
// output: an array of default sources (not default filters !) 
// if there are no default sources then it returns an array with all sources
export const getDefaultSourcesFromContext = (user_filters) => {
  let default_filters = [];
  let buttons ;
  if(user_filters && user_filters['filter-types']){
    buttons = user_filters['filter-types'];
  }
  
  if(buttons){
    let localBtnName = buttons[1];
    let MedwiseBtnName = buttons[0];
    let BtnPatientName = buttons[2];
    // as of now only medwise filter are default
    if(user_filters && user_filters[MedwiseBtnName] && user_filters[MedwiseBtnName].length>0){
      user_filters[MedwiseBtnName].forEach(element => {
        if (element && element['is_default']===true){
          if(element['sources']){
            element['sources'].forEach(src => {
              default_filters.push(src);
            });
          }
        }
      });
    }
  }
  // incase we have no default src all srcs are the default
  if (default_filters.length<=0){
    if(user_filters && user_filters['sources'] && user_filters['sources'].length>0){
      default_filters = user_filters['sources'];
    }
  }
  return default_filters;
};

// Return an array of OBJECT from user sources with "default" === true
export const getDefaultSourcesObject = () => {
  let user_info = getUser();
  if (!user_info){
    user_info= getAnonymousUser();
  }
  let default_sources = [];
  if(user_info && user_info['sources'] && user_info['sources'].length>0){
    user_info['sources'].forEach(element => {
      if (element && element['is_default']===true){
        default_sources.push(element);
      }
    });
  } 
  return default_sources;
};

// output: all sources as an array of objects , even if user is anonymous 
export const getAllSources = () => {
  // let user_info = getUser();
  let user_filters = getUserFilters();
  let all_sources = [];
  //from now we display group of src not
  if (user_filters) {
    Object.values(user_filters).forEach((val) => { 
      if (val && val.length > 0) {
        val.forEach(filter => {
          if (filter && filter['sources'] && filter['sources'].length > 0) {
            filter['sources'].forEach(source => {
              all_sources.push(source);
            });
          };
        });
      };
    });

    if (all_sources.length > 0) {
      all_sources = [...new Set(all_sources)];
    };
  };
  return all_sources;
};

// return: an array of group src or an array of source if no display_sources (no group src)
export const getAllDisplayFilters = () =>{
  let user_filters = getUserFilters();
  let all_group_sources = [];
  let buttons ;
  if(user_filters && user_filters['filter-types']){
    buttons = user_filters['filter-types'];
  }
  if(buttons){
    let localBtnName = buttons[1];
    let MedwiseBtnName = buttons[0];
    let BtnPatientName = buttons[2];
    if(user_filters && user_filters[localBtnName] && user_filters[localBtnName].length>0){
      all_group_sources = user_filters[localBtnName];
      }
    if(user_filters && user_filters[MedwiseBtnName] && user_filters[MedwiseBtnName].length>0){
      all_group_sources = all_group_sources.concat(user_filters[MedwiseBtnName]) ;
      }
    }
    if (all_group_sources.length <=0){
      all_group_sources = getAllSources(); 
  }
  return all_group_sources;
};


// returns an array of strings with the names of the deafault sources 
export const getDefaultSourcesAsStrings = () => {
  let default_sources = getDefaultSourcesObject();
  let out = [] ;
  default_sources.forEach(element => {
    out.push(element["name"]);
  });
  return out ;
}

// returns an array of strings with the names of the deafault sources 
export const getDefaultFiltersAsStrings = () => {
  let default_sources = getDefaultFilters();
  let out = [] ;
  default_sources.forEach(element => {
    out.push(element["name"]);
  });
  return out ;
}

// return a dictionary relating src names and encrypted_url 
// output : a dictionary  with : 
//    keys: all names of src and encrypted_url src as key 
//    values: either the encrypted_url associated with the name or the name associated with the encrypted_url
export const getDictCustomSources = () =>{
  let test_sources2 = {};
  const all_sources = getAllSources(); // an arrray of objects 
  all_sources.forEach(element => {
    test_sources2[element.name] = element.encrypted_url;
    test_sources2[element.encrypted_url] = element.name;
  });
  return test_sources2; 
}

// return a dictionary with keys  encrypted url and value a src object
// output : a dictionary  with : 
//    keys: encrypted url as key 
//    values: the corresponding src object
// assuming all sources from user have unique encrypted url 
export const getDictUserSources = () =>{
  let test_sources2 = {};
  const all_sources = getAllSources(); // an arrray of objects 
  all_sources.forEach(element => {
    if(element && element.encrypted_url){
      test_sources2[element.encrypted_url] = element;
    }
    // test_sources2[element.name] = element.encrypted_url;
  });
  return test_sources2; 
}


// return a dictionary relating src names and encrypted_url 
// output : a dictionary  with : 
//    keys: all names of src and encrypted_url src as key 
//    values: either the encrypted_url associated with the name or the name associated with the encrypted_url
export const getDictDisplayFilters = () =>{
  let all_display_sources = {};
  const all_sources = getAllDisplayFilters(); // an array of display filter or an array of src object 
  all_sources.forEach(element => {
    // if element is a src object
    if ( element.encrypted_url  && typeof element.encrypted_url === "string"){
      all_display_sources[element.name] = element.encrypted_url;
      all_display_sources[element.encrypted_url] = element.name;
    }
    else{
    // element is a display filters
      if(element['sources']){
        all_display_sources[element.name] = element['sources'];//  element['sources'] or 
        element['sources'].forEach (url=> {
        all_display_sources[url] = element.name;
        });
      }
    }
  });
  // let source_dict = getDictCustomSources();
  // let merge_dict = Object.assign(source_dict, all_display_sources);
  return all_display_sources; 
};


// returns an array of string with the names of all available
// group sources/display filters (for user with display_setting) or source (for user without display_seetings)
// so it will NOT include encrypted url of form 'private:'  and 'custom:'
// can not be use for ask endpoint
export const getNameOfAllDisplayFilters = () =>{
  let all_filters = getAllDisplayFilters(); // either gets an array of displayFilters or an array of sources 
  let array_names = [];
  all_filters.forEach(element => {
    array_names.push(element['name']);
  });
  return array_names; 
}

// TODO : change all code to use this strings instead of the code strings
// returns a dict associating all name to a decoded name with the names of all available
// display filters (for user with display_setting) or source (for user without display_seetings)
// so it will NOT include encrypted url of form 'private:'  and 'custom:'
// can not be use for ask endpoint
export const getDicNameOfAllDisplayFiltersDecoded= () =>{
  let all_sources = getAllDisplayFilters(); // either gets an array of displayFilters or an array of sources 
  let dic_of_names={} ;
  var parser = new DOMParser();
  all_sources.forEach(element => {
    var decodedPublisherName = parser.parseFromString(element['name'], 'text/html').body.textContent;
    dic_of_names[element['name']]= decodedPublisherName;
    dic_of_names[decodedPublisherName]= element['name'];
  });
  return dic_of_names; 
}


// Filter icon
export const getTypeOfAllDisplayFilters = () =>{
  let all_sources = getAllDisplayFilters();
  let array_types = [];
  all_sources.forEach(element => {
    if (element['sources'].length > 1)
      array_types.push("group");
    else if (element['sources'][0].startsWith("private:"))
      array_types.push("folder");
    else if (element['sources'][0].startsWith("single:"))
      array_types.push("single");
    else
      array_types.push("website");
  });
  return array_types; 
}

// returns an array of string with the names of all available group sources (for user with display_setting) or source (for user without display_seetings)
// so it will NOT include encrypted url of form 'private:'  and 'custom:'
// can not be use for ask endpoint
export const getNameOfAllSources = () =>{
  // let all_sources = getAllSources();
  let all_sources = getAllDisplayFilters(); // either gets an array of displayFilters or an array of sources 
  let array_names = [];
  all_sources.forEach(element => {
    array_names.push(element['name']);
  });
  return array_names; 
}


// checks if source name can be send to ask 
// it will return invalid if source_name is an encrypted_url
// input: an string
// output: boolean
//TODO : this does not make sense anymore check where it was used 
export const isValidSourceName = (source_name) => {
  let is_valid_source = false;
  let all_valid_sources = getNameOfAllSources();
  all_valid_sources.forEach(element => {
      if (element=== source_name){
        is_valid_source = true;
      }
    });
  return is_valid_source; 
};

// checks if a string is associated with an url or an encrypted url
// If source is in user sources it is consider valid
// input: an string can be an encrypted_url or a source name, but can not be the name of an encrypted source
// output: boolean
export const isValidSourceForAsk = (source_string) => {
  let is_valid_source = false;
  let all_valid_sources = getAllSources(); 
  all_valid_sources.forEach(element => {
    if( source_string === element['encrypted_url'] ){
      is_valid_source = true;
    }
  });
  return is_valid_source; 
};

// the function loops through all the user sources 
// if we have no sources it returns and empty string ''
// output: a string representing all the sources available 
// output can be use in ask endpoint
export const getAllSourcesForAsk = () => {
  let allSources = '';
  let all_valid_sources = getAllSources(); 
  all_valid_sources.forEach(element => {
    if(element['encrypted_url'] ){
      allSources = allSources + ','+element['encrypted_url'];
    }
  });
  allSources = allSources.substring(1); 
  return allSources; 
};

//assumes encrypted url are unique 
export const getSourceFromUrl = (url)=>{
  let src ;
  let all_valid_sources = getAllSources(); 
  all_valid_sources.forEach(element => {
    if( url === element['encrypted_url'] ){
      src = element;
    }
  });
  return src; 
};

//assumes Names are unique 
// return undefined if no sources is found 
export const getSourceFromName = (name)=>{
  let src = undefined;
  let all_valid_sources = getAllSources(); 
  all_valid_sources.forEach(element => {
    if( name === element['name'] ){
      src = element;
    }
  });
  return src; 
};

//TODO: check because we already have one and remove
// this return a funny object with encrypted url as name 
export const getAllSourcesObject = () =>{
  let user_info = getUser();
  if (!user_info){
    user_info = getAnonymousUser();
  }
  let all_sources = [];
  if(user_info && user_info['sources'] && user_info['sources'].length>0){
    user_info['sources'].forEach(element => {
      all_sources.push({name:element['encrypted_url'],permanent:element['is_removable'] });
    });
  } 
  return all_sources;

}


//TODO: see how to do it now that we no longer have org-sources 
//return custom-org sources with correct pernament property for an admin user
// does NOT include user custom sources
export const getAllSourcesObjectAdmin = () =>{
  let default_sources3 = []
  default_sources3.splice(0, default_sources3.length);
  let permanent_sources=[]; 
  // to do: use a function to get obj
  const user_info = getUser();
  if(user_info && user_info['org_name']){
    default_sources3= [];
  }
  else {
    default_sources3 = [
      { name: "NICE CKS" ,permanent:true } ,
      { name: "NICE Guidelines",permanent:true },
      { name: "GP notebook",permanent:true },
      { name: "Patient.info",permanent:true},
      { name: "BNF",permanent:true},
      { name: "BNFC",permanent:true},
      { name: "Dermnet NZ",permanent:true},
      { name: "FSRH",permanent:true},
      { name: "Green Book",permanent:true},
      { name: "PCDS",permanent:true}];
  }
  if (user_info){ 
    if(user_info['custom-sources-org']  && user_info['custom-sources-org'].length>0 ){
      user_info['custom-sources-org'].forEach(element => {
        if(element['private']===true){
          permanent_sources.push({name: element.name,permanent:true} );
        }
        else{
          permanent_sources.push({name: element.name,permanent:false} );
        }
      });
    }
  }
  return  permanent_sources.concat(default_sources3); 
}

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem('accessToken') || null;
};

export const getNumOfSearches = () => {
  return localStorage.getItem('userNumSearches') || null;
};

export const getNumOfGroupSearches = () => {
  return localStorage.getItem('userNumGroupSearches') || null;
};

export const getNumOfBookmarks = () => {
  return localStorage.getItem('userNumOfBookmarks') || null;
};

export const getNumOfCpdEntries = () => {
  return localStorage.getItem('userNumOfCpdEntries') || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('userNumOfCpdEntries');
  localStorage.removeItem('userNumOfBookmarks');
  localStorage.removeItem('userNumSearches');
  localStorage.removeItem('userNumGroupSearches');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('user_info');
  localStorage.removeItem('orgInfo');
  localStorage.removeItem('REACT_APP_USERS_VERSION');
  localStorage.removeItem('orgInfo');
};

export const removeUserStats= () => {
  localStorage.removeItem('userNumOfCpdEntries');
  localStorage.removeItem('userNumOfBookmarks');
  localStorage.removeItem('userNumSearches');
  localStorage.removeItem('userNumGroupSearches');
  localStorage.removeItem('accessToken');
};


export const setUserSession = (user) => {
  // var old_user = localStorage.getItem('user_info');

  localStorage.setItem('REACT_APP_USERS_VERSION', process.env.REACT_APP_USERS_VERSION);
  localStorage.setItem('user_info', JSON.stringify(user));

};

export const setUserFilters = (user_filters) => {
  localStorage.setItem('user_filters', JSON.stringify(user_filters));
};

export const setResultSources = (result_sources) => {
  localStorage.setItem('result_sources', JSON.stringify(result_sources));
};

export const setResultSourceNames = (result_source_names) => {
  localStorage.setItem('result_source_names', JSON.stringify(result_source_names));
};

export const setUserSourceIds = (user_source_ids) => {
  localStorage.setItem('user_source_ids', JSON.stringify(user_source_ids));
};

export const setNumOfSearches = (num_searches) => {
  localStorage.setItem('userNumSearches', num_searches);
};

export const setNumOfGroupSearches =(num_of_group_searches) => {
  localStorage.setItem('userNumGroupSearches', num_of_group_searches);
};

export const setNumOfBookmarks = (num_searches) => {
  localStorage.setItem('userNumOfBookmarks', num_searches);
};

export const setNumOfCpdEntries = (num_searches) => {
  localStorage.setItem('userNumOfCpdEntries', num_searches);
};

// set the token
export const setToken = (token) => {
  localStorage.setItem('accessToken', token);
};